"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toCapitalCase = void 0; // eslint-disable-next-line import/prefer-default-export

var toCapitalCase = function toCapitalCase() {
  var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var parts = str.replace(/[^0-9](?=[0-9])/g, '$& ').replace(/(?:[\d])+(?=[a-z])/g, '$& ').replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim().replace(/\s+/g, ' ').toLowerCase().split(' ');
  return parts.map(function (p) {
    return p.charAt(0).toUpperCase() + p.slice(1);
  }).join(' ');
};

exports.toCapitalCase = toCapitalCase;