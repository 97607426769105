import { calculateDistance } from 'aegion_common_utilities/lib/MapProcessingUtil';

import { ALIGNMENT_READING_TABLE_TYPES } from '../actions/util/station-alignment';

import { realignReadingsAndStations } from './utils/alignStations';

const { staticType, editableType } = ALIGNMENT_READING_TABLE_TYPES;

// surveyType is only used for staticType, but keeping for posterity
const defaultState = {
	optionsByStationAlignmentType: {
		[staticType]: {
			surveyType: null,
			readingsSelected: [
				// Will Look like:
				// stationIdEditing, readingIndex, ...readingObject
			]
		},
		[editableType]: {
			surveyType: null,
			readingsSelected: [
				// Will Look like:
				// stationIdEditing, readingIndex, ...readingObject
			]
		}
	},
	realignedComputedReadings: [], // These are "computedReadings" with the new station ids
	mergedSelectedReadings: [] // These are the "readingsSelected" combined for both "types" (static and editable)
};

const _getReadingsSelectedByTableType = (state, tableType) => {
	const { optionsByStationAlignmentType } = state;
	const oldOptions = optionsByStationAlignmentType[tableType];
	const { readingsSelected } = oldOptions;

	return readingsSelected;
};

const _getOrderedReadings = selectedReadings => {
	return selectedReadings.sort((a, b) => a.readingIndex - b.readingIndex);
};

const _removeStationIdEditing = selectedReadings => {
	return selectedReadings.map(sl => {
		const { sourceStationId, ...otherProps } = sl;

		return { ...otherProps };
	});
};

const getGpsOffseet = (reading1, reading2) => {
	if (!reading1 || !reading2) {
		return null;
	}

	const { coordinates: coordinate1 } = reading1;
	const { coordinates: coordinate2 } = reading2;

	if (!coordinate1 || !coordinate2) {
		return null;
	}

	if (!coordinate1.length || !coordinate2.length) {
		return null;
	}

	return calculateDistance(coordinate1, coordinate2).toFixed(1);
};

const _createMergedSelectedReading = (
	staticSelectedReading = {},
	editableSelectedReading = {}
) => {
	const { stationId: sourceStationId } = staticSelectedReading;

	const gpsOffset = getGpsOffseet(
		staticSelectedReading,
		editableSelectedReading
	);

	const mergedSelectedReading = {
		// First copy any readingOptions available so that things work
		...staticSelectedReading,
		...editableSelectedReading,
		// Then make copies of the data so we can access direction later
		source: staticSelectedReading,
		destination: editableSelectedReading,
		gpsOffset
	};

	const newEditableReading = {
		...editableSelectedReading,
		sourceStationId
	};

	return {
		mergedSelectedReading,
		newEditableReading
	};
};

const _getReadingsByTypeWithUnknown = (
	state,
	readings1,
	readings1TableType
) => {
	const { optionsByStationAlignmentType } = state;
	// const reading2TableType = _getOtherType(readings1TableType);
	switch (readings1TableType) {
		case staticType:
			return {
				staticSelectedReadings: readings1,
				editableSelectedReadings:
					optionsByStationAlignmentType[editableType].readingsSelected
			};
		default:
			return {
				editableSelectedReadings: readings1,
				staticSelectedReadings:
					optionsByStationAlignmentType[staticType].readingsSelected
			};
	}
};

const _matchSelectedReadings = (staticReadings, editableReadings) => {
	const cleanedEditableReadings = _removeStationIdEditing(editableReadings);

	const largestCount = Math.max(
		staticReadings.length,
		cleanedEditableReadings.length
	);

	const mergedSelectedReadings = [];
	const newEditableReadings = [];
	for (let i = 0; i < largestCount; i += 1) {
		const staticReading = staticReadings[i];
		const editableReading = cleanedEditableReadings[i];
		const {
			mergedSelectedReading,
			newEditableReading
		} = _createMergedSelectedReading(staticReading, editableReading);
		mergedSelectedReadings.push(mergedSelectedReading);
		if (editableReading) {
			newEditableReadings.push(newEditableReading);
		}
	}

	return { mergedSelectedReadings, newEditableReadings };
};

const _addToSelectedReadings = (
	readingsOriginallySelected,
	newSelectedReading,
	readingIndex
) => {
	const selectedReadingWithReadingIndex = {
		...newSelectedReading,
		readingIndex
	};
	const selectedReadingsWithNewReading = [
		...readingsOriginallySelected,
		selectedReadingWithReadingIndex
	];
	const sortedSelectedReadings = _getOrderedReadings(
		selectedReadingsWithNewReading
	);

	return sortedSelectedReadings;
};

const _isReadingInSelectedReadings = (oldReadingsSelected, readingIndex) => {
	return oldReadingsSelected.some(
		readingObject => readingObject.readingIndex === readingIndex
	);
};

const _removeFromSelectedReadings = (oldReadingsSelected, readingIndex) => {
	return oldReadingsSelected.filter(
		originalReadingObject => originalReadingObject.readingIndex !== readingIndex
	);
};

const getComputedReadings = (convertedReadings, editableReadingsSelected) => {
	return realignReadingsAndStations(
		convertedReadings,
		editableReadingsSelected,
		'computedStationId'
	);
};

const _getOptionsBySurveyType = (
	state,
	newEditableReadings,
	staticSelectedReadings
) => {
	const { optionsByStationAlignmentType } = state;

	const staticOptions = optionsByStationAlignmentType[staticType];
	const editableOptions = optionsByStationAlignmentType[editableType];

	return {
		...optionsByStationAlignmentType,
		[staticType]: {
			...staticOptions,
			readingsSelected: staticSelectedReadings
		},
		[editableType]: {
			...editableOptions,
			readingsSelected: newEditableReadings
		}
	};
};

const selectReadingForStationAlignment = (
	state,
	tableType,
	readingIndex,
	readingObject,
	convertedReadings
) => {
	const oldReadingsSelected = _getReadingsSelectedByTableType(state, tableType);

	let readingsSelected;
	// TODO: Make sure this still works when selected from the "ledger"
	if (_isReadingInSelectedReadings(oldReadingsSelected, readingIndex)) {
		readingsSelected = _removeFromSelectedReadings(
			oldReadingsSelected,
			readingIndex
		);
	} else {
		readingsSelected = _addToSelectedReadings(
			oldReadingsSelected,
			readingObject,
			readingIndex
		);
	}

	// Until here, we did not know for certain which type we were working with
	const {
		editableSelectedReadings,
		staticSelectedReadings
	} = _getReadingsByTypeWithUnknown(state, readingsSelected, tableType);

	const {
		mergedSelectedReadings,
		newEditableReadings
	} = _matchSelectedReadings(staticSelectedReadings, editableSelectedReadings);

	const realignedComputedReadings = getComputedReadings(
		convertedReadings,
		newEditableReadings
	);

	const optionsByStationAlignmentType = _getOptionsBySurveyType(
		state,
		newEditableReadings,
		staticSelectedReadings
	);

	const newState = {
		...state,
		optionsByStationAlignmentType,
		mergedSelectedReadings,
		realignedComputedReadings
	};

	return newState;
};

// When we set a survey, we want to reset all other options for this tableType
const setSelectedSurvey = (state, surveyType, tableType) => {
	const { optionsByStationAlignmentType } = state;

	// If the selected survey has not actually changed, just ignore
	const oldSurveyType = optionsByStationAlignmentType[tableType].surveyType;
	if (oldSurveyType === surveyType) {
		return state;
	}

	const defaultOptions = defaultState.optionsByStationAlignmentType[tableType];

	return {
		...state,
		optionsByStationAlignmentType: {
			...optionsByStationAlignmentType,
			[tableType]: {
				...defaultOptions,
				surveyType
			}
		}
	};
};

const setEditableSurveyType = (state, surveyType) => {
	const { optionsByStationAlignmentType } = state;
	const editableOptions = optionsByStationAlignmentType[editableType];
	return {
		...state,
		optionsByStationAlignmentType: {
			...optionsByStationAlignmentType,
			[editableType]: {
				...editableOptions,
				surveyType
			}
		}
	};
};

const stationAlignmentReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'CISV_STATION_ALIGNMENT_SET_READING_SELECTED': {
			return selectReadingForStationAlignment(
				state,
				action.tableType,
				action.readingIndex,
				action.readingObject,
				action.convertedReadings
			);
		}

		case 'CISV_STATION_ALIGNMENT_SET_DAT_FILE':
			return setSelectedSurvey(state, action.surveyType, action.tableType);

		case 'CISV_STATION_ALIGNMENT_SET_EDITABLE_SURVEY_TYPE':
			return setEditableSurveyType(state, action.surveyType);
		case 'CISV_STATION_ALIGNMENT_RESET':
			return {
				...state,
				...defaultState
			};
		default:
			return state;
	}
};

export default stationAlignmentReducer;
