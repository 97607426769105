import { get } from 'lodash';
import Columns from 'aegion_common_utilities/lib/AVDataUtil/Columns';
import { APPROVAL_MODE } from '../assetView/constants';

const { valueAccessor } = Columns;

export function getItem(source, approval) {
	if (approval === APPROVAL_MODE.unapproved) {
		const { preapproval: { item } = {} } = source || {};
		return item || {};
	}
	const { item } = source;
	return item || {};
}

export const getSource = (asset, approval) => {
	const { properties, _source } = asset;
	const source = properties || _source;
	if (approval === APPROVAL_MODE.unapproved) {
		const { preapproval, ...remainingSource } = source;

		return { ...remainingSource, ...preapproval };
	}
	return source;
};

export const getIsItemUnapproved = item => {
	if (item._source === undefined) {
		return item.preapproval !== undefined;
	}
	return item._source.preapproval !== undefined;
};

function cleanSource(src, history = false) {
	if (src === undefined) {
		return src;
	}
	if (history) {
		return src.replace('.preapproval', '');
	}
	return src;
}

function isHistory(originalRow) {
	const { type } = originalRow;
	if (type !== 'current') {
		return true;
	}

	return false;
}

function getSourceName(original, sources) {
	const history = isHistory(original);
	let src = sources.find(
		s => get(original, cleanSource(s, history)) !== undefined
	);
	if (src === undefined) {
		const [firstSrc] = sources;
		if (firstSrc === undefined) {
			return undefined;
		}
		src = firstSrc;
	}
	if (history) {
		return src.replace('.preapproval', '');
	}
	return src;
}

export function getKey(original, sources) {
	return getSourceName(original, sources);
}

export function getInitialValueValue(initialValue, original, src) {
	if (original._source.preapproval === undefined) {
		return initialValue;
	}
	return valueAccessor(original, src);
}
