// TODO Add all survey specific utilities here

const SurveyUtils = {
	validateStationNumber(stationNumberString) {
		const stationNumber = +stationNumberString;

		if (Number.isNaN(stationNumber) || stationNumberString === '') {
			throw new TypeError(`Invalid Station Number: ${stationNumberString}`);
		}

		return stationNumber;
	},
	getCISSurvey(surveyType) {
		if (['DCVG' || 'ACCA'].includes(surveyType)) {
			return false;
		}
		return true;
	},
	// For some reason the backend is appending a "length" property to each of the MasterLSTs - filter these out
	removeLengthPropertyFromMasterLST(originalMasterLST) {
		const newMasterLST = {};
		const fileNames = Object.keys(originalMasterLST);
		fileNames.forEach(fileName => {
			if (fileName === 'length') {
				return;
			}

			newMasterLST[fileName] = originalMasterLST[fileName];
		});

		return newMasterLST;
	},
	addFileNameToMasterLSTFiles(originalMasterLST) {
		const newMasterLST = {};
		const fileNames = Object.keys(originalMasterLST);
		fileNames.forEach(fileName => {
			newMasterLST[fileName] = {
				...originalMasterLST[fileName],
				fileName
			};
		});

		return newMasterLST;
	},
	cleanMasterLST(originalMasterLST) {
		const masterLSTWithoutLengthProperty = SurveyUtils.removeLengthPropertyFromMasterLST(
			originalMasterLST
		);

		const masterLSTWithCorrectFilenames = SurveyUtils.addFileNameToMasterLSTFiles(
			masterLSTWithoutLengthProperty
		);

		return masterLSTWithCorrectFilenames;
	},
	extendDataWithSurveyType(datFile, globalData) {
		return {
			...datFile,
			surveyType: datFile.surveyType || globalData.surveyType || 'unknown'
		};
	},
	extendSurveyFilesWithSurveyType(originalGlobalData) {
		const newMasterLST = {};
		const oldMasterLST = originalGlobalData.MasterLST;
		Object.keys(oldMasterLST).forEach(fileName => {
			const datFile = oldMasterLST[fileName];

			newMasterLST[fileName] = SurveyUtils.extendDataWithSurveyType(
				datFile,
				originalGlobalData
			);
		});

		const globalData = {
			...originalGlobalData,
			MasterLST: newMasterLST
		};

		return globalData;
	},
	extendAllDataWithSurveyType(originalGlobalData, originalData) {
		const data = originalData.map(datFile =>
			this.extendDataWithSurveyType(datFile, originalGlobalData)
		);

		return {
			data,
			globalData: this.extendSurveyFilesWithSurveyType(originalGlobalData)
		};
	}
};

export default SurveyUtils;
