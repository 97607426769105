/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { createSelector } from 'reselect';
import { ROOT_SLICE } from './constants';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectUsers = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { users } = slice;
	return users;
};

export const selectIsLoadingUsers = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoadingUsers } = slice;
	return isLoadingUsers;
};

export const selectIsLoadedUsers = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoadedUsers } = slice;
	return isLoadedUsers;
};

export const selectHasLoadingErrorUsers = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { hasLoadingErrorUsers } = slice;
	return hasLoadingErrorUsers;
};

export const selectUsersTotalCount = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { usersTotalCount } = slice;
	return usersTotalCount;
};

export const selectIsLoadedUsersCount = createSelector(
	selectUsers,
	users => (users || []).length
);

export const selectSelectedUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedUser } = slice;
	return selectedUser;
};

export const selectSelectedUserChangesClone = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedUserChangesClone } = slice;
	return selectedUserChangesClone;
};

export const selectIsSavingUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isSavingUser } = slice;
	return isSavingUser;
};

export const selectHasChanges = createSelector(
	selectSelectedUser,
	selectSelectedUserChangesClone,
	(selectedUser, selectedClone) => {
		const user = _.cloneDeep(selectedUser) || {};
		user.hasGroupHashMap = user.hasGroupHashMap || {};

		const clone = _.cloneDeep(selectedClone) || {};
		clone.hasGroupHashMap = clone.hasGroupHashMap || {};

		return user && clone && !_.isEqual(user, clone);
	}
);

export const selectNewUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { newUser } = slice;
	return newUser;
};

export const selectIsSavingNewUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isSavingNewUser } = slice;
	return isSavingNewUser;
};

export const selectErrorMessageSaveNewUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { errorMessageSaveNewUser } = slice;
	return errorMessageSaveNewUser;
};

export const selectIsSavingEditedUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isSavingEditedUser } = slice;
	return isSavingEditedUser;
};

export const selectErrorMessageSaveEditedUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { errorMessageSaveEditedUser } = slice;
	return errorMessageSaveEditedUser;
};
