"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "LocationType", {
  enumerable: true,
  get: function get() {
    return _LocationType["default"];
  }
});
exports.calculateAzimuthFromGpsReadings = calculateAzimuthFromGpsReadings;
Object.defineProperty(exports, "calculateDestination", {
  enumerable: true,
  get: function get() {
    return _calculateDestination["default"];
  }
});
Object.defineProperty(exports, "calculateDirection", {
  enumerable: true,
  get: function get() {
    return _calculateDirection["default"];
  }
});
Object.defineProperty(exports, "calculateDistance", {
  enumerable: true,
  get: function get() {
    return _calculateDistance.calculateDistance;
  }
});
exports.calculateInterpolatedPosition = calculateInterpolatedPosition;
exports.computeDistance = computeDistance;
exports.isGps = exports.interpolationHash = exports.indexesOfGpsReadings = exports.getSmoothedGpsReadingsFromSegmentGap = exports.getPreviousGpsReading = exports.getNextGpsReading = exports.config = void 0;
exports.isPointSignificantlyMoved = isPointSignificantlyMoved;
exports.readingCoordinates = readingCoordinates;
exports.sendWindowResizeEvent = void 0;
exports.switchToInterpolated = switchToInterpolated;
exports.updateGpsReadingsWithSegmentSmoothedPoints = exports.updateGPSMeasurements = void 0;

var _MiscUtils = _interopRequireDefault(require("./MiscUtils"));

var _memoize = _interopRequireDefault(require("./memoize"));

var _LocationType = _interopRequireDefault(require("./ReadingsUtil/LocationType"));

var _NoGpsReadingWithIndexError = _interopRequireDefault(require("./ErrorClasses/NoGpsReadingWithIndexError"));

var _calculateDistance = require("./MapProcessing/calculateDistance");

var _calculateDestination = _interopRequireDefault(require("./MapProcessing/calculateDestination"));

var _calculateDirection = _interopRequireDefault(require("./MapProcessing/calculateDirection"));

var config = _interopRequireWildcard(require("./MapProcessing/config"));

exports.config = config;
var _excluded = ["nextDistance", "prevDistance", "nextAzimuth", "prevAzimuth"];

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
} // Number of feet GPS smoothing should move to be considered significant


var GPS_COORDINATE_SIGNIFICANTLY_MOVED_THRESHOLD = 1; // We want this to be global so that we can cache gps interpolations

var interpolationHash = {};
exports.interpolationHash = interpolationHash;
var memoizedGpsReadingsIndexes = new _memoize["default"]();

function flattenArgObject(argObject) {
  var response = Object.keys(argObject).map(function (key) {
    return "".concat(key, "-").concat(argObject[key]);
  });
  return response.join('|');
}

function flattenArg(arg) {
  switch (_typeof(arg)) {
    case 'object':
      return flattenArgObject(arg);

    default:
      return arg;
  }
}

function createHashKeyFromArguments(args) {
  var flattenedArgs = args.map(flattenArg);
  return Array.prototype.join.call(flattenedArgs);
}

function switchToInterpolated(gpsreadings) {
  return _toConsumableArray(gpsreadings.map(function (_ref) {
    var nextDistance = _ref.nextDistance,
        prevDistance = _ref.prevDistance,
        nextAzimuth = _ref.nextAzimuth,
        prevAzimuth = _ref.prevAzimuth,
        reading = _objectWithoutProperties(_ref, _excluded);

    return _objectSpread(_objectSpread({}, reading), {}, {
      locationType: _LocationType["default"].INTERPOLATED
    });
  }));
}

var isGps = function isGps(reading) {
  return reading.locationType === _LocationType["default"].GPS;
}; // TODO: This doesn't really find the next GPSReading if the startIndex is a GPSReading


exports.isGps = isGps;

var getNextGpsReading = function getNextGpsReading(readings, startIndex) {
  var length = readings.length;

  for (var i = startIndex; i < length; i += 1) {
    var gpsReading = readings[i];

    if (isGps(gpsReading)) {
      return {
        i: i,
        gpsReading: gpsReading
      };
    }
  }

  throw new _NoGpsReadingWithIndexError["default"]('Could not find the next gps reading');
};

exports.getNextGpsReading = getNextGpsReading;

var getPreviousGpsReading = function getPreviousGpsReading(gpsReadings, currentIndex) {
  for (var i = currentIndex - 1; i >= 0; i -= 1) {
    var gpsReading = gpsReadings[i];

    if (isGps(gpsReading)) {
      return {
        i: i,
        gpsReading: gpsReading
      };
    }
  }

  throw new _NoGpsReadingWithIndexError["default"]('Could not find the previous gps reading');
};

exports.getPreviousGpsReading = getPreviousGpsReading;

function calculateInterpolatedPosition() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  var lon = args[0],
      lat = args[1],
      nextDistance = args[2],
      nextAzimuth = args[3],
      readingsIndex = args[4],
      readingsArrLength = args[5],
      _args$ = args[6],
      options = _args$ === void 0 ? {} : _args$;
  var _options$algorithm = options.algorithm,
      algorithm = _options$algorithm === void 0 ? config.GEO_ALGORITHMS.GEOD : _options$algorithm,
      _options$roundCoords = options.roundCoords,
      roundCoords = _options$roundCoords === void 0 ? 8 : _options$roundCoords; // TODO: I think the benefits of caching no longer outway the costs

  var interpolationHashKey = createHashKeyFromArguments(args);
  var interpolationFromHashKey = interpolationHash[interpolationHashKey];

  if (interpolationFromHashKey) {
    return interpolationFromHashKey;
  }

  var interpolatedCoordinates = (0, _calculateDestination["default"])([lon, lat], nextDistance * (readingsIndex / readingsArrLength), nextAzimuth, {
    roundCoords: roundCoords,
    algorithm: algorithm
  });
  interpolationHash[interpolationHashKey] = interpolatedCoordinates;
  return interpolatedCoordinates;
}

function readingCoordinates(readingsIndex, gpsReading, readingsArrLength) {
  var coords = [];

  if (readingsIndex === 0 || !gpsReading.nextDistance) {
    coords = gpsReading.coordinates;
  } else {
    coords = calculateInterpolatedPosition(gpsReading.coordinates[1], gpsReading.coordinates[0], gpsReading.nextDistance, gpsReading.nextAzimuth, readingsIndex, readingsArrLength);
  }

  return coords;
}

function isPointSignificantlyMoved(reading) {
  var distanceMoved = reading.distanceMoved,
      isMoved = reading.is_moved;
  var distanceSignificantlyMoved = isMoved && distanceMoved >= GPS_COORDINATE_SIGNIFICANTLY_MOVED_THRESHOLD;
  return distanceSignificantlyMoved;
}

function calculateAzimuthFromGpsReadings(fromReading, toReading) {
  var fromCoords = fromReading.coordinates;
  var toCoords = toReading.coordinates;
  return (0, _calculateDirection["default"])(fromCoords, toCoords);
}

var _getMemoizedGpsReadingsIndexes = function _getMemoizedGpsReadingsIndexes(flattenedGpsReadings) {
  return memoizedGpsReadingsIndexes.get([flattenedGpsReadings]);
};

var _setMemoizedGpsReadingsIndexes = function _setMemoizedGpsReadingsIndexes(flattenedGpsReadings, indexes) {
  memoizedGpsReadingsIndexes.set([flattenedGpsReadings], indexes);
};

var _indexesOfGpsReadingsAll = function _indexesOfGpsReadingsAll(flattenedGpsReadings) {
  var cachedIndexes = _getMemoizedGpsReadingsIndexes(flattenedGpsReadings);

  if (cachedIndexes) {
    return cachedIndexes;
  }

  var indexes = [];

  for (var i = 0; i < flattenedGpsReadings.length; i += 1) {
    var gpsReading = flattenedGpsReadings[i];
    var locationType = gpsReading.locationType;

    if (locationType === _LocationType["default"].GPS) {
      indexes.push(i);
    }
  }

  _setMemoizedGpsReadingsIndexes(flattenedGpsReadings, indexes);

  return indexes;
};

var _normalize = function _normalize(value) {
  return _MiscUtils["default"].roundToDigitPlace(value, 3);
};

var _getDistance = function _getDistance(gps1, gps2) {
  return (0, _calculateDistance.calculateDistance)(gps1.coordinates, gps2.coordinates);
};

var _getAzimuth = function _getAzimuth(gps1, gps2) {
  return calculateAzimuthFromGpsReadings(gps1, gps2);
};

var _setGpsReadingMeasurements = function _setGpsReadingMeasurements(flattenedGpsReadings, gpsIndexes) {
  var createCopy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  gpsIndexes.forEach(function (indexAfter, i) {
    if (i === 0) {
      return;
    }

    var nextGpsReading = flattenedGpsReadings[indexAfter];

    if (createCopy) {
      nextGpsReading = _objectSpread({}, nextGpsReading);
      flattenedGpsReadings[indexAfter] = nextGpsReading;
    }

    var indexBefore = gpsIndexes[i - 1];
    var prevGpsReading = flattenedGpsReadings[indexBefore];

    var distance = _normalize(_getDistance(prevGpsReading, nextGpsReading));

    var nextAzimuth = _normalize(_getAzimuth(prevGpsReading, nextGpsReading));

    var prevAzimuth = _normalize(_getAzimuth(nextGpsReading, prevGpsReading));

    var readingsCount = indexAfter - indexBefore;

    var gap = _normalize(distance / readingsCount);

    nextGpsReading.prevDistance = distance;
    prevGpsReading.nextDistance = distance;
    nextGpsReading.prevGap = gap;
    prevGpsReading.nextGap = gap;
    prevGpsReading.nextAzimuth = nextAzimuth;
    nextGpsReading.prevAzimuth = prevAzimuth;
  });
};

var _setInterpolatedReadingMeasurements = function _setInterpolatedReadingMeasurements(readings, gpsIndexes) {
  var isUpdateInterpolatedCoords = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var createCopy = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  gpsIndexes.forEach(function (previousIndex, i) {
    var nextIndex = gpsIndexes[i + 1];

    if (!nextIndex) {
      return;
    } // There are no interpolated points between these - nothing to do


    if (previousIndex + 1 === nextIndex) {
      return;
    }

    var arrayLength = nextIndex - previousIndex;
    var previousGpsReading = readings[previousIndex];
    var nextGpsReading = readings[nextIndex]; // For interpolated readings, we need to get azimuth, calculate gap, and ignore distance (distance is only relevent for GPS readings)

    for (var n = previousIndex + 1; n < nextIndex; n += 1) {
      var thisInterpolatedReading = readings[n];

      if (createCopy) {
        thisInterpolatedReading = _objectSpread({}, thisInterpolatedReading);
        readings[n] = thisInterpolatedReading;
      }

      thisInterpolatedReading.nextAzimuth = previousGpsReading.nextAzimuth;
      thisInterpolatedReading.prevAzimuth = nextGpsReading.prevAzimuth;

      var gap = _normalize(previousGpsReading.nextDistance / arrayLength);

      thisInterpolatedReading.nextGap = gap;
      thisInterpolatedReading.prevGap = gap;

      if (isUpdateInterpolatedCoords) {
        var coordinates = previousGpsReading.coordinates,
            nextDistance = previousGpsReading.nextDistance,
            nextAzimuth = previousGpsReading.nextAzimuth;
        var coords = calculateInterpolatedPosition(coordinates[1], coordinates[0], nextDistance, nextAzimuth, n - previousIndex, nextIndex - previousIndex);
        thisInterpolatedReading.coordinates = coords;
      }
    }
  });
};

function findGPSReadingIndex(gpsIndexes, index) {
  return gpsIndexes.findIndex(function (i) {
    return i === index;
  });
}

function getGPSReadingForReading(gpsIndexes, flattenedGpsReadings, index) {
  var positionOfGpsReadingIndex = findGPSReadingIndex(gpsIndexes, index);

  if (positionOfGpsReadingIndex !== -1) {
    return positionOfGpsReadingIndex;
  }

  var _getPreviousGpsReadin = getPreviousGpsReading(flattenedGpsReadings, index),
      i = _getPreviousGpsReadin.i;

  return findGPSReadingIndex(gpsIndexes, i);
}

var indexesOfGpsReadings = function indexesOfGpsReadings(flattenedGpsReadings, index) {
  if (index >= flattenedGpsReadings.length) {
    return null;
  }

  var gpsIndexes = _indexesOfGpsReadingsAll(flattenedGpsReadings);

  if (index === undefined) {
    return gpsIndexes;
  }

  var positionOfGpsReadingIndex = getGPSReadingForReading(gpsIndexes, flattenedGpsReadings, index);

  if (positionOfGpsReadingIndex === -1) {
    throw new Error("Could not find reading with location type=GPS and index=".concat(index));
  }

  if (index === 0) {
    return gpsIndexes.slice(positionOfGpsReadingIndex, // previous reading
    positionOfGpsReadingIndex + 2 // next reading
    );
  } // Will return 3 readings in total


  return gpsIndexes.slice(positionOfGpsReadingIndex - 1, // previous reading
  positionOfGpsReadingIndex + 2 // next reading
  );
}; // Note: This mutates data directly - be sure to make a copy beforehand
// Note: "index" is an optional parameter, if index=undefined, then the entire readings set will be updated
// Note: - the arg "index" represents the "current index" - that index and the one after (next gps reading) will be updated
// Note: "createCopy" is an optional parameter, if true then will create a copy of the gps reading before updating...the copy will replace the original reading


exports.indexesOfGpsReadings = indexesOfGpsReadings;

var updateGPSMeasurements = function updateGPSMeasurements(flattenedGpsReadings) {
  var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      index = _ref2.index,
      _ref2$isUpdateInterpo = _ref2.isUpdateInterpolatedCoords,
      isUpdateInterpolatedCoords = _ref2$isUpdateInterpo === void 0 ? false : _ref2$isUpdateInterpo,
      _ref2$createCopy = _ref2.createCopy,
      createCopy = _ref2$createCopy === void 0 ? false : _ref2$createCopy;

  var gpsIndexes = indexesOfGpsReadings(flattenedGpsReadings, index);

  if (!gpsIndexes) {
    return;
  } // The gps reading at the current index and and the *next* gps reading will be updated - note that the previous gps reading will not be updated


  _setGpsReadingMeasurements(flattenedGpsReadings, gpsIndexes, createCopy); // All readings between the previous gps readings, and next gps readings will be updated


  _setInterpolatedReadingMeasurements(flattenedGpsReadings, gpsIndexes, isUpdateInterpolatedCoords, createCopy);
};

exports.updateGPSMeasurements = updateGPSMeasurements;
var TARGET_READING = {
  PREVIOUS: -1,
  SAME: 0,
  NEXT: 1
};

function getTargetCoordinates(currentReading, targetReading, target, targetPreChanged) {
  switch (true) {
    case target === TARGET_READING.PREVIOUS && targetPreChanged:
      return currentReading.prev_coordinate_before_move || targetReading.coordinate_before_moved || targetReading.coordinates;

    case target === TARGET_READING.SAME && targetPreChanged:
      return targetReading.coordinate_before_moved || targetReading.coordinates;

    case target === TARGET_READING.NEXT && targetPreChanged:
      return currentReading.next_coordinate_before_move || targetReading.coordinate_before_moved || targetReading.coordinates;

    case !targetPreChanged:
      return targetReading.coordinates;

    default:
      return targetReading.coordinate_before_moved || targetReading.coordinates;
  }
}

function computeDistance(currentIndex, targetIndex, readings, // Try to pull the pre-moved coordinates first
currentPreChanged, targetPreChanged) {
  var _ref3 = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {},
      nextGpsReadingRecord = _ref3.nextGpsReadingRecord;

  var currentReading = readings[currentIndex];

  if (currentReading === undefined) {
    return 0;
  }

  var target = currentIndex - targetIndex; // let targetGpsReading;

  var actualTargetIndex;
  var targetReadings;

  if (readings[targetIndex]) {
    // targetGpsReading = previousGpsReading;
    actualTargetIndex = targetIndex;
    targetReadings = readings;
  } else if (nextGpsReadingRecord) {
    var targetGpsReading = nextGpsReadingRecord;
    targetReadings = targetGpsReading.readings;
    actualTargetIndex = 0; // the first item
  } else {
    return 0;
  }

  var targetReading = targetReadings[actualTargetIndex]; // To get pre-moved coords

  var coordsOtherPoint = getTargetCoordinates(currentReading, targetReading, target, targetPreChanged); // To get actual/current coords (post-moved)

  var currentCoords;

  if (currentPreChanged) {
    currentCoords = currentReading.coordinate_before_moved;
  } else {
    currentCoords = currentReading.coordinates;
  }

  if (coordsOtherPoint === undefined || currentCoords === undefined) {
    return 0;
  }

  var feetFromOtherPoint = (0, _calculateDistance.calculateDistance)([currentCoords[1], currentCoords[0]], [coordsOtherPoint[1], coordsOtherPoint[0]], {
    reverse: false
  });
  return feetFromOtherPoint;
}

var getSmoothedGpsReadingsFromSegmentGap = function getSmoothedGpsReadingsFromSegmentGap(gpsreadings, smoothedGpsReadingsIndices) {
  // We sort by descending so that we can start at the top
  var smoothedGpsReadingsIndicesSorted = smoothedGpsReadingsIndices.slice() // Make copy so sort does not mutate array
  .sort(function (a, b) {
    return b - a;
  }); // Shallow copy

  var newGpsReadings = _toConsumableArray(gpsreadings); // smoothedGpsReadingsIndicesSorted starts with the greatest number


  smoothedGpsReadingsIndicesSorted.forEach(function (index) {
    // This shouldn't be possible, but we don't want it to happen anyways
    if (index === 0) {
      return;
    }

    newGpsReadings[index] = _objectSpread(_objectSpread({}, newGpsReadings[index]), {}, {
      locationType: _LocationType["default"].INTERPOLATED
    });
  });
  smoothedGpsReadingsIndicesSorted.forEach(function (index) {
    if (index === 0) {
      return;
    }

    var _getPreviousGpsReadin2 = getPreviousGpsReading(newGpsReadings, index),
        previousGpsReadingIndex = _getPreviousGpsReadin2.i;

    var _getNextGpsReading = getNextGpsReading(newGpsReadings, index),
        nextGpsReading = _getNextGpsReading.gpsReading,
        nextGpsReadingIndex = _getNextGpsReading.i;

    updateGPSMeasurements(newGpsReadings, {
      previousGpsReadingIndex: previousGpsReadingIndex,
      isUpdateInterpolatedCoords: true,
      createCopy: true
    });

    if (isGps(nextGpsReading || {})) {
      // A shallow copy so that we can update the gps Meansurements
      var nextGpsReadingCopy = _objectSpread({}, nextGpsReading);

      newGpsReadings[nextGpsReadingIndex] = nextGpsReadingCopy;
      updateGPSMeasurements(newGpsReadings, {
        index: nextGpsReadingIndex,
        isUpdateInterpolatedCoords: true,
        // We need to create copies of readings as we are not certain when they were updated
        createCopy: true
      });
    }
  });
  return newGpsReadings;
}; // returns an immutable copy of globalData/data with the smoothed points within


exports.getSmoothedGpsReadingsFromSegmentGap = getSmoothedGpsReadingsFromSegmentGap;

var updateGpsReadingsWithSegmentSmoothedPoints = function updateGpsReadingsWithSegmentSmoothedPoints(smoothedPoints, globalData, data) {
  var datFilesByName = globalData.MasterLST;
  var smoothedPointsByDatFileName = {};
  smoothedPoints.forEach(function (smoothedPoint) {
    var datFile = smoothedPoint.datFile;
    var fileName = datFile.fileName;

    if (!smoothedPointsByDatFileName[fileName]) {
      smoothedPointsByDatFileName[fileName] = [];
    }

    smoothedPointsByDatFileName[fileName].push(smoothedPoint);
  });
  var smoothedDataByDatFileName = {};
  Object.keys(smoothedPointsByDatFileName).forEach(function (datFileName) {
    var originalDatFile = datFilesByName[datFileName];
    var gpsreadings = originalDatFile.gpsreadings;
    var smoothedPointsForThisDatFile = smoothedPointsByDatFileName[datFileName];
    var gpsReadingsSmoothedIndices = smoothedPointsForThisDatFile.map(function (smoothedPoint) {
      return smoothedPoint.gpsReadingIndex;
    });
    smoothedDataByDatFileName[datFileName] = getSmoothedGpsReadingsFromSegmentGap(gpsreadings, gpsReadingsSmoothedIndices);
  });
  var newData = data.map(function (datFile) {
    var fileName = datFile.fileName;

    if (!smoothedDataByDatFileName[fileName]) {
      return datFile;
    }

    return _objectSpread(_objectSpread({}, datFile), {}, {
      isEdited: true,
      gpsreadings: smoothedDataByDatFileName[fileName]
    });
  });
  var newMasterLST = {};
  newData.forEach(function (datFile) {
    var fileName = datFile.fileName;
    newMasterLST[fileName] = datFile;
  });

  var newGlobalData = _objectSpread(_objectSpread({}, globalData), {}, {
    MasterLST: newMasterLST
  });

  return {
    newData: newData,
    newGlobalData: newGlobalData
  };
}; // Send an event which will cause the map and charts to automatically resize


exports.updateGpsReadingsWithSegmentSmoothedPoints = updateGpsReadingsWithSegmentSmoothedPoints;

var sendWindowResizeEvent = function sendWindowResizeEvent() {
  try {
    // eslint-disable-next-line no-undef
    window.dispatchEvent(new Event('resize'));
  } catch (_unused) {
    // eslint-disable-next-line no-console
    console.error('Could not reset window size');
  }
};

exports.sendWindowResizeEvent = sendWindowResizeEvent;