export const createNewCustomerObject = () => ({
	name: '',
	summary: '',
	learnmore: '',
	icon: '',
	path: '',
	order: 0,
	external: false
});

export default { createNewCustomerObject };
