/* eslint-disable no-param-reassign */
import createBaseSurveyMeta from './_BASE';
import colors from '../../Colors';
import {
	computeUuid,
	computeFormattedStationId,
	computeDistance
} from '../util/readings.parse.computeProps';
import { createGetXYMinMax } from '../util/yMinYMax';

const INVERT_Y_AXIS = true;

const createComputeYMinYMax = rks => {
	const props = rks.map(rk => rk.value);
	const getXYMinMax = createGetXYMinMax(props);
	return (survey, reading) => {
		const {
			xMin: currentXMin,
			xMax: currentXMax,
			yMin: currentYMin,
			yMax: currentYMax
		} = survey;
		const { xMin, xMax, yMin, yMax } = getXYMinMax(
			reading,
			currentXMin,
			currentXMax,
			currentYMin,
			currentYMax
		);
		survey.xMin = xMin;
		survey.xMax = xMax;
		survey.yMin = Math.min(yMin, 0);
		survey.yMax = yMax;
	};
};

// eslint-disable-next-line no-unused-vars
export default _survey => {
	// CONSTANTS

	const gapThreshold = 200;

	// READING KEYS

	const readingKeys = [
		// {
		// 	label: 'DEPTH',
		// 	value: 'doc',
		// 	seriesType: 'bar',
		// 	isPercentage: true,
		// 	color: colors.getColor()
		// }
		{
			label: 'Top of Pipe',
			value: 'topDep',
			seriesType: 'bar',
			isPercentage: true,
			color: colors.getColor()
		},
		{
			label: 'Center of Pipe',
			value: 'copDep',
			seriesType: 'bar',
			isPercentage: true,
			color: colors.getColor()
		},
		{
			label: 'Electric Depth',
			value: 'elcDep',
			seriesType: 'bar',
			isPercentage: true,
			color: colors.getColor()
		},
		{
			label: 'Probe of Pipe',
			value: 'prbDep',
			seriesType: 'bar',
			isPercentage: true,
			color: colors.getColor()
		}
	];
	const readingKeyProps = readingKeys.map(k => k.value);
	const computeYMinYMax = createComputeYMinYMax(readingKeys);

	// SKIP READING

	const isSkipReading = reading =>
		reading && readingKeys.every(rk => reading[rk.value] === 0);

	// HAS GAP

	const hasGap = (currReading, prevReading) => {
		return !!prevReading && currReading.id - prevReading.id > gapThreshold;
	};

	// INTERPOLATE

	const interpolate = (
		// eslint-disable-next-line no-unused-vars
		_prevDepolReading,
		// eslint-disable-next-line no-unused-vars
		_nextDepolReading,
		// eslint-disable-next-line no-unused-vars
		_alignToReading
	) => {};

	const computeHilo = r => {
		r.low = r.low || 0;
	};

	// COMPUTE PROPS - to be used by readings.parse
	const computeProps = (survey, _prevReading, currReading, currIdx) => {
		computeUuid(currReading, currIdx);
		computeFormattedStationId(currReading);
		computeHilo(currReading);
		computeYMinYMax(survey, currReading);
	};

	const computePropsForDerivedSurvey = (
		survey,
		prevReading,
		currReading,
		// eslint-disable-next-line no-unused-vars
		_currIdx
	) => {
		computeDistance(prevReading, currReading);
		computeYMinYMax(survey, currReading);
	};

	// SIMPLIFIED READINGS - creates a smaller data set to be used for rendering polyline on map
	// most survey types will not have simplified readings
	// eslint-disable-next-line no-unused-vars
	const createSimplifiedReadings = ({ readings, subtype }) => undefined;

	// MAIN

	const meta = {
		...createBaseSurveyMeta(_survey),
		isChartInversed: INVERT_Y_AXIS,
		readingKeys,
		readingKeyProps,
		fkey: 'topDep',
		// fkey: 'topDep',
		columns: [
			{
				label: 'ID',
				value: 'formattedStationId',
				checked: true,
				width: 64,
				required: true
			},
			// { label: 'DEPTH', value: 'doc', checked: true, width: 54 },
			{ label: 'TOP OF PIPE', value: 'topDep', checked: true, width: 54 },
			{ label: 'CENTER OF PIPE', value: 'copDep', checked: true, width: 54 },
			{ label: 'ELECTRIC DEPTH', value: 'elcDep', checked: true, width: 54 },
			{ label: 'PROBE DEPTH', value: 'prbDep', checked: true, width: 54 },
			{ label: 'DISTANCE', value: 'dst', checked: true, width: 80 },
			{ label: 'LAT', value: 'lat', checked: true, width: 65 },
			{ label: 'LNG', value: 'lng', checked: true, width: 70 },
			{
				label: 'COMMENTS',
				value: 'cmt',
				checked: true,
				width: 255,
				type: 'text'
			}
		],
		isSkipReading,
		hasGap,
		interpolate,
		computeProps,
		computePropsForDerivedSurvey,
		computeYMinYMax,
		createSimplifiedReadings
	};

	return meta;
};
