/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import scanlineMapUtils from '../../mapUtils';
import { arrayToMap } from '../../utils/array';
import { selectPrimarySurveys } from './alignedReadings';

// ********************
// SURVEYS
// ********************
export const selectSurveys = stateApp => {
	const { surveys } = stateApp;
	return surveys;
};

export const selectMapSurveys = createSelector(selectSurveys, surveys =>
	arrayToMap(surveys)
);

// *******************
// Surveys By ChartId
// *******************
export const selectSurveysByChartId = (stateApp, chartId) => {
	const surveys = selectSurveys(stateApp);
	return surveys.filter(s => s.CHART_ID === chartId);
};

// ********************
// CIS SURVEYS
// ********************

export const selectCisSurveys = createSelector(selectSurveys, surveys => {
	surveys.filter(survey => scanlineMapUtils.isCis(survey.id));
});

export const selectMapCisSurveys = createSelector(selectCisSurveys, surveys =>
	arrayToMap(surveys)
);

// ********************
// SELECTED SURVEYS
// ********************

export const selectSelectedSurveys = createSelector(selectSurveys, surveys => {
	return surveys.filter(survey => !!survey.checked);
});

export const selectMapSelectedSurveys = createSelector(
	selectSelectedSurveys,
	surveys => arrayToMap(surveys)
);

// ********************
// SELECTED CIS SURVEYS
// ********************

export const selectSelectedCisSurveys = createSelector(
	selectCisSurveys,
	surveys => {
		surveys.filter(survey => !!survey.checked);
	}
);

export const selectMapSelectedCisSurveys = createSelector(
	selectSelectedCisSurveys,
	surveys => arrayToMap(surveys)
);

// ********************
// MISC
// ********************

export const selectSurveysForAnalytics = createSelector(
	selectSurveys,
	surveys => {
		return (surveys || []).filter(s => !s.isDerived);
	}
);

export const selectSurvey = (stateApp, surveyId) => {
	return stateApp.surveys.find(s => s.id === surveyId);
};

export const selectDerivedSurvey = (stateApp, survey, derivedSubtype) => {
	const derivedSurveyId = scanlineMapUtils.deriveSurveyId(
		survey,
		derivedSubtype
	);
	return selectSurvey(stateApp, derivedSurveyId);
};

export const selectPrimarySurveyFromDerived = (stateApp, derivedSurvey) => {
	if (!derivedSurvey.isDerived) {
		return derivedSurvey;
	}

	const primarySurveys = [...selectPrimarySurveys(stateApp)].sort((a, b) => {
		const ORDER_REF = ['ON_OFF', 'ON-OFF', 'ON', 'DEPOL', 'NATIVE'];

		const { survey_subtype: a_survey_subtype } = a;
		const { survey_subtype: b_survey_subtype } = b;

		const a_order_index = ORDER_REF.indexOf(a_survey_subtype);
		const b_order_index = ORDER_REF.indexOf(b_survey_subtype);

		const a_order = a_order_index !== -1 ? a_order_index : 9999;
		const b_order = b_order_index !== -1 ? b_order_index : 9999;

		switch (true) {
			case a_order > b_order:
				return 1;
			case a_order < b_order:
				return -1;
			default:
				return 0;
		}
	});

	const primarySurvey =
		primarySurveys.find(
			s => !!derivedSurvey.jobId && s.jobId === derivedSurvey.jobId
		) || undefined;

	return primarySurvey;
};

export const selectUnsupportedSurveysToLoad = (stateApp = {}) => {
	const { unsupportedSurveysToLoad } = stateApp;
	return unsupportedSurveysToLoad;
};

export const selectDefaultSurvey = (stateApp = {}) => {
	const { defaultSurvey } = stateApp;
	return defaultSurvey;
};

export const selectDefaultSurveyJobId = (stateApp = {}) => {
	const { defaultSurveyJobId } = stateApp;
	return defaultSurveyJobId;
};
