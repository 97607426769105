/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
import { createActions } from 'redux-actions';
import fieldlineUtils from '../scanlineUtils';
import { addChartIsLoading, removeChartIsLoading } from './charts';
import { doDeleteProjectData } from './myProjects';
import { setReadingsDownloadMessage } from './dataDownloadMessages';
import SurveyMeta from '../utils/SurveyMeta';

import { getAppSettings } from '../../util/user/index';
import { getChartId } from '../utils/chart';
import { computeDefaultSurveyProps } from '../reducers/util/surveys';
import { setDefaultSurveyProps } from './surveys';
import { thunkRecomputeAllDataXYMinsMaxes } from '../reducers/slices/StoreXYMinMax/actions';

const {
	fetchUnsupportedReadings,
	normalizeUnsupportedReadings,
	unsupportedReadingsLoaded,
	receiveUnsupportedReadings,
	requestUnsupportedReadings,
	setUnsupportedReadingDownloadsComplete,
	setUnsupportedReadingsInitialized,
	setReadingsFiltered
} = createActions({
	FETCH_UNSUPPORTED_READINGS: (jobId, type, subtype, index) =>
		doFetchUnsupportedReadings(jobId, type, subtype, index),
	NORMALIZE_UNSUPPORTED_READINGS: (survey, copyDepol) => ({
		survey,
		copyDepol
	}),
	UNSUPPORTED_READINGS_LOADED: survey => ({ survey }),
	RECEIVE_UNSUPPORTED_READINGS: (json, survey, s3Key, batchIdx, batchCount) => {
		return {
			data: json.data,
			header: json.header,
			meta: {
				filteredCount: json.filteredCount,
				originalCount: json.originalCount
			},
			survey,
			s3Key,
			batchIdx,
			batchCount
		};
	},
	REQUEST_UNSUPPORTED_READINGS: survey => ({ survey }),
	SET_UNSUPPORTED_READING_DOWNLOADS_COMPLETE: () => ({}),
	SET_UNSUPPORTED_READINGS_INITIALIZED: () => ({}),
	SET_READINGS_FILTERED: (survey, datasets) => ({
		survey,
		datasets
	})
});

const computeNewSurveyExternalIdsToLoad = (
	unsupportedSurveyS3KeysToLoad,
	unsupportedSurveyS3KeysLoading
) => {
	const toLoad = Object.keys(unsupportedSurveyS3KeysToLoad);
	const loading = Object.keys(unsupportedSurveyS3KeysLoading);

	return toLoad.filter(
		externalId1 =>
			loading.findIndex(externalId2 => externalId1 === externalId2) < 0
	);
};

const doFetchMissingUnsupportedReadings = (
	lineId,
	projectId,
	callback = () => {}
) => (dispatch, getState) => {
	const { app } = getState();
	const {
		unsupportedSurveyS3KeysToLoad = {},
		unsupportedSurveyS3KeysLoading = [],
		defaultSurvey
	} = app;

	const newSurveyExternalIdsToLoad = computeNewSurveyExternalIdsToLoad(
		unsupportedSurveyS3KeysToLoad,
		unsupportedSurveyS3KeysLoading
	);

	const readingsCount = newSurveyExternalIdsToLoad.length;
	let readingsLoadedCount = 0;
	const onReadingsLoadComplete = err => {
		if (err) {
			callback(err);
		} else {
			readingsLoadedCount += 1;
			if (readingsLoadedCount === readingsCount) {
				newSurveyExternalIdsToLoad.forEach(surveyExternalId => {
					dispatch(setReadingsDownloadMessage(surveyExternalId, undefined));
				});
				if (!defaultSurvey) {
					const [
						newDefaultSurvey,
						newDefaultSurveyJobId
					] = computeDefaultSurveyProps(defaultSurvey, getState().app.surveys);
					if (newDefaultSurvey) {
						dispatch(
							setDefaultSurveyProps(newDefaultSurvey, newDefaultSurveyJobId)
						);
					}
				}
				callback(undefined, 'all readings loaded');
			}
		}
	};

	if (readingsCount) {
		newSurveyExternalIdsToLoad.forEach((surveyExternalId, idx) => {
			dispatch(
				setReadingsDownloadMessage(
					surveyExternalId,
					`downloading readings for uploaded survey #${idx + 1}`
				)
			);
			dispatch(
				doFetchUnsupportedReadings(
					lineId,
					projectId,
					surveyExternalId,
					onReadingsLoadComplete
				)
			);
		});
	} else {
		callback(undefined, 'no readings to load');
	}
};

// eslint-disable-next-line no-unused-vars
const doFetchUnsupportedReadings = (
	lineId,
	projectId,
	surveyExternalId,
	callback = () => {}
) => (dispatch, getState) => {
	// console.log('>>> FETCH unsupported batch');
	const { app } = getState();
	const { unsupportedSurveysToLoad: surveys } = app;
	const survey = surveys.find(s => s.survey_guid === surveyExternalId);

	const { unsupportedSurveyS3KeysToLoad } = app;
	const thisSurveyS3KeysToLoad =
		unsupportedSurveyS3KeysToLoad[surveyExternalId] || [];
	const batchCount = thisSurveyS3KeysToLoad.length;

	let batchCompleteCount = 0;
	const onBatchesComplete = err => {
		if (err) {
			// console.log('>>> err unsupported batch');
			callback(err);
		} else {
			batchCompleteCount += 1;
			// console.log('>>> unsupported batch loaded');
			if (batchCompleteCount === batchCount) {
				// console.log('>>> ALL UNSUPPORTED BATCHES LOADED');
				callback(undefined, 'all reading batches loaded');
			}
		}
	};
	if (batchCount) {
		dispatch(requestUnsupportedReadings(survey));
		thisSurveyS3KeysToLoad.forEach((s3Key, batchIdx) => {
			dispatch(addChartIsLoading(getChartId(survey)));
			dispatch(
				fetchUnsupportedReadingsBatch(
					lineId,
					projectId,
					survey,
					s3Key,
					batchIdx,
					batchCount,
					onBatchesComplete
				)
			);
		});
	} else {
		callback(undefined, 'no reading batches to load');
	}
};

const isUnknownSurveyILI = (survey, getState) => {
	const { survey_guid } = survey;
	const { surveys } = getState().app || {};
	return (
		surveys.filter(
			s =>
				s.survey_guid === survey_guid &&
				['ili-exmtlloss', 'ili-inmtlloss', 'ili-swl', 'ili-dent'].indexOf(
					(s.subtype || '').toLowerCase()
				) > -1
		).length > 0
	);
};

const doCreateILIFilteredDatasets = survey => (dispatch, getState) => {
	const surveyMeta = SurveyMeta.getSurveyMeta({
		...survey,
		survey_subtype: 'ILI-exMtlLoss',
		subtype: 'ILI-exMtlLoss'
	});
	const { computeFilteredDataSets } = surveyMeta;
	const { survey_guid } = survey;
	const { surveys: appSurveys, readings: appReadings } = getState().app;

	const surveys = appSurveys.filter(s => s.survey_guid === survey_guid);
	surveys.forEach(s => {
		const readings = appReadings[s.id] || [];
		const datasets = computeFilteredDataSets(s, readings);
		dispatch(setReadingsFiltered(s, datasets));
	});
};

// @todo: Should probably move to its own config file
const shouldDeleteIli = () => {
	const appSettings = getAppSettings('SCANLINE');

	return appSettings.autoRemoveIliData;
};

const fetchUnsupportedReadingsBatch = (
	lineId,
	projectId,
	survey,
	s3Key,
	batchIdx,
	batchCount,
	callback = () => {}
) => (dispatch, getState) => {
	const {
		survey_type: type,
		survey_subtype: subtype,
		survey_guid: surveyGuid
	} = survey;
	return fieldlineUtils.getUnsupportedReadings(
		lineId,
		projectId,
		surveyGuid,
		type,
		subtype,
		s3Key,
		(err, res) => {
			if (err) {
				console.error(err);
			} else {
				dispatch(
					receiveUnsupportedReadings(res, survey, s3Key, batchIdx, batchCount)
				);
				if (!isDownloadingThisSurveyReadings(getState().app, survey)) {
					dispatch(unsupportedReadingsLoaded(survey));

					if (isUnknownSurveyILI(survey, getState)) {
						if (shouldDeleteIli()) {
							dispatch(
								doDeleteProjectData(survey.survey_guid, { silent: true })
							);
						}
						dispatch(doCreateILIFilteredDatasets(survey));
					}

					if (!isDownloadingReadings(getState().app)) {
						dispatch(setUnsupportedReadingDownloadsComplete());
						dispatch(setUnsupportedReadingsInitialized());
					}
					dispatch(thunkRecomputeAllDataXYMinsMaxes());

					setTimeout(() => {
						dispatch(removeChartIsLoading(getChartId(survey)));
					}, 8);
				}
				callback(undefined, 'single batch complete');
			}
		}
	);
};

const isDownloadingThisSurveyReadings = (state, survey) => {
	const { unsupportedSurveysToLoad } = state;
	return (unsupportedSurveysToLoad || []).some(s => s.id === survey.id);
};

const isDownloadingReadings = state => {
	const { loadingReadings } = state;
	return (
		Object.keys(loadingReadings).filter(prop => !!loadingReadings[prop])
			.length > 0
	);
};

export {
	fetchUnsupportedReadings,
	normalizeUnsupportedReadings,
	unsupportedReadingsLoaded,
	receiveUnsupportedReadings,
	requestUnsupportedReadings,
	setUnsupportedReadingDownloadsComplete,
	setUnsupportedReadingsInitialized,
	setReadingsFiltered,
	doFetchMissingUnsupportedReadings
};
