/* eslint-disable no-confusing-arrow */
import _ from 'lodash';
import { createSortDate } from 'aegion_common_utilities/lib/Sort';

const SURVEY_ON_OFF = 'ON_OFF,ON-OFF';
const SURVEY_DEPOL = 'DEPOL';
const SURVEY_ON = 'ON';
const SURVEY_NATIVE = 'NATIVE';
const SURVEY_AC = 'AC';
const SURVEY_DOC = 'DOC';
const SURVEY_SPATIAL = 'SPATIAL';
const SURVEY_REL = 'REL';
const SURVEY_DCVG = 'DCVG';
const SURVEY_ACVG = 'ACVG';
const SURVEY_ACCA = 'ACCA';
const SURVEY_ILI = 'ILI';
const SURVEY_ILI_EX = 'ILI-exMtlLoss';
const SURVEY_ILI_IN = 'ILI-inMtlLoss';
const SURVEY_ILI_SWL = 'ILI-swl';
const SURVEY_ILI_DENT = 'ILI-dent';
const SURVEY_UNKNOWN = 'UNKNOWN';

const SURVEY_PRIORITY = [
	SURVEY_ON_OFF,
	SURVEY_ON,
	SURVEY_DEPOL,
	SURVEY_NATIVE,
	SURVEY_ILI,
	SURVEY_ILI_EX,
	SURVEY_ILI_IN,
	SURVEY_ILI_SWL,
	SURVEY_ILI_DENT,
	SURVEY_ACVG,
	SURVEY_DCVG,
	SURVEY_ACCA,
	SURVEY_AC,
	SURVEY_DOC,
	SURVEY_REL,
	SURVEY_SPATIAL,
	SURVEY_UNKNOWN
];

const EMPTY_ARRAY = [];

const getSurveyTypeOrder = type => {
	const order = SURVEY_PRIORITY.findIndex(k => {
		const val = type ? type.toUpperCase() : type;
		const keys = k.toUpperCase().split(',');
		return keys.indexOf(val) > -1;
	});
	return order > -1 ? order : SURVEY_PRIORITY.length;
};

const compareSurveyType = (surveyA = {}, surveyB = {}) => {
	const aType = typeof surveyA === 'string' ? surveyA : surveyA.survey_subtype;
	const bType = typeof surveyB === 'string' ? surveyB : surveyB.survey_subtype;
	const A = getSurveyTypeOrder(aType);
	const B = getSurveyTypeOrder(bType);
	const aIndex = A === -1 ? 9999 : A;
	const bIndex = B === -1 ? 9999 : B;
	return aIndex - bIndex;
};

const sortSurveysByType = (surveys = []) => {
	return _.sortBy(surveys, compareSurveyType);
};

const helperSortSurveysByJobId = _.memoize(surveys => {
	const selectJobId = s => s.jobId;
	const toUniqueIds = (acc, jobId) =>
		acc.indexOf(jobId) > -1 ? acc : [...acc, jobId];
	const toIndexMap = (acc, jobId, idx) => ({ ...acc, [jobId]: idx });
	const jobIdIndexes = surveys
		.map(selectJobId)
		.reduce(toUniqueIds, [])
		.reduce(toIndexMap, {});
	const compareSurveysByJobId = (a, b) =>
		jobIdIndexes[a.jobId] - jobIdIndexes[b.jobId];
	return surveys.sort(compareSurveysByJobId);
});

const sortSurveysByJobId = paramSurveys => {
	const surveys =
		paramSurveys && paramSurveys.length > 0 ? paramSurveys : EMPTY_ARRAY;
	return helperSortSurveysByJobId(surveys);
};

const selectSurveyDate = (s = {}) => {
	if (s.end_date) {
		return s.end_date;
	}
	return s.start_date || undefined;
};

const sortSuveysByDateASC = surveys => {
	const sort = createSortDate('ASC', {
		getValue: selectSurveyDate
	});

	return [...surveys].sort(sort);
};

const sortSuveysByDateDESC = surveys => {
	const sort = createSortDate('DESC', {
		getValue: selectSurveyDate
	});

	return [...surveys].sort(sort);
};

const isDerivedSurvey = s => !!s.isDerived;

const isCisvSurvey = s => !!(!isDerivedSurvey(s) && !s.is_external_survey);

const isUsnupportedExternalSurvey = s => {
	const isUnknown = (s.survey_subtype || '').toUpperCase() === SURVEY_UNKNOWN;
	return !!(
		!isDerivedSurvey(s) &&
		!isCisvSurvey(s) &&
		(isUnknown || s.parsedFrom)
	);
};

const isSupportedExternalSurvey = s =>
	!!(
		!isDerivedSurvey(s) &&
		!isCisvSurvey(s) &&
		!isUsnupportedExternalSurvey(s)
	);

const TESTS = {
	compareSurveyType,
	sortSuveysByDateASC,
	sortSuveysByDateDESC,
	sortSurveysByType,
	sortSurveysByJobId,
	isDerivedSurvey,
	isCisvSurvey,
	isSupportedExternalSurvey,
	isUsnupportedExternalSurvey
};

export {
	sortSuveysByDateASC,
	sortSuveysByDateDESC,
	sortSurveysByType,
	sortSurveysByJobId,
	isDerivedSurvey,
	isCisvSurvey,
	isSupportedExternalSurvey,
	isUsnupportedExternalSurvey,
	TESTS
};

export default {
	sortSuveysByDateASC,
	sortSuveysByDateDESC,
	sortSurveysByType,
	sortSurveysByJobId,
	isDerivedSurvey,
	isCisvSurvey,
	isSupportedExternalSurvey,
	isUsnupportedExternalSurvey,
	TESTS
};
