/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import SurveyMeta from '../../utils/SurveyMeta';

const isDefined = v =>
	v !== undefined && v !== null && !(typeof v === 'string' && v.trim() === '');

export const createInterpolatedDataSources = (srcSurvey, appState) => {
	const surveyMeta = SurveyMeta.getSurveyMeta(srcSurvey);
	const { interpolate } = surveyMeta;

	const refSurvey = selectInterpolateReferenceSurvey(srcSurvey, appState);
	const refReadings = selectReadings(refSurvey, appState);

	const srcReadings = selectReadings(srcSurvey, appState);
	const srcReadingsIndexMap = selectReadingsIndexMap(srcSurvey, appState);

	const [
		newReadings,
		newReadingsWithChartGaps,
		newReadingsMap,
		newReadingsIndexMap,
		newReadingsWithChartGapsIndexMap,
		newSeries,
		newSeriesIndexMap
	] = interpolate(srcReadings, srcReadingsIndexMap, refReadings);
	return [
		newReadings,
		newReadingsWithChartGaps,
		newReadingsMap,
		newReadingsIndexMap,
		newReadingsWithChartGapsIndexMap,
		newSeries,
		newSeriesIndexMap
	];
};

export const createNormalizedInterpolatedDataSources = (
	srcSurvey,
	appState
) => {
	const surveyMeta = SurveyMeta.getSurveyMeta(srcSurvey);
	const { interpolate } = surveyMeta;

	const refSurvey = selectInterpolateReferenceSurvey(srcSurvey, appState);
	const refReadings = selectReadings(refSurvey, appState);

	const srcReadings = selectReadingsNormalized(srcSurvey, appState);
	const srcReadingsIndexMap = selectReadingsNormalizedIndexMap(
		srcSurvey,
		appState
	);

	const [
		newReadings,
		newReadingsWithChartGaps,
		newReadingsMap,
		newReadingsIndexMap,
		newReadingsWithChartGapsIndexMap,
		newSeries,
		newSeriesIndexMap
	] = interpolate(srcReadings, srcReadingsIndexMap, refReadings);
	return [
		newReadings,
		newReadingsWithChartGaps,
		newReadingsMap,
		newReadingsIndexMap,
		newReadingsWithChartGapsIndexMap,
		newSeries,
		newSeriesIndexMap
	];
};

// copy interpolated values from depol to on-off so they can be displayed in table
// @todo - needs to be generalized to work with any
export const addInterpolatedSourceValuesToReferenceDataset = (
	srcReadings,
	referenceReadingsMap
) => {
	srcReadings.forEach(srcReading => {
		const refReading = referenceReadingsMap[srcReading.id];
		if (
			refReading &&
			srcReading &&
			isDefined(srcReading.on) &&
			!isDefined(refReading.iDepolVal)
		) {
			if (srcReading.isInterpolated) {
				refReading.isInterpolated = srcReading.isInterpolated;
			}
			refReading.iDepolVal = srcReading.on;
		}
	});
};

// copy interpolated values from depol to on-off so they can be displayed in table
// @todo - needs to be generalized to work with any
export const addNormalizedInterpolatedSourceValuesToReferenceDataset = (
	srcReadings,
	referenceReadingsMap
) => {
	srcReadings.forEach(srcReading => {
		const refReading = referenceReadingsMap[srcReading.id];
		if (
			refReading &&
			srcReading &&
			isDefined(srcReading.on) &&
			!isDefined(refReading.niDepolVal)
		) {
			if (srcReading.isNormalized) {
				refReading.isNiDepolValNormalized = true;
			}
			if (srcReading.isInterpolated) {
				refReading.isNiDepolValInterpolated = true;
			}
			refReading.niDepolVal = srcReading.on;
		}
	});
};
export const isInterpolateReferenceSurvey = survey => {
	const meta = SurveyMeta.getSurveyMeta(survey);

	return !!meta.isInterpolateReference;
};
export const isNormalizeReferenceSurvey = survey => {
	const meta = SurveyMeta.getSurveyMeta(survey);

	return !!meta.isNormalizeReference;
};

export const canSurveyBeInterpolated = survey => {
	const meta = SurveyMeta.getSurveyMeta(survey);

	return !!meta.canUseInterpolate;
};
export const canSurveyBeNormalized = survey => {
	const meta = SurveyMeta.getSurveyMeta(survey);

	return !!meta.canUseNormalize;
};

export const selectReadings = (survey, appState) => {
	const { readings = {} } = appState;
	return readings[survey.id];
};

export const selectReadingsIndexMap = (survey, appState) => {
	const { readingsIndexMap = {} } = appState;
	return readingsIndexMap[survey.id];
};

export const selectReadingsNormalized = (survey, appState) => {
	const { readingsNormalized = {} } = appState;
	return readingsNormalized[survey.id];
};

export const selectReadingsNormalizedIndexMap = (survey, appState) => {
	const { readingsNormalizedIndexMap = {} } = appState;
	return readingsNormalizedIndexMap[survey.id];
};

export const selectReadingsInterpolated = (survey, appState) => {
	const { interpReadings = {} } = appState;
	return interpReadings[survey.id];
};

export const selectReadingsInterpolatedIndexMap = (survey, appState) => {
	const { interpReadingsIndexMap = {} } = appState;
	return interpReadingsIndexMap[survey.id];
};

export const selectReadingsInterpolatedNormalized = (survey, appState) => {
	const { interpReadingsNormalized = {} } = appState;
	return interpReadingsNormalized[survey.id];
};

export const selectReadingsInterpolatedNormalizedIndexMap = (
	survey,
	appState
) => {
	const { interpReadingsNormalizedIndexMap = {} } = appState;
	return interpReadingsNormalizedIndexMap[survey.id];
};

export const selectInterpolateReferenceSurvey = (srcSurvey, appState) => {
	const { surveys } = appState;
	const { jobId, survey_type } = srcSurvey;
	const referenceSurvey = surveys.find(s => {
		return (
			jobId === s.jobId &&
			survey_type === s.survey_type &&
			isInterpolateReferenceSurvey(s)
		);
	});
	return referenceSurvey || undefined;
};

export const selectInterpolateTargetSurveys = (refSurvey, appState) => {
	const { surveys } = appState;
	const { jobId, survey_type } = refSurvey;
	const targetSurveys = surveys.filter(s => {
		return (
			jobId === s.jobId &&
			survey_type === s.survey_type &&
			canSurveyBeInterpolated(s)
		);
	});
	return targetSurveys;
};

export const selectNormalizeReferenceSurvey = (srcSurvey, appState) => {
	const { surveys } = appState;
	const { jobId, survey_type } = srcSurvey;
	const referenceSurvey = surveys.find(s => {
		return (
			jobId === s.jobId &&
			survey_type === s.survey_type &&
			isNormalizeReferenceSurvey(s)
		);
	});
	return referenceSurvey || undefined;
};

export const selectNormalizeTargetSurveys = (refSurvey, appState) => {
	const { surveys } = appState;
	const { jobId, survey_type } = refSurvey;
	const targetSurveys = surveys.filter(s => {
		return (
			jobId === s.jobId &&
			survey_type === s.survey_type &&
			canSurveyBeNormalized(s)
		);
	});
	return targetSurveys;
};

/**
 * SERIES
 */

export const selectSeries = (survey, prop, appState) => {
	const { series = {} } = appState;
	return (series[survey.id] || {})[prop];
};

export const selectSeriesIndexMap = (survey, prop, appState) => {
	const { seriesIndexMap = {} } = appState;
	return (seriesIndexMap[survey.id] || {})[prop];
};

export const selectSeriesNormalized = (survey, prop, appState) => {
	const { seriesNormalized = {} } = appState;
	return (seriesNormalized[survey.id] || {})[prop];
};

export const selectSeriesNormalizedIndexMap = (survey, prop, appState) => {
	const { seriesNormalizedIndexMap = {} } = appState;
	return (seriesNormalizedIndexMap[survey.id] || {})[prop];
};

export const selectInterpSeries = (survey, prop, appState) => {
	const { interpSeries = {} } = appState;
	return (interpSeries[survey.id] || {})[prop];
};

export const selectInterpSeriesIndexMap = (survey, prop, appState) => {
	const { interpSeriesIndexMap = {} } = appState;
	return (interpSeriesIndexMap[survey.id] || {})[prop];
};

export const selectInterpSeriesNormalized = (survey, prop, appState) => {
	const { interpSeriesNormalized = {} } = appState;
	return (interpSeriesNormalized[survey.id] || {})[prop];
};

export const selectInterpSeriesNormalizedIndexMap = (
	survey,
	prop,
	appState
) => {
	const { interpSeriesNormalizedIndexMap = {} } = appState;
	return (interpSeriesNormalizedIndexMap[survey.id] || {})[prop];
};
