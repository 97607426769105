/* eslint-disable import/no-cycle */

import { calculateDistance } from 'aegion_common_utilities/lib/MapProcessingUtil';

import { getIndexFromDat } from '../../../../components/leaflet/utils/ToolbarUtils/index';
import { getDatFile } from '../../../../utils/DatFiles';

import * as smoothToolUtil from './smoothTool';

import { getCommentsWithCoordinate } from '../comments';

export const _hasEnoughPointsToFindComments = smoothedPoints => {
	return smoothedPoints.length >= 2;
};

export const getDistances = (currentSmoothPoints, dats) => {
	if (!_hasEnoughPointsToFindComments(currentSmoothPoints)) {
		return currentSmoothPoints;
	}

	const { dat: { fileName } = {} } = currentSmoothPoints[0].point;

	const dat = getDatFile(dats, fileName);

	// TODO: Should move to its own named function
	return currentSmoothPoints.map((smoothPoint, idx) => {
		if (idx === 0) {
			return smoothPoint;
		}
		const lastSmoothPoint = currentSmoothPoints[idx - 1];

		const firstIdx = getIndexFromDat(dat, currentSmoothPoints[idx - 1]);
		const lastIdx = getIndexFromDat(dat, smoothPoint);
		const readings = dat.gpsreadings.slice(firstIdx, lastIdx);

		const distance = calculateDistance(
			smoothPoint.center,
			lastSmoothPoint.center
		);

		const gapDistance = (readings.length !== 0
			? distance / readings.length
			: 0
		).toFixed(2);

		return { ...smoothPoint, distance: gapDistance };
	});
};

export const getCommentPoints = (currentSmoothPoints, dats) => {
	if (!_hasEnoughPointsToFindComments(currentSmoothPoints)) {
		return null;
	}

	const { dat: { fileName } = {} } = currentSmoothPoints[0].point;
	const dat = getDatFile(dats, fileName);
	const commentPoints = currentSmoothPoints.reduce((acc, sPnt, idx) => {
		if (idx > 0) {
			const firstIdx = getIndexFromDat(dat, currentSmoothPoints[idx - 1]);
			const lastIdx = getIndexFromDat(dat, sPnt);

			// We are getting flattened array of readings here
			const readings = dat.gpsreadings.slice(firstIdx, lastIdx);
			const comments = getCommentsWithCoordinate(
				readings,
				dat,
				firstIdx,
				lastIdx
			);
			acc.push(...comments);
		}
		return acc;
	}, []);

	return commentPoints;
};

export const getSmoothLine = currentSmoothPoints => {
	if (!_hasEnoughPointsToFindComments(currentSmoothPoints)) {
		return null;
	}

	return currentSmoothPoints.map(({ center }) => center);
};

export { smoothToolUtil };
