/* eslint-disable import/no-cycle */
import { SET_VIEWPORT } from '../Map/actionTypes';

import { setPin } from '../Map/actions';
import assetViewActions from '../../actions/actions';

import HistoryListener from './util/historyListener';
import { APP_VIEW } from '../../components/utils/AppView';
import { APPROVAL_MODE, ASSET_PANELS } from '../../constants';
import { getSource } from '../../../util/item';
import { SET_SEARCHES } from '../Search/actionTypes';
import { HIDE_EXPORT_PANEL, SHOW_EXPORT_PANEL } from '../Exports/actionTypes';

const historyListener = new HistoryListener();

const forwardMapItem = store => next => action => {
	if (action.type === assetViewActions.MAP_ITEM) {
		const state = store.getState();
		const {
			assetViewMain: { isApprovalMode },
			assetview: { map }
		} = state;
		const zoom = map.zoom > 15 ? map.zoom : 15;
		const { item } = action;
		const source = getSource(item, isApprovalMode);
		const location = source ? source.location : item.location;
		if (location) {
			const forwardState = () =>
				store.dispatch(
					setPin({
						zoom,
						center: [location[1], location[0]],
						zoomTo: action.zoomTo
					})
				);
			setTimeout(forwardState, 1);
		}
	}

	return next(action);
};

// SET_VIEWPORT
const forwardSetViewport = store => next => action => {
	if (action.type === SET_VIEWPORT) {
		const forwardState = () =>
			store.dispatch(assetViewActions.setViewport(action.payload));
		setTimeout(forwardState, 1);
	}

	return next(action);
};

const pathUpdated = params => {
	const { groupName, assetType, assetId } = params;
	let forwardState = () => {};
	if (groupName) {
		if (assetType && assetId) {
			forwardState = () =>
				historyListener.dispatch(
					assetViewActions.getAssetCardAsset(
						decodeURIComponent(groupName),
						decodeURIComponent(assetId),
						decodeURIComponent(assetType)
					)
				);
		}
	} else {
		forwardState = () => historyListener.dispatch(assetViewActions.clearCard());
	}

	setTimeout(forwardState, 1);
};

const setPathLocation = (state, action) => {
	const {
		assetview: {
			map: { zoom, lat, lon },
			search: { searches },
			exports: { showExportPanel }
		},
		assetViewMain
	} = state;
	const {
		pageItem,
		appView = APP_VIEW.map,
		selected,
		isApprovalMode = APPROVAL_MODE.unapproved,
		assetPanel = ASSET_PANELS.details
	} = assetViewMain;
	const { history } = historyListener;

	const path = `/AssetView/${zoom}/${lat}/${lon}/`;
	let historyAction = history.push;
	let search = `tab=${appView}&s=${JSON.stringify(selected)}${
		isApprovalMode !== APPROVAL_MODE.approved ? `&ap=${isApprovalMode}` : ''
	}`;

	if (pageItem && pageItem.cardKey) {
		search += `&g=${encodeURIComponent(pageItem.cardKey)}`;
	}
	if (pageItem && pageItem.assetType) {
		search += `&t=${encodeURIComponent(
			pageItem.assetType
		)}&a=${encodeURIComponent(
			pageItem.id || pageItem._id
		)}&p=${encodeURIComponent(assetPanel)}`;
	}
	if (searches.length > 0) {
		search = `${search}&search=${searches
			.map(({ field, value }) => encodeURIComponent(`${field}:${value}`))
			.join('|')}`;
	}
	if (showExportPanel) {
		search += `&e=t`;
	}

	switch (action.type) {
		case assetViewActions.SET_ASSETCARD_ASSET:
			break;
		case SET_VIEWPORT:
			historyAction = history.replace;
			break;
		default:
			break;
	}
	historyListener.unlisten();
	historyAction({
		pathname: path,
		search
	});
	historyListener.listen().then(pathUpdated);
};

const setLocationPathMiddleware = store => next => action => {
	if (!historyListener.dispatch) {
		historyListener.dispatch = store.dispatch;
	}
	switch (action.type) {
		case SHOW_EXPORT_PANEL:
		case HIDE_EXPORT_PANEL:
		case SET_VIEWPORT:
		case assetViewActions.SET_ASSETCARD_ASSET:
		case assetViewActions.SET_APP_VIEW:
		case assetViewActions.CLEAR_CARD:
		case assetViewActions.SET_SELECTION:
		case assetViewActions.SET_APPROVAL_MODE:
		case assetViewActions.SET_ASSET_PANEL:
		case SET_SEARCHES:
			setTimeout(() => setPathLocation(store.getState(), action), 1);
			break;
		default:
			break;
	}
	return next(action);
};

historyListener.listen().then(pathUpdated);

export default [forwardSetViewport, setLocationPathMiddleware, forwardMapItem];
