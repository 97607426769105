/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';

const {
	setDataXYMinMax,
	bulkSetDataXYMinMax,
	setZoomXYMinMax,
	setInputXYMinMax
} = createActions(
	{
		SET_DATA_X_Y_MIN_MAX: (key, { xMin, xMax, yMin, yMax }) => ({
			key,
			xMin,
			xMax,
			yMin,
			yMax
		}),
		BULK_SET_DATA_X_Y_MIN_MAX: NewStoreKeyValues => ({ NewStoreKeyValues }),
		SET_ZOOM_X_Y_MIN_MAX: (key, { xMin, xMax, yMin, yMax }) => ({
			key,
			xMin,
			xMax,
			yMin,
			yMax
		}),
		SET_INPUT_X_Y_MIN_MAX: (key, { xMin, xMax, yMin, yMax }) => ({
			key,
			xMin,
			xMax,
			yMin,
			yMax
		})
	},
	{ prefix: 'StoreXYMinMax' }
);

export {
	setDataXYMinMax,
	bulkSetDataXYMinMax,
	setZoomXYMinMax,
	setInputXYMinMax
};

export default {
	setDataXYMinMax,
	bulkSetDataXYMinMax,
	setZoomXYMinMax,
	setInputXYMinMax
};
