/* eslint-disable import/prefer-default-export */
export const createXYMinMaxObject = (
	{ xMin, xMax, yMin, yMax },
	originalXYMinMaxObject = {}
) => {
	return {
		xMin: xMin !== undefined ? xMin : originalXYMinMaxObject.xMin,
		xMax: xMax !== undefined ? xMax : originalXYMinMaxObject.xMax,
		yMin: yMin !== undefined ? yMin : originalXYMinMaxObject.yMin,
		yMax: yMax !== undefined ? yMax : originalXYMinMaxObject.yMax
	};
};
