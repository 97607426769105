/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createActions } from 'redux-actions';
import fieldlineUtils from '../scanlineUtils';
import { selectUnsupportedSurveysToLoad } from '../reducers/selectors/surveys';
import {
	createSurveyExternalIds,
	createS3BySurveyExternalIdsMap
} from './util/surveys.unsupportedSurveys';
import { setSurveysDownloadMessage } from './dataDownloadMessages';
import { getChartId, getSurveyIdFromChartId } from '../utils/chart';
import {
	thunkAddChart,
	thunkChartSideEffectsForDeleteSurvey
} from '../reducers/slices/Charts/Main/actions';
import { isChartPrimarySurvey } from './surveys.util';
import { universalSortSurveys } from '../utils/surveys.sort';

const {
	requestSurveys,
	receiveSurveys,
	setSurveysLoadErrorMessage,
	toggleSurvey,
	toggleSurveyCopyList,
	toggleSurveySettings,
	toggleSurveyColumn,
	setSurveyEditMode,
	deleteSurvey,

	requestSurveyS3Keys,
	receiveSurveyS3Keys,

	setUsableSurveys,
	setDefaultSurveyProps
} = createActions({
	RECEIVE_SURVEYS: json => {
		return { surveys: json.surveys };
	},
	REQUEST_SURVEYS: () => ({}),
	SET_SURVEYS_LOAD_ERROR_MESSAGE: message => ({ message }),
	TOGGLE_SURVEY: survey => ({ survey }),
	TOGGLE_SURVEY_COPY_LIST: survey => ({ survey }),
	TOGGLE_SURVEY_SETTINGS: survey => ({ survey }),
	TOGGLE_SURVEY_COLUMN: (survey, column) => ({ survey, column }),
	SET_SURVEY_EDIT_MODE: (survey, mode) => ({ survey, mode }),
	DELETE_SURVEY: survey => ({ survey }),
	REQUEST_SURVEY_S3_KEYS: surveyExternalId => ({
		externalId: surveyExternalId
	}),
	RECEIVE_SURVEY_S3_KEYS: (surveyExternalId, s3Keys) => ({
		externalId: surveyExternalId,
		s3Keys
	}),
	SET_USABLE_SURVEYS: usableSurveys => ({ usableSurveys }),
	SET_DEFAULT_SURVEY_PROPS: (defaultSurvey, defaultSurveyJobId) => ({
		defaultSurvey,
		defaultSurveyJobId
	})
});

const getUniqueInternalIds = (surveys = []) => {
	const jobIds = surveys
		.map(s => s.internalId)
		.filter(internalId => internalId);
	return [...new Set(jobIds)];
};

const computeUsableSurveys = () => (dispatch, getState) => {
	const { app } = getState();
	const { surveys: unsortedSurveys, chartIds } = app;
	const surveys = universalSortSurveys(unsortedSurveys);

	const usableSurveyIds = chartIds.reduce((acc, id) => {
		acc[getSurveyIdFromChartId(id)] = true;
		return acc;
	}, {});

	const usableSurveys = surveys.filter(s => usableSurveyIds[s.id]);
	dispatch(setUsableSurveys(usableSurveys));
};

const initMissingChartsSettings = () => (dispatch, getState) => {
	const { app } = getState();
	const { surveys, chartIds } = app;

	surveys.forEach(s => {
		const isChartable = isChartPrimarySurvey(s, surveys);
		const chartId = getChartId(s);

		if (isChartable && chartIds.indexOf(chartId) < 0) {
			dispatch(thunkAddChart(chartId));
		}
	});
	dispatch(computeUsableSurveys());
};

const thunkToggleSurvey = survey => dispatch => {
	dispatch(toggleSurvey(survey));
	dispatch(computeUsableSurveys());
};

const thunkToggleSurveyCopyList = survey => dispatch => {
	dispatch(toggleSurveyCopyList(survey));
	dispatch(computeUsableSurveys());
};

const thunkToggleSurveySettings = survey => dispatch => {
	dispatch(toggleSurveySettings(survey));
	dispatch(computeUsableSurveys());
};

const thunkToggleSurveyColumn = (survey, column) => dispatch => {
	dispatch(toggleSurveyColumn(survey, column));
	dispatch(computeUsableSurveys());
};

const thunkSetSurveyEditMode = (survey, mode) => dispatch => {
	dispatch(setSurveyEditMode(survey, mode));
	dispatch(computeUsableSurveys());
};

const thunkDeleteSurvey = survey => (dispatch, getState) => {
	const { app } = getState();
	const isDefaultSurvey = app.defaultSurvey === survey;
	dispatch(deleteSurvey(survey));
	dispatch(computeUsableSurveys());
	dispatch(thunkChartSideEffectsForDeleteSurvey(survey));
	if (isDefaultSurvey && window.location) {
		window.location.reload();
	}
};

const doFetchAllMissingSurveys = (
	{ lineId, lineName, projectId, projectName },
	callback = () => {}
) => {
	return (dispatch, getState) => {
		const state = getState();
		const { app: { surveys = [] } = {} } = state;
		const internalIds = getUniqueInternalIds(surveys);
		const surveysDownloadMessageKey = 'downloading-surveys';
		const surveysDownloadMessage = internalIds.length
			? 'Downloading addtional surveys'
			: 'Downloading surveys';

		dispatch(
			setSurveysDownloadMessage(
				surveysDownloadMessageKey,
				surveysDownloadMessage
			)
		);
		dispatch(requestSurveys());

		return fieldlineUtils.getSurveys2(
			{ lineId, lineName, projectId, projectName, ignoreIds: internalIds },
			(err, res) => {
				if (err) {
					callback(err);
					// console.error(err);
				} else {
					dispatch(receiveSurveys(res));
					(res.surveys || []).forEach(s => {
						if (isChartPrimarySurvey(s, res.surveys)) {
							dispatch(thunkAddChart(getChartId(s)));
						}
					});
					callback(null, res.surveys);
				}
				dispatch(
					setSurveysDownloadMessage(surveysDownloadMessageKey, undefined)
				);
			}
		);
	};
};

const doFetchUnsupportedSurveysS3Keys = (callback = () => {}) => {
	return (dispatch, getState) => {
		const surveys = selectUnsupportedSurveysToLoad(getState().app);
		const surveyExternalIds = createSurveyExternalIds(surveys);
		const s3KeysBySurveyExternalId = createS3BySurveyExternalIdsMap(
			surveyExternalIds
		);

		const batchCount = surveyExternalIds.length;
		let batchCompleteCount = 0;
		const createOnBatchComplete = surveyExternalId => (err, res) => {
			if (err) {
				callback(err);
			} else {
				batchCompleteCount += 1;
				s3KeysBySurveyExternalId[surveyExternalId] = res.s3Keys;

				dispatch(receiveSurveyS3Keys(surveyExternalId, res.s3Keys));
				if (batchCompleteCount === batchCount) {
					callback(undefined, s3KeysBySurveyExternalId);
				}
			}
		};

		if (batchCount) {
			surveyExternalIds.forEach(surveyExternalId => {
				const onBatchComplete = createOnBatchComplete(surveyExternalId);
				dispatch(requestSurveyS3Keys(surveyExternalId));

				return fieldlineUtils.getSurveyS3Keys(
					{ surveyExternalId },
					onBatchComplete
				);
			});
		} else {
			callback(undefined, {});
		}
	};
};

export {
	receiveSurveys,
	requestSurveys,
	setSurveysLoadErrorMessage,
	toggleSurvey,
	toggleSurveyCopyList,
	toggleSurveySettings,
	toggleSurveyColumn,
	setSurveyEditMode,
	deleteSurvey,
	requestSurveyS3Keys,
	receiveSurveyS3Keys,
	doFetchAllMissingSurveys,
	doFetchUnsupportedSurveysS3Keys,
	initMissingChartsSettings,
	computeUsableSurveys,
	setUsableSurveys,
	setDefaultSurveyProps,
	thunkToggleSurvey,
	thunkToggleSurveyCopyList,
	thunkToggleSurveySettings,
	thunkToggleSurveyColumn,
	thunkSetSurveyEditMode,
	thunkDeleteSurvey
};
