import {
	HIDE_EXPORT_PANEL,
	SHOW_EXPORT_PANEL,
	SET_PROFILE_NAME,
	SET_SELECTED,
	SET_PROFILE
} from './actionTypes';

const initialState = {
	showExportPanel: false,
	profiles: [],
	selected: 'New Profile',
	profile: {}
};

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SHOW_EXPORT_PANEL:
			return { ...state, showExportPanel: true };
		case HIDE_EXPORT_PANEL:
			return { ...state, showExportPanel: false };
		case SET_PROFILE_NAME:
			return { ...state, profiles: payload };
		case SET_SELECTED:
			return { ...state, selected: payload };
		case SET_PROFILE:
			return { ...state, profile: payload };
		default:
			return state;
	}
};

export default reducer;
