import { createMultiModifierKey } from 'aegion_common_utilities/lib/ComplexReadingStoreUtil/util/computeModifierKey';
import { initializeDataWrapper } from '../util/dataWrapper';
import { STATUS } from '../constants';
import { setDataWrappers } from './actions';
import { defaultCreateSimplifiedReadings } from '../../../../utils/SurveyMeta/util/general';
import {
	isNormalizeReferenceSurvey,
	isInterpolateReferenceSurvey,
	selectInterpolateTargetSurveys,
	selectNormalizeTargetSurveys,
	selectNormalizeReferenceSurvey,
	selectInterpolateReferenceSurvey,
	selectSeries,
	selectInterpSeries,
	selectSeriesNormalized,
	selectInterpSeriesNormalized
} from '../../../util/readings.interpolate';
import SurveyMeta from '../../../../utils/SurveyMeta';

const createDataWrapper = ({
	targetSurvey,
	parentSurvey,
	alignmentFlags: { useAligned = false, useInterpolated = false } = {},
	belowCriterionFlags: {
		useShadow = false,
		useThreshold = false,
		threshold
	} = {},
	hasPropFlags: { prop },
	decimateFlags: { decimate = true } = {}
}) => {
	const targetKey = targetSurvey.id;
	const parentKey = parentSurvey && parentSurvey.id ? parentSurvey.id : 'NONE';
	const modifierKey = createMultiModifierKey({
		alignmentFlags: { useAligned, useInterpolated },
		belowCriterionFlags: { useShadow, useThreshold, threshold },
		hasPropFlags: { prop },
		decimateFlags: { decimate }
	});
	// INITIALIZE DATA WRAPPER AND PERSIST
	const dataWrapper = initializeDataWrapper();
	dataWrapper.status = STATUS.PROCESSING;

	return { targetKey, parentKey, modifierKey, dataWrapper };
};

const createDecimatedDataWrapper = (
	series,
	{
		targetSurvey,
		parentSurvey,
		alignmentFlags: { useAligned = false, useInterpolated = false } = {},
		belowCriterionFlags: {
			useShadow = false,
			useThreshold = false,
			threshold
		} = {},
		hasPropFlags: { prop },
		decimateFlags: { decimate = true } = {}
	}
) => {
	// COMPUTE KEYS
	const targetKey = targetSurvey.id;
	const parentKey = parentSurvey && parentSurvey.id ? parentSurvey.id : 'NONE';
	const modifierKey = createMultiModifierKey({
		alignmentFlags: { useAligned, useInterpolated },
		belowCriterionFlags: { useShadow, useThreshold, threshold },
		hasPropFlags: { prop },
		decimateFlags: { decimate }
	});
	// INITIALIZE DATA WRAPPER
	const dataWrapper = initializeDataWrapper();
	dataWrapper.dataset = defaultCreateSimplifiedReadings({
		readings: series,
		propsOverride: [prop]
	});
	dataWrapper.status = STATUS.AVAILABLE;
	return { targetKey, parentKey, modifierKey, dataWrapper };
};

const thunkDecimateCisSurvey = survey => (dispatch, getState) => {
	const surveyReadingProps = SurveyMeta.getSurveyMeta(survey).readingKeyProps;
	const blnInterpolateRef = isInterpolateReferenceSurvey(survey);
	const blnNormalizeRef = isNormalizeReferenceSurvey(survey);
	const reduxNormalizeReferenceSurvey = selectNormalizeReferenceSurvey(
		survey,
		getState().app
	);
	const blnNormalizeTarget =
		!!reduxNormalizeReferenceSurvey &&
		reduxNormalizeReferenceSurvey.id !== survey.id;
	const reduxInterpolateReferenceSurvey = selectInterpolateReferenceSurvey(
		survey,
		getState().app
	);
	const blnInterpolateTarget =
		!!reduxInterpolateReferenceSurvey &&
		reduxInterpolateReferenceSurvey.id !== survey.id;
	const processingDataWrapper = surveyReadingProps.map(readingProp => {
		return createDataWrapper({
			targetSurvey: survey,
			hasPropFlags: { prop: readingProp }
		});
	});
	dispatch(setDataWrappers(processingDataWrapper));

	// DECIMATE MAIN
	const availableDataWrapper = surveyReadingProps.map(readingProp => {
		const series = selectSeries(survey, readingProp, getState().app);

		return createDecimatedDataWrapper(series, {
			targetSurvey: survey,
			hasPropFlags: { prop: readingProp }
		});
	});
	dispatch(setDataWrappers(availableDataWrapper));

	// COMPUTED SERIES DATAWRAPPERS
	const computedSeriesDataWrappers = [];

	// DECIMATE INTERP READINGS
	if (blnInterpolateRef) {
		const surveysToInterpolate = selectInterpolateTargetSurveys(
			survey,
			getState().app
		);
		surveysToInterpolate.forEach(targetSurvey => {
			const targetReadingProps = SurveyMeta.getSurveyMeta(targetSurvey)
				.readingKeyProps;
			targetReadingProps.forEach(targetReadingProp => {
				const interpSeries = selectInterpSeries(
					targetSurvey,
					targetReadingProp,
					getState().app
				);
				computedSeriesDataWrappers.push(
					createDecimatedDataWrapper(interpSeries, {
						targetSurvey,
						parentSurvey: survey,
						alignmentFlags: { useInterpolated: true },
						hasPropFlags: { prop: targetReadingProp }
					})
				);
			});
		});
	}

	// DECIMATE NORM READINGS
	if (blnNormalizeRef) {
		const surveysToNormalize = selectNormalizeTargetSurveys(
			survey,
			getState().app
		);
		surveysToNormalize.forEach(targetSurvey => {
			const targetReadingProps = SurveyMeta.getSurveyMeta(targetSurvey)
				.readingKeyProps;
			targetReadingProps.forEach(targetReadingProp => {
				const seriesNormalized = selectSeriesNormalized(
					targetSurvey,
					targetReadingProp,
					getState().app
				);
				computedSeriesDataWrappers.push(
					createDecimatedDataWrapper(seriesNormalized, {
						targetSurvey,
						parentSurvey: survey,
						alignmentFlags: { useAligned: true },
						hasPropFlags: { prop: targetReadingProp }
					})
				);
			});
		});
	}

	// DECIMATE NORM/interp READINGS
	if (blnInterpolateRef && blnNormalizeRef) {
		const surveysToInterpolate = selectInterpolateTargetSurveys(
			survey,
			getState().app
		);
		const surveysToNormalize = selectNormalizeTargetSurveys(
			survey,
			getState().app
		);
		const surveysToInterpNorm = surveysToInterpolate.filter(
			s => surveysToNormalize.indexOf(s) > -1
		);
		surveysToInterpNorm.forEach(targetSurvey => {
			const targetReadingProps = SurveyMeta.getSurveyMeta(targetSurvey)
				.readingKeyProps;
			targetReadingProps.forEach(targetReadingProp => {
				const interpSeriesNormalized = selectInterpSeriesNormalized(
					targetSurvey,
					targetReadingProp,
					getState().app
				);
				computedSeriesDataWrappers.push(
					createDecimatedDataWrapper(interpSeriesNormalized, {
						targetSurvey,
						parentSurvey: survey,
						alignmentFlags: { useAligned: true, useInterpolated: true },
						hasPropFlags: { prop: targetReadingProp }
					})
				);
			});
		});
	}

	// inverse of the above
	if (!blnInterpolateRef && !blnNormalizeRef) {
		// DECIMATE INTERP READINGS
		if (blnInterpolateTarget) {
			const surveyRef = selectInterpolateReferenceSurvey(
				survey,
				getState().app
			);
			surveyReadingProps.forEach(readingProp => {
				const interpSeries = selectInterpSeries(
					survey,
					readingProp,
					getState().app
				);
				computedSeriesDataWrappers.push(
					createDecimatedDataWrapper(interpSeries, {
						targetSurvey: survey,
						parentSurvey: surveyRef,
						alignmentFlags: { useInterpolated: true },
						hasPropFlags: { prop: readingProp }
					})
				);
			});
		}

		// DECIMATE NORM READINGS
		if (blnNormalizeTarget) {
			const surveyRef = selectNormalizeReferenceSurvey(survey, getState().app);
			surveyReadingProps.forEach(readingProp => {
				const seriesNormalized = selectSeriesNormalized(
					survey,
					readingProp,
					getState().app
				);
				computedSeriesDataWrappers.push(
					createDecimatedDataWrapper(seriesNormalized, {
						targetSurvey: survey,
						parentSurvey: surveyRef,
						alignmentFlags: { useAligned: true },
						hasPropFlags: { prop: readingProp }
					})
				);
			});
		}

		// DECIMATE NORM/interp READINGS
		if (blnInterpolateTarget && blnNormalizeTarget) {
			const surveyRef = selectNormalizeReferenceSurvey(survey, getState().app);
			surveyReadingProps.forEach(readingProp => {
				const interSeriesNormalized = selectInterpSeriesNormalized(
					survey,
					readingProp,
					getState().app
				);
				computedSeriesDataWrappers.push(
					createDecimatedDataWrapper(interSeriesNormalized, {
						targetSurvey: survey,
						parentSurvey: surveyRef,
						alignmentFlags: { useAligned: true, useInterpolated: true },
						hasPropFlags: { prop: readingProp }
					})
				);
			});
		}
	}

	// PERSIST DECIMATED COMPUTED SERIES
	if (computedSeriesDataWrappers.length) {
		dispatch(setDataWrappers(computedSeriesDataWrappers));
	}
};

export { thunkDecimateCisSurvey };

export default { thunkDecimateCisSurvey };
