/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';

import avColumnsReducer from './Columns/reducer';
import managerReducer from '../reducers/manager';
import assetViewMap from './Map/reducer';
import editAssetCard from './EditAssetCard/reducer';
import editTable from './EditTable/reducer';
import search from './Search/reducer';
import exportsReducer from './Exports/reducer';
import filtersReducer from './Filters/reducer';

import { ROOT_SLICE_KEY as assetMergeKey } from './AssetMerge/constants';
import assetMergeReducer from './AssetMerge/reducer';

import { ROOT_SLICE_KEY as dateRangeFilterKey } from './DateRangeFilter/constants';
import dateRangeFilterReducer from './DateRangeFilter/reducer';

import { FILE_EXPORT_TYPE } from '../components/Downloads/utils/fileExport';

export default combineReducers({
	xlsExportColumns: avColumnsReducer(FILE_EXPORT_TYPE.EXCEL),
	corrExportColumns: avColumnsReducer(FILE_EXPORT_TYPE.CORRELATOR),
	managerSettings: avColumnsReducer(FILE_EXPORT_TYPE.MANAGER),
	manager: managerReducer,
	map: assetViewMap,
	editAssetCard,
	filters: filtersReducer,
	editTable,
	search,
	[assetMergeKey]: assetMergeReducer,
	exports: exportsReducer,
	[dateRangeFilterKey]: dateRangeFilterReducer
});
