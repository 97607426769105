const isDefined = v => v !== undefined && v !== null;

const toIndexMap = (acc, r, idx) => {
	if (!r.isGap) {
		acc[r.uuid] = idx;
	}
	return acc;
};

const toSegmentIndexMap = (acc, segment, idx1) => {
	if (segment && segment.length) {
		segment.forEach((r, idx2) => {
			if (!r.isGap) {
				acc[r.uuid] = [idx1, idx2];
			}
		});
	}
	return acc;
};

const toReadingsMap = (acc, r) => {
	if (!r.isGap) {
		acc[r.id] = r;
	}
	return acc;
};

const getComputeProps = surveyMeta => {
	return surveyMeta && surveyMeta.computePropsForDerivedSurvey
		? surveyMeta.computePropsForDerivedSurvey
		: () => {};
};

const createFnReduceReadings = (
	survey,
	isValidReading,
	computeProps,
	refLastReadingWithLoc
) => (acc, r) => {
	const hasLoc = rx => rx && rx.loc && rx.loc.length === 2;
	if (isValidReading(r)) {
		const clonedR = { ...r };
		const prevR = refLastReadingWithLoc.lastReadingWithLoc;
		if (hasLoc(clonedR) && !clonedR.isGap) {
			computeProps(survey, prevR, clonedR);
			// eslint-disable-next-line no-param-reassign
			refLastReadingWithLoc.lastReadingWithLoc = clonedR;
		}
		acc.push(clonedR);
	}

	return acc;
};

export const isValidReadingValue = v => isDefined(v) && !Number.isNaN(v);

export const createIsValidReading = (propKeys, options = {}) => {
	const { ignoreGaps = false, requireNumeric = true } = options;

	const createValuesArray = (reading = {}) =>
		propKeys.reduce((acc, propKey) => [...acc, reading[propKey]], []);

	const hasValidPropCheck = requireNumeric
		? reading => {
				const values = createValuesArray(reading);
				return values.some(isValidReadingValue);
		  }
		: reading => {
				const values = createValuesArray(reading);
				return values.some(isDefined);
		  };

	if (ignoreGaps) {
		return hasValidPropCheck;
	}

	return reading => reading.isGap || hasValidPropCheck(reading);
};

const deriveChartGapDataSetsFromProps = (
	survey,
	propKeys,
	paramReadingsWithChartGaps,
	options = {}
) => {
	const { isValidOptions, surveyMeta } = options;

	const refLastReadingWithLoc = { lastReadingWithLoc: undefined };
	const computeProps = getComputeProps(surveyMeta);
	const isValidReading = createIsValidReading(propKeys, isValidOptions);
	const reduceReadings = createFnReduceReadings(
		survey,
		isValidReading,
		computeProps,
		refLastReadingWithLoc
	);

	const readingsWithChartGaps = paramReadingsWithChartGaps.reduce(
		reduceReadings,
		[]
	);

	const readingsWithChartGapsIndexMap = readingsWithChartGaps.reduce(
		toIndexMap,
		{}
	);

	return {
		readingsWithChartGaps,
		readingsWithChartGapsIndexMap
	};
};

const deriveGeoGapDataSetsFromProps = (
	survey,
	propKeys,
	paramReadingsWithGeoGaps,
	options = {}
) => {
	const { isValidOptions, surveyMeta } = options;

	const refLastReadingWithLoc = { lastReadingWithLoc: undefined };
	const computeProps = getComputeProps(surveyMeta);
	const isValidReading = createIsValidReading(propKeys, isValidOptions);
	const reduceReadings = createFnReduceReadings(
		survey,
		isValidReading,
		computeProps,
		refLastReadingWithLoc
	);

	const readingsWithGeoGaps = paramReadingsWithGeoGaps
		.map(seg => seg.reduce(reduceReadings, []))
		.filter(arr => arr.length);

	const readingsWithGeoGapsIndexMap = readingsWithGeoGaps.reduce(
		toSegmentIndexMap,
		{}
	);

	return {
		readingsWithGeoGaps,
		readingsWithGeoGapsIndexMap
	};
};

const createSeriesDataSetsFromProps = (
	survey,
	propKeys,
	paramReadings,
	options = {}
) => {
	const series = {};
	const seriesIndexMap = {};

	propKeys.forEach(propKey => {
		const { isValidOptions, surveyMeta } = options;
		const refLastReadingWithLoc = { lastReadingWithLoc: undefined };
		const computeProps = getComputeProps(surveyMeta);
		const isValidReading = createIsValidReading([propKey], isValidOptions);
		const reduceReadings = createFnReduceReadings(
			survey,
			isValidReading,
			computeProps,
			refLastReadingWithLoc
		);

		const readings = paramReadings.reduce(reduceReadings, []);
		const readingsIndexMap = readings.reduce(toIndexMap, {});

		series[propKey] = readings;
		seriesIndexMap[propKey] = readingsIndexMap;
	});

	return {
		series,
		seriesIndexMap
	};
};

export const deriveDataSetsFromProps = (
	survey,
	propKeys,
	paramReadings,
	paramReadingsWithChartGaps,
	paramReadingsWithGeoGaps,
	options = {}
) => {
	const { isValidOptions, surveyMeta } = options;

	const refLastReadingWithLoc = { lastReadingWithLoc: undefined };
	const computeProps = getComputeProps(surveyMeta);
	const isValidReading = createIsValidReading(propKeys, isValidOptions);
	const reduceReadings = createFnReduceReadings(
		survey,
		isValidReading,
		computeProps,
		refLastReadingWithLoc
	);

	const readings = [...paramReadings].reduce(reduceReadings, []);
	const readingsIndexMap = readings.reduce(toIndexMap, {});
	const readingsMap = readings.reduce(toReadingsMap, {});

	const {
		readingsWithChartGaps,
		readingsWithChartGapsIndexMap
	} = deriveChartGapDataSetsFromProps(
		survey,
		propKeys,
		paramReadingsWithChartGaps,
		options
	);

	const {
		readingsWithGeoGaps,
		readingsWithGeoGapsIndexMap
	} = deriveGeoGapDataSetsFromProps(
		survey,
		propKeys,
		paramReadingsWithGeoGaps,
		options
	);

	const { series, seriesIndexMap } = createSeriesDataSetsFromProps(
		survey,
		propKeys,
		readings,
		options
	);

	return {
		readings,
		readingsIndexMap,
		readingsMap,
		readingsWithChartGaps,
		readingsWithChartGapsIndexMap,
		readingsWithGeoGapsIndexMap,
		readingsWithGeoGaps,
		series,
		seriesIndexMap
	};
};

export const TEST = {
	isValidReadingValue,
	createIsValidReading,
	deriveDataSetsFromProps,
	deriveChartGapDataSetsFromProps,
	deriveGeoGapDataSetsFromProps
};

export default {
	isValidReadingValue,
	createIsValidReading,
	deriveDataSetsFromProps,
	deriveChartGapDataSetsFromProps,
	deriveGeoGapDataSetsFromProps
};
