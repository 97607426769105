/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import scanlineMapUtils from '../../mapUtils';

import {
	removeSurvey,
	cleanupUnsupportedSurveys,
	createCoreSurveyFromUnsupportedSurvey,
	createSurveysFromUnsupportedCoreSurvey
} from './surveys';

import { generateReadingDataStructuresWithComputableProps } from './readings.parse';

import { createDerivedSurveys } from './derived.readings.parse';
import { universalSortSurveys } from '../../utils/surveys.sort';

const decorateSurvey = (survey, meta) => {
	survey.isChartInversed = meta.isChartInversed;
	survey.readingKeys = meta.readingKeys;
	survey.fkey = meta.fkey;
	survey.color = meta.readingKeys[0].color;
	survey.columns = meta.columns;
	survey.thresholds = meta.thresholds;
};

export const processUnsupportedReadingsLoaded = (survey, headers, state) => {
	const { id } = survey;

	// REMOVE UNSUPPORTED SURVEYS THAT WE CANNOT PROCESS
	const {
		unsupportedSurveysToProcess,
		loadingUnsupportReadingsHeaders,
		loadingUnsupportReadings
	} = cleanupUnsupportedSurveys(state);

	// IF ALL IS WELL - CREATE NEW SUPPORTED SURVEYS & LOAD UNSUPPORTED READINGS
	const readingsToProcess = _.flatten(loadingUnsupportReadings[id]);
	const coreSurveyHeaders = loadingUnsupportReadingsHeaders[survey.id];
	const coreSurvey = createCoreSurveyFromUnsupportedSurvey(
		survey,
		coreSurveyHeaders,
		readingsToProcess.length
	);

	const computedSurveys = createSurveysFromUnsupportedCoreSurvey(
		coreSurveyHeaders,
		coreSurvey
	);

	const ARRAY_KEYS = ['surveys'];
	const statePropValuesToAdd = computedSurveys.reduce((acc, s) => {
		const newProps = { ...acc };
		const stateProps = generateSurveyAndReadingsStateProps(
			s,
			readingsToProcess
		);
		Object.keys(stateProps).forEach(key => {
			if (ARRAY_KEYS.indexOf(key) > -1) {
				newProps[key] = newProps[key] || [];
				newProps[key] = [...newProps[key], ...stateProps[key]];
			} else {
				newProps[key] = newProps[key] || {};
				newProps[key] = {
					...newProps[key],
					...stateProps[key]
				};
			}
		});
		return newProps;
	}, {});

	return {
		unsupportedSurveysToProcess: removeSurvey(
			coreSurvey,
			unsupportedSurveysToProcess
		),
		loadingUnsupportReadingsHeaders: {
			...loadingUnsupportReadingsHeaders,
			[coreSurvey.id]: undefined
		},
		loadingUnsupportReadings: {
			...loadingUnsupportReadings,
			[coreSurvey.id]: undefined
		},
		surveys: universalSortSurveys([
			...state.surveys,
			...statePropValuesToAdd.surveys
		]),
		readings: {
			...state.readings,
			...statePropValuesToAdd.readings
		},
		readingsWithGeoGaps: {
			...state.readingsWithGeoGaps,
			...statePropValuesToAdd.readingsWithGeoGaps
		},
		readingsWithChartGaps: {
			...state.readingsWithChartGaps,
			...statePropValuesToAdd.readingsWithChartGaps
		},
		readingsMap: {
			...state.readingsMap,
			...statePropValuesToAdd.readingsMap
		},
		readingsIndexMap: {
			...state.readingsIndexMap,
			...statePropValuesToAdd.readingsIndexMap
		},
		readingsWithChartGapsIndexMap: {
			...state.readingsWithChartGapsIndexMap,
			...statePropValuesToAdd.readingsWithChartGapsIndexMap
		},
		readingsWithGeoGapsIndexMap: {
			...state.readingsWithGeoGapsIndexMap,
			...statePropValuesToAdd.readingsWithGeoGapsIndexMap
		},
		simplifiedReadings: {
			...state.simplifiedReadings,
			...statePropValuesToAdd.simplifiedReadings
		},
		readingsMeta: {
			...state.readingsMeta,
			...statePropValuesToAdd.readingsMeta
		},
		series: {
			...state.series,
			...statePropValuesToAdd.series
		},
		seriesIndexMap: {
			...state.seriesIndexMap,
			...statePropValuesToAdd.seriesIndexMap
		}
	};
};

const generateSurveyAndReadingsStateProps = (survey, readings) => {
	const meta = scanlineMapUtils.getSurveyMeta(survey);
	const readingsToProcess = meta.filterReadingsOfUnsupportedSurveyType(
		readings,
		survey
	);

	const [
		processedReadings,
		processedReadingsWithGeoGaps,
		processedReadingsWithChartGaps,
		processedReadingsMap,
		processedReadingsIndexMap,
		processedReadingsWithChartGapsIndexMap,
		processedReadingsWithGeoGapsIndexMap,
		processedSeries,
		processedSeriesIndexMap,
		processedReadingsStationIdIntegers,
		processedSimplifiedReadings
	] = generateReadingDataStructuresWithComputableProps(
		survey,
		readingsToProcess,
		true
	);

	decorateSurvey(survey, meta);

	if (scanlineMapUtils.shouldCreateDerivedSurveys(survey)) {
		// @todo - createDerivedSurveys - this needs to be pulled out into "generateReadingsWithComputableProps"
		const {
			readings: derivedReadings,
			readingsWithChartGaps: derivedReadingsWithChartGaps,
			readingsWithGeoGaps: derivedReadingsWithGeoGaps,
			readingsMap: derivedReadingsMap,
			readingsIndexMap: derivedReadingsIndexMap,
			readingsWithChartGapsIndexMap: derivedReadingsWithChartGapsIndexMap,
			readingsWithGeoGapsIndexMap: derivedReadingsWithGeoGapsIndexMap,
			readingsMeta: derivedReadingsMeta,
			surveys: derivedSurveys,
			series: derivedSeries,
			seriesIndexMap: derivedSeriesIndexMap
		} = createDerivedSurveys(
			processedReadings,
			processedReadingsWithChartGaps,
			processedReadingsWithGeoGaps,
			survey.type,
			readingsToProcess,
			survey.jobId,
			meta,
			survey.checked
		);

		return {
			surveys: universalSortSurveys([survey, ...derivedSurveys]),
			readings: {
				...derivedReadings,
				[survey.id]: processedReadings
			},
			readingsWithGeoGaps: {
				...derivedReadingsWithGeoGaps,
				[survey.id]: processedReadingsWithGeoGaps
			},
			readingsWithChartGaps: {
				...derivedReadingsWithChartGaps,
				[survey.id]: processedReadingsWithChartGaps
			},
			readingsMap: {
				...derivedReadingsMap,
				[survey.id]: processedReadingsMap
			},
			readingsIndexMap: {
				...derivedReadingsIndexMap,
				[survey.id]: processedReadingsIndexMap
			},
			readingsWithChartGapsIndexMap: {
				...derivedReadingsWithChartGapsIndexMap,
				[survey.id]: processedReadingsWithChartGapsIndexMap
			},
			readingsWithGeoGapsIndexMap: {
				...derivedReadingsWithGeoGapsIndexMap,
				[survey.id]: processedReadingsWithGeoGapsIndexMap
			},
			simplifiedReadings: {
				[survey.id]: processedSimplifiedReadings
			},
			readingsMeta: {
				[survey.id]: meta,
				...derivedReadingsMeta
			},
			series: {
				[survey.id]: {
					...processedSeries
				},
				...derivedSeries
			},
			seriesIndexMap: {
				[survey.id]: {
					...processedSeriesIndexMap
				},
				...derivedSeriesIndexMap
			}
		};
	}
	return {
		surveys: [survey],
		readings: { [survey.id]: processedReadings },
		readingsWithGeoGaps: {
			[survey.id]: processedReadingsWithGeoGaps
		},
		readingsWithChartGaps: {
			[survey.id]: processedReadingsWithChartGaps
		},
		readingsMap: { [survey.id]: processedReadingsMap },
		readingsIndexMap: {
			[survey.id]: processedReadingsIndexMap
		},
		readingsWithChartGapsIndexMap: {
			[survey.id]: processedReadingsWithChartGapsIndexMap
		},
		readingsWithGeoGapsIndexMap: {
			[survey.id]: processedReadingsWithGeoGapsIndexMap
		},
		simplifiedReadings: {
			[survey.id]: processedSimplifiedReadings
		},
		readingsMeta: { [survey.id]: meta },
		series: {
			[survey.id]: {
				...processedSeries
			}
		},
		seriesIndexMap: {
			[survey.id]: {
				...processedSeriesIndexMap
			}
		}
	};
};
