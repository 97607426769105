import { EMPTY_OBJECT } from '../slices/Charts/Main/constants';
import { selectDerivedSurvey } from './surveys';

// PRIMARY

export const selectSeriesStore = stateApp => {
	return stateApp.series || EMPTY_OBJECT;
};

export const selectSeries = (stateApp, survey) => {
	const store = selectSeriesStore(stateApp);
	return survey ? store[survey.id] : undefined;
};

export const selectSeriesIndexMap = (stateApp, survey) => {
	return survey ? stateApp.seriesIndexMap[survey.id] : undefined;
};

// COMPUTED
export const selectComputedSeries = (
	stateApp,
	survey,
	useInterpolated,
	useNormalized
) => {
	if (survey) {
		let prop = 'series';
		if (useInterpolated && useNormalized) {
			prop = 'interpSeriesNormalized';
		} else if (useInterpolated) {
			prop = 'interpSeries';
		} else if (useNormalized) {
			prop = 'seriesNormalized';
		}
		return stateApp[prop][survey.id];
	}
	return undefined;
};

export const selectComputedSeriesIndexMap = (
	stateApp,
	survey,
	useInterpolated,
	useNormalized
) => {
	if (survey) {
		let prop = 'seriesIndexMap';
		if (useInterpolated && useNormalized) {
			prop = 'interpSeriesNormalizedIndexMap';
		} else if (useInterpolated) {
			prop = 'interpSeriesIndexMap';
		} else if (useNormalized) {
			prop = 'seriesNormalizedIndexMap';
		}
		return stateApp[prop][survey.id];
	}
	return undefined;
};

// SPATIAL
export const selectSpatialSeries = (stateApp, survey) => {
	const spatialSurvey = selectDerivedSurvey(stateApp, survey, 'SPATIAL');
	return selectSeries(stateApp, spatialSurvey);
};

export const selectSpatialSeriesIndexMap = (stateApp, survey) => {
	const spatialSurvey = selectDerivedSurvey(stateApp, survey, 'SPATIAL');
	return selectSeriesIndexMap(stateApp, spatialSurvey);
};

// DEPOL
export const selectDepolSeriesFromCisSurvey = (
	stateApp,
	cisSurvey,
	useInterpolated,
	useNormalized
) => {
	const depolSurvey = selectDerivedSurvey(stateApp, cisSurvey, 'DEPOL');

	if (depolSurvey) {
		let prop = 'series';
		if (useInterpolated && useNormalized) {
			prop = 'interpSeriesNormalized';
		} else if (useInterpolated) {
			prop = 'interpSeries';
		} else if (useNormalized) {
			prop = 'seriesNormalized';
		}
		return stateApp[prop][depolSurvey.id];
	}
	return undefined;
};

export const selectDepolSeriesIndexMapFromCisSurvey = (
	stateApp,
	cisSurvey,
	useInterpolated,
	useNormalized
) => {
	const depolSurvey = selectDerivedSurvey(stateApp, cisSurvey, 'DEPOL');

	if (depolSurvey) {
		let prop = 'seriesIndexMap';
		if (useInterpolated && useNormalized) {
			prop = 'interpSeriesNormalizedIndexMap';
		} else if (useInterpolated) {
			prop = 'interpSeriesIndexMap';
		} else if (useNormalized) {
			prop = 'seriesNormalizedIndexMap';
		}
		return stateApp[prop][depolSurvey.id];
	}
	return undefined;
};
