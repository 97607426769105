import jQuery from 'jquery';
import { pick, pickBy, mapKeys, identity } from 'lodash';

import { MiscUtils } from 'aegion_common_utilities';
import TokenUtil from '../TokenUtil';
// eslint-disable-next-line import/no-cycle
import AutoCorrectionUtil from '../AutoCorrectionUtil';

// eslint-disable-next-line import/no-cycle
import { onLoadAll } from '../Cache/JobCache';

// eslint-disable-next-line import/no-cycle
import ajax from '../ajax';

// eslint-disable-next-line import/no-cycle
import saveGpsReadings, {
	postSaveGpsReadings
} from './JobUtil.saveGpsReadings';
import { ALL_DAT_PROPERTIES_FROM_URL_PROPERTIES } from './JobUtil.constants';

// eslint-disable-next-line import/no-cycle
import { getNewGlobalDataFromNewDatFiles, getDatData } from './JobUtil.DatData';

// eslint-disable-next-line import/no-cycle
import {
	jobAjax,
	jobAjaxforExtended,
	jobAjaxReadOnly,
	update,
	_getCookie,
	getUploadPolicy
} from './JubUtil.api';

const JobUtil = {
	ALL_DAT_PROPERTIES_FROM_URL_PROPERTIES,
	job: null,
	jobguid: null,

	update,
	_getCookie,
	getUploadPolicy,

	timePassedByFile: {},

	resetTimePassed: () => {
		JobUtil.timePassedByFile = {};
	},

	extendTimePassed: (timeByFilename, key) => {
		Object.keys(timeByFilename).forEach(filename => {
			const time = timeByFilename[filename];
			JobUtil.timePassedByFile[filename] = {
				...JobUtil.timePassedByFile[filename],
				[key]: time
			};
		});
	},

	saveGpsReadings,
	// Fetches all data and then mutates the datFile

	getAndUpdateNewDatFileData(datFile) {
		return getDatData(datFile).then(({ newDatFile, totalTimePassed }) => {
			const { fileName } = datFile;

			JobUtil.extendTimePassed(
				{ [fileName]: totalTimePassed },
				'non-map-items'
			);
			return {
				...newDatFile
			};
		});
	},

	extendDatFileWithDefaultProperties(datFile, index) {
		return {
			...datFile,
			visible: true,
			color: MiscUtils.getColorByIndexOrRandom(index)
		};
	},

	extendDatFilesWithDefaultProperties(globalData) {
		const datFiles = Object.values(globalData.MasterLST);
		const newDatFiles = datFiles.map(
			JobUtil.extendDatFileWithDefaultProperties
		);

		return getNewGlobalDataFromNewDatFiles(globalData, newDatFiles);
	},

	getJobGlobalData(jobguid) {
		return new Promise((res, rej) => {
			return JobUtil.get(jobguid, (err, response) => {
				if (err) {
					rej(err);
					return;
				}
				const globalData = response.Item;
				res(globalData);
			});
		});
	},
	getAdditionalInfoForDatFilesForJob(globalData) {
		const datFiles = Object.values(globalData.MasterLST || {});
		return Promise.all(datFiles.map(JobUtil.getAndUpdateNewDatFileData)).then(
			newDatFiles => {
				const timePassedStats = JobUtil.timePassedByFile;
				onLoadAll(timePassedStats);
				JobUtil.resetTimePassed();
				return getNewGlobalDataFromNewDatFiles(globalData, newDatFiles);
			}
		);
	},

	// This is a succinct way to handle lots of data processing - mostly used for secondary components like uploader/additionalSurveys
	getJobData(jobguid) {
		return JobUtil.getJobGlobalData(jobguid)
			.then(JobUtil.extendDatFilesWithDefaultProperties)
			.then(JobUtil.getAdditionalInfoForDatFilesForJob);
	},
	get(jobguid, callback, hasDeleted = false) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				jobguid,
				hasDeleted
			});
			return jobAjaxReadOnly('/get', data, 'POST', (err, res) => {
				if (err && callback) {
					callback(err);
				} else {
					// TODO: This whole thing should be stateless, move this out of here and use store.getState()
					this.job = res.Item;
					if (callback) {
						callback(null, res);
					}
				}
			});
		}
		return Promise.reject();
	},
	getHistoricalJobs(job, lineName, customer, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				lineName,
				job,
				customer
			});
			jobAjaxReadOnly('/getExistingSurveys', data, 'POST', (err, res) => {
				if (err) {
					callback(err);
				}
				callback(null, res);
			});
		}
	},

	deleteReadings(datFile, globalData, readingIndex, numberOfReadings) {
		const { timeuuid: jobId } = globalData;
		const { fileName: datFileName, fileKey } = datFile;
		return new Promise((res, rej) => {
			jobAjax(
				'/deleteReadings',
				JSON.stringify({
					jobId,
					datFileName,
					fileKey,
					readingIndex,
					numberOfReadings
				}),
				'POST',
				async (err, response) => {
					if (err) {
						rej(err);
						return;
					}
					postSaveGpsReadings(datFile, undefined, jobId);
					res(response);
				}
			);
		});
	},

	downloadGPSC(jobguid, fileNames, lineName, surveyType, callback) {
		jobAjaxReadOnly(
			'/csvdownload',
			JSON.stringify({
				fileNames,
				job: jobguid,
				lineName,
				surveyType
			}),
			'POST',
			(err, res) => {
				callback(null, res);
			}
		);
	},
	downloadSkipReport(jobguid, fileNames, lineName, callback) {
		jobAjaxReadOnly(
			'/skipsummary',
			JSON.stringify({
				fileNames,
				job: jobguid,
				lineName
			}),
			'POST',
			(err, res) => {
				callback(null, res);
			}
		);
	},

	downloadDCVG(jobguid, fileNames, lineName, callback) {
		jobAjaxReadOnly(
			'/dcvgDownload',
			JSON.stringify({
				fileNames,
				job: jobguid,
				lineName
			}),
			'POST',
			(err, res) => {
				callback(null, res);
			}
		);
	},

	downloadDats(jobguid, lineName, callback) {
		jobAjaxReadOnly(
			'/zipdownload',
			JSON.stringify({
				job: jobguid,
				lineName
			}),
			'POST',
			(err, res) => {
				callback(res);
			}
		);
	},
	convertedDat(jobguid, lineName, fileName, callback) {
		jobAjaxReadOnly(
			'/convertedDat',
			JSON.stringify({
				job: jobguid,
				lineName,
				fileName
			}),
			'POST',
			(err, res) => {
				callback(res);
			}
		);
	},

	downloadOtherFile(jobguid, fileName, callback) {
		jobAjaxReadOnly(
			'/downloadOtherFiles',
			JSON.stringify({
				fileName,
				job: jobguid
			}),
			'POST',
			(err, res) => {
				if (!err) {
					callback(res);
				}
			}
		);
	},

	dataToCustomer(jobguid, fileNames, callback) {
		jobAjaxforExtended(
			'/dataToCustomer',
			JSON.stringify({
				fileNames,
				job: jobguid
			}),
			'POST',
			(err, res) => {
				callback(res);
			}
		);
	},

	list(evalKey, callback = () => {}) {
		const accessToken = this._getCookie('access_token');

		if (accessToken !== '') {
			let data = null;
			data = JSON.stringify({
				job: evalKey
			});

			return jobAjaxReadOnly('/list', data, 'POST', (err, res) => {
				if (err) {
					callback(err);
				} else {
					// TODO: The MasterLST property always includes a weird "length" property (even though MasterLST is dictionary object)
					callback(null, res);
				}
			});
		}
		return Promise.reject(new Error('No token'));
	},
	// Reading data is now being minimized to just send the data needed
	minimizeReadingsOutput(readingsData) {
		const prepareData = [];

		const keyMap = {
			comments: 'cmts',
			StationId: 'sId',
			time: 't',
			reading1: 'r1',
			reading2: 'r2'
		};

		readingsData.forEach(item => {
			item.readings.forEach(reading => {
				let obj = pick(reading.value, [
					'reading1',
					'reading2',
					'time',
					'StationId'
				]);
				obj = pickBy(obj, identity);
				obj = mapKeys(obj, (value, key) => {
					return keyMap[key];
				});
				prepareData.push(obj);
			});
		});
		return prepareData;
	},

	// Keep around for posterity but is no longer used
	autoCorrectReadingsOriginal(readingsData) {
		const gpsReadingsSets = [JobUtil.minimizeReadingsOutput(readingsData)];
		return new Promise((res, rej) => {
			jobAjax(
				'predictReadings',
				JSON.stringify({ gpsReadingsSets }),
				'POST',
				(err, response) => {
					if (err) {
						rej(err);
					} else {
						const autoCorrectedReadings = this.copyReadingCorrectionResponseToOriginalData(
							response,
							readingsData
						);
						res({
							autoCorrectedReadings,
							response
						});
					}
				},
				false,
				'mlServerLambdaApi'
			);
		});
	},

	getSegmentGroupsForAutoCorrection(gpsReadings) {
		const numberOfReadings = gpsReadings.length;
		// We don't want to spin too many lambdas, let's try to keep a max of around 10 lambdas per call
		let maxReadingsPerGroup;
		if (numberOfReadings < 10000) {
			maxReadingsPerGroup = 1000;
		} else if (numberOfReadings >= 10000 && numberOfReadings < 20000) {
			maxReadingsPerGroup = 2000;
		} else {
			maxReadingsPerGroup = 3500;
		}

		const numberOfGroups = Math.ceil(numberOfReadings / maxReadingsPerGroup);
		const readingsPerGroup = Math.ceil(numberOfReadings / numberOfGroups);

		const emptyArrayOfGroups = Array.from(Array(numberOfGroups).keys());

		const segmentGroups = emptyArrayOfGroups.map((emptyArray, i) => [
			i * readingsPerGroup,
			(i + 1) * readingsPerGroup
		]);

		return { numberOfReadings, segmentGroups };
	},

	callMLAutoCorrectionAjax(predictionType = 'predictReadings', data) {
		return new Promise((res, rej) => {
			jobAjax(
				predictionType,
				JSON.stringify(data),
				'POST',
				(err, response) => {
					if (err) {
						rej(err);
					} else {
						res(response);
					}
				},
				false,
				'mlServerLambdaApi'
			);
		});
	},

	countReadingsByFieldName(gpsreadings) {
		let reading1Count = 0;
		let reading2Chount = 0;

		for (let i = 0; i < gpsreadings.length; i += 1) {
			const reading = gpsreadings[i];

			if (reading.reading1) {
				reading1Count += 1;
			}
			if (reading.reading2) {
				reading2Chount += 1;
			}
		}

		return {
			reading1Count,
			reading2Chount
		};
	},

	createStatisticsFromMergedResponses(
		readingsLength,
		flagLedgers,
		gpsreadings
	) {
		const { coordinates, readings1, readings2 } = flagLedgers;

		if (flagLedgers.coordinates.length) {
			return {
				numberChanged: coordinates.length,
				percentChanged: (coordinates.length / readingsLength) * 100
			};
		}

		const { reading1Count, reading2Chount } = this.countReadingsByFieldName(
			gpsreadings
		);

		return {
			numberReading1Changed: readings1.length,
			percentChangedReading1: (readings1.length / reading1Count) * 100,
			numberReading2Changed: readings2.length,
			percentChangedReading2: (readings2.length / reading2Chount) * 100
		};
	},

	mergeAutoCorrectionResponseFromSegments(
		responses,
		readingsLength,
		gpsreadings
	) {
		const abTestInfo = responses[0].ab_test_info; // The first response always has the abTestInfo

		const mergedFlagLedgersAndResponse = {};

		const newFlagLedger = {
			coordinates: [],
			readings1: [],
			readings2: []
		};
		responses.forEach(gpsReadingSet => {
			const response = gpsReadingSet.gpsreadings;

			Object.keys(response).forEach(key => {
				const flagLedger = response[key];

				newFlagLedger[key] = [...newFlagLedger[key], ...flagLedger];
			});

			return newFlagLedger;
		});
		mergedFlagLedgersAndResponse.gpsreadings = newFlagLedger;

		mergedFlagLedgersAndResponse.ab_test_info = abTestInfo;
		mergedFlagLedgersAndResponse.outputStatistics = this.createStatisticsFromMergedResponses(
			readingsLength,
			mergedFlagLedgersAndResponse.gpsreadings,
			gpsreadings
		);

		return mergedFlagLedgersAndResponse;
	},

	callAutoCorrectionWithSegments(
		predictionType = 'predictReadings',
		gpsreadingsurl,
		gpsreadings,
		_modelToUse
	) {
		const s3Url = new URL(gpsreadingsurl).pathname.replace(/^\/+/g, '');

		const {
			numberOfReadings,
			segmentGroups
		} = this.getSegmentGroupsForAutoCorrection(gpsreadings);

		const modelToUse = _modelToUse || AutoCorrectionUtil.getRandomModelToUse();

		const options = {
			minimize: false,
			should_flatten_output: true,
			s3Url,
			modelToUse
		};

		const ajaxCalls = segmentGroups.map(segments =>
			this.callMLAutoCorrectionAjax(predictionType, {
				...options,
				segments
			})
		);

		return Promise.all(ajaxCalls).then(responseGroups => {
			const response = this.mergeAutoCorrectionResponseFromSegments(
				responseGroups,
				numberOfReadings,
				gpsreadings
			);
			return response;
		});
	},

	autoCorrectReadings(gpsreadingsurl, gpsreadings, modelToUse) {
		return this.callAutoCorrectionWithSegments(
			'predictReadings',
			gpsreadingsurl,
			gpsreadings,
			modelToUse
		);
	},

	autoCorrectCoordinates(data) {
		return new Promise((res, rej) => {
			jobAjax(
				'predictCoordinates',
				JSON.stringify(data),
				'POST',
				(err, response) => {
					if (err) {
						rej(err);
					} else {
						res(response);
					}
				},
				false,
				'mlServerApi'
			);
		});
	},

	autoCorrectCoordinatesByS3Url(
		gpsReadingsUrl = [],
		gpsReadingSets,
		modelToUse
	) {
		return Promise.all(
			gpsReadingsUrl.map((gpsreadingsurl, i) => {
				const gpsreadings = gpsReadingSets[i];
				return this.callAutoCorrectionWithSegments(
					'predictCoordinates',
					gpsreadingsurl,
					gpsreadings,
					modelToUse
				);
			})
		).then(results => {
			return results;
		});
	},

	filter(evalKey, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			let data = null;
			if (evalKey) {
				data = JSON.stringify({
					assignTo: evalKey
				});
			}
			jobAjaxReadOnly('/filter', data, 'POST', (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			});
		}
	},
	flip(data, callback) {
		jobAjax('/flip', JSON.stringify(data), 'POST', callback);
	},
	addStn(data, callback) {
		jobAjaxforExtended('/addStn', JSON.stringify(data), 'POST', callback);
	},
	moveToSegment(data, callback) {
		jobAjaxforExtended(
			'/moveToSegment',
			JSON.stringify(data),
			'POST',
			callback
		);
	},

	changeFileStatus(data, callback) {
		jobAjaxforExtended(
			'/changeFileStatus',
			JSON.stringify(data),
			'POST',
			callback
		);
	},

	overlapCommentsOnly(data, callback) {
		jobAjax('/overlapCommentsOnly', JSON.stringify(data), 'POST', callback);
	},

	merge(trims, jobguid, fileName, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				trims,
				jobguid,
				fileName
			});
			jobAjax('/merge', data, 'POST', callback);
		}
	},
	deleteTrim(trimName, jobguid, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				job: trimName,
				jobguid
			});
			jobAjax('/deleteTrim', data, 'POST', (err, res) => {
				if (err) {
					callback(err);
				} else {
					jQuery.extend(this.job, res.Attributes);
					callback(null, res);
				}
			});
		}
	},
	changeStatus(job, newStatus, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				job,
				newStatus
			});

			jobAjaxforExtended('/changeStatus', data, 'POST', (err, res) => {
				callback(err, res);
			});
		}
	},
	changeAssistingDP(job, assistingDP, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				job,
				assistingDP
			});

			jobAjaxforExtended('/changeAssistingDP', data, 'POST', (err, res) => {
				callback(err, res);
			});
		}
	},
	editJob(job, updatedjob, lineNo, checkedItems, fromJobCard, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				oldJobNo: job,
				newJobNo: updatedjob,
				flag: 'DP',
				lineName: lineNo,
				checkedItems,
				fromJobCard
			});

			jobAjax('/editJob', data, 'POST', (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			});
		}
	},
	updateLineLevelDetails(formdata, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				key: formdata.key,
				updatedValue: formdata.updatedValue,
				jobguids: formdata.jobguids
			});
			jobAjax('/updateLineLevelDetails', data, 'POST', (err, res) => {
				if (err) {
					callback(err);
				} else {
					jQuery.extend(this.job, res.Attributes);
					callback(null, res);
				}
			});
		}
	},
	deleteOtherFile(otherFileName, jobguid, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				otherFileName,
				jobguid
			});
			jobAjaxforExtended('/deleteS3Object', data, 'POST', (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			});
		}
	},
	deleteDatRef(datName, jobguid, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				datName,
				jobguid
			});
			jobAjax('/deleteDatRef', data, 'POST', (err, res) => {
				if (err) {
					callback(err);
				} else {
					jQuery.extend(this.job, res.Attributes);
					callback(null, res);
				}
			});
		}
	},
	deleteAllDat(jobguid, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				jobguid
			});
			jobAjax('/deleteAllDatRef', data, 'POST', (err, res) => {
				if (err) {
					callback(err);
				} else {
					jQuery.extend(this.job, res.Attributes);
					callback(null, res);
				}
			});
		}
	},

	items(callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			jobAjax('/thisweekjobs', '', 'POST', (err, res) => {
				callback(null, res);
			});
		}
	},
	customers(callback) {
		jobAjaxReadOnly('/customers', '', 'POST', callback);
	},
	assignTos(role, callback) {
		jobAjaxReadOnly('/assignTos', JSON.stringify({ role }), 'POST', callback);
	},
	pmsOrCts(customer, groupName, callback) {
		const data = JSON.stringify({
			customer,
			groupName
		});

		jobAjaxReadOnly('/pmsorcts', data, 'POST', callback);
	},

	entities(customer, callback) {
		jobAjaxReadOnly('/entity', JSON.stringify({ customer }), 'POST', callback);
	},
	datmapper(job, callback) {
		jobAjax('/datmapper', JSON.stringify({ job }), callback);
	},
	create(formdata, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			jobAjax('/createjob', JSON.stringify(formdata), 'POST', (err, res) => {
				if (err) {
					if (err.status === 409) {
						callback(JSON.stringify('Job already exists'), null);
					} else {
						callback(err, null);
					}
				} else {
					this.job = res.Item;
					callback(null, res);
				}
			});
		}
	},
	uploadFile(file, policyData, callback) {
		const fd = new FormData();
		fd.append('key', policyData.key);
		fd.append('acl', 'private');
		fd.append('Content-Type', file.type);
		fd.append('AWSAccessKeyId', policyData.access_key);
		fd.append('policy', policyData.encoded_policy);
		fd.append('signature', policyData.signature);
		fd.append('file', file, file.name);
		ajax({
			url: policyData.upload_url,
			method: 'POST',
			data: fd,
			processData: false,
			contentType: false
		})
			.then(() => {
				callback(null, 'Uploaded');
			})
			.catch(response => {
				callback(response);
			});
	},
	checkupload(job, fileName, callback) {
		let counter = 0;
		const interval = setInterval(() => {
			jobAjax(
				'/checkupload',
				JSON.stringify({ job, fileName }),
				'POST',
				(err, res) => {
					if (err) {
						callback(err, res);
					}
					if (res) {
						if (res.Item.MasterLST) {
							if (fileName.toLowerCase().indexOf('.dat') === -1) {
								clearInterval(interval);
								callback(err, res);
							} else {
								Object.keys(res.Item.MasterLST).forEach(attr => {
									if (attr !== 'length') {
										const datFile = res.Item.MasterLST[attr];
										if (
											datFile.calcStart !== undefined &&
											(datFile.isDeleted === false ||
												datFile.isDeleted === undefined)
										) {
											clearInterval(interval);
											callback(err, res);
										}
									}
								});
							}
						}
					}
				}
			);
			counter += 1;
			if (counter === 32) {
				clearInterval(interval);
				callback(new Error('Timeout'));
			}
		}, 1000);
	},

	exportLST(jobguid, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			jobAjaxReadOnly(`/export?jobguid=${jobguid}`, null, 'GET', callback);
		}
	},
	undoMapEdit(jobguid, datFile, fileKey, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				jobguid,
				datFile,
				fileKey
			});
			return jobAjaxforExtended(
				'/deleteS3Version',
				data,
				'POST',
				(err, res) => {
					if (callback) {
						callback(err, res);
					}
				}
			);
		}
		return Promise.reject(new Error('Not Logged In'));
	},
	changeReading(jobguid, datFile, fileKey, onOff, station, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				jobguid,
				datFile,
				fileKey,
				onOff,
				station
			});
			jobAjaxforExtended('/changeReading', data, 'POST', (err, res) => {
				callback(err, res);
			});
		}
	},

	overlay(jobguid, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				jobguid
			});
			jobAjaxforExtended('/overlay', data, 'POST', (err, res) => {
				callback(err, res);
			});
		}
	},

	changeMultipleReading(
		jobguid,
		datFile,
		fileKey,
		onAvgLowerthreshold,
		onAvgUpperthreshold,
		offAvgLowerthreshold,
		offAvgUpperthreshold,
		startStation,
		endStation,
		callback
	) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			const data = JSON.stringify({
				jobguid,
				datFile,
				fileKey,
				onAvgLowerthreshold,
				onAvgUpperthreshold,
				offAvgLowerthreshold,
				offAvgUpperthreshold,
				startStation,
				endStation
			});
			jobAjaxforExtended('/changeMultipleReading', data, 'POST', (err, res) => {
				callback(err, res);
			});
		}
	},
	updateS3Tree(data, callback) {
		jobAjaxforExtended('/updateS3Tree', JSON.stringify(data), 'POST', callback);
	},

	_ajaxforExtended(url, data, method, callback, isAnonymous) {
		const accessToken = TokenUtil.getCookie('access_token');
		if (accessToken !== null || isAnonymous) {
			const headers = {
				'Content-Type': 'application/json'
			};
			if (!isAnonymous) {
				headers.Authorization = accessToken;
			}
			return ajax({ aip: 'cisviewextended', data, url, headers, method })
				.then(res => {
					if (callback) {
						callback(null, res);
					}
					return res;
				})
				.catch(err => {
					callback(err);
				});
		}
		callback(new Error('Not Logged In'));
		return Promise.reject(new Error('Not Logged In'));
	}
};

export default JobUtil;
