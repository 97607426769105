/* eslint-disable import/no-cycle */
import {
	setCustomers,
	setCustomersApplications,
	setCustomersApplicationsChangesClone,
	setIsLoadingLoadedCustomers,
	setHasLoadingErrorCustomers,
	setSelectedCustomer,
	setSelectedCustomerChangesClone
} from './actions';
import { ROOT_SLICE } from './constants';
import {
	selectRootSlice,
	selectIsLoadingCustomers,
	selectIsLoadedCustomers,
	selectHasLoadingErrorCustomers
} from './selectors';

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
	[setCustomers]: (state, { payload: { customers } }) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, customers }
		};
	},

	[setCustomersApplications]: (
		state,
		{ payload: { customersApplications } }
	) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, customersApplications }
		};
	},

	[setCustomersApplicationsChangesClone]: (
		state,
		{ payload: { customersApplicationsChangesClone } }
	) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, customersApplicationsChangesClone }
		};
	},

	[setHasLoadingErrorCustomers]: (state, { payload: { hasError } }) => {
		const rootSlice = selectRootSlice(state);
		const currentHasError = selectHasLoadingErrorCustomers(state);

		if (hasError === currentHasError) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				hasLoadingErrorCustomers: hasError
			}
		};
	},

	[setSelectedCustomer]: (state, { payload: { selectedCustomer } }) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, selectedCustomer }
		};
	},

	[setSelectedCustomerChangesClone]: (
		state,
		{ payload: { selectedCustomerChangesClone } }
	) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, selectedCustomerChangesClone }
		};
	},

	[setIsLoadingLoadedCustomers]: (
		state,
		{
			payload: {
				isLoadingCustomers: newIsLoadingCustomers,
				isLoadedCustomers: newIsLoadedCustomers
			}
		}
	) => {
		const rootSlice = selectRootSlice(state);
		const isLoadingCustomers = selectIsLoadingCustomers(state);
		const isLoadedCustomers = selectIsLoadedCustomers(state);

		const isLoadingChanged =
			isDefined(newIsLoadingCustomers) &&
			newIsLoadingCustomers !== isLoadingCustomers;
		const isLoadedChanged =
			isDefined(newIsLoadedCustomers) &&
			newIsLoadedCustomers !== isLoadedCustomers;

		if (!isLoadingChanged && !isLoadedChanged) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isLoadingCustomers: isLoadingChanged
					? newIsLoadingCustomers
					: isLoadingCustomers,
				isLoadedCustomers: isLoadedChanged
					? newIsLoadedCustomers
					: isLoadedCustomers
			}
		};
	}
};

export default reducerParts;
