/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';

// ADMIN APPLICATIONS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_APPLICATIONS } from './slices/Applications/constants';
import { initialState as AdminApplicationsInitialState } from './slices/Applications/initialState';
import AdminApplicationsReducerParts from './slices/Applications/reducerParts';

// ADMIN COMPANIES
import { ROOT_SLICE as ROOT_SLICE_ADMIN_COMPANIES } from './slices/Companies/constants';
import { initialState as AdminCompaniesInitialState } from './slices/Companies/initialState';
import AdminCompaniesReducerParts from './slices/Companies/reducerParts';

// ADMIN CUSTOMERS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_CUSTOMERS } from './slices/Customers/constants';
import { initialState as AdminCustomersInitialState } from './slices/Customers/initialState';
import AdminCustomersReducerParts from './slices/Customers/reducerParts';

// ADMIN USERS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_USERS } from './slices/Users/constants';
import { initialState as AdminUsersInitialState } from './slices/Users/initialState';
import AdminUsersReducerParts from './slices/Users/reducerParts';

// ADMIN USERS SEARCH
import { ROOT_SLICE as ROOT_SLICE_ADMIN_USERS_SEARCH } from './slices/UsersSearch/constants';
import { initialState as AdminUsersSearchInitialState } from './slices/UsersSearch/initialState';
import AdminUsersSearchReducerParts from './slices/UsersSearch/reducerParts';

// ADMIN PERMISSION DEFINITIONS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_PERMISSION_DEFINITIONS } from './slices/PermissionDefinitions/constants';
import { initialState as AdminPermissionDefinitionsInitialState } from './slices/PermissionDefinitions/initialState';
import AdminPermissionDefinitionsReducerParts from './slices/PermissionDefinitions/reducerParts';

// ADMIN SAVE ERRORS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_SAVE_ERRORS } from './slices/SaveErrors/constants';
import { initialState as AdminSaveErrorsInitialState } from './slices/SaveErrors/initialState';
import AdminSaveErrorsReducerParts from './slices/SaveErrors/reducerParts';

const initialState = {
	[ROOT_SLICE_ADMIN_APPLICATIONS]: {
		...AdminApplicationsInitialState
	},
	[ROOT_SLICE_ADMIN_COMPANIES]: {
		...AdminCompaniesInitialState
	},
	[ROOT_SLICE_ADMIN_CUSTOMERS]: {
		...AdminCustomersInitialState
	},
	[ROOT_SLICE_ADMIN_USERS]: {
		...AdminUsersInitialState
	},
	[ROOT_SLICE_ADMIN_USERS_SEARCH]: {
		...AdminUsersSearchInitialState
	},
	[ROOT_SLICE_ADMIN_PERMISSION_DEFINITIONS]: {
		...AdminPermissionDefinitionsInitialState
	},
	[ROOT_SLICE_ADMIN_SAVE_ERRORS]: {
		...AdminSaveErrorsInitialState
	}
};

const reducerParts = {
	...AdminApplicationsReducerParts,
	...AdminCompaniesReducerParts,
	...AdminCustomersReducerParts,
	...AdminUsersReducerParts,
	...AdminUsersSearchReducerParts,
	...AdminPermissionDefinitionsReducerParts,
	...AdminSaveErrorsReducerParts
};

export default handleActions(reducerParts, initialState);
