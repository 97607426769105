import { updateBrush } from '../../../../actions/dataRangeAndZoom';
import { setZoomXYMinMax } from './actions';

const isChartId = val => {
	if (typeof val !== 'string') {
		return false;
	}
	const parts = val.split('-');
	return parts[0].toLowerCase() === 'sfchart';
};

const thunkSetZoomXYMinMax = (key, { xMin, xMax, yMin, yMax }) => dispatch => {
	dispatch(setZoomXYMinMax(key, { xMin, xMax, yMin, yMax }));
	dispatch(updateBrush({ xMin, xMax }));
};

const thunkHandleZoomSyncEvent = ({
	key,
	yMin,
	yMax,
	xMin,
	xMax
}) => dispatch => {
	if (isChartId(key)) {
		dispatch(thunkSetZoomXYMinMax(key, { xMin, xMax, yMin, yMax }));
	}
};

export { thunkSetZoomXYMinMax, thunkHandleZoomSyncEvent };

export default {
	thunkSetZoomXYMinMax,
	thunkHandleZoomSyncEvent
};
