/* eslint import/no-cycle:0 */
import {
	SET_SURVEYS,
	SET_VISIBLE_SURVEYS,
	SET_CONVERTED_READINGS,
	SET_FILTERS
} from '../actions/additionalSurveys';

const defaultState = {
	filtersByType: {
		// will look like:
		// 'DEPOL': { filterText, filteredReadings }
	},
	surveys: [],
	datFilesBySurveyType: {
		// will look like:
		// 'DEPOL': [{gpsreadings: ....}]
	},
	visibleSurveys: [],
	convertedReadingsBySurveytype: {
		// Will look like:
		// 'DEPOL': {'file.csv': []}
	}
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_SURVEYS:
			return {
				...state,
				surveys: payload.surveys,
				datFilesBySurveyType: payload.datFilesBySurveyType
			};
		case SET_VISIBLE_SURVEYS:
			return { ...state, visibleSurveys: payload };
		case SET_CONVERTED_READINGS:
			return { ...state, convertedReadingsBySurveytype: payload };
		case SET_FILTERS:
			return {
				...state,
				filtersByType: payload.filtersByType
			};
		default:
			return state;
	}
};

export default reducer;
