import getMetaByApp from './getMetaByApp';

const mapToBASE = (view, index, parsers) => {
	const {
		getId,
		getSurveyType,
		getLongitude,
		getLattitude,
		getDateCreated
	} = parsers;
	return {
		id: getId(view, index),
		type: getSurveyType(view, index),
		loc: [getLongitude(view, index), getLattitude(view, index)],
		date: getDateCreated(view, index)
	};
};

const getMapTos = appName => {
	const { parsers } = getMetaByApp(appName);

	const { getReadingValue1, getReadingValue2 } = parsers;

	return {
		ON_OFF: (view, index) => ({
			...mapToBASE(view, index, parsers),
			on: getReadingValue1(view, index) / 1000,
			off: getReadingValue2(view, index) / 1000
		}),

		'ON-OFF': (view, index) => ({
			...mapToBASE(view, index, parsers),
			on: getReadingValue1(view, index) / 1000,
			off: getReadingValue2(view, index) / 1000
		}),

		ON: (view, index) => ({
			...mapToBASE(view, index, parsers),
			on: getReadingValue1(view, index) / 1000
		}),

		DOC: (view, index) => ({
			...mapToBASE(view, index, parsers),
			topDep: getReadingValue1(view, index) / 100 || undefined,
			elcDep: getReadingValue2(view, index) / 100 || undefined
			// { label: 'TOP OF PIPE', value: 'topDep', checked: true, width: 54 },
			// { label: 'CENTER OF PIPE', value: 'copDep', checked: true, width: 54 },
			// { label: 'ELECTRIC DEPTH', value: 'elcDep', checked: true, width: 54 },
			// { label: 'PROBE DEPTH', value: 'prbDep', checked: true, width: 54 },
		}),

		REL: (view, index) => ({
			...mapToBASE(view, index, parsers),
			gRate: getReadingValue1(view, index),
			dep: getReadingValue2(view, index)
		}),

		DEPOL: (view, index) => ({
			...mapToBASE(view, index, parsers),
			on: getReadingValue1(view, index) / 1000
		}),

		NATIVE: (view, index) => ({
			...mapToBASE(view, index, parsers),
			on: getReadingValue1(view, index) / 1000
		}),

		DCVG: (view, index) => ({
			...mapToBASE(view, index, parsers),
			ir_pct: getReadingValue1(view, index) / 1000
		}),

		ACCA: (view, index) => ({
			...mapToBASE(view, index, parsers),
			ir_pct: getReadingValue1(view, index) / 1000
		}),

		ACVG: (view, index) => ({
			...mapToBASE(view, index, parsers),
			decMicroV: getReadingValue1(view, index) / 100,
			normDecMicroV: getReadingValue2(view, index) / 100
		})
	};
};

export const getReadingMapper = (surveyType, appName) => {
	const MAP_TO = getMapTos(appName);
	return MAP_TO[surveyType] || MAP_TO.ON_OFF;
};

export default {
	getReadingMapper
};

// @note-supported-constant-needs-abstraction
