/* eslint-disable no-param-reassign */
import { isDate } from 'aegion_common_utilities/lib/DateUtil';
import { MM_DD_YYYY } from 'aegion_common_utilities/lib/format/datetimeless';
import createBaseSurveyMeta from './_BASE';
import colors from '../../Colors';

import interp from './DEPOL.NATIVE.interpolate';
import {
	computeUuid,
	computeFormattedStationId,
	computePipeDepth,
	computeAC,
	computeSpatial,
	computeDistance,
	computeMvTransform,
	assumeIsInMV
} from '../util/readings.parse.computeProps';
import { formatMillivolt } from '../../formatNumber';
import { createComputeYMinYMax } from '../util/yMinYMax';

const MASTER_SURVEY_SUBTYPES = ['ON_OFF', 'ON-OFF', 'ONOFF', 'ON', 'OFF'];

const INVERT_Y_AXIS = true;

const createInterpolate = (propKeys, isSkipReading, hasGap) => {
	return (targetReadings, targetReadingsIndexMap, referenceReadings) => {
		return interp(
			targetReadings,
			targetReadingsIndexMap,
			referenceReadings,
			propKeys,
			isSkipReading,
			hasGap
		);
	};
};

// eslint-disable-next-line no-unused-vars
export default _survey => {
	// CONSTANTS

	const gapThreshold = 20;
	const voltPercentThreashold = 5;

	// READING KEYS

	const readingKeys = [
		{
			label: 'DEPOL',
			value: 'on',
			seriesType: 'Line',
			color: colors.getColor(),
			getValue: r => formatMillivolt(r.on)
		},
		{
			label: 'SHADOW',
			value: 'sh',
			seriesType: 'Line',
			color: colors.getColor()
		}
	];
	const readingKeyProps = readingKeys.map(k => k.value);
	const computeYMinYMax = createComputeYMinYMax(readingKeys);

	// HAS MASTER SURVEY
	const hasMasterSurvey = (survey = {}, surveys = []) => {
		return surveys.find(s => {
			return (
				s.id !== survey.id &&
				s.job_id === survey.job_id &&
				MASTER_SURVEY_SUBTYPES.indexOf(s.survey_subtype) > -1
			);
		});
	};

	// SKIP READING

	const isSkipReading = reading =>
		reading && readingKeys.every(rk => !reading[rk.value]);

	// HAS GAP

	const hasGap = (currReading, prevReading) => {
		const hasGapByStationId =
			!!prevReading && currReading.id - prevReading.id > gapThreshold;
		const hasGapByDistance = +currReading.dst > gapThreshold;
		return hasGapByStationId || hasGapByDistance;
	};

	// INTERPOLATE

	const interpolate = createInterpolate(readingKeyProps, isSkipReading, hasGap);

	const computeHilo = r => {
		r.low = r.low || 0;
	};

	const computeShadow = r => {
		if (r.on) {
			r.sh = r.on - 100;
		}
	};

	const shouldStandardizeToMV = (cntReadings, cntVoltReadings) => {
		const voltPercent = (cntReadings / cntVoltReadings) * 100;
		return voltPercent >= voltPercentThreashold;
	};

	const standardizeToMV = reading => {
		computeMvTransform(reading, 'on');
		computeShadow(reading);
	};

	// @note - @tobedeleted once mvfuzzy logic is removed;
	const computeAggregates = (currReading, aggregates = {}) => {
		aggregates.cntVoltReadings =
			(aggregates.cntVoltReadings || 0) +
			(assumeIsInMV(currReading.on) ? 0 : 1);

		return aggregates;
	};
	const computeProps = (survey, prevReading, currReading, currIdx) => {
		computeUuid(currReading, currIdx);
		computeFormattedStationId(currReading);
		computePipeDepth(currReading);
		computeAC(currReading);
		computeSpatial(currReading);
		computeDistance(prevReading, currReading);
		computeHilo(currReading);
		computeShadow(currReading);
		computeYMinYMax(survey, currReading);
	};

	// SIMPLIFIED READINGS - creates a smaller data set to be used for rendering polyline on map
	// most survey types will not have simplified readings
	// eslint-disable-next-line no-unused-vars
	const createSimplifiedReadings = ({ readings, subtype }) => undefined;

	// MAIN

	const meta = {
		...createBaseSurveyMeta(_survey),
		computeProps,
		computeYMinYMax,
		isChartInversed: INVERT_Y_AXIS,
		readingKeys,
		readingKeyProps,
		fkey: 'depol',
		columns: [
			{
				label: 'ID',
				value: 'formattedStationId',
				checked: true,
				width: 100,
				required: true
			},
			{ label: 'ON', value: 'on', checked: true, width: 44 },
			{ label: 'DISTANCE', value: 'dst', checked: true, width: 80 },
			{ label: 'LAT', value: 'lat', checked: true, width: 65 },
			{ label: 'LNG', value: 'lng', checked: true, width: 70 },
			{
				label: 'DATE',
				value: 'date',
				checked: false,
				width: 120,
				getValue: r => (isDate(r.date) ? MM_DD_YYYY(r.date) : '')
			},
			{
				label: 'COMMENTS',
				value: 'cmt',
				checked: true,
				width: 255,
				type: 'text'
			}
		],
		isSkipReading,
		hasGap,
		interpolate,
		canUseInterpolate: true,
		canUseNormalize: true,
		shouldCheckMVOnLoad: true,
		shouldStandardizeToMV,
		standardizeToMV,
		computeAggregates,
		createSimplifiedReadings,
		hasMasterSurvey
	};

	return meta;
};
