/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import {
	getCustomers,
	updateCustomer,
	deleteCustomer,
	insertCustomer
} from '../../../../api/customers';
import {
	setCustomers,
	setSelectedCustomer,
	setSelectedCustomerChangesClone,
	setErrorMessageSaveEditedCustomer,
	setIsSavingEditedCustomer,
	setCustomersApplications,
	setCustomersApplicationsChangesClone
} from './actions';

import {
	selectSelectedCustomerChangesClone,
	selectCustomers,
	selectCustomersApplications,
	selectCustomersApplicationsChangesClone,
	selectHasCustomerChanges,
	selectHasCustomersApplicationsChanges
} from '../selectors';

import { createNewCustomerObject } from '../util';

import { selectRootSliceAdmin } from '../../../Main/selectors';
import { loadApplicationsIfMissing } from '../../Applications/actions/thunks';
import {
	getCustomersApplications,
	insertCustomersApplication,
	deleteCustomersApplication
} from '../../../../api/customersApplications';
import { selectApplications } from '../../Applications/selectors';

const loadCustomers = () => dispatch => {
	getCustomers().then(customers => {
		dispatch(setCustomers(customers));
	});
};

const loadCustomerApplcations = (customer = {}) => dispatch => {
	const { id } = customer;
	if (id === undefined) {
		dispatch(setCustomersApplications(undefined));
		dispatch(setCustomersApplicationsChangesClone(undefined));
		return;
	}
	getCustomersApplications(id).then(customerApps => {
		const clone = customer ? _.cloneDeep(customerApps) : customer;
		dispatch(setCustomersApplications(customerApps));
		dispatch(setCustomersApplicationsChangesClone(clone));
	});
};

const thunkSetSelectedCustomer = (
	customer,
	isLoadCustomerApplcations = true
) => dispatch => {
	const clone = customer ? _.cloneDeep(customer) : customer;
	dispatch(setSelectedCustomer(customer));
	dispatch(setSelectedCustomerChangesClone(clone));
	dispatch(loadApplicationsIfMissing());
	if (isLoadCustomerApplcations) {
		dispatch(loadCustomerApplcations(customer));
	}
};

const thunkSetErrorMessageSaveEditedCustomer = error => dispatch => {
	if (typeof error === 'string') {
		dispatch(setErrorMessageSaveEditedCustomer(error));
	} else if (error instanceof Object) {
		const { message } = error;
		if (message) {
			dispatch(setErrorMessageSaveEditedCustomer(message));
		} else {
			dispatch(setErrorMessageSaveEditedCustomer('An unknown error occured'));
		}
	} else {
		dispatch(setErrorMessageSaveEditedCustomer('An unknown error occured'));
	}
};

const _thunkReplaceCustomerWithUpdatedCustomer = (updatedCustomer = {}) => (
	dispatch,
	getState
) => {
	if (updatedCustomer.id) {
		const stateAdmin = selectRootSliceAdmin(getState());
		const newCustomers = [...selectCustomers(stateAdmin)];

		const currentIndex = newCustomers.findIndex(
			app => app.id === updatedCustomer.id
		);
		if (currentIndex > -1) {
			newCustomers[currentIndex] = updatedCustomer;
		} else {
			newCustomers.push(updatedCustomer);
			newCustomers.sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			});
		}

		dispatch(setCustomers(newCustomers));
	}
};

const _thunkRemoveCustomer = (updatedCustomer = {}) => (dispatch, getState) => {
	if (updatedCustomer.id) {
		const stateAdmin = selectRootSliceAdmin(getState());
		const newCustomers = [...selectCustomers(stateAdmin)];

		const currentIndex = newCustomers.findIndex(
			app => app.id === updatedCustomer.id
		);
		if (currentIndex > -1) {
			newCustomers.splice(currentIndex, 1);
		}

		dispatch(setCustomers(newCustomers));
	}
};

const thunkSaveEditedCustomerProps = () => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const editedCustomer = selectSelectedCustomerChangesClone(stateAdmin);
	const hasCustomerChanges = selectHasCustomerChanges(stateAdmin);
	if (hasCustomerChanges && editedCustomer) {
		let promise;
		if (editedCustomer.id) {
			promise = updateCustomer({ customer: editedCustomer });
		} else {
			promise = insertCustomer({ customer: editedCustomer });
		}
		return promise
			.then(updatedCustomer => {
				dispatch(_thunkReplaceCustomerWithUpdatedCustomer(updatedCustomer));
				dispatch(thunkSetSelectedCustomer(updatedCustomer, false));
				return updatedCustomer;
			})
			.catch(err => {
				dispatch(thunkSetErrorMessageSaveEditedCustomer(err));
			});
	}
	return Promise.resolve({});
};

const thunkSaveEditedCustomerApplications = () => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const editedCustomer = selectSelectedCustomerChangesClone(stateAdmin);
	const applications = selectApplications(stateAdmin);
	const customersApplications = selectCustomersApplications(stateAdmin) || [];
	const editedCustomersApplications = selectCustomersApplicationsChangesClone(
		stateAdmin
	);
	const hasCustomersApplicationChanges = selectHasCustomersApplicationsChanges(
		stateAdmin
	);
	const promises = [];
	if (hasCustomersApplicationChanges && editedCustomersApplications) {
		dispatch(setErrorMessageSaveEditedCustomer(undefined));
		dispatch(setIsSavingEditedCustomer(true));
		promises.push(
			...applications
				.filter(app => {
					const { id } = app;
					if (
						customersApplications.some(ca => ca.id === id) &&
						editedCustomersApplications.some(e => e.id === id)
					) {
						return false;
					}
					if (
						!customersApplications.some(ca => ca.id === id) &&
						!editedCustomersApplications.some(e => e.id === id)
					) {
						return false;
					}
					return true;
				})
				.map(app => {
					const { id } = app;
					if (
						customersApplications.some(ca => ca.id === id) &&
						!editedCustomersApplications.some(e => e.id === id)
					) {
						return deleteCustomersApplication(editedCustomer.id, id);
					}
					if (
						!customersApplications.some(ca => ca.id === id) &&
						editedCustomersApplications.some(e => e.id === id)
					) {
						return insertCustomersApplication(editedCustomer.id, id);
					}
					return Promise.resolve({ action: 'unknow', app });
				})
		);
	}
	return Promise.all(promises).catch(err => {
		dispatch(thunkSetErrorMessageSaveEditedCustomer(err));
	});
};

const thunkSaveEditedCustomer = () => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const editedCustomer = selectSelectedCustomerChangesClone(stateAdmin);
	const editedCustomersApplications = selectCustomersApplicationsChangesClone(
		stateAdmin
	);
	const hasCustomerChanges = selectHasCustomerChanges(stateAdmin);
	const hasCustomersApplicationChanges = selectHasCustomersApplicationsChanges(
		stateAdmin
	);
	if (
		(hasCustomersApplicationChanges && editedCustomersApplications) ||
		(hasCustomerChanges && editedCustomer)
	) {
		dispatch(setErrorMessageSaveEditedCustomer(undefined));
		dispatch(setIsSavingEditedCustomer(true));
	}

	dispatch(thunkSaveEditedCustomerProps())
		.then(updatedCustomer => {
			return dispatch(thunkSaveEditedCustomerApplications()).then(() => {
				dispatch(thunkSetSelectedCustomer(updatedCustomer));
			});
		})
		.finally(() => {
			dispatch(setIsSavingEditedCustomer(false));
		});
};

const thunkDeleteCustomer = () => (dispatch, getState) => {
	// eslint-disable-next-line no-alert
	const userRes = window.prompt("Please confirm delete by typing in 'Delete'");
	if (userRes !== 'Delete') {
		return;
	}
	const stateAdmin = selectRootSliceAdmin(getState());
	const editedCustomer = selectSelectedCustomerChangesClone(stateAdmin);

	if (editedCustomer && editedCustomer.id) {
		dispatch(setErrorMessageSaveEditedCustomer(undefined));
		dispatch(setIsSavingEditedCustomer(true));

		deleteCustomer({ customer: editedCustomer })
			.then(deletedCustomer => {
				dispatch(_thunkRemoveCustomer(deletedCustomer));
				dispatch(thunkSetSelectedCustomer(undefined));
			})
			.catch(err => {
				dispatch(thunkSetErrorMessageSaveEditedCustomer(err));
			})
			.finally(() => {
				dispatch(setIsSavingEditedCustomer(false));
			});
	}
};

const thunkInitNewCustomer = () => dispatch => {
	dispatch(thunkSetSelectedCustomer(createNewCustomerObject()));
};

export {
	loadCustomers,
	thunkSetSelectedCustomer,
	thunkSaveEditedCustomer,
	thunkDeleteCustomer,
	thunkInitNewCustomer
};
