export const initialState = {
	customers: [],
	customersApplications: undefined,
	customersApplicationsChangesClone: undefined,
	isLoadingCustomers: false,
	isLoadedCustomers: false,
	hasLoadingErrorCustomers: false,
	selectedCustomer: undefined,
	selectedCustomerChangesClone: undefined
};

export default initialState;
