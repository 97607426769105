"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.createMultiModifierKey = exports.createHasPropModifierKey = exports.createCustomSelectModifierKey = exports.createCombineFunctionModifierKey = exports.createBCFunctionModifierKey = void 0;

var _FUNCTIONS;

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}
/* eslint-disable no-confusing-arrow */


var ITEM_DELIMITER = '|';
var KEY_VALUE_DELIMITER = ':';
var MODIFIER_NONE = 'NONE';
var KEY_THRESHOLD = 'threshold';
var KEY_FUNCTION = 'function';
var KEY_SUBSET_PROP = 'subsetProp';
var FUNC_ALIGNED = 'aligned';
var FUNC_INTERPOLATED = 'interpolated';
var FUNC_ALIGNED_INTERPOLATED = 'alignedInterpolated';
var FUNC_BELOW_CRITERION_THRESHOLD = 'belowCriterionThreshold';
var FUNC_BELOW_CRITERION_100MV = 'belowCriterion100mv';
var FUNC_CUSTOM_SELECT = 'customSelectedValues';
var FUNC_DECIMATE = 'decimate';
var FUNC_HAS_PROP = 'filterHasProp'; // @todo - remove this if we end up not neeeding it to be exported
// eslint-disable-next-line no-unused-vars

var FUNCTIONS = (_FUNCTIONS = {}, _defineProperty(_FUNCTIONS, FUNC_ALIGNED, FUNC_ALIGNED), _defineProperty(_FUNCTIONS, FUNC_INTERPOLATED, FUNC_INTERPOLATED), _defineProperty(_FUNCTIONS, FUNC_ALIGNED_INTERPOLATED, FUNC_ALIGNED_INTERPOLATED), _defineProperty(_FUNCTIONS, FUNC_BELOW_CRITERION_100MV, FUNC_BELOW_CRITERION_100MV), _defineProperty(_FUNCTIONS, FUNC_BELOW_CRITERION_THRESHOLD, FUNC_BELOW_CRITERION_THRESHOLD), _defineProperty(_FUNCTIONS, FUNC_CUSTOM_SELECT, FUNC_CUSTOM_SELECT), _defineProperty(_FUNCTIONS, FUNC_DECIMATE, FUNC_DECIMATE), _defineProperty(_FUNCTIONS, FUNC_HAS_PROP, FUNC_HAS_PROP), _FUNCTIONS);

var isDefined = function isDefined(v) {
  return v !== undefined && v !== null;
};

var isEmptyString = function isEmptyString(v) {
  return typeof v === 'string' && v.trim().length === 0;
};

var createKeyValueItem = function createKeyValueItem(key, value) {
  return isDefined(value) && !isEmptyString(value) ? "".concat(key).concat(KEY_VALUE_DELIMITER).concat(value) : undefined;
};

var createThresholdKeyValue = function createThresholdKeyValue(thresholdValue) {
  return createKeyValueItem(KEY_THRESHOLD, thresholdValue);
};

var createFunctionKeyValue = function createFunctionKeyValue(func) {
  return func ? "".concat(KEY_FUNCTION).concat(KEY_VALUE_DELIMITER).concat(func) : undefined;
};

var selectCombineFunction = function selectCombineFunction(_ref) {
  var _ref$useAligned = _ref.useAligned,
      useAligned = _ref$useAligned === void 0 ? false : _ref$useAligned,
      _ref$useInterpolated = _ref.useInterpolated,
      useInterpolated = _ref$useInterpolated === void 0 ? false : _ref$useInterpolated;

  if (useAligned && useInterpolated) {
    return FUNC_ALIGNED_INTERPOLATED;
  }

  if (useAligned) {
    return FUNC_ALIGNED;
  }

  if (useInterpolated) {
    return FUNC_INTERPOLATED;
  }

  return undefined;
};

var selectBelowCriterionFunction = function selectBelowCriterionFunction(_ref2) {
  var useShadow = _ref2.useShadow;

  if (useShadow) {
    return FUNC_BELOW_CRITERION_100MV;
  }

  return FUNC_BELOW_CRITERION_THRESHOLD;
};

var combineModifierKeyParts = function combineModifierKeyParts() {
  var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var assumedKey = arr.filter(function (v) {
    return !!v;
  }).join(ITEM_DELIMITER);
  return assumedKey.length > 0 ? assumedKey : MODIFIER_NONE;
}; // used for combining CIS & Depol to produce a depol dataset aligned and/or interpolated against CIS


var createCombineFunctionModifierKey = function createCombineFunctionModifierKey(_ref3) {
  var useAligned = _ref3.useAligned,
      useInterpolated = _ref3.useInterpolated;
  var functionName = selectCombineFunction({
    useAligned: useAligned,
    useInterpolated: useInterpolated
  });
  var functionItem = createFunctionKeyValue(functionName);
  return combineModifierKeyParts([functionItem]);
}; // used for below criterion data sets derived from CIS


exports.createCombineFunctionModifierKey = createCombineFunctionModifierKey;

var createBCFunctionModifierKey = function createBCFunctionModifierKey(_ref4) {
  var useShadow = _ref4.useShadow,
      threshold = _ref4.threshold;
  var functionName = selectBelowCriterionFunction({
    useShadow: useShadow
  });
  var functionItem = createFunctionKeyValue(functionName);

  if (useShadow) {
    return combineModifierKeyParts([functionItem]);
  }

  var thresholdValueItem = createThresholdKeyValue(threshold);

  if (thresholdValueItem) {
    return combineModifierKeyParts([functionItem, thresholdValueItem]);
  }

  return MODIFIER_NONE;
}; // used for hasProp


exports.createBCFunctionModifierKey = createBCFunctionModifierKey;

var createHasPropModifierKey = function createHasPropModifierKey(_ref5) {
  var prop = _ref5.prop;

  if (isDefined(prop)) {
    var functionItem = createFunctionKeyValue(FUNC_HAS_PROP);
    var valueItem = "prop:".concat(prop.toLowerCase());
    return combineModifierKeyParts([functionItem, valueItem]);
  }

  return MODIFIER_NONE;
}; // used for user selected readings of some CIS dataset
// subset prop should be used if only selecting subset values, leave undefined if not needed.


exports.createHasPropModifierKey = createHasPropModifierKey;

var createCustomSelectModifierKey = function createCustomSelectModifierKey() {
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      subsetProp = _ref6.subsetProp;

  var functionName = FUNC_CUSTOM_SELECT;
  var functionItem = createFunctionKeyValue(functionName);
  var keyValueItem = createKeyValueItem(KEY_SUBSET_PROP, subsetProp);
  var parts = [functionItem, keyValueItem].filter(function (v) {
    return isDefined(v);
  });
  return combineModifierKeyParts(parts);
};

exports.createCustomSelectModifierKey = createCustomSelectModifierKey;

var createDecimateFunctionModifierKey = function createDecimateFunctionModifierKey(_ref7) {
  var decimate = _ref7.decimate;

  if (decimate) {
    return createFunctionKeyValue(FUNC_DECIMATE);
  }

  return MODIFIER_NONE;
};

var createMultiModifierKey = function createMultiModifierKey(_ref8) {
  var _ref8$alignmentFlags = _ref8.alignmentFlags,
      alignmentFlags = _ref8$alignmentFlags === void 0 ? {} : _ref8$alignmentFlags,
      _ref8$belowCriterionF = _ref8.belowCriterionFlags,
      belowCriterionFlags = _ref8$belowCriterionF === void 0 ? {} : _ref8$belowCriterionF,
      _ref8$decimateFlags = _ref8.decimateFlags,
      decimateFlags = _ref8$decimateFlags === void 0 ? {} : _ref8$decimateFlags,
      _ref8$hasPropFlags = _ref8.hasPropFlags,
      hasPropFlags = _ref8$hasPropFlags === void 0 ? {} : _ref8$hasPropFlags; // MULTI KEYS

  var keys = {
    alignment: undefined,
    belowCriterion: undefined,
    decimate: undefined,
    hasProp: undefined
  }; // ALIGNMENT MODIFIER

  var _alignmentFlags$useAl = alignmentFlags.useAligned,
      useAligned = _alignmentFlags$useAl === void 0 ? false : _alignmentFlags$useAl,
      _alignmentFlags$useIn = alignmentFlags.useInterpolated,
      useInterpolated = _alignmentFlags$useIn === void 0 ? false : _alignmentFlags$useIn;
  keys.alignment = createCombineFunctionModifierKey({
    useAligned: useAligned,
    useInterpolated: useInterpolated
  }); // BELOW CRITERION MODIFIER

  var _belowCriterionFlags$ = belowCriterionFlags.useShadow,
      useShadow = _belowCriterionFlags$ === void 0 ? false : _belowCriterionFlags$,
      _belowCriterionFlags$2 = belowCriterionFlags.useThreshold,
      useThreshold = _belowCriterionFlags$2 === void 0 ? false : _belowCriterionFlags$2,
      threshold = belowCriterionFlags.threshold;
  keys.belowCriterion = createBCFunctionModifierKey({
    useShadow: useShadow,
    threshold: useThreshold && isDefined(threshold) ? threshold : undefined
  }); // FILTER HAS PROP

  var prop = hasPropFlags.prop;

  if (isDefined(prop)) {
    keys.hasProp = createHasPropModifierKey({
      prop: prop
    });
  } // DECIMATE MODIFIER


  var decimate = decimateFlags.decimate;
  keys.decimate = createDecimateFunctionModifierKey({
    decimate: decimate
  }); // COMBINE ALL KEYS

  var arrKeyParts = [keys.alignment, keys.belowCriterion, keys.hasProp, keys.decimate].filter(function (v) {
    return !!v && v !== MODIFIER_NONE;
  });
  var modifierKey = combineModifierKeyParts(arrKeyParts); // RESULT

  return modifierKey || MODIFIER_NONE;
};

exports.createMultiModifierKey = createMultiModifierKey;
var _default = {
  createCombineFunctionModifierKey: createCombineFunctionModifierKey,
  createBCFunctionModifierKey: createBCFunctionModifierKey,
  createHasPropModifierKey: createHasPropModifierKey,
  createCustomSelectModifierKey: createCustomSelectModifierKey,
  createMultiModifierKey: createMultiModifierKey
};
exports["default"] = _default;