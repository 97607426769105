/* eslint import/no-cycle:0 */

import { set, cloneDeep } from 'lodash';

import Columns from 'aegion_common_utilities/lib/AVDataUtil/Columns';

// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import EditTableWorker from 'workerize-loader?inline!../webworkers/edittable.worker';
import { getAsset } from '../redux/Charts/utils/api';
import { selectEditableAssetList } from '../selectors/assetViewMain';
import assetViewActions from './actions';
import { saveNewInspections } from '../components/Manager/utils/api';
import AccountUtil from '../../account/components/utils/AccountUtil';
import Filter from '../filters';
import { refreshAVLayers } from '../redux/Map/utils';
import { setAssetDetails } from '../redux/AssetDetails/actions';
import { setUserAppSettings } from '../redux/EditTable/actions';
import { MANAGER_LIST_PAGE } from '../constants';
import { findColumnSettings } from '../webworkers/edittable.worker';

const { getStructureSettings } = Columns;

const NAME_SPACE = 'AV_MANAGER_';

const filters = new Filter();
const editTableWorker = EditTableWorker();

export const SET_IS_SAVING = `${NAME_SPACE} SET_IS_SAVING`;

const setIsSaving = saving => {
	return {
		type: SET_IS_SAVING,
		payload: saving
	};
};

export const SET_NEW_INSPECTIONS = `${NAME_SPACE}SET_NEW_INSPECTIONS`;

export const setNewInspections = rows => {
	return {
		type: SET_NEW_INSPECTIONS,
		payload: rows
	};
};

export const SET_EXISTING_INSPECTION_DATE = `${NAME_SPACE}SET_EXISTING_INSPECTION_DATE`;

export const setExistingInspectionDate = ({
	'_source.item.inspectionDate': inspectionDate
}) => {
	return {
		type: SET_EXISTING_INSPECTION_DATE,
		payload: inspectionDate
	};
};

export const SET_IS_EXTENT_FILTER = `${NAME_SPACE}SET_IS_EXTENT_FILTER`;

export const setIsExtentFilter = active => {
	return {
		type: SET_IS_EXTENT_FILTER,
		payload: active
	};
};

export const createNewInspections = (inspections, name) => (
	dispatch,
	getState
) => {
	const {
		assetViewMain: { managerSettings }
	} = getState();
	const structSettings = getStructureSettings(managerSettings, name);
	const { columns = [] } = structSettings;

	const newInspections = inspections.map(inspection => {
		const { values } = inspection;
		const newValue = Object.keys(values).reduce((acc, attr) => {
			const attrName = /(?<name>.*)\.(\d*)/.exec(attr).groups.name;
			const { column = {} } = findColumnSettings(columns, attrName) || {
				inspectionCreation: { copy: true }
			};
			const { inspectionCreation: { copy = true } = {} } = column;
			if (copy) {
				acc[attr] = values[attr];
			} else {
				acc[attr] = '';
			}
			return acc;
		}, {});
		return { ...inspection, values: newValue };
	});
	dispatch(setNewInspections(newInspections));
};

export const updateNewInspections = (inspection, attribute, value) => (
	dispatch,
	getState
) => {
	const state = getState();
	const {
		assetview: {
			manager: { newInspections }
		}
	} = state;
	const idx = newInspections.indexOf(inspection);
	const { values: inspectionValues } = inspection;
	const updateInspection = {
		...newInspections[idx],
		values: { ...inspectionValues, [attribute]: value }
	};
	const updatedNewInspections = [...newInspections];
	updatedNewInspections.splice(idx, 1, updateInspection);
	dispatch(setNewInspections(updatedNewInspections));
};

export const SET_IS_LOADING = `${NAME_SPACE}SET_IS_LOADING`;
export const setIsLoading = loading => {
	return {
		type: SET_IS_LOADING,
		payload: loading
	};
};

export const SET_SHOW_MAP = `${NAME_SPACE}SET_SHOW_MAP`;
export const setShowMap = showMap => {
	return {
		type: SET_SHOW_MAP,
		payload: showMap
	};
};

export const SETUP_MANAGER = `${NAME_SPACE} SETUP_MANAGER`;

const setupManager = (hiddenColumns, columnLengths, columnLabels, columns) => {
	return {
		type: SETUP_MANAGER,
		payload: { hiddenColumns, columnLengths, columnLabels, columns }
	};
};

export const SET_EDIT_LIST = `${NAME_SPACE} SET_EDIT_LIST`;

const setEditList = (name, list, total) => {
	return {
		type: SET_EDIT_LIST,
		payload: { name, list, total }
	};
};

export const getEditList = name => (dispatch, getState) => {
	dispatch(setIsLoading(true));
	dispatch(setEditList(name, []));
	const {
		assetViewMain: { selected = {}, isApprovalMode, managerSettings = [] },
		assetview: {
			manager: {
				isExtentFilter,
				loadAll,
				selectedStructureItem: { count: totalValue = 10000 } = {}
			},
			map: { extent: mapExtent }
		}
	} = getState();
	const extent = mapExtent.toBBoxString();
	const max = loadAll.indexOf(name) === -1 ? MANAGER_LIST_PAGE : totalValue;
	const _selectedFilters = { ...selected };
	if (name) {
		_selectedFilters.Structure = [name];
	}
	filters
		.list({
			selected: _selectedFilters,
			from: 0,
			size: max,
			extent: isExtentFilter ? JSON.parse(`[${extent}]`) : undefined,
			approval: isApprovalMode,
			isManager: true
		})
		.then(res => {
			const { hits, total } = res;
			const structureSettings = getStructureSettings(managerSettings, name);

			Promise.all([
				editTableWorker.findEmptyColumns(
					hits,
					structureSettings,
					isApprovalMode
				),

				editTableWorker.getColumnLengths(
					hits,
					structureSettings,
					isApprovalMode
				),
				editTableWorker.getColumnLabels(
					hits,
					structureSettings,
					isApprovalMode
				),
				editTableWorker.getColumns(hits, structureSettings, isApprovalMode)
			]).then(([emptyColumns, columnLengths, columnLabels, columns]) => {
				// console.log(emptyColumns, columnLengths, columnLabels);
				dispatch(
					setupManager(emptyColumns, columnLengths, columnLabels, columns)
				);
				dispatch(setEditList(name, hits, total));
				dispatch(setIsLoading(false));
			});
		});
};

export const SET_LOAD_ALL = `${NAME_SPACE} SET_LOAD_ALL`;
export const setLoadAll = () => (dispatch, getState) => {
	const state = getState();
	const {
		assetview: {
			manager: { loadAll, selectedStructureItem: { item: structureType } = {} }
		}
	} = state;
	const newLoadAll = [...loadAll];
	if (newLoadAll.indexOf(structureType) === -1) {
		newLoadAll.push(structureType);
	}
	dispatch({ type: SET_LOAD_ALL, payload: newLoadAll });
	dispatch(getEditList(structureType));
};

export const reloadManagerList = () => (dispatch, getState) => {
	const state = getState();
	const {
		assetview: {
			manager: { selectedStructureItem: { item: structureType } = {} }
		}
	} = state;
	dispatch(getEditList(structureType));
};

export const saveInspections = (inspectionDate, name) => (
	dispatch,
	getState
) => {
	const state = getState();
	const {
		assetview: {
			manager: { newInspections }
		}
	} = state;
	saveNewInspections(inspectionDate, newInspections).then(res => {
		if (res.errors) {
			/* eslint-disable-next-line no-alert */
			window.alert(
				'Saving Issue... Inspections already exist for those dates for some of the inspections'
			);
			return;
		}
		dispatch(setNewInspections(undefined));
		dispatch(getEditList(name));
	});
};

export const SET_ROW_NUM = `${NAME_SPACE} SET_ROW_NUM`;

export const setRowNum = rowNum => ({
	type: SET_ROW_NUM,
	payload: rowNum
});

export const SET_CALC_MODE = `${NAME_SPACE} SET_CALC_MODE`;

export const setCalcMode = calcMode => {
	return {
		type: SET_CALC_MODE,
		payload: calcMode
	};
};

export const SET_CALC_COLUMN = `${NAME_SPACE} SET_CALC_COLUMN`;

export const setCalcColumn = column => {
	return {
		type: SET_CALC_COLUMN,
		payload: column
	};
};

export const SET_SHOW_DELETE = `${NAME_SPACE} SET_SHOW_DELETE`;

export const setShowDelete = showDelete => {
	return {
		type: SET_SHOW_DELETE,
		payload: showDelete
	};
};

export const SET_COLUMN_ORDER = `${NAME_SPACE} SET_COLUMN_ORDER`;

export const setColumnOrder = columns => {
	return { type: SET_COLUMN_ORDER, payload: columns };
};

export const resetColumnOrder = name => (dispatch, getState) => {
	const {
		assetview: {
			manager: { columnOrder = {}, editList: { [name]: list, total } = {} }
		}
	} = getState();

	dispatch(setColumnOrder({}));

	const { [name]: n, ...newColumnOrder } = columnOrder;

	dispatch(setColumnOrder(newColumnOrder));
	AccountUtil.updateSettings({
		assetView: { columnOrder: newColumnOrder }
	}).then(() => {
		dispatch(setUserAppSettings({ columnOrder: newColumnOrder }));
		// if (list) {
		//	dispatch(setEditList(name, [...list], { ...total }));
		// }
	});
};

export const updateColumnOrder = (name, draggableId, destIndex, allColumns) => (
	dispatch,
	getState
) => {
	const {
		assetview: {
			manager: {
				columnOrder: { [name]: structureColOrder } = {},
				columnOrder = {}
			}
		}
	} = getState();

	const newStructureColOrder = {
		...structureColOrder,
		[draggableId]: destIndex
	};
	const updatedStructureColOrder = Object.keys(newStructureColOrder).reduce(
		(acc, key) => {
			const [column] = allColumns.filter(({ id }) => id === key);
			return { ...acc, [key]: allColumns.indexOf(column) };
		},
		{}
	);
	dispatch(setColumnOrder(updatedStructureColOrder));
	const newColumnOrder = {
		...columnOrder,
		[name]: updatedStructureColOrder
	};
	dispatch(setColumnOrder(newColumnOrder));
	AccountUtil.updateSettings({
		assetView: { columnOrder: newColumnOrder }
	}).then(() => {
		dispatch(setUserAppSettings({ columnOrder: newColumnOrder }));
	});
};

export const SET_DELETING = `${NAME_SPACE} SET_DELETING`;

export const setDeleting = deleting => {
	return { type: SET_DELETING, payload: deleting };
};

export const deleteAssets = () => (dispatch, getState) => {
	const {
		assetViewMain: { isApprovalMode },
		assetview: {
			manager: {
				selectedFlatRows = [],
				selectedStructureItem: { item: structureType } = {}
			}
		}
	} = getState();
	dispatch(setDeleting(true));

	const ids = selectedFlatRows.map(({ original: { _id } }) => _id);
	filters.deleteAssets(ids, isApprovalMode).then(() => {
		setTimeout(() => {
			dispatch(getEditList(structureType));
			dispatch(setDeleting(false));
			dispatch(setShowDelete(false));
		}, 1000);
	});
};

export const approve = (name, selected) => dispatch => {
	dispatch(setIsSaving(true));
	filters.approve(selected).then(reqCount => {
		setTimeout(() => {
			dispatch(getEditList(name));
			dispatch(setIsSaving(false));
		}, 500 * reqCount);
	});
};

export const markEOL = (name, selected) => dispatch => {
	dispatch(setIsSaving(true));
	filters.markEOL(selected).then(() => {
		// setTimeout(() => {
		dispatch(getEditList(name));
		dispatch(setIsSaving(false));
		// }, 500);
	});
};

export const SET_SELECTED_FLAT_ROWS = `${NAME_SPACE} SET_SELECTED_FLAT_ROWS`;

export const setSelectedFlatRows = rows => {
	return { type: SET_SELECTED_FLAT_ROWS, payload: rows };
};

export const SET_ROWS = `${NAME_SPACE} SET_ROWS`;

export const setRows = rows => {
	return { type: SET_ROWS, payload: rows };
};

export const SET_SELETED_STRUCTURE_ITEM = `${NAME_SPACE} SET_SELETED_STRUCTURE_ITEM`;

export const setSelectedStructureItem = item => {
	return { type: SET_SELETED_STRUCTURE_ITEM, payload: item };
};

export const SET_SHOW_COLUMN_SETTINGS = `${NAME_SPACE} SET_SHOW_COLUMN_SETTINGS`;

export const setShowColumnSettings = isShowing => {
	return { type: SET_SHOW_COLUMN_SETTINGS, payload: isShowing };
};

const mergeList = (list = [], updates) => {
	const newList = [...list];
	const edits = Object.keys(updates).map(key => {
		const update = updates[key];
		return update;
	});
	edits.forEach(({ _id: updateId, source, value }) => {
		const [row] = newList.filter(({ _id }) => updateId.indexOf(_id) === 0);
		if (row === undefined) {
			return;
		}
		const newRow = cloneDeep(row);
		if (newRow._id === updateId) {
			set(newRow, source, value);
		} else {
			const [subRow] = newRow.subRows.filter(({ _id }) => _id === updateId);
			const newSubRow = cloneDeep(subRow);
			set(newSubRow, source, value);
			newRow.subRows.splice(newRow.subRows.indexOf(subRow), 1, newSubRow);
		}
		newList.splice(newList.indexOf(row), 1, newRow);
	});
	return newList;
};

export const SET_UPDATES = `${NAME_SPACE} SET_UPDATES`;

export const setUpdates = updates => {
	return {
		type: SET_UPDATES,
		payload: updates
	};
};

export const addUpdate = ({
	editId,
	_id,
	_index,
	source,
	value,
	preEditData,
	inspectionDate
}) => (dispatch, getState) => {
	const {
		assetview: {
			manager: { updates }
		}
	} = getState();
	const newUpdates = {
		...updates,
		[editId]: { _id, _index, source, value, preEditData, inspectionDate }
	};

	dispatch(setUpdates(newUpdates));
};

export const removeUpdate = id => (dispatch, getState) => {
	const {
		assetview: {
			manager: { updates }
		}
	} = getState();
	const newUpdates = {
		...updates
	};
	delete newUpdates[id];

	dispatch(setUpdates(newUpdates));
};

const mergeDetails = updates => (dispatch, getState) => {
	const {
		assetDetails: { display } = {},
		assetDetails,
		assetViewMain: { pageItem }
	} = getState();
	const { id: pageItemId } = pageItem;
	const [updateKey] = Object.keys(updates).filter(key => {
		const { _id } = updates[key];
		return _id === pageItemId;
	});
	if (updateKey === undefined) {
		return;
	}
	const update = updates[updateKey];
	const attrKey = update.source.replace('_source.', '');
	const newPageItem = {
		...pageItem,
		item: { ...pageItem.item }
	};
	set(newPageItem, attrKey, update.value);
	dispatch(assetViewActions.updatePageItem(newPageItem));
	const newDisplay = display.map(d => {
		return d.map(dataRow => {
			return {
				...dataRow,
				rows: dataRow.rows.map(row => {
					const { keys } = row;
					const [key] = keys.filter(k => k === attrKey);
					if (key === undefined) {
						return row;
					}
					const values = [...row[Object.keys(row)[0]]];
					values.splice(keys.indexOf(key), 1, update.value);
					return {
						[Object.keys(row)[0]]: values,
						keys
					};
				})
			};
		});
	});
	dispatch(setAssetDetails({ ...assetDetails, display: newDisplay }));
};

export const saveUpdates = name => (dispatch, getState) => {
	const state = getState();
	const {
		assetViewMain: { selected = {}, listSort, listSortProp },
		assetview: {
			manager: { updates, editList: { [name]: list = [] } = {} },
			map: { map }
		}
	} = state;

	dispatch(mergeDetails(updates));

	dispatch(setIsSaving(true));

	const newList = mergeList(list, updates);
	filters
		.updates(updates)
		.then(() => {
			dispatch(setEditList(name, newList));
			dispatch(setIsSaving(false));
			dispatch(setUpdates({}));
			setTimeout(() => {
				dispatch(assetViewActions.getList(selected, 0, listSort, listSortProp));
				refreshAVLayers(map);
			}, 500);
		})
		.catch(() => {
			dispatch(setIsSaving(false));
		});
};

const getInspecitonDate = row => {
	const {
		original: { _source: { item: { inspectionDate } = {} } = {} },
		values: {
			'_source.preapproval.item.inspectionDate': pinspectionDate,
			'_source.item.inspectionDate': ainspectionDate
		}
	} = row;
	if (pinspectionDate !== undefined) {
		return pinspectionDate;
	}
	if (ainspectionDate !== undefined) {
		return ainspectionDate;
	}
	return inspectionDate;
};

export const getHistory = (template, name, row, key) => (
	dispatch,
	getState
) => {
	const inspectionDate = getInspecitonDate(row);
	getAsset(template, name, 'manager').then(({ data = [] }) => {
		data.sort(
			(
				{
					_source: { item: { inspectionDate: inspectionDateA = 0 } = {} } = {}
				},
				{ _source: { item: { inspectionDate: inspectionDateB = 0 } = {} } = {} }
			) => {
				return parseInt(inspectionDateB, 10) - parseInt(inspectionDateA, 10);
			}
		);
		const filteredData = data.filter(
			({ _source: { item: { inspectionDate: iDate = 0 } = {} } = {} }) => {
				return iDate !== inspectionDate;
			}
		);
		const state = getState();
		const {
			assetview: {
				manager: { editList: { total = {} } = {} }
			}
		} = state;
		const list = [...selectEditableAssetList(state, key)];
		const { index } = row;
		const item = { ...list[index], subRows: filteredData };
		list.splice(index, 1, item);
		dispatch(setEditList(key, list, total));
	});
};

export const clearUpdates = () => dispatch => {
	dispatch(setUpdates({}));
};
