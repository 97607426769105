/* eslint-disable no-param-reassign */
import createBaseSurveyMeta from './_BASE';
import colors from '../../Colors';
import {
	computeUuid,
	computeFormattedStationId
} from '../util/readings.parse.computeProps';
import { createComputeYMinYMax } from '../util/yMinYMax';

const INVERT_Y_AXIS = true;

// eslint-disable-next-line no-unused-vars
export default _survey => {
	// CONSTANTS

	const gapThreshold = 200;

	// READING KEYS

	const readingKeys = [
		{
			label: 'dB{µV}',
			value: 'decMicroV',
			seriesType: 'bar',
			isPercentage: true,
			color: colors.getColor()
		},
		{
			label: 'dB{µV}-N1A',
			value: 'normDecMicroV',
			seriesType: 'bar',
			isPercentage: true,
			color: colors.getColor()
		}
	];
	const readingKeyProps = readingKeys.map(k => k.value);
	const computeYMinYMax = createComputeYMinYMax(readingKeys);

	// SKIP READING

	const isSkipReading = reading =>
		reading &&
		readingKeys.every(
			rk => reading[rk.value] === 0 || reading[rk.value] === ''
		);

	// HAS GAP

	const hasGap = (currReading, prevReading) => {
		return !!prevReading && currReading.id - prevReading.id > gapThreshold;
	};

	// INTERPOLATE

	const interpolate = (
		// eslint-disable-next-line no-unused-vars
		_prevDepolReading,
		// eslint-disable-next-line no-unused-vars
		_nextDepolReading,
		// eslint-disable-next-line no-unused-vars
		_alignToReading
	) => {};

	const computeHilo = r => {
		r.low = r.low || 0;
	};

	// COMPUTE PROPS - to be used by readings.parse
	const computeProps = (survey, _prevReading, currReading, currIdx) => {
		computeUuid(currReading, currIdx);
		computeFormattedStationId(currReading);
		computeHilo(currReading);
		computeYMinYMax(survey, currReading);
	};

	// SIMPLIFIED READINGS - creates a smaller data set to be used for rendering polyline on map
	// most survey types will not have simplified readings
	// eslint-disable-next-line no-unused-vars
	const createSimplifiedReadings = ({ readings, subtype }) => undefined;

	// MAIN

	const meta = {
		...createBaseSurveyMeta(_survey),
		isChartInversed: INVERT_Y_AXIS,
		readingKeys,
		readingKeyProps,
		fkey: 'decMicroV',
		columns: [
			{
				label: 'ID',
				value: 'formattedStationId',
				checked: true,
				width: 64,
				required: true
			},
			{ label: 'dB{µV}', value: 'decMicroV', checked: true, width: 54 },
			{ label: 'dB{µV}-N1A', value: 'normDecMicroV', checked: true, width: 54 },
			{ label: 'TX OUTPUT', value: 'txOutput', checked: true, width: 54 },
			{ label: 'DISTANCE', value: 'dst', checked: true, width: 80 },
			{ label: 'LAT', value: 'lat', checked: true, width: 65 },
			{ label: 'LNG', value: 'lng', checked: true, width: 70 },
			{
				label: 'COMMENTS',
				value: 'cmt',
				checked: true,
				width: 255,
				type: 'text'
			}
		],
		isSkipReading,
		hasGap,
		interpolate,
		computeProps,
		computeYMinYMax,
		createSimplifiedReadings
	};

	return meta;
};
