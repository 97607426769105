/* eslint-disable import/no-cycle */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
import _ from 'lodash';

import scanlineMapUtils from '../mapUtils';
import { calculateBoundaryXMinXMax } from './util/surveysAndReadings.boundaryXMinXMax';
import { splitArray } from '../utils/array';
import { deleteProps } from '../utils/object';
import { cloneReadings } from '../utils/reading';

import {
	setAppInitMessage,
	setAppInitError,
	incrementPendingParseFileRequests,
	decrementPendingParseFileRequests,
	resetPendingParseFileRequests,
	setIsSurveyUploaderOpen
} from '../actions/app';

import {
	setAllComponentsLocked,
	setComponentLockAlertVisibility
} from '../actions/componentLock';

import { toggleMapVisibility } from '../actions/mapToggle';

import {
	clearMapLines,
	receiveMapLines,
	requestMapLines
} from '../actions/mapLines';

import {
	clearReadings,
	selectReading,
	normalizeReadings,
	readingsLoaded,
	receiveInterpolatedReadings,
	receiveNormalizedInterpolatedReadings,
	receiveReadings,
	requestReadings,
	setReadingDownloadsComplete,
	setReadingsInitialized,
	setUseInterpolatedDepol,
	setUseNormalizedDepol
} from '../actions/readings';

import {
	requestUnsupportedReadings,
	receiveUnsupportedReadings,
	unsupportedReadingsLoaded,
	setReadingsFiltered
} from '../actions/unsupportedReadings';

import {
	requestSurveys,
	receiveSurveys,
	setSurveysLoadErrorMessage,
	toggleSurvey,
	toggleSurveyCopyList,
	toggleSurveySettings,
	setSurveyEditMode,
	toggleSurveyColumn,
	deleteSurvey,
	requestSurveyS3Keys,
	receiveSurveyS3Keys,
	setUsableSurveys,
	setDefaultSurveyProps
} from '../actions/surveys';

import { receiveDatFileData, requestDatFileData } from '../actions/datFiles';

import { receiveShapefile, requestShapefile } from '../actions/shapefiles';

import {
	receiveReport,
	requestReport,
	receiveReportData,
	requestReportData,
	receiveSaveReportData,
	requestSaveReportData,
	receiveHasChangesReportData,
	requestHasChangesReportData,
	requestDownloadPdf,
	receiveDownloadPdf,
	receiveDownloadPdfError,
	hideDownloadPdfError,
	clearDownloadPdf,
	requestDownloadPdfZip,
	receiveDownloadPdfZip,
	receiveDownloadPdfZipError,
	hideDownloadPdfZipError,
	clearDownloadPdfZip,
	requestDownloadDoc,
	receiveDownloadDoc,
	receiveDownloadDocError,
	hideDownloadDocError,
	clearDownloadDoc,
	requestDownloadDocZip,
	receiveDownloadDocZip,
	receiveDownloadDocZipError,
	hideDownloadDocZipError,
	clearDownloadDocZip,
	requestDownloadClosure,
	receiveDownloadClosure,
	receiveDownloadClosureError,
	hideClosureDownloadErrors,
	clearDownloadClosure,
	requestDownloadD40Pdf,
	receiveDownloadD40Pdf,
	receiveDownloadD40PdfError,
	hideDownloadD40PdfError,
	clearDownloadD40Pdf,
	hideActionPlanDownloadErrors
} from '../actions/actionReports';

import { receiveShareLink, requestShareLink } from '../actions/shareLink';

import {
	receiveGetRating,
	requestGetRating,
	receiveSaveRating,
	requestSaveRating
} from '../actions/rating';

import {
	requestCompleteCreateProject,
	requestCreateProject,
	removeSurveyByJobId
} from '../actions/myProjects';

import {
	setJobsModalVisibility,
	setAllReadingsFlag,
	setExpandedChart,
	setFocusedChart,
	setHoveredChart
} from '../actions/mapComponent';

import { updateBrush } from '../actions/dataRangeAndZoom';

import {
	acSetViewAs,
	requestViewAsList,
	receiveViewAsList,
	requestIsPm,
	receiveIsPm,
	setViewAsErrorMessage
} from '../actions/viewAs';

import {
	setAlignedReadings,
	deleteAlignedReadings,
	setDisplayAlignedReadingsInRibbon,
	setRenderAlignedReadingsToSurvey,
	setAlignedReadingsFiltered,
	setRenderReadingsFilter,
	setAlignedRibbonVisibility,
	setAlignedReadingsThreshold,
	setAlignedReadingsStats
} from '../actions/alignedReadings';

import { newBrushState } from './util/dataRangeAndZoom.brush';

import {
	removeSurvey,
	removeSurveyS3Key,
	computeSupportedAndUnsupportedSurveys,
	parseUnsupportedReadingsHeader,
	cleanupUnsupportedSurveys,
	computeDefaultSurveyProps,
	removeSurveysByJobId
} from './util/surveys';

import { processUnsupportedReadingsLoaded } from './util/action.unsupportedReadingsLoaded';

import {
	generateReadingDataStructuresWithComputableProps,
	generateReadingDataStructures
} from './util/readings.parse';

import { createDerivedSurveys } from './util/derived.readings.parse';

import ViewAsUtil from './util/viewAs';

import {
	addChartIsLoading,
	removeChartIsLoading,
	addChartIsRendering,
	removeChartIsRendering
} from '../actions/charts';

import {
	clearProjectManagers,
	receiveProjectManagers,
	requestProjectManagers
} from '../actions/projectManagers';
import {
	setReadingsDownloadMessage,
	clearReadingsDownloadMessages,
	setSurveysDownloadMessage,
	clearSurveysDownloadMessages,
	toggleDataDownloadMessagesCollapse
} from '../actions/dataDownloadMessages';

import {
	addDepolValuesToCIS,
	addNormalizedDepolValuesToCIS
} from './appScanline.normalize.helpers';
import { getUserName } from '../../util/user';
import { universalSortSurveys } from '../utils/surveys.sort';
import StationIdHoverSync from '../utils/VisualizationSync/StationIdHoverSync';

const EMPTY_ARRAY = [];
const reducerParts = {
	// MAPS REDUCERS
	[clearMapLines]: state => {
		return {
			...state,
			mapLines: {},
			isLoadingMapLines: false
		};
	},

	[receiveMapLines]: (state, { payload: { surveyId, mapLines } }) => {
		return {
			...state,
			isLoadingMapLines: false,
			mapLines: {
				...state.mapLines,
				[surveyId]: mapLines
			}
		};
	},

	[requestMapLines]: state => {
		return { ...state, isLoadingMapLines: true };
	},

	// EXTERNAL DATA

	[incrementPendingParseFileRequests]: state => {
		const { countPendingParseFileRequests } = state;

		return {
			...state,
			countPendingParseFileRequests: countPendingParseFileRequests + 1
		};
	},

	[decrementPendingParseFileRequests]: state => {
		const { countPendingParseFileRequests } = state;

		return {
			...state,
			countPendingParseFileRequests: Math.max(
				0,
				countPendingParseFileRequests - 1
			)
		};
	},

	[resetPendingParseFileRequests]: state => {
		return {
			...state,
			countPendingParseFileRequests: 0
		};
	},

	[setIsSurveyUploaderOpen]: (state, { payload: { open } }) => {
		return {
			...state,
			isSurveyUploaderOpen: open
		};
	},

	// countPendingParseFileRequests: 0,
	// isSurveyUploaderOpen: false,

	// READINGS REDUCERS
	[requestReadings]: (state, { payload: { survey } }) => {
		const { surveysLoading = [] } = state;
		return {
			...state,
			isReadingsLoading: true,
			surveysLoading: [...surveysLoading, survey]
		};
	},

	[setReadingDownloadsComplete]: state => {
		return { ...state, isReadingsLoading: false };
	},

	[setReadingsInitialized]: state => {
		return { ...state, isReadingsInitialized: true };
	},

	[clearReadings]: state => {
		return {
			...state,
			readings: {},
			readingsWithGeoGaps: {},
			readingsWithChartGaps: {},
			readingsMap: {},
			readingsIndexMap: {},
			readingsMeta: {},
			readingsWithChartGapsIndexMap: {},
			readingsWithGeoGapsIndexMap: {},

			interpReadings: {},
			interpReadingsWithChartGaps: {},
			interpReadingsMap: {},
			interpReadingsIndexMap: {},
			interpReadingsWithChartGapsIndexMap: {}
		};
	},

	[receiveReadings]: (
		state,
		{ payload: { data, meta, survey, batchIdx, batchCount } }
	) => {
		const { id } = survey;
		const surveyReadingsPages = [
			...(state.loadingReadings[id] || new Array(batchCount))
		];
		surveyReadingsPages[batchIdx] = data;
		const loadingReadings = {
			...state.loadingReadings,
			[id]: surveyReadingsPages
		};
		// also update the metadata
		const readingsMeta = { ...state.readingsMeta, [id]: meta };
		return { ...state, loadingReadings, readingsMeta };
	},

	[readingsLoaded]: (state, { payload: { survey } }) => {
		const { id, survey_type: type, job_id: jobId } = survey;
		const meta = {
			...state.readingsMeta[id],
			isLoaded: true
		};
		const { surveysToLoad, surveysLoading } = state;

		const newSurveysToLoad = removeSurvey(survey, surveysToLoad);
		const newSurveysLoading = removeSurvey(survey, surveysLoading);

		let readingsMeta = { ...state.readingsMeta, [id]: meta };

		let readings;
		let readingsWithGeoGaps;
		let readingsWithChartGaps;
		let readingsMap;
		let readingsIndexMap;
		let readingsWithChartGapsIndexMap;
		let readingsWithGeoGapsIndexMap;

		let loadingReadings;
		let simplifiedReadings;
		let series;
		let seriesIndexMap;
		let readingsStationIdIntegers;
		const surveyReadings = _.flatten(state.loadingReadings[id] || []);

		let newState = {
			...state
		};

		const [
			processedReadings,
			processedReadingsWithGeoGaps,
			processedReadingsWithChartGaps,
			processedReadingsMap,
			processedReadingsIndexMap,
			processedReadingsWithChartGapsIndexMap,
			processedReadingsWithGeoGapsIndexMap,
			processedSeries,
			processedSeriesIndexMap,
			processedReadingsStationIdIntegers,
			processedSimplifiedReadings
		] = generateReadingDataStructuresWithComputableProps(
			survey,
			surveyReadings
		);

		if (scanlineMapUtils.shouldCreateDerivedSurveys(survey)) {
			// @todo - createDerivedSurveys - this needs to be pulled out into "generateReadingsWithComputableProps"
			const {
				readings: derivedReadings,
				readingsWithChartGaps: derivedReadingsWithChartGaps,
				readingsWithGeoGaps: derivedReadingsWithGeoGaps,
				readingsMap: derivedReadingsMap,
				readingsIndexMap: derivedReadingsIndexMap,
				readingsWithChartGapsIndexMap: derivedReadingsWithChartGapsIndexMap,
				readingsWithGeoGapsIndexMap: derivedReadingsWithGeoGapsIndexMap,
				readingsMeta: derivedReadingsMeta,
				surveys: derivedSurveys,
				series: derivedSeries,
				seriesIndexMap: derivedSeriesIndexMap,
				readingsStationIdIntegers: derivedReadingsStationIdIntegers
			} = createDerivedSurveys(
				processedReadings,
				processedReadingsWithChartGaps,
				processedReadingsWithGeoGaps,
				type,
				surveyReadings,
				jobId,
				meta,
				survey.checked
			);

			readings = {
				...state.readings,
				...derivedReadings,
				[id]: processedReadings
			};
			readingsWithGeoGaps = {
				...state.readingsWithGeoGaps,
				...derivedReadingsWithGeoGaps,
				[id]: processedReadingsWithGeoGaps
			};
			readingsWithChartGaps = {
				...state.readingsWithChartGaps,
				...derivedReadingsWithChartGaps,
				[id]: processedReadingsWithChartGaps
			};
			readingsMap = {
				...state.readingsMap,
				...derivedReadingsMap,
				[id]: processedReadingsMap
			};
			readingsIndexMap = {
				...state.readingsIndexMap,
				...derivedReadingsIndexMap,
				[id]: processedReadingsIndexMap
			};
			readingsWithChartGapsIndexMap = {
				...state.readingsWithChartGapsIndexMap,
				...derivedReadingsWithChartGapsIndexMap,
				[id]: processedReadingsWithChartGapsIndexMap
			};
			readingsWithGeoGapsIndexMap = {
				...state.readingsWithGeoGapsIndexMap,
				...derivedReadingsWithGeoGapsIndexMap,
				[id]: processedReadingsWithGeoGapsIndexMap
			};
			simplifiedReadings = {
				...state.simplifiedReadings,
				[id]: processedSimplifiedReadings
			};
			series = {
				...state.series,
				[survey.id]: {
					...(state.series[survey.id] || {}),
					...processedSeries
				},
				...derivedSeries
			};
			seriesIndexMap = {
				...state.seriesIndexMap,
				[survey.id]: {
					...(state.seriesIndexMap[survey.id] || {}),
					...processedSeriesIndexMap
				},
				...derivedSeriesIndexMap
			};
			readingsStationIdIntegers = {
				...state.readingsStationIdIntegers,
				[survey.id]: {
					...processedReadingsStationIdIntegers
				},
				...derivedReadingsStationIdIntegers
			};

			readingsMeta = { ...readingsMeta, ...derivedReadingsMeta };
			loadingReadings = { ...state.loadingReadings, [id]: undefined };
			const surveys = universalSortSurveys([
				...state.surveys,
				...derivedSurveys
			]);

			newState = {
				...state,
				surveys,
				surveysToLoad: newSurveysToLoad,
				surveysLoading: newSurveysLoading,
				readings,
				readingsWithGeoGaps,
				readingsWithChartGaps,
				readingsMap,
				readingsIndexMap,
				readingsWithChartGapsIndexMap,
				readingsWithGeoGapsIndexMap,
				loadingReadings,
				readingsMeta,
				simplifiedReadings,
				series,
				seriesIndexMap,
				readingsStationIdIntegers
			};
		} else {
			readings = { ...state.readings, [id]: processedReadings };
			readingsWithGeoGaps = {
				...state.readingsWithGeoGaps,
				[id]: processedReadingsWithGeoGaps
			};
			readingsWithChartGaps = {
				...state.readingsWithChartGaps,
				[id]: processedReadingsWithChartGaps
			};
			readingsMap = { ...state.readingsMap, [id]: processedReadingsMap };
			readingsIndexMap = {
				...state.readingsIndexMap,
				[id]: processedReadingsIndexMap
			};
			readingsWithChartGapsIndexMap = {
				...state.readingsWithChartGapsIndexMap,
				[id]: processedReadingsWithChartGapsIndexMap
			};
			readingsWithGeoGapsIndexMap = {
				...state.readingsWithGeoGapsIndexMap,
				[id]: processedReadingsWithGeoGapsIndexMap
			};
			simplifiedReadings = {
				...state.simplifiedReadings,
				[id]: processedSimplifiedReadings
			};
			series = {
				...state.series,
				[survey.id]: {
					...(state.series[survey.id] || {}),
					...processedSeries
				}
			};
			seriesIndexMap = {
				...state.seriesIndexMap,
				[survey.id]: {
					...(state.seriesIndexMap[survey.id] || {}),
					...processedSeriesIndexMap
				}
			};
			readingsStationIdIntegers = {
				...state.readingsStationIdIntegers,
				[survey.id]: {
					...processedReadingsStationIdIntegers
				}
			};

			loadingReadings = { ...state.loadingReadings, [id]: undefined };

			newState = {
				...state,
				surveysToLoad: newSurveysToLoad,
				surveysLoading: newSurveysLoading,
				readings,
				readingsWithGeoGaps,
				readingsWithChartGaps,
				readingsMap,
				readingsIndexMap,
				readingsWithChartGapsIndexMap,
				readingsWithGeoGapsIndexMap,
				loadingReadings,
				readingsMeta,
				simplifiedReadings,
				series,
				seriesIndexMap,
				readingsStationIdIntegers
			};
		}

		return {
			...newState,
			boundaryXMinXMax: calculateBoundaryXMinXMax(newState)
		};
	},

	[receiveInterpolatedReadings]: (
		state,
		{
			payload: {
				survey,
				readings,
				readingsWithChartGaps,
				readingsMap,
				readingsIndexMap,
				readingsWithChartGapsIndexMap,
				series,
				seriesIndexMap
			}
		}
	) => {
		const interpReadings = {
			...state.interpReadings,
			[survey.id]: readings
		};
		const interpReadingsWithChartGaps = {
			...state.interpReadingsWithChartGaps,
			[survey.id]: readingsWithChartGaps
		};
		const interpReadingsMap = {
			...state.interpReadingsMap,
			[survey.id]: readingsMap
		};
		const interpReadingsIndexMap = {
			...state.interpReadingsIndexMap,
			[survey.id]: readingsIndexMap
		};
		const interpReadingsWithChartGapsIndexMap = {
			...state.interpReadingsWithChartGapsIndexMap,
			[survey.id]: readingsWithChartGapsIndexMap
		};
		const interpSeries = {
			...state.interpSeries,
			[survey.id]: {
				...(state.interpSeries[survey.id] || {}),
				...series
			}
		};
		const interpSeriesIndexMap = {
			...state.interpSeriesIndexMap,
			[survey.id]: {
				...(state.interpSeriesIndexMap[survey.id] || {}),
				...seriesIndexMap
			}
		};

		const newState = {
			...state,
			interpReadings,
			interpReadingsWithChartGaps,
			interpReadingsMap,
			interpReadingsIndexMap,
			interpReadingsWithChartGapsIndexMap,
			interpSeries,
			interpSeriesIndexMap
		};

		return {
			...newState,
			boundaryXMinXMax: calculateBoundaryXMinXMax(newState)
		};
	},

	[receiveNormalizedInterpolatedReadings]: (
		state,
		{
			payload: {
				survey,
				readings,
				readingsWithChartGaps,
				readingsMap,
				readingsIndexMap,
				readingsWithChartGapsIndexMap,
				series,
				seriesIndexMap
			}
		}
	) => {
		const interpReadingsNormalized = {
			...state.interpReadingsNormalized,
			[survey.id]: readings
		};
		const interpReadingsNormalizedWithChartGaps = {
			...state.interpReadingsNormalizedWithChartGaps,
			[survey.id]: readingsWithChartGaps
		};
		const interpReadingsNormalizedMap = {
			...state.interpReadingsNormalizedMap,
			[survey.id]: readingsMap
		};
		const interpReadingsNormalizedIndexMap = {
			...state.interpReadingsNormalizedIndexMap,
			[survey.id]: readingsIndexMap
		};
		const interpReadingsNormalizedWithChartGapsIndexMap = {
			...state.interpReadingsNormalizedWithChartGapsIndexMap,
			[survey.id]: readingsWithChartGapsIndexMap
		};
		const interpSeriesNormalized = {
			...state.interpSeriesNormalized,
			[survey.id]: {
				...(state.interpSeriesNormalized[survey.id] || {}),
				...series
			}
		};
		const interpSeriesNormalizedIndexMap = {
			...state.interpSeriesNormalizedIndexMap,
			[survey.id]: {
				...(state.interpSeriesNormalizedIndexMap[survey.id] || {}),
				...seriesIndexMap
			}
		};

		const newState = {
			...state,
			interpReadingsNormalized,
			interpReadingsNormalizedWithChartGaps,
			interpReadingsNormalizedMap,
			interpReadingsNormalizedIndexMap,
			interpReadingsNormalizedWithChartGapsIndexMap,
			interpSeriesNormalized,
			interpSeriesNormalizedIndexMap
		};

		return {
			...newState,
			boundaryXMinXMax: calculateBoundaryXMinXMax(newState)
		};
	},

	// This actually normalizes (stretches or shrinks) survey readings based on a parent survey's readings
	[normalizeReadings]: (state, { payload: { survey, copyDepol } }) => {
		const normKeys = {
			DEPOL: {
				depolKey: 'nDepolVal',
				depolFlagKey: 'isNormalized',
				targetValueKey: 'on'
			}
		};

		const { job_id: jobId } = survey;

		// We normalize a survey based on a parent "CIS" survey defined here
		const cisSurveyId = scanlineMapUtils.createSurveyId('CIS', 'ON_OFF', jobId);

		// @todo - this is how we identify the data set to be normalized, seems brittle, should have a clearer more predictable way of selecting data set to normalize
		const possibleTargetTypes = ['DEPOL', 'DCVG', 'ACCA'];
		const possibleTargetIds = possibleTargetTypes.map(key =>
			scanlineMapUtils.createSurveyId('CIS', key, jobId)
		);
		const targetSurveyId = possibleTargetIds.find(id => !!state.readings[id]);
		const targetSurvey = state.surveys.find(s => s.id === targetSurveyId);

		if (!targetSurvey) {
			return state;
		}

		const subtype = targetSurvey.survey_subtype;

		// This is the survey that is to be normalized
		const cisReadings = state.readings[cisSurveyId] || [];
		const cisReadingsMap = state.readingsMap[cisSurveyId] || {};
		// @hack - prevent normalize readings from mutating cisReadings
		// @todo - come up with a more manageable solution, copying values
		// to cisReadings during normalize function is proving difficult to maintain
		const cisClonedReadings = cloneReadings(cisReadings);
		const cisClonedReadingsMap = cisClonedReadings.reduce((acc, r) => {
			if (r && !r.isGap && !r.isFauxDataPoint && !acc[r.id]) {
				acc[r.id] = r;
			}
			return acc;
		}, {});
		const targetReadings = state.readings[targetSurveyId] || [];

		if (!cisReadings.length || !targetReadings.length) {
			return state;
		}

		const preNormalizedReadings = {
			...state.preNormalizedReadings,
			[targetSurveyId]: targetReadings.map(r => ({ ...r }))
		};

		const normalizedReadings = scanlineMapUtils.normalizeReadings(
			cisClonedReadings,
			cisClonedReadingsMap,
			targetReadings,
			subtype,
			normKeys[subtype],
			copyDepol
		);

		addDepolValuesToCIS(targetReadings, cisReadingsMap);
		addNormalizedDepolValuesToCIS(normalizedReadings, cisReadingsMap);

		const [
			processedReadingsNormalized,
			processedReadingsNormalizedWithGeoGaps,
			processedReadingsNormalizedWithChartGaps,
			processedReadingsNormalizedMap,
			processedReadingsNormalizedIndexMap,
			processedReadingsNormalizedWithChartGapsIndexMap,
			// eslint-disable-next-line no-unused-vars
			ignoreProcessedReadingsNormalizedWithGeoGapsIndexMap, // we will not be using geo gaps for aligned/interpolated
			processedSeriesNormalized,
			processedSeriesNormalizedIndexMap,
			processedReadingsStationIdIntegers
		] = generateReadingDataStructures(targetSurvey, normalizedReadings);

		const readingsNormalized = {
			...state.readingsNormalized,
			[targetSurveyId]: processedReadingsNormalized
		};
		const readingsNormalizedWithGeoGaps = {
			...state.readingsNormalizedWithGeoGaps,
			[targetSurveyId]: processedReadingsNormalizedWithGeoGaps
		};
		const readingsNormalizedWithChartGaps = {
			...state.readingsNormalizedWithChartGaps,
			[targetSurveyId]: processedReadingsNormalizedWithChartGaps
		};
		const readingsNormalizedMap = {
			...state.readingsNormalizedMap,
			[targetSurveyId]: processedReadingsNormalizedMap
		};
		const readingsNormalizedIndexMap = {
			...state.readingsNormalizedIndexMap,
			[targetSurveyId]: processedReadingsNormalizedIndexMap
		};
		const readingsNormalizedWithChartGapsIndexMap = {
			...state.readingsNormalizedWithChartGapsIndexMap,
			[targetSurveyId]: processedReadingsNormalizedWithChartGapsIndexMap
		};
		const seriesNormalized = {
			...state.seriesNormalized,
			[targetSurveyId]: {
				...(state.seriesNormalized[targetSurveyId] || {}),
				...processedSeriesNormalized
			}
		};
		const seriesNormalizedIndexMap = {
			...state.seriesNormalizedIndexMap,
			[targetSurveyId]: {
				...(state.seriesNormalizedIndexMap[targetSurveyId] || {}),
				...processedSeriesNormalizedIndexMap
			}
		};

		const newState = {
			...state,
			readingsNormalized,
			readingsNormalizedWithGeoGaps,
			readingsNormalizedWithChartGaps,
			readingsNormalizedMap,
			readingsNormalizedIndexMap,
			readingsNormalizedWithChartGapsIndexMap,
			preNormalizedReadings,
			seriesNormalized,
			seriesNormalizedIndexMap
		};

		return {
			...newState,
			boundaryXMinXMax: calculateBoundaryXMinXMax(newState)
		};
	},

	[setAllComponentsLocked]: (state, { payload: { locked = false } }) => {
		return {
			...state,
			allComponentsLocked: locked
		};
	},

	[setComponentLockAlertVisibility]: (state, { payload: show = false }) => {
		return {
			...state,
			showComponentLockButtonAlert: show
		};
	},

	[toggleMapVisibility]: state => {
		return {
			...state,
			isMapVisible: !state.isMapVisible
		};
	},

	[selectReading]: (state, { payload: { survey, reading } }) => {
		if (state.selectedReading === reading) {
			// breaks an infinite loop
			return state;
		}
		StationIdHoverSync.updateStationId((reading || {}).id);
		return {
			...state,
			selectedReading: reading,
			selectedReadingSurvey: survey || state.selectedReadingSurvey
		};
	},

	[setAppInitMessage]: (state, { payload: { message } }) => {
		return {
			...state,
			appInitMessage: message
		};
	},

	[setAppInitError]: (state, { payload: { error } }) => {
		return {
			...state,
			appInitError: error
		};
	},

	[requestSurveys]: state => {
		return {
			...state,
			surveysLoadErrorMessage: undefined
		};
	},

	[setSurveysLoadErrorMessage]: (state, { payload: { message } }) => {
		return {
			...state,
			surveysLoadErrorMessage: message
		};
	},

	[receiveSurveys]: (state, { payload: { surveys: newSurveys } }) => {
		const {
			surveys = [],
			surveysToLoad = [],
			unsupportedSurveysToLoad = [],
			defaultSurvey
		} = state;

		const [
			supportedSurveys,
			unsupportedSurveys
		] = computeSupportedAndUnsupportedSurveys(newSurveys);

		const [
			newDefaultSurvey,
			newDefaultSurveyJobId
		] = computeDefaultSurveyProps(defaultSurvey, [
			...surveys,
			...supportedSurveys
		]);

		const newState = {
			...state,
			surveys: universalSortSurveys([...surveys, ...supportedSurveys]),
			surveysToLoad: [...surveysToLoad, ...supportedSurveys],
			unsupportedSurveysToLoad: [
				...unsupportedSurveysToLoad,
				...unsupportedSurveys
			],
			defaultSurvey: newDefaultSurvey,
			defaultSurveyJobId: newDefaultSurveyJobId
		};

		return {
			...newState,
			boundaryXMinXMax: calculateBoundaryXMinXMax(newState)
		};
	},

	[setUsableSurveys]: (state, { payload: { usableSurveys } }) => {
		return {
			...state,
			usableSurveys
		};
	},

	[setDefaultSurveyProps]: (
		state,
		{ payload: { defaultSurvey, defaultSurveyJobId } }
	) => {
		return {
			...state,
			defaultSurvey,
			defaultSurveyJobId
		};
	},

	// eslint-disable-next-line no-unused-vars
	[requestSurveyS3Keys]: (state, { payload: { externalId } }) => {
		// a place holder to manage loading state if needed
		return state;
	},

	[receiveSurveyS3Keys]: (state, { payload: { externalId, s3Keys = [] } }) => {
		const { unsupportedSurveyS3KeysToLoad: toLoad } = state;
		const thisSurveyS3Keys = toLoad[externalId] || [];
		return {
			...state,
			unsupportedSurveyS3KeysToLoad: {
				...toLoad,
				[externalId]: [...thisSurveyS3Keys, ...s3Keys]
			}
		};
	},

	[toggleSurvey]: (state, { payload: { survey } }) => {
		const surveys = state.surveys.map(item => {
			if (item.id !== survey.id) {
				return item;
			}
			if (item.checked) {
				return {
					...item,
					checked: false,
					isCopyListOpen: false,
					isSettingsOpen: false
				};
			}
			return { ...item, checked: true };
		});

		const newState = {
			...state,
			surveys
		};

		return {
			...newState,
			boundaryXMinXMax: calculateBoundaryXMinXMax(newState)
		};
	},

	[toggleSurveyCopyList]: (state, { payload: { survey } }) => {
		const surveys = state.surveys.map(item => {
			if (item.id !== survey.id) {
				return item;
			}
			return { ...item, isCopyListOpen: !item.isCopyListOpen };
		});
		return { ...state, surveys };
	},

	[toggleSurveySettings]: (state, { payload: { survey } }) => {
		const surveys = state.surveys.map(item => {
			if (item.id !== survey.id) {
				return item;
			}
			return { ...item, isSettingsOpen: !item.isSettingsOpen };
		});
		return { ...state, surveys };
	},

	[toggleSurveyColumn]: (state, { payload: { survey, column } }) => {
		const surveys = state.surveys.map(item => {
			if (item.id !== survey.id) {
				return item;
			}
			const columns = item.columns.map(itemColumn => {
				if (itemColumn.label !== column.label) {
					return itemColumn;
				}
				return { ...itemColumn, checked: !itemColumn.checked };
			});
			return { ...item, columns };
		});
		return { ...state, surveys };
	},

	[setSurveyEditMode]: (state, { payload: { survey, mode } }) => {
		const hasSurveyInExceptionsMode = mode === 'exceptions';
		const surveys = state.surveys.map(item => {
			if (item.id !== survey.id) {
				return item;
			}
			return { ...item, editMode: mode };
		});
		const mostRecentBcSeriesGroup = hasSurveyInExceptionsMode
			? state.mostRecentBcSeriesGroup
			: undefined;
		return { ...state, surveys, mostRecentBcSeriesGroup };
	},

	[deleteSurvey]: (state, { payload: { survey } }) => {
		// we only allow deletion of uploaded surveys
		if (!survey.jobId) {
			return state;
		}

		const [surveys, surveysToRemove] = splitArray(
			state.surveys,
			s => s.jobId !== survey.jobId
		);

		const surveyIdsToRemove = surveysToRemove.map(s => s.id);

		const readingsMeta = deleteProps(state.readingsMeta, surveyIdsToRemove);
		const readings = deleteProps(state.readings, surveyIdsToRemove);
		const readingsWithGeoGaps = deleteProps(
			state.readingsWithGeoGaps,
			surveyIdsToRemove
		);
		const readingsWithChartGaps = deleteProps(
			state.readingsWithChartGaps,
			surveyIdsToRemove
		);
		const readingsMap = deleteProps(state.readingsMap, surveyIdsToRemove);
		const readingsIndexMap = deleteProps(
			state.readingsIndexMap,
			surveyIdsToRemove
		);
		const readingsWithChartGapsIndexMap = deleteProps(
			state.readingsWithChartGapsIndexMap,
			surveyIdsToRemove
		);
		const readingsWithGeoGapsIndexMap = deleteProps(
			state.readingsWithGeoGapsIndexMap,
			surveyIdsToRemove
		);
		const interpReadings = deleteProps(state.interpReadings, surveyIdsToRemove);
		const interpReadingsWithChartGaps = deleteProps(
			state.interpReadingsWithChartGaps,
			surveyIdsToRemove
		);
		const interpReadingsMap = deleteProps(
			state.interpReadingsMap,
			surveyIdsToRemove
		);
		const interpReadingsIndexMap = deleteProps(
			state.interpReadingsIndexMap,
			surveyIdsToRemove
		);
		const interpReadingsWithChartGapsIndexMap = deleteProps(
			state.interpReadingsWithChartGapsIndexMap,
			surveyIdsToRemove
		);

		return {
			...state,
			surveys,

			readingsMeta,

			readings,
			readingsWithGeoGaps,
			readingsWithChartGaps,

			readingsMap,
			readingsIndexMap,
			readingsWithChartGapsIndexMap,
			readingsWithGeoGapsIndexMap,

			interpReadings,
			interpReadingsWithChartGaps,

			interpReadingsMap,
			interpReadingsIndexMap,
			interpReadingsWithChartGapsIndexMap
		};
	},

	// DATFILES REDUCERS
	[requestDatFileData]: state => {
		return { ...state, isUploadingFile: true };
	},

	// @TODO @WARNING - this looks broken
	[receiveDatFileData]: (
		state,
		// eslint-disable-next-line no-unused-vars
		{ payload: { header, map, data, meta: _meta, surveyId, job_id } }
	) => {
		const type = header.survey_type;
		const subtype =
			header.onoff === 'T' ? 'ON_OFF' : header.survey_subtype || data.length;
		// @note-supported-constant-needs-abstraction

		// TODO: Add back once approved by KM - 'ILI-swl', 'ILI-dent',

		if (
			[
				'ON_OFF',
				'ON-OFF',
				'NATIVE',
				'ON',
				'DOC',
				'REL',
				'ACCA',
				'DCVG',
				'ACVG',
				'ACCD',
				'ACPS',
				'DCCD',
				'DCPS',
				'SRES',
				'ILI-exMtlLoss',
				'ILI-inMtlLoss',
				'REL'
			].indexOf(subtype) === -1
		) {
			// eslint-disable-next-line no-undef
			alert(`Survey subtype ${subtype} not supported.`);
			return { ...state, isUploadingFile: false };
		}

		// abandon if survey already exists
		const isSurveyExists = state.surveys.filter(s => s.id === surveyId).length;
		if (isSurveyExists) {
			// eslint-disable-next-line no-use-before-define
			surveys = universalSortSurveys(
				state.surveys.map(item => {
					if (item.id !== surveyId) {
						return item;
					}
					return {
						...item,
						displayName: `${item.survey_subtype}(${item.parsedFrom}-${data.length})`,
						checked: true,
						isUploaded: true,
						..._meta
					};
				})
			);
		}

		let {
			surveys,
			readingsMeta,
			readings,
			readingsWithGeoGaps,
			readingsWithChartGaps,
			readingsMap,
			readingsIndexMap,
			readingsWithChartGapsIndexMap,
			readingsWithGeoGapsIndexMap,
			loadingReadings,
			simplifiedReadings,
			series,
			seriesIndexMap
		} = state;

		let newState = {
			...state
		};
		// @todo - need a way of setting withDepol flag on survey for external on/off datasets containing depol.
		const isCIS = scanlineMapUtils.isCis(surveyId);
		const survey = {
			displayName: `${subtype}(${header.parsedFrom}-${data.length})`,
			survey_type: type,
			survey_subtype: subtype,
			parsedFrom: header.parsedFrom,
			id: surveyId,
			checked: true,
			withThreshold: isCIS,
			withDepol: false,
			isUploaded: true,
			job_id,
			jobId: job_id,
			..._meta
		};

		const [
			processedReadings,
			processedReadingsWithGeoGaps,
			processedReadingsWithChartGaps,
			processedReadingsMap,
			processedReadingsIndexMap,
			processedReadingsWithChartGapsIndexMap,
			processedReadingsWithGeoGapsIndexMap,
			processedSeries,
			processedSeriesIndexMap,
			processedReadingsStationIdIntegers,
			processedSimplifiedReadings
		] = generateReadingDataStructuresWithComputableProps(survey, data, true);

		// attach metadata
		const meta = scanlineMapUtils.getSurveyMeta(survey);
		// const readingKeyProps = meta.readingKeys.map(key => key.value);
		survey.isChartInversed = meta.isChartInversed;
		survey.readingKeys = meta.readingKeys;
		survey.fkey = meta.fkey;
		survey.color = meta.readingKeys[0].color;
		survey.columns = meta.columns;
		survey.thresholds = meta.thresholds;

		if (scanlineMapUtils.shouldCreateDerivedSurveys(survey)) {
			// @todo - createDerivedSurveys - this needs to be pulled out into "generateReadingsWithComputableProps"
			const {
				readings: derivedReadings,
				readingsWithChartGaps: derivedReadingsWithChartGaps,
				readingsWithGeoGaps: derivedReadingsWithGeoGaps,
				readingsMap: derivedReadingsMap,
				readingsIndexMap: derivedReadingsIndexMap,
				readingsWithChartGapsIndexMap: derivedReadingsWithChartGapsIndexMap,
				readingsWithGeoGapsIndexMap: derivedReadingsWithGeoGapsIndexMap,
				readingsMeta: derivedReadingsMeta,
				surveys: derivedSurveys,
				series: derivedSeries,
				seriesIndexMap: derivedSeriesIndexMap
			} = createDerivedSurveys(
				processedReadings,
				processedReadingsWithChartGaps,
				processedReadingsWithGeoGaps,
				type,
				data,
				job_id,
				meta,
				survey.checked
			);

			debugger;

			readings = {
				...readings,
				...derivedReadings,
				[survey.id]: processedReadings
			};
			readingsWithGeoGaps = {
				...readingsWithGeoGaps,
				...derivedReadingsWithGeoGaps,
				[survey.id]: processedReadingsWithGeoGaps
			};
			readingsWithChartGaps = {
				...readingsWithChartGaps,
				...derivedReadingsWithChartGaps,
				[survey.id]: processedReadingsWithChartGaps
			};
			readingsMap = {
				...readingsMap,
				...derivedReadingsMap,
				[survey.id]: processedReadingsMap
			};
			readingsIndexMap = {
				...readingsIndexMap,
				...derivedReadingsIndexMap,
				[survey.id]: processedReadingsIndexMap
			};
			readingsWithChartGapsIndexMap = {
				...readingsWithChartGapsIndexMap,
				...derivedReadingsWithChartGapsIndexMap,
				[survey.id]: processedReadingsWithChartGapsIndexMap
			};
			readingsWithGeoGapsIndexMap = {
				...readingsWithGeoGapsIndexMap,
				...derivedReadingsWithGeoGapsIndexMap,
				[survey.id]: processedReadingsWithGeoGapsIndexMap
			};
			simplifiedReadings = {
				...state.simplifiedReadings,
				[survey.id]: processedSimplifiedReadings
			};
			readingsMeta = {
				...readingsMeta,
				[survey.id]: meta,
				...derivedReadingsMeta
			};
			series = {
				...series,
				[survey.id]: {
					...(series[survey.id] || {}),
					...processedSeries
				},
				...derivedSeries
			};
			seriesIndexMap = {
				...seriesIndexMap,
				[survey.id]: {
					...(seriesIndexMap[survey.id] || {}),
					...processedSeriesIndexMap
				},
				...derivedSeriesIndexMap
			};
			loadingReadings = { ...loadingReadings, [survey.id]: undefined };
			surveys = universalSortSurveys([...surveys, survey, ...derivedSurveys]);

			newState = {
				...state,
				surveys,
				readings,
				readingsWithGeoGaps,
				readingsWithChartGaps,
				readingsMap,
				readingsIndexMap,
				readingsWithChartGapsIndexMap,
				readingsWithGeoGapsIndexMap,
				loadingReadings,
				readingsMeta,
				simplifiedReadings,
				series,
				seriesIndexMap
			};
		} else {
			readings = { ...readings, [survey.id]: processedReadings };
			readingsWithGeoGaps = {
				...readingsWithGeoGaps,
				[survey.id]: processedReadingsWithGeoGaps
			};
			readingsWithChartGaps = {
				...readingsWithChartGaps,
				[survey.id]: processedReadingsWithChartGaps
			};
			readingsMap = { ...readingsMap, [survey.id]: processedReadingsMap };
			readingsIndexMap = {
				...readingsIndexMap,
				[survey.id]: processedReadingsIndexMap
			};
			readingsWithChartGapsIndexMap = {
				...readingsWithChartGapsIndexMap,
				[survey.id]: processedReadingsWithChartGapsIndexMap
			};
			readingsWithGeoGapsIndexMap = {
				...readingsWithGeoGapsIndexMap,
				[survey.id]: processedReadingsWithGeoGapsIndexMap
			};
			simplifiedReadings = {
				...state.simplifiedReadings,
				[survey.id]: processedSimplifiedReadings
			};
			readingsMeta = { ...readingsMeta, [survey.id]: meta };
			series = {
				...series,
				[survey.id]: {
					...(series[survey.id] || {}),
					...processedSeries
				}
			};
			seriesIndexMap = {
				...seriesIndexMap,
				[survey.id]: {
					...(seriesIndexMap[survey.id] || {}),
					...processedSeriesIndexMap
				}
			};
			loadingReadings = { ...loadingReadings, [survey.id]: undefined };
			surveys = universalSortSurveys([...surveys, survey]);

			newState = {
				...state,
				surveys,
				readings,
				readingsWithGeoGaps,
				readingsWithChartGaps,
				readingsMap,
				readingsIndexMap,
				readingsWithChartGapsIndexMap,
				readingsWithGeoGapsIndexMap,
				loadingReadings,
				readingsMeta,
				simplifiedReadings,
				series,
				seriesIndexMap
			};
		}

		return { ...newState, isUploadingFile: false };
	},

	// SHAPEFILES REDUCERS
	[requestShapefile]: state => {
		return { ...state, isDownloadingShapefile: true };
	},

	[receiveShapefile]: state => {
		return { ...state, isDownloadingShapefile: false };
	},

	// ACTIONREPORTS REDUCERS
	[requestReport]: state => {
		return { ...state, isDownloadingReport: true };
	},

	[receiveReport]: state => {
		return { ...state, isDownloadingReport: false };
	},

	[requestReportData]: state => {
		return { ...state, isFetchingReportData: true };
	},

	[receiveReportData]: state => {
		return { ...state, isFetchingReportData: false };
	},

	[requestSaveReportData]: state => {
		return { ...state, isSavingReportData: true };
	},

	[receiveSaveReportData]: state => {
		return { ...state, isSavingReportData: false };
	},

	[requestHasChangesReportData]: state => {
		return { ...state, isCheckingForChangesReportData: true };
	},

	[receiveHasChangesReportData]: state => {
		return { ...state, isCheckingForChangesReportData: false };
	},

	// SHARELINK REDUCERS
	[requestShareLink]: state => {
		return { ...state, isGeneratingShareLink: true };
	},

	[receiveShareLink]: state => {
		return { ...state, isGeneratingShareLink: false };
	},

	// RATING REDUCERS
	[requestGetRating]: state => {
		return { ...state, isFetchingRating: true };
	},

	[receiveGetRating]: state => {
		return { ...state, isFetchingRating: false };
	},

	[requestSaveRating]: state => {
		return { ...state, isSavingRating: true };
	},

	[receiveSaveRating]: state => {
		return { ...state, isSavingRating: false };
	},

	// MYPROJECT REDUCERS
	[requestCreateProject]: state => {
		return { ...state, isCreatingProject: true };
	},

	[requestCompleteCreateProject]: state => {
		return { ...state, isCreatingProject: false };
	},

	[removeSurveyByJobId]: (state, { payload: { jobId } }) => {
		return {
			...state,
			surveys: universalSortSurveys(removeSurveysByJobId(state.surveys, jobId))
		};
	},

	[updateBrush]: (state, { payload: { pos } }) => {
		return newBrushState(state, pos);
	},

	[setFocusedChart]: (state, { payload: { CHART_ID } }) => {
		if (CHART_ID === state.focusedChart) {
			return state;
		}
		return {
			...state,
			focusedChart: CHART_ID
		};
	},

	[setHoveredChart]: (state, { payload: { CHART_ID } }) => {
		if (CHART_ID === state.hoveredChart) {
			return state;
		}
		return {
			...state,
			hoveredChart: CHART_ID
		};
	},

	[setJobsModalVisibility]: (state, { payload: { bool } }) => {
		return { ...state, isJobsModalVisible: bool };
	},
	[setAllReadingsFlag]: (state, { payload: { bool } }) => {
		return { ...state, shouldGetAllReadings: bool };
	},
	[setExpandedChart]: (state, { payload: { chartId } }) => {
		let expandedChart = null;
		if (chartId) {
			expandedChart = { chartId };
		}
		return { ...state, expandedChart };
	},

	[addChartIsLoading]: (state, { payload: { CHART_ID } }) => {
		if (state.isLoadingChartIds.indexOf(CHART_ID) === -1) {
			return {
				...state,
				isLoadingChartIds: [...state.isLoadingChartIds, CHART_ID]
			};
		}

		return state;
	},

	[removeChartIsLoading]: (state, { payload: { CHART_ID } }) => {
		const idx = state.isLoadingChartIds.indexOf(CHART_ID);
		const arr = state.isLoadingChartIds;
		if (idx > -1) {
			return {
				...state,
				isLoadingChartIds: [...arr.slice(0, idx), ...arr.slice(idx + 1)]
			};
		}

		return state;
	},

	[addChartIsRendering]: (state, { payload: { CHART_ID } }) => {
		if (state.isRenderingChartIds.indexOf(CHART_ID) === -1) {
			return {
				...state,
				isRenderingChartIds: [...state.isRenderingChartIds, CHART_ID]
			};
		}

		return state;
	},

	// @todo - this code is no longer executable - need to remove.
	[removeChartIsRendering]: (state, { payload: { CHART_ID } }) => {
		const idx = state.isRenderingChartIds.indexOf(CHART_ID);
		const arr = state.isRenderingChartIds;

		let result = state;

		if (idx > -1) {
			result = {
				...state,
				isRenderingChartIds: [...arr.slice(0, idx), ...arr.slice(idx + 1)]
			};
		}

		if (
			(state.defaultSurvey && CHART_ID.indexOf(state.defaultSurvey.id) > -1) ||
			result.isRenderingChartIds.length === 0
		) {
			result.isFirstChartRendered = true;
		}

		return result;
	},

	[setUseInterpolatedDepol]: (
		state,
		{ payload: { survey, useInterpolatedDepol } }
	) => {
		const newState = {
			...state,
			useInterpolatedDepol: {
				...state.useInterpolatedDepol,
				[survey.id]: useInterpolatedDepol
			}
		};

		return {
			...newState,
			boundaryXMinXMax: calculateBoundaryXMinXMax(newState)
		};
	},

	[setUseNormalizedDepol]: (
		state,
		{ payload: { survey, useNormalizedDepol } }
	) => {
		const newState = {
			...state,
			useNormalizedDepol: {
				...state.useNormalizedDepol,
				[survey.id]: useNormalizedDepol
			}
		};

		return {
			...newState,
			boundaryXMinXMax: calculateBoundaryXMinXMax(newState)
		};
	},

	[requestDownloadPdf]: state => {
		return {
			...state,
			isDownloadingActionPlanPdf: true,
			downloadActionPlanPdfLink: undefined,
			downloadActionPlanPdfError: undefined,
			isHiddenDownloadActionPlanPdfError: undefined
		};
	},

	[receiveDownloadPdf]: (state, { payload: { link } }) => {
		return {
			...state,
			isDownloadingActionPlanPdf: false,
			downloadActionPlanPdfLink: link
		};
	},

	[receiveDownloadPdfError]: (state, { payload: { error } }) => {
		return {
			...state,
			isDownloadingActionPlanPdf: false,
			downloadActionPlanPdfLink: undefined,
			downloadActionPlanPdfError: error,
			isHiddenDownloadActionPlanPdfError: false
		};
	},

	[hideDownloadPdfError]: state => {
		return {
			...state,
			isHiddenDownloadActionPlanPdfError: true
		};
	},

	[clearDownloadPdf]: state => {
		return {
			...state,
			isDownloadingActionPlanPdf: false,
			downloadActionPlanPdfLink: undefined,
			downloadActionPlanPdfError: undefined,
			isHiddenDownloadActionPlanPdfError: undefined
		};
	},

	[requestDownloadPdfZip]: state => {
		return {
			...state,
			isDownloadingActionPlanPdfZip: true,
			downloadActionPlanPdfZipLink: undefined,
			downloadActionPlanPdfZipError: undefined,
			isHiddenDownloadActionPlanPdfZipError: undefined
		};
	},

	[receiveDownloadPdfZip]: (state, { payload: { link } }) => {
		return {
			...state,
			isDownloadingActionPlanPdfZip: false,
			downloadActionPlanPdfZipLink: link
		};
	},

	[receiveDownloadPdfZipError]: (state, { payload: { error } }) => {
		return {
			...state,
			isDownloadingActionPlanPdfZip: false,
			downloadActionPlanPdfZipLink: undefined,
			downloadActionPlanPdfZipError: error,
			isHiddenDownloadActionPlanPdfZipError: false
		};
	},

	[hideDownloadPdfZipError]: state => {
		return {
			...state,
			isHiddenDownloadActionPlanPdfZipError: true
		};
	},

	[clearDownloadPdfZip]: state => {
		return {
			...state,
			isDownloadingActionPlanPdfZip: false,
			downloadActionPlanPdfZipLink: undefined,
			downloadActionPlanPdfZipError: undefined,
			isHiddenDownloadActionPlanPdfZipError: undefined
		};
	},

	[requestDownloadDoc]: state => {
		return {
			...state,
			isDownloadingActionPlanDoc: true,
			downloadActionPlanDocLink: undefined,
			downloadActionPlanDocError: undefined,
			isHiddenDownloadActionPlanDocError: undefined
		};
	},

	[receiveDownloadDoc]: (state, { payload: { link } }) => {
		return {
			...state,
			isDownloadingActionPlanDoc: false,
			downloadActionPlanDocLink: link
		};
	},

	[receiveDownloadDocError]: (state, { payload: { error } }) => {
		return {
			...state,
			isDownloadingActionPlanDoc: false,
			downloadActionPlanDocLink: undefined,
			downloadActionPlanDocError: error,
			isHiddenDownloadActionPlanDocError: false
		};
	},

	[hideDownloadDocError]: state => {
		return {
			...state,
			isHiddenDownloadActionPlanDocError: true
		};
	},

	[clearDownloadDoc]: state => {
		return {
			...state,
			isDownloadingActionPlanDoc: false,
			downloadActionPlanDocLink: undefined,
			downloadActionPlanDocError: undefined,
			isHiddenDownloadActionPlanDocError: undefined
		};
	},

	[requestDownloadDocZip]: state => {
		return {
			...state,
			isDownloadingActionPlanDocZip: true,
			downloadActionPlanDocZipLink: undefined,
			downloadActionPlanDocZipError: undefined,
			isHiddenDownloadActionPlanDocZipError: undefined
		};
	},

	[receiveDownloadDocZip]: (state, { payload: { link } }) => {
		return {
			...state,
			isDownloadingActionPlanDocZip: false,
			downloadActionPlanDocZipLink: link
		};
	},

	[receiveDownloadDocZipError]: (state, { payload: { error } }) => {
		return {
			...state,
			isDownloadingActionPlanDocZip: false,
			downloadActionPlanDocZipLink: undefined,
			downloadActionPlanDocZipError: error,
			isHiddenDownloadActionPlanDocZipError: false
		};
	},

	[hideDownloadDocZipError]: state => {
		return {
			...state,
			isHiddenDownloadActionPlanDocZipError: true
		};
	},

	[requestDownloadD40Pdf]: state => {
		return {
			...state,
			isDownloadingActionPlanD40Pdf: true,
			downloadActionPlanD40PdfLink: undefined,
			downloadActionPlanD40PdfError: undefined,
			isHiddenDownloadActionPlanD40PdfError: undefined
		};
	},

	[receiveDownloadD40Pdf]: (state, { payload: { link } }) => {
		return {
			...state,
			isDownloadingActionPlanD40Pdf: false,
			downloadActionPlanD40PdfLink: link
		};
	},

	[receiveDownloadD40PdfError]: (state, { payload: { error } }) => {
		return {
			...state,
			isDownloadingActionPlanD40Pdf: false,
			downloadActionPlanD40PdfLink: undefined,
			downloadActionPlanD40PdfError: error,
			isHiddenDownloadActionPlanD40PdfError: false
		};
	},

	[hideDownloadD40PdfError]: state => {
		return {
			...state,
			isHiddenDownloadActionPlanD40PdfError: true
		};
	},

	[clearDownloadD40Pdf]: state => {
		return {
			...state,
			isDownloadingActionPlanD40Pdf: false,
			downloadActionPlanD40PdfLink: undefined,
			downloadActionPlanD40PdfError: undefined,
			isHiddenDownloadActionPlanD40PdfError: undefined
		};
	},
	[hideActionPlanDownloadErrors]: state => ({
		...state,
		isHiddenDownloadActionPlanPdfError: true,
		isHiddenDownloadActionPlanPdfZipError: true,
		isHiddenDownloadActionPlanDocError: true,
		isHiddenDownloadActionPlanDocZipError: true,
		isHiddenDownloadActionPlanD40PdfError: true
	}),

	[clearDownloadDocZip]: state => {
		return {
			...state,
			isDownloadingActionPlanDocZip: false,
			downloadActionPlanDocZipLink: undefined,
			downloadActionPlanDocZipError: undefined,
			isHiddenDownloadActionPlanDocZipError: undefined
		};
	},

	[requestDownloadClosure]: state => {
		return {
			...state,
			isDownloadingClosure: true,
			downloadClosureLink: undefined,
			downloadClosureError: undefined,
			isHiddenDownloadClosureError: undefined
		};
	},

	[receiveDownloadClosure]: (state, { payload: { link } }) => {
		return {
			...state,
			isDownloadingClosure: false,
			downloadClosureLink: link
		};
	},

	[receiveDownloadClosureError]: (state, { payload: { error } }) => {
		return {
			...state,
			isDownloadingClosure: false,
			downloadClosureLink: undefined,
			downloadClosureError: error,
			isHiddenDownloadClosureError: false
		};
	},

	[hideClosureDownloadErrors]: state => {
		return {
			...state,
			isHiddenDownloadClosureError: true
		};
	},

	[clearDownloadClosure]: state => {
		return {
			...state,
			isDownloadingClosure: false,
			downloadClosureLink: undefined,
			downloadClosureError: undefined,
			isHiddenDownloadClosureError: undefined
		};
	},

	[hideActionPlanDownloadErrors]: state => ({
		...state,
		isHiddenDownloadActionPlanPdfError: true,
		isHiddenDownloadActionPlanPdfZipError: true,
		isHiddenDownloadActionPlanDocError: true,
		isHiddenDownloadActionPlanDocZipError: true
	}),

	[requestUnsupportedReadings]: (state, { payload: { survey } }) => {
		return {
			...state,
			unsupportedSurveyS3KeysLoading: {
				...state.unsupportedSurveyS3KeysLoading,
				[survey.survey_guid]: [
					...state.unsupportedSurveyS3KeysToLoad[survey.survey_guid]
				]
			}
		};
	},

	[receiveUnsupportedReadings]: (
		state,
		{
			payload: { data, header, meta = {}, survey, s3Key, batchIdx, batchCount }
		}
	) => {
		const { id, survey_guid } = survey;

		// headers
		const surveyReadingsHeadersPages = [
			...(state.loadingUnsupportReadingsHeaders[id] || new Array(batchCount))
		];
		surveyReadingsHeadersPages[batchIdx] = parseUnsupportedReadingsHeader(
			header,
			data
		);
		const loadingUnsupportReadingsHeaders = {
			...state.loadingUnsupportReadingsHeaders,
			[id]: surveyReadingsHeadersPages
		};

		// readings
		const surveyReadingsPages = [
			...(state.loadingUnsupportReadings[id] || new Array(batchCount))
		];
		surveyReadingsPages[batchIdx] = data;
		const loadingUnsupportReadings = {
			...state.loadingUnsupportReadings,
			[id]: surveyReadingsPages
		};

		const unsupportedSurveyS3KeysToLoad = removeSurveyS3Key(
			survey,
			s3Key,
			state.unsupportedSurveyS3KeysToLoad
		);
		const unsupportedSurveyS3KeysLoading = removeSurveyS3Key(
			survey,
			s3Key,
			state.unsupportedSurveyS3KeysLoading
		);

		const isSurveyDone =
			!unsupportedSurveyS3KeysToLoad[survey_guid] &&
			!unsupportedSurveyS3KeysLoading[survey_guid];

		const unsupportedSurveysToLoad = isSurveyDone
			? removeSurvey(survey, state.unsupportedSurveysToLoad)
			: state.unsupportedSurveysToLoad;

		const unsupportedSurveysToProcess = isSurveyDone
			? [...(state.unsupportedSurveysToProcess || []), survey]
			: state.unsupportedSurveysToProcess;

		// also update the metadata
		const readingsMeta = { ...state.readingsMeta, [id]: meta };
		return {
			...state,
			unsupportedSurveysToProcess,
			unsupportedSurveysToLoad,
			unsupportedSurveyS3KeysToLoad,
			unsupportedSurveyS3KeysLoading,
			loadingUnsupportReadings,
			loadingUnsupportReadingsHeaders,
			readingsMeta
		};
	},

	[unsupportedReadingsLoaded]: (state, { payload: { survey } }) => {
		const { id } = survey;

		// REMOVE UNSUPPORTED SURVEYS THAT WE CANNOT PROCESS
		const {
			unsupportedSurveysToProcess,
			loadingUnsupportReadingsHeaders,
			loadingUnsupportReadings,
			unsupportedSurveyS3KeysToLoad,
			unsupportedSurveyS3KeysLoading
		} = cleanupUnsupportedSurveys(state);

		// SHORT CIRCUIT readingsLoaded IF WE REMOVED PASSED IN SURVEY
		const stillHasReadingsToLoad = unsupportedSurveysToProcess.find(
			s => s.id === id
		);
		if (!stillHasReadingsToLoad) {
			return {
				...state,
				unsupportedSurveysToProcess,
				loadingUnsupportReadingsHeaders,
				loadingUnsupportReadings,
				unsupportedSurveyS3KeysToLoad,
				unsupportedSurveyS3KeysLoading
			};
		}
		const newStatePropValues = processUnsupportedReadingsLoaded(
			survey,
			undefined,
			state
		);

		const newState = {
			...state,
			...newStatePropValues
		};

		return {
			...newState,
			boundaryXMinXMax: calculateBoundaryXMinXMax(newState)
		};
	},

	[clearProjectManagers]: state => {
		return {
			...state,
			projectManagers: undefined,
			isFetchingProjectManagers: false
		};
	},

	[receiveProjectManagers]: (
		state,
		{ payload: { projectManagers: paramProjectManagers } }
	) => {
		return {
			...state,
			projectManagers: paramProjectManagers || [],
			isFetchingProjectManagers: false
		};
	},

	[requestProjectManagers]: state => {
		return {
			...state,
			isFetchingProjectManagers: true
		};
	},

	[acSetViewAs]: (state, { payload: { user } }) => {
		const isNotSessionUser = user.userId !== getUserName();
		return {
			...state,
			viewAs: isNotSessionUser ? user : undefined
		};
	},

	[requestViewAsList]: state => ({
		...state,
		viewAsList: EMPTY_ARRAY,
		isLoadingViewAsList: true
	}),

	[receiveViewAsList]: (state, { payload: { users } }) => {
		const usersWithSessionUser = ViewAsUtil.injectSessionUser(users);
		const uniqueUsers = ViewAsUtil.toUniqueUsers(usersWithSessionUser);
		const enrichedUsers = ViewAsUtil.enrichUsers(uniqueUsers);

		return {
			...state,
			isLoadingViewAsList: false,
			viewAsList: enrichedUsers
		};
	},

	[setViewAsErrorMessage]: (state, { payload: { message } }) => {
		return {
			...state,
			viewAsErrorMessage: message
		};
	},

	[requestIsPm]: state => ({
		...state,
		isPm: false,
		isRequestingIsPm: true
	}),

	[receiveIsPm]: (state, { payload: { isPm } }) => {
		return {
			...state,
			isPm,
			isRequestingIsPm: false
		};
	},

	[setAlignedReadings]: (
		state,
		{
			payload: {
				primarySurvey,
				targetSurvey,
				readings,
				readingsWithChartGaps,
				readingsMap,
				readingsIndexMap,
				readingsWithChartGapsIndexMap,
				series,
				seriesIndexMap
			}
		}
	) => {
		const newState = {
			...state,
			alignedReadings: {
				...state.alignedReadings,
				[primarySurvey.id]: {
					...state.alignedReadings[primarySurvey.id],
					[targetSurvey.id]: {
						readings,
						readingsWithChartGaps,
						readingsMap,
						readingsIndexMap,
						readingsWithChartGapsIndexMap,
						series,
						seriesIndexMap
					}
				}
			}
		};

		return {
			...newState,
			boundaryXMinXMax: calculateBoundaryXMinXMax(newState)
		};
	},

	[setAlignedReadingsFiltered]: (
		state,
		{ payload: { primarySurvey, targetSurvey, filteredDatasetsByKey } }
	) => {
		const newState = {
			...state,
			alignedReadingsFiltered: {
				...state.alignedReadingsFiltered,
				[primarySurvey.id]: {
					...state.alignedReadingsFiltered[primarySurvey.id],
					[targetSurvey.id]: filteredDatasetsByKey
				}
			}
		};

		return {
			...newState,
			boundaryXMinXMax: calculateBoundaryXMinXMax(newState)
		};
	},

	[setDisplayAlignedReadingsInRibbon]: (
		state,
		{ payload: { targetSurveyId, display } }
	) => {
		const { alignedReadingsControl } = state;
		return {
			...state,
			alignedReadingsControl: {
				...alignedReadingsControl,
				[targetSurveyId]: {
					...(alignedReadingsControl[targetSurveyId] || {}),
					displayInRibbon: display
				}
			}
		};
	},

	[setRenderAlignedReadingsToSurvey]: (
		state,
		{ payload: { targetSurvey, primarySurvey } }
	) => {
		const { alignedReadingsControl } = state;
		const newState = {
			...state,
			alignedReadingsControl: {
				...alignedReadingsControl,
				[targetSurvey.id]: {
					...(alignedReadingsControl[targetSurvey.id] || {}),
					renderToSurvey: (primarySurvey || {}).id,
					displayInRibbon: !!primarySurvey
				}
			}
		};

		return {
			...newState,
			boundaryXMinXMax: calculateBoundaryXMinXMax(newState)
		};
	},

	[setAlignedRibbonVisibility]: (
		state,
		{ payload: { primarySurvey, visibility } }
	) => {
		return {
			...state,
			alignedReadingsRibbonsVisible: {
				...state.alignedReadingsRibbonsVisible,
				[primarySurvey.id]: visibility
			}
		};
	},

	// Note, this is built to be able to handle any combinations of primary/target surveys
	[setAlignedReadingsStats]: (
		state,
		{ payload: { primarySurvey, targetSurvey, stats } }
	) => {
		const primarySurveyLeaf =
			state.alignedReadingsStats[primarySurvey.id] || {};
		const targetSurveyLeaf = primarySurveyLeaf[targetSurvey.id] || {};

		if (Object.keys(stats).length !== 3) {
			throw new Error(
				`Expected exactly two keys in stats but found ${
					Object.keys(stats).length
				}. This is a programming error`
			);
		}

		const { target, primary, distances } = stats;
		return {
			...state,
			alignedReadingsStats: {
				...state.alignedReadingsStats,
				[primarySurvey.id]: {
					...primarySurveyLeaf,
					[targetSurvey.id]: {
						...targetSurveyLeaf,
						stats: {
							// Target and primary will include keys like "countFoundBetween", "countReadingsLostAfter", etc
							target,
							primary,
							distances
						}
					}
				}
			}
		};
	},

	[setAlignedReadingsThreshold]: (
		state,
		{ payload: { primarySurvey, threshold, tempThreshold } }
	) => {
		return {
			...state,
			alignedReadingsThreshold: {
				...state.alignedReadingsThreshold,
				[primarySurvey.id]: {
					threshold,
					tempThreshold
				}
			}
		};
	},

	[setRenderReadingsFilter]: (state, { payload: { survey, filterKey } }) => {
		const { alignedReadingsControl } = state;
		const newState = {
			...state,
			alignedReadingsControl: {
				...alignedReadingsControl,
				[survey.id]: {
					...(alignedReadingsControl[survey.id] || {}),
					filterKey
				}
			}
		};

		return {
			...newState,
			boundaryXMinXMax: calculateBoundaryXMinXMax(newState)
		};
	},

	// TODO: rremove this from iliSuveysToDisplayInPrimarySurvey
	[deleteAlignedReadings]: (state, { payload: { targetSurvey } }) => {
		const { alignedReadings } = state;
		const primarySurveyIds = Object.keys(alignedReadings);
		const newAlignedReadings = primarySurveyIds.reduce((acc, key) => {
			const primaryAlignedReadings = { ...alignedReadings[key] };
			delete primaryAlignedReadings[targetSurvey.id];
			return {
				...acc,
				[key]: primaryAlignedReadings
			};
		}, {});

		return {
			...state,
			alignedReadings: newAlignedReadings
		};
	},

	[setReadingsDownloadMessage]: (state, { payload: { key, message } }) => {
		const readingsDownloadMessagesStore = {
			...state.readingsDownloadMessagesStore,
			[key]: message
		};

		const readingsDownloadMessages = Object.keys(
			readingsDownloadMessagesStore
		).reduce((acc, k) => {
			if (readingsDownloadMessagesStore[k]) {
				acc.push(readingsDownloadMessagesStore[k]);
			}
			return acc;
		}, []);

		return {
			...state,
			readingsDownloadMessagesStore,
			readingsDownloadMessages
		};
	},

	[clearReadingsDownloadMessages]: state => {
		return {
			...state,
			readingsDownloadMessagesStore: {},
			readingsDownloadMessages: []
		};
	},

	[setSurveysDownloadMessage]: (state, { payload: { key, message } }) => {
		const surveysDownloadMessagesStore = {
			...state.surveysDownloadMessagesStore,
			[key]: message
		};

		const surveysDownloadMessages = Object.keys(
			surveysDownloadMessagesStore
		).reduce((acc, k) => {
			if (surveysDownloadMessagesStore[k]) {
				acc.push(surveysDownloadMessagesStore[k]);
			}
			return acc;
		}, []);

		return {
			...state,
			surveysDownloadMessagesStore,
			surveysDownloadMessages
		};
	},

	[clearSurveysDownloadMessages]: state => {
		return {
			...state,
			surveysDownloadMessagesStore: {},
			surveysDownloadMessages: []
		};
	},

	[toggleDataDownloadMessagesCollapse]: state => {
		const { isDataDownloadMessagesExpanded } = state;
		return {
			...state,
			isDataDownloadMessagesExpanded: !isDataDownloadMessagesExpanded
		};
	},

	[setReadingsFiltered]: (state, { payload: { survey, datasets } }) => {
		const { readingsFiltered } = state;
		return {
			...state,
			readingsFiltered: {
				...readingsFiltered,
				[survey.id]: datasets
			}
		};
	}
};

export default reducerParts;
