import executeLastCall from './executeLastCall';

export const createDebouncedRefresh = chart => {
	const execChartRefresh = () => {
		if (chart && chart.refresh && chart.element) {
			chart.refresh();
		}
	};

	return executeLastCall(execChartRefresh, 24);
};

export const ChartGroupChartProps = {
	enableSideBySidePlacement: false,
	background: 'transparent',
	height: '300px',
	primaryXAxis: {
		stripLines: [
			{
				start: 0,
				end: 0,
				color: '#512fff',
				visible: false,
				border: {
					color: '#512fff',
					width: 1
				}
			}
		]
	},
	primaryYAxis: {
		isInversed: true
	},
	crosshair: {
		enable: true,
		lineType: 'Vertical'
	},
	zoomSettings: {
		enabled: true,
		enableMouseWheelZooming: false,
		enableSelectionZooming: false
	},
	legacyTooltipConfig: {
		enable: true,
		shared: true,
		displayMode: 'Always',
		format: '${series.name}: ${point.y}' // eslint-disable-line no-template-curly-in-string
	},
	legendSettings: {
		visible: true,
		// series selection on legend
		toggleVisibility: false
	}
};

export const ChartGroupStyles = {
	group: {
		position: 'relative',
		marginBottom: '10px',
		paddingRight: '200px',
		borderBottom: '1px dotted #b5b5b5'
	},
	groupContent: {
		position: 'relative',
		paddingBottom: '5px',
		marginTop: '10px'
	},
	groupContentExpanded: {
		position: 'fixed',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		zIndex: 9999,
		padding: '20px',
		background: '#ffffff',
		border: '20px solid #ffffff'
	},
	groupSidebar: {
		position: 'relative',
		top: 0,
		bottom: 0,
		marginRight: '15px',
		padding: '10px 0px 15px 10px',
		borderLeft: '1px dotted #b5b5b5'
	},
	navigationButtonDisabled: {
		padding: '0px',
		width: '22px',
		height: '20px',
		color: '#bbbbbb',
		fontSize: '16px',
		background: '#e0e0e0',
		border: 'none'
	},
	navigationButton: {
		padding: '0px',
		width: '22px',
		height: '20px',
		fontSize: '16px',
		background: '#e0e0e0',
		border: 'none'
	},
	inputLabel: {
		marginLeft: '5px',
		width: '130px',
		fontSize: '13px',
		textAlign: 'right'
	},
	inputField: {
		width: '60px',
		background: 'transparent',
		border: '1px solid #b5b5b5'
	},
	checkbox: {
		position: 'relative',
		bottom: '-2px',
		margin: '0px'
	},
	table: {
		height: '285px',
		fontSize: '12px'
	}
};

export const cleanReadings = (readings, key) => {
	return readings.filter(reading => reading[key] !== 0);
};

export const cleanDepolReadingsForChart = (readings, key) => {
	return readings.map(reading => {
		if (reading[key] === 0) {
			return { ...reading, [key]: undefined };
		}
		return reading;
	});
};

export const getChartId = (survey = {}, options = {}) => {
	let id = survey;
	if (typeof id === 'object') {
		id = survey.id;
	}

	const { prefix } = options;
	if (prefix) {
		return id ? `sfchart-${prefix}-${id}` : undefined;
	}
	return id ? `sfchart-${id}` : undefined;
};

export const getSurveyIdFromChartId = chartId => {
	if (!chartId) {
		return undefined;
	}

	return chartId.replace('sfchart-', '');
};

export const CHART_EVENTS_DELAY_MOUSE_OVER = 17;
export const CHART_EVENTS_DELAY_ZOOM = 100;
