/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { createSelector } from 'reselect';

import { ROOT_SLICE } from './constants';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectCustomers = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { customers } = slice;
	return customers;
};

export const selectCustomersApplications = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { customersApplications } = slice;
	return customersApplications;
};

export const selectCustomersApplicationsChangesClone = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { customersApplicationsChangesClone } = slice;
	return customersApplicationsChangesClone;
};

export const selectCustomersApplication = (stateAdmin, id) => {
	const slice = selectRootSlice(stateAdmin);
	const { customersApplicationsChangesClone = [] } = slice;
	return customersApplicationsChangesClone.find(app => app.id === id);
};

export const selectIsLoadingCustomers = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoadingCustomers } = slice;
	return isLoadingCustomers;
};

export const selectIsLoadedCustomers = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoadedCustomers } = slice;
	return isLoadedCustomers;
};

export const selectHasLoadingErrorCustomers = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { hasLoadingErrorCustomers } = slice;
	return hasLoadingErrorCustomers;
};

export const selectSelectedCustomer = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedCustomer } = slice;
	return selectedCustomer;
};

export const selectSelectedCustomerChangesClone = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedCustomerChangesClone } = slice;
	return selectedCustomerChangesClone;
};

export const selectHasCustomerChanges = createSelector(
	selectSelectedCustomer,
	selectSelectedCustomerChangesClone,
	(selectedCustomer, selectedClone) => {
		const customer = _.cloneDeep(selectedCustomer) || {};

		const clone = _.cloneDeep(selectedClone) || {};

		return customer && clone && !_.isEqual(customer, clone);
	}
);

export const selectHasCustomersApplicationsChanges = createSelector(
	selectCustomersApplications,
	selectCustomersApplicationsChangesClone,
	(customersApplications, customersApplicationsClone) => {
		const customersApps = _.cloneDeep(customersApplications) || [];

		const clone = _.cloneDeep(customersApplicationsClone) || [];

		return customersApps && clone && !_.isEqual(customersApps, clone);
	}
);

export const selectHasChanges = createSelector(
	selectHasCustomerChanges,
	selectHasCustomersApplicationsChanges,
	selectSelectedCustomer,
	(hasCustomerChanges, hasCustomersApplicatChanges, customer = {}) => {
		if (customer.id === undefined) {
			return hasCustomerChanges;
		}
		return hasCustomerChanges || hasCustomersApplicatChanges;
	}
);

export const selectIsSavingEditedCustomer = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isSavingEditedCustomer } = slice;
	return isSavingEditedCustomer;
};
