/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import {
	setUsers,
	setLoadMeta,
	setHasLoadingErrorUsers,
	setSelectedUser,
	setSelectedUserChangesClone,
	setNewUser,
	setIsSavingNewUser,
	setErrorMessageSaveNewUser,
	setIsSavingEditedUser,
	setErrorMessageSaveEditedUser
} from './actions';
import { getUsers, sendInvite, updateUser } from '../../../../api/users';
import { selectRootSliceAdmin } from '../../../Main/selectors';
import { selectCompanyName } from '../../UsersSearch/selectors';
import {
	selectUsers,
	selectNewUser,
	selectErrorMessageSaveNewUser,
	selectHasChanges,
	selectSelectedUserChangesClone
} from '../selectors';
import { createNewUserObject } from '../util';
import { reloadUsers } from '../../../../components/UserAdminPage/onAppLoad';

const loadUsers = () => dispatch => {
	dispatch(setUsers([]));
	dispatch(
		setLoadMeta({
			isLoadingUsers: true,
			isLoadedUsers: false,
			usersTotalCount: 0
		})
	);

	getUsers(undefined, (err, users) => {
		if (err) {
			dispatch(setHasLoadingErrorUsers(true));
			dispatch(
				setLoadMeta({
					isLoadingUsers: false,
					isLoadedUsers: false,
					usersTotalCount: 0
				})
			);
		} else {
			dispatch(setUsers(users));
			dispatch(
				setLoadMeta({
					isLoadingUsers: false,
					isLoadedUsers: true,
					usersTotalCount: (users || []).length
				})
			);
		}
	});
};

const thunkSetSelectedUser = user => dispatch => {
	const clone = user ? _.cloneDeep(user) : user;
	dispatch(setSelectedUser(user));
	dispatch(setSelectedUserChangesClone(clone));
};

const thunkInitNewUser = () => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const company = selectCompanyName(stateAdmin);
	dispatch(setNewUser(createNewUserObject(company)));
};

const thunkSetNewUserEmail = email => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const newUser = selectNewUser(stateAdmin);
	if (newUser.email !== email) {
		dispatch(setNewUser({ ...newUser, email }));
	}
};

const thunkClearErrorMessageSaveNewUser = () => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const message = selectErrorMessageSaveNewUser(stateAdmin);
	if (message) {
		dispatch(setErrorMessageSaveNewUser(undefined));
	}
};

const thunkSetErrorMessageSaveNewUser = error => dispatch => {
	if (typeof error === 'string') {
		dispatch(setErrorMessageSaveNewUser(error));
	} else if (error instanceof Object) {
		const { message } = error;
		if (message) {
			dispatch(setErrorMessageSaveNewUser(message));
		} else {
			dispatch(
				setErrorMessageSaveNewUser(
					'An unknown error occured, review email and try again'
				)
			);
		}
	} else {
		dispatch(
			setErrorMessageSaveNewUser(
				'An unknown error occured, review email and try again'
			)
		);
	}
};

const thunkSendInvite = () => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const newUser = selectNewUser(stateAdmin) || {};

	dispatch(setIsSavingNewUser(true));
	sendInvite(newUser, (err, res) => {
		if (err) {
			dispatch(thunkSetErrorMessageSaveNewUser(err));
		} else {
			const { message } = res || {};
			if (message) {
				if (message.indexOf('User account already exists') === 0) {
					dispatch(
						thunkSetErrorMessageSaveNewUser('User account already exists.')
					);
				} else {
					dispatch(thunkSetErrorMessageSaveNewUser(message));
				}
			} else {
				dispatch(reloadUsers());
			}
		}
		dispatch(setIsSavingNewUser(false));
	});
};

const thunkSetErrorMessageSaveEditedUser = error => dispatch => {
	if (typeof error === 'string') {
		dispatch(setErrorMessageSaveEditedUser(error));
	} else if (error instanceof Object) {
		const { message } = error;
		if (message) {
			dispatch(setErrorMessageSaveEditedUser(message));
		} else {
			dispatch(setErrorMessageSaveEditedUser('An unknown error occured'));
		}
	} else {
		dispatch(setErrorMessageSaveEditedUser('An unknown error occured'));
	}
};

const _thunkReplaceUserWithUpdatedUser = (updatedUser = {}) => (
	dispatch,
	getState
) => {
	if (updatedUser.username) {
		const stateAdmin = selectRootSliceAdmin(getState());
		const newUsers = [...selectUsers(stateAdmin)];

		const currentIndex = newUsers.findIndex(
			u => u.username === updatedUser.username
		);
		if (currentIndex > -1) {
			newUsers[currentIndex] = updatedUser;
		} else {
			newUsers.push(updatedUser);
		}

		dispatch(setUsers(newUsers));
	}
};

const thunkSaveEditedUser = () => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const editedUser = selectSelectedUserChangesClone(stateAdmin);
	const hasChanges = selectHasChanges(stateAdmin);

	if (hasChanges && editedUser && editedUser.username) {
		dispatch(setErrorMessageSaveEditedUser(undefined));
		dispatch(setIsSavingEditedUser(true));

		updateUser({ user: editedUser }, (err, updatedUser) => {
			if (err) {
				dispatch(thunkSetErrorMessageSaveEditedUser(err));
			} else {
				dispatch(_thunkReplaceUserWithUpdatedUser(updatedUser));
				dispatch(thunkSetSelectedUser(updatedUser));
			}
			dispatch(setIsSavingEditedUser(false));
		});
	}
};

export {
	loadUsers,
	thunkSetSelectedUser,
	thunkInitNewUser,
	thunkSetNewUserEmail,
	thunkSetErrorMessageSaveNewUser,
	thunkClearErrorMessageSaveNewUser,
	thunkSendInvite,
	thunkSetErrorMessageSaveEditedUser,
	thunkSaveEditedUser
};
