/* eslint-disable import/no-cycle */
export {
	/* ******************
	 * GENERAL CONSTANTS
	 * */
	INPROGRESS,
	COMPLETE,
	NEW,
	/* ******************
	 * AC CONSTANTS
	 * */
	SET_GLOBAL_DATA,
	SET_GPS_READINGS_STILL_LOADING,
	SET_SAVING_COMPLETE,
	REMOVE_DAT,
	SET_DAT_VISIBILITY,
	SET_DAT_TRIMS,
	SET_READING_COMMENT,
	SET_REPLACE_READING,
	SET_DELETE_READING,
	SET_DELETE_SKIP,
	SET_ADD_GPS_POINT,
	SET_ADD_SKIP,
	ADD_DAT_FILE_EDITED_COUNT,
	REMOVE_DAT_FILE_EDITED_COUNT,
	SET_MAP_EDIT_IN_PROCESS,
	SET_READINGS_CHANGED_CACHED_DATA,
	SET_IS_UNDOING,
	/* ******************
	 * PURE ACTION CREATORS
	 * */
	setGlobalData,
	setGpsReadingsStillLoading,
	setSavingComplete,
	setRemoveDat,
	setDatVisibility,
	setDatTrims,
	setReadingComment,
	setReplaceReading,
	setDeleteReading,
	setDeleteSkip,
	setAddGpsPoint,
	setAddSkip,
	setAddDatFileCount,
	setRemoveDatFileCount,
	setMapEditInProcess,
	setCachedReadingsData,
	setIsUndoing
} from './job';

/* ******************
 * THUNK ACTION CREATORS - file edits
 * */
export {
	onReadingsChanged,
	changeDatFileVisibility,
	changeDatFileTrims,
	decrementDatFileSavingCount,
	incrementDatFileSavingCount,
	save,
	removeDat,
	removeAllDatFiles,
	updateReadingComment,
	updateGlobalData,
	replaceReading,
	deleteReading,
	addSkip,
	updateTrims,
	addStn,
	deleteSkip,
	saveHeaderInfo,
	saveStartStation,
	saveStationAlignment,
	_updateJobWithFlips,
	flipDatFiles,
	flipDatFile,
	saveInterval,
	savePipeDiameter,
	saveSegmentGaps,
	moveToSegment,
	changeFileStatus,
	changeSync,
	changeCisvStnAlign,
	setHistoricalJobs,
	changeStatus,
	changeAssistingDP
} from './job.thunks.fileEdits';

/* ******************
 * THUNK ACTION CREATORS - map edits
 * */
export {
	addGPSPoint,
	activateMoveTool,
	deactivateMoveTool,
	activateSmoothTool,
	deactivateSmoothTool,
	deactivateMapTools,
	undoMapTools
} from './job.thunks.mapEdits';

/* ******************
 * THUNK ACTION CREATORS - machine learning edits
 * */
export {
	setMlChangesSaved,
	saveGpsAutoCorrection,
	saveReadingAutoCorrection
} from './job.thunks.mlEdits';
