export const getReadings = (
	surveyId,
	surveys = [],
	readings = {},
	depolData = {},
	alignedReadingsByTargetSurveyId = {}
) => {
	const {
		readingsNormalized = {},
		interpReadings = {},
		interpReadingsNormalized = {},
		useInterpolatedDepol: useInterpolatedDepolMap = {},
		useNormalizedDepol: useNormalizedDepolMap = {}
	} = depolData;

	const survey = surveys.find(s => s.id === surveyId);
	const isDepol = (survey || {}).survey_subtype === 'DEPOL';

	if (isDepol) {
		const parentSurvey = surveys.find(
			s =>
				s.job_number === survey.job_number &&
				s.id !== survey.id &&
				s.survey_type === 'CIS'
		);

		if (parentSurvey) {
			const useInterpolatedDepol = useInterpolatedDepolMap[parentSurvey.id];
			const useNormalizedDepol = useNormalizedDepolMap[parentSurvey.id];

			if (
				useInterpolatedDepol &&
				useNormalizedDepol &&
				interpReadingsNormalized[surveyId] &&
				interpReadingsNormalized[surveyId].length
			) {
				return interpReadingsNormalized[surveyId];
			}

			if (
				useNormalizedDepol &&
				readingsNormalized[surveyId] &&
				readingsNormalized[surveyId].length
			) {
				return readingsNormalized[surveyId];
			}

			if (
				useInterpolatedDepol &&
				interpReadings[surveyId] &&
				interpReadings[surveyId].length
			) {
				return interpReadings[surveyId];
			}
		}
	}

	const isAligned = alignedReadingsByTargetSurveyId[surveyId];
	if (isAligned) {
		return alignedReadingsByTargetSurveyId[surveyId];
	}

	return readings[surveyId];
};

export const isSurveyCurrentlyAlignedAsATarget = (state, surveyid) => {
	const { alignedReadingsControl = {} } = state;
	const control = alignedReadingsControl[surveyid];
	if (!control) {
		return false;
	}

	const { renderToSurvey } = control;

	if (!renderToSurvey) {
		return false;
	}

	return true;
};

export const getFlattenAlignedTargetReadings = targetReadingObjects => {
	let alignedTargetReadings = {};

	targetReadingObjects.forEach(obj => {
		Object.keys(obj).forEach(key => {
			const value = obj[key];
			const { readings } = value;
			alignedTargetReadings = {
				...alignedTargetReadings,
				[key]: readings
			};
		});
	});

	return alignedTargetReadings;
};

export const flattenAlignedTarget = state => {
	// alignedReadings are nested such that the first level is primarySurveyId and the second level is targetSurveyId. We want a dictionary of all targetSurveyIds
	const { alignedReadings } = state;

	if (!alignedReadings || Object.keys(alignedReadings).length === 0) {
		return {};
	}

	const targetReadingObjects = Object.values(alignedReadings);

	return getFlattenAlignedTargetReadings(targetReadingObjects);
};

export const filterAlignedTargetsIfCurrentlyAligned = (
	state,
	flattenedReadingsById
) => {
	const output = {};

	Object.keys(flattenedReadingsById).forEach(surveyId => {
		if (!isSurveyCurrentlyAlignedAsATarget(state, surveyId)) {
			return;
		}

		output[surveyId] = flattenedReadingsById[surveyId];
	});

	return output;
};

export const getAlignedTargetReadings = state => {
	const flattenedReadingsById = flattenAlignedTarget(state);

	return filterAlignedTargetsIfCurrentlyAligned(state, flattenedReadingsById);
};

const getVisibleSurveyIds = surveys => {
	if (!surveys) {
		return [];
	}
	return surveys.filter(s => s.checked).map(s => s.id);
};

export const calculateBoundaryXMinXMax = (state = {}) => {
	const { boundaryXMinXMax } = state;
	let result = { ...boundaryXMinXMax };

	const {
		surveys = [],
		readings = {},
		readingsNormalized = {},
		interpReadings = {},
		interpReadingsNormalized = {},
		useInterpolatedDepol = {},
		useNormalizedDepol = {}
	} = state;

	const alignedReadingsByTargetSurveyId = getAlignedTargetReadings(state);
	const surveyKeys = getVisibleSurveyIds(surveys);

	let xMin;
	let xMax;

	surveyKeys.forEach(key => {
		const depolData = {
			readingsNormalized,
			interpReadings,
			interpReadingsNormalized,
			useInterpolatedDepol,
			useNormalizedDepol
		};
		const surveyReadings =
			getReadings(
				key,
				surveys,
				readings,
				depolData,
				alignedReadingsByTargetSurveyId
			) || [];

		const hasFirstReading =
			surveyReadings.length &&
			surveyReadings[0].id !== undefined &&
			surveyReadings[0].id !== null;
		const hasLastReading =
			surveyReadings.length &&
			surveyReadings[surveyReadings.length - 1].id !== undefined &&
			surveyReadings[surveyReadings.length - 1].id !== null;

		if (hasFirstReading) {
			const firstReading = surveyReadings[0];
			// console.log(`firstReading.id --> ${firstReading.id}`);

			if (xMin === undefined || firstReading.id < xMin) {
				xMin = firstReading.id;
			}
		}

		if (hasLastReading) {
			const lastReading = surveyReadings[surveyReadings.length - 1];
			// console.log(`lastReading.id --> ${lastReading.id}`);

			if (xMax === undefined || lastReading.id > xMax) {
				xMax = lastReading.id;
			}
		}
	});

	if (xMin !== result.xMin) {
		result = {
			...result,
			xMin
		};
	}

	if (xMax !== result.xMax) {
		result = {
			...result,
			xMax
		};
	}

	if (
		result.xMin === boundaryXMinXMax.xMin &&
		result.xMax === boundaryXMinXMax.xMax
	) {
		return boundaryXMinXMax;
	}

	return result;
};

export default { calculateBoundaryXMinXMax };
