/* eslint-disable no-param-reassign */
import createBaseSurveyMeta from './_BASE';

import {
	computeUuid,
	computeFormattedStationId
} from '../util/readings.parse.computeProps';
import { generateReadingDataStructures } from '../../../reducers/util/readings.parse';
import { createComputeYMinYMax } from '../util/yMinYMax';

const SERIES_TYPE_COLUMN_THRESHOLD = 5500;

const INVERT_Y_AXIS = false;

const isNewFeature = r =>
	r && (r.feature || '').toLowerCase() === 'new feature';
const isGrowth = r => r && (r.feature || '').toLowerCase() === 'growth';
const isNoGrowth = r => r && (r.feature || '').toLowerCase() === 'no growth';

const mapArrDataStructuresToObj = arrDataStructures => {
	const [
		readings,
		readingsWithGeoGaps,
		readingsWithChartGaps,
		readingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		readingsWithGeoGapsIndexMap,
		series,
		seriesIndexMap,
		readingsStationIdIntegers,
		simplifiedReadings
	] = arrDataStructures;

	return {
		readings,
		readingsWithGeoGaps,
		readingsWithChartGaps,
		readingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		readingsWithGeoGapsIndexMap,
		series,
		seriesIndexMap,
		readingsStationIdIntegers,
		simplifiedReadings
	};
};

export default _survey => {
	// CONSTANTS

	const gapThreshold = 200;
	const labelMap = {
		exMtlLoss: 'External_Metal_Loss',
		inMtlLoss: 'Internal_Metal_Loss',
		swl: 'Seem_Weld',
		dent: 'Dent'
	};

	const fieldLabelMap = {
		exMtlLoss: 'G-RATE',
		inMtlLoss: 'G-RATE',
		swl: 'G-RATE',
		dent: 'DENT'
	};

	const fieldNameMap = {
		exMtlLoss: 'gRate',
		inMtlLoss: 'gRate',
		swl: 'gRate',
		dent: 'dent'
	};

	const colorsMap = {
		exMtlLoss: '#00C27A', // pastel green
		inMtlLoss: '#357cd2', // blue
		swl: '#FA83C3', // pastel pink
		dent: '#E98272' // mat orange
	};

	// READING KEYS

	const fieldName = _survey.survey_subtype.replace('ILI-', ''); // should be "exMtlLoss" for example
	const readingKeys = [
		{
			label: labelMap[fieldName],
			value: fieldNameMap[fieldName],
			color: colorsMap[fieldName],
			seriesType: 'bar',
			ribbonKey: fieldName,
			getSeriesType: (dataset = []) => {
				const isBeyondPerformanceLimitations =
					dataset.length > SERIES_TYPE_COLUMN_THRESHOLD;

				if (isBeyondPerformanceLimitations) {
					return 'line';
				}

				return 'bar';
			}
		}
	];
	const readingKeyProps = readingKeys.map(k => k.value);
	const computeYMinYMax = createComputeYMinYMax(readingKeys);

	// SKIP READING

	const isSkipReading = reading =>
		reading && readingKeys.every(rk => reading[rk.value] === 0);

	// HAS GAP

	const hasGap = (currReading, prevReading) => {
		return !!prevReading && currReading.id - prevReading.id > gapThreshold;
	};

	// INTERPOLATE

	const interpolate = (
		// eslint-disable-next-line no-unused-vars
		prevDepolReading,
		// eslint-disable-next-line no-unused-vars
		nextDepolReading,
		// eslint-disable-next-line no-unused-vars
		alignToReading
	) => {};

	const computeHilo = r => {
		r.low = r.low || 0;
	};

	const computeCasing = r => {
		if (r.casing === true) {
			r.casing = 'Yes';
		} else if (r.casing === false) {
			r.casing = 'No';
		}
	};

	// COMPUTE PROPS - to be used by readings.parse
	const computeProps = (survey, _prevReading, currReading, currIdx) => {
		computeUuid(currReading, currIdx);
		computeFormattedStationId(currReading);
		computeHilo(currReading);
		computeCasing(currReading);
		computeYMinYMax(survey, currReading);
	};

	// SIMPLIFIED READINGS - creates a smaller data set to be used for rendering polyline on map
	// most survey types will not have simplified readings
	// eslint-disable-next-line no-unused-vars
	const createSimplifiedReadings = ({ readings, subtype }) => undefined;

	const filterReadingsOfUnsupportedSurveyType = (readings, survey) => {
		if (!readings) {
			return readings;
		}

		let key;
		let hasAnomaly;
		switch (survey.survey_subtype) {
			case 'ILI-exMtlLoss':
				key = 'gRate';
				hasAnomaly = txt => /EXT\sML/gi.test(txt);
				break;
			case 'ILI-inMtlLoss':
				key = 'gRate';
				hasAnomaly = txt => /INT\sML/gi.test(txt);
				break;
			// case 'ILI-swl': // add back once approved by KM
			//   key = 'gRate';
			//   hasAnomaly = txt => /SWL/gi.test(txt);
			//   break;
			// case 'ILI-dent': // add back once approved by KM
			// 	key = 'dent';
			// 	hasAnomaly = txt => /DENT/gi.test(txt);
			// 	break;
			default:
				key = 'ignore-this';
				hasAnomaly = () => false;
		}

		return readings.filter(
			r => r[key] !== null && r[key] !== undefined && hasAnomaly(r.anomaly)
		);
	};

	// MAIN

	const meta = {
		...createBaseSurveyMeta(_survey),
		isChartInversed: INVERT_Y_AXIS,
		readingKeys,
		readingKeyProps,
		fkey: fieldName,
		columns: [
			{ label: 'ID', value: 'id', checked: true, width: 64, required: true },
			{ label: 'LAT', value: 'lat', checked: false, width: 65 },
			{ label: 'LNG', value: 'lng', checked: false, width: 70 },
			{
				label: fieldLabelMap[fieldName],
				value: fieldNameMap[fieldName],
				checked: true,
				width: 90
			},
			{
				label: 'DEPTH',
				value: 'dep',
				checked: true,
				width: 60
			},
			{ label: 'CASING', value: 'casing', checked: false, width: 65 },
			{
				label: 'FEATURE',
				value: 'feature',
				checked: true,
				width: 70,
				type: 'text'
			},
			{
				label: 'ANOMALY_TYPE',
				value: 'anomaly',
				checked: true,
				width: 85,
				type: 'text'
			},
			{
				label: 'COMMENTS',
				value: 'cmt',
				checked: true,
				width: 85,
				type: 'text'
			}
		],
		isSkipReading,
		hasGap,
		interpolate,
		computeProps,
		createSimplifiedReadings,
		computeYMinYMax,
		filterReadingsOfUnsupportedSurveyType,
		computeFilteredDataSets: (survey, readings = []) => {
			const growths = [];
			const noGrowths = [];
			const newFeatures = [];
			readings.forEach(r => {
				if (isGrowth(r)) {
					growths.push(r);
				} else if (isNoGrowth(r)) {
					noGrowths.push(r);
				} else if (isNewFeature(r)) {
					newFeatures.push(r);
				}
			});
			return {
				growths: mapArrDataStructuresToObj(
					generateReadingDataStructures(survey, growths)
				),
				noGrowths: mapArrDataStructuresToObj(
					generateReadingDataStructures(survey, noGrowths)
				),
				newFeatures: mapArrDataStructuresToObj(
					generateReadingDataStructures(survey, newFeatures)
				)
			};
		}

		// TODO: Possibly add thresholds to ILI
		/* thresholds: [{
            start: 50,
            end: 50.01,
            color: '#ff512f',
            visible: true,
            border: { color: '#ff512f', width: 1 },
        }], */
	};

	return meta;
};
