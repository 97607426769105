const MARKER_WIDTH = 10;
const LINE_WIDTH = 10;

export const markerConfig = {
	visible: true,
	width: MARKER_WIDTH,
	height: MARKER_WIDTH
};

export const defaultSeriesConfig = {
	type: 'Line',
	xName: 'id',
	yName: 'y',
	name: 'ON',
	width: LINE_WIDTH,

	marker: markerConfig
};

export const config = {
	width: '100%',
	height: '100px',
	selectionMode: 'Series',
	legendSettings: {
		visible: false
	},

	// Initializing Primary X Axis
	primaryXAxis: {
		edgeLabelPlacement: 'Shift',
		majorGridLines: { width: 0 }
	},

	// Initializing Primary X Axis
	primaryYAxis: {
		edgeLabelPlacement: 'Hide',
		rangePadding: 'None',
		minimum: 0,
		maximum: 1,
		interval: 1,
		lineStyle: { width: 0 },
		majorTickLines: { width: 0 },
		minorTickLines: { width: 0 }
	},

	chartArea: {
		border: {
			width: 0
		}
	},

	// Initializing Chart Series
	series: [],

	tooltip: {
		enable: true
	},

	zoomSettings: {
		mode: 'X',
		enableMouseWheelZooming: true,
		enableSelectionZooming: true
	}

	// chartMouseClick: (e) => {
	//   const deselectOne = () => {
	//     const chart = window.wb;
	//     enableAnimation(chart, false);
	//     window.wb.selectionMode = 'none';
	//     window.wb.refresh();
	//     window.wb.selectionMode = 'Series';
	//   }
	//   const deselectTwo = () => {
	//     const chart = window.wb;
	//     deselectSeries(chart);
	//   }
	//   console.log(e.target);
	//   setTimeout(deselectTwo, 1000);
	// },

	// chartMouseMove: (e) => {
	//   console.log(e.name);
	// },

	// tooltipRender: (e) => {
	//   console.log(e.name);
	//   console.log(e.text);
	//   console.log(e.cancel);
	//   console.log(!!e.point);
	//   console.log(!!e.point.tooltip);
	//   console.log(++cntTR)
	//   debugger;
	// }

	// width: ej.base.Browser.isDevice ? '100%' : '60%',
};
