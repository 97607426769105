import { createSelector } from 'reselect';
import { getSurveyIdFromChartId } from '../../utils/chart';
import {
	selectPrimarySurveyFromDerived,
	selectSurvey,
	selectSurveysForAnalytics
} from './surveys';

const isDefined = v => v !== undefined && v !== null;

export const selectReadingsStore = stateApp => {
	const { readings } = stateApp;
	return readings;
};

export const selectReadingsMapStore = stateApp => {
	const { readingsMap } = stateApp;
	return readingsMap;
};

export const selectReadingsIndexMapStore = stateApp => {
	const { readingsIndexMap } = stateApp;
	return readingsIndexMap;
};

export const selectReadingsWithChartGapsStore = stateApp => {
	const { readingsWithChartGaps } = stateApp;
	return readingsWithChartGaps;
};

export const selectSimplifiedReadingsStore = stateApp => {
	const { simplifiedReadings } = stateApp;
	return simplifiedReadings;
};

export const getId = val => {
	if (typeof val === 'object') {
		return val.id;
	}
	return val;
};

export const selectReadings = (stateApp, survey) => {
	const surveyId = getId(survey);
	const readingsStore = selectReadingsStore(stateApp) || {};
	return readingsStore[surveyId];
};

export const selectReadingsMap = (stateApp, survey) => {
	const surveyId = getId(survey);
	const readingsMapStore = selectReadingsMapStore(stateApp) || {};
	return readingsMapStore[surveyId];
};

export const selectReadingsIndexMap = (stateApp, survey) => {
	const surveyId = getId(survey);
	const readingsStore = selectReadingsIndexMapStore(stateApp) || {};
	return readingsStore[surveyId];
};

export const selectReadingsForAnalytics = createSelector(
	selectSurveysForAnalytics,
	selectReadingsStore,
	(surveys, readingsStore) => {
		if (!surveys || surveys.length === 0) {
			return {};
		}

		const surveyIds = surveys.map(s => s.id);
		const readingsKeys = Object.keys(readingsStore);
		const filteredReadingsStore = readingsKeys.reduce((acc, key) => {
			const hasMatch = surveyIds.indexOf(key) > -1;
			if (hasMatch) {
				return {
					...acc,
					[key]: readingsStore[key]
				};
			}

			return acc;
		}, {});

		return filteredReadingsStore;
	}
);

export const selectReadingsStationIdIntegers = (stateApp, surveyId) =>
	stateApp.readingsStationIdIntegers[surveyId];

const calcDelta = (v1, v2) => {
	const max = Math.max(v1, v2);
	const min = Math.min(v1, v2);
	return Math.abs(max - min);
};

const closest = (target, v1, v2) => {
	const deltaV1 = calcDelta(target, v1);
	const deltaV2 = calcDelta(target, v2);
	return deltaV1 <= deltaV2 ? v1 : v2;
};

const evalClosestInt = (map, intId, closestInt, xFloor) => {
	if (isDefined(map[intId])) {
		if (!isDefined(closestInt)) {
			return intId;
		}
		if (closest(xFloor, intId, closestInt) === intId) {
			return intId;
		}
	}
	return closestInt;
};

const getClosestIntForward = (start, end, map, xFloor) => {
	let closestInt;
	for (let intId = start; intId <= end; intId += 1) {
		closestInt = evalClosestInt(map, intId, closestInt, xFloor);
	}

	return closestInt;
};

const getClosestIntBackwards = (start, end, map, xFloor) => {
	let closestInt;
	for (let intId = end; intId >= start; intId -= 1) {
		closestInt = evalClosestInt(map, intId, closestInt, xFloor);
	}

	return closestInt;
};

const getClosestId = (map, closestInt, x) => {
	let closestId;
	const ids = map[closestInt];
	ids.forEach(id => {
		if (!isDefined(closestId)) {
			closestId = id;
		} else if (closest(x, id, closestId) === id) {
			closestId = id;
		}
	});
	return closestId;
};

const selectMap = (stateApp, surveyId) => {
	const survey = selectSurvey(stateApp, surveyId);

	let primarySurvey = survey;
	if (survey.isDerived) {
		primarySurvey = selectPrimarySurveyFromDerived(stateApp, survey);
	}

	return selectReadingsStationIdIntegers(stateApp, primarySurvey.id);
};

export const selectClosestReading = (stateApp, chartId, x, distance = 5) => {
	const surveyId = getSurveyIdFromChartId(chartId);
	if (!surveyId) {
		return undefined;
	}
	const map = selectMap(stateApp, surveyId);
	const xFloor = Math.floor(x);
	const start = xFloor - distance;
	const end = xFloor + distance;

	const closestInt = getClosestIntForward(start, end, map, xFloor);

	if (!isDefined(closestInt)) {
		return undefined;
	}

	const closestId = getClosestId(map, closestInt, x);

	if (!isDefined(closestId)) {
		return undefined;
	}

	const readingsMap = selectReadingsMap(stateApp, {
		id: surveyId
	});
	const reading = readingsMap[closestId];

	return reading;
};

export const selectClosestReadings = (
	stateApp,
	chartId,
	xMin,
	xMax,
	distance = 5
) => {
	const surveyId = getSurveyIdFromChartId(chartId);
	if (!surveyId) {
		return undefined;
	}
	const map = selectMap(stateApp, surveyId);
	const xMinFloor = Math.floor(xMin);
	const xMaxFloor = Math.floor(xMax);
	const start = xMinFloor - distance;
	const end = xMaxFloor + distance;

	const closestMinInt = getClosestIntForward(start, end, map, xMinFloor);
	const closestMaxInt = getClosestIntBackwards(start, end, map, xMaxFloor);

	if (!isDefined(closestMinInt) || !isDefined(closestMaxInt)) {
		return undefined;
	}

	const closestMinId = getClosestId(map, closestMinInt, xMin);
	const closestMaxId = getClosestId(map, closestMaxInt, xMax);

	if (!isDefined(closestMinId) || !isDefined(closestMaxId)) {
		return undefined;
	}

	const readingsMap = selectReadingsMap(stateApp, {
		id: surveyId
	});
	const minReading = readingsMap[closestMinId];
	const maxReading = readingsMap[closestMaxId];

	return { minReading, maxReading };
};
