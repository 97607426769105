/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from './constants';

const { setSearchText, setCompanyName } = createActions(
	{
		SET_SEARCH_TEXT: searchText => ({ searchText }),
		SET_COMPANY_NAME: companyName => ({
			companyName
		})
	},
	{ prefix: ROOT_SLICE }
);

export { setSearchText, setCompanyName };
