/* eslint-disable no-unused-vars */
import { setEmphasisLine } from '../../../components/ChartGroupComponent/components/Chart/util/chart.getterssetters';
import { defaultComputeProps } from '../util/readings.parse.computeProps';

const EMPTY_ARRAY = [];
// @see AC.js for additional example
const tooltipRender = (event, chart) => {
	// do nothing for now
};

const createTooltipRender = (chart, externalFn) => (...args) => {
	externalFn(...args);
	const event = args[0];
	setTimeout(() => {
		tooltipRender(event, chart);
	}, 1);
};

// eslint-disable-next-line no-unused-vars
export default _survey => {
	return {
		computeProps: defaultComputeProps,
		computePropsForDerivedSurvey: () => {},
		computeYMinYMax: () => {},
		isSkipReading: () => false,
		hasGap: () => false,
		hasMasterSurvey: () => false,
		filterReadingsOfUnsupportedSurveyType: readings => readings,
		tooltip: {
			enable: true,
			shared: true,
			displayMode: 'Always',
			format: '${series.name}: ${point.y}' // eslint-disable-line no-template-curly-in-string
		},
		createTooltipRender,
		computeFilteredDataSets: (s, r) => undefined, // see ILI for example implementation
		shouldCheckMVOnLoad: false,
		shouldStandardizeToMV: () => false,
		standardizeToMV: () => {},
		selectReadingKeysByBcPriority: () => EMPTY_ARRAY
	};
};
