export const selectedReadingRowHighlightColor = '#d7a2e0';

const DEFAULT_COLORS = {
	PREPROCESSED: '#c8c8c866', // .4 Alpha = 66
	SELECTED: selectedReadingRowHighlightColor,
	MINOR_ON: '#d62728',
	MINOR_OFF: '#ff7f0e',
	NEW_ON: '#2ca02c',
	NEW_OFF: '#1f77b4',
	HIGHLIGHTED: '#800080'
};

// traces like "nextOn", etc
const minorTracesOptions = {
	dash: 'dot'
};

const highlightOptions = {
	size: 15
};

const selectedRowOptions = {
	size: 10
};

export const DEFAULT_TRACE_INFO_BY_NAME = {
	preprocessedOnTrace: {
		color: DEFAULT_COLORS.PREPROCESSED,
		display: 'Preprocessed On',
		options: { width: 1 }
	},
	preprocessedOffTrace: {
		color: DEFAULT_COLORS.PREPROCESSED,
		display: 'Preprocessed Off',
		options: { width: 1 }
	},
	oldOnTrace: { color: DEFAULT_COLORS.MINOR_ON, display: 'Original On' },
	oldOffTrace: { color: DEFAULT_COLORS.MINOR_OFF, display: 'Original Off' },
	newOnTrace: { color: DEFAULT_COLORS.NEW_ON, display: 'Current On' },
	newOffTrace: { color: DEFAULT_COLORS.NEW_OFF, display: 'Current Off' },
	nextOnTrace: {
		color: DEFAULT_COLORS.MINOR_ON,
		display: 'Next On',
		options: minorTracesOptions
	},
	nextOffTrace: {
		color: DEFAULT_COLORS.MINOR_OFF,
		display: 'Next Off',
		options: minorTracesOptions
	},
	previousOnTrace: {
		color: DEFAULT_COLORS.MINOR_ON,
		display: 'Previous On',
		options: minorTracesOptions
	},
	previousOffTrace: {
		color: DEFAULT_COLORS.MINOR_OFF,
		display: 'Previous Off',
		options: minorTracesOptions
	},
	readingRowSelectedOnTraces: {
		color: DEFAULT_COLORS.SELECTED,
		display: 'Selected On',
		options: selectedRowOptions
	},
	readingRowSelectedOffTraces: {
		color: DEFAULT_COLORS.SELECTED,
		display: 'Selected Off',
		options: selectedRowOptions
	},
	highlightedOnTrace: {
		color: DEFAULT_COLORS.HIGHLIGHTED,
		display: 'Highlighted On',
		options: highlightOptions
	},
	highlightedOffTrace: {
		color: DEFAULT_COLORS.HIGHLIGHTED,
		display: 'Highlighted Off',
		options: highlightOptions
	}
	// These traces already have options in other places
	// oldOnCommentTrace,
	// minCriteriaLine: minCriteriaLineTrace,
	// maxCriteriaLine: maxCriteriaLineTrace,
};

export const showCommentsOptions = [
	{
		value: 'always',
		label: 'Always'
	},
	{
		value: 'optimum-zoom',
		label: 'Optimum Zoom'
	},
	{
		value: 'never',
		label: 'Never'
	}
];

export const showOriginalLineOptions = [
	{
		value: 'always',
		label: 'Always'
	},
	{
		value: 'optimum-zoom',
		label: 'Optimum Zoom'
	}
];

export const OPTIMUM_ZOOM_IN_FEET = 1000;

export const TRACES_TO_ALWAYS_SHOW = [
	'newOnTrace',
	'newOffTrace',
	'nextOnTrace',
	'nextOffTrace',
	'previousOnTrace',
	'previousOffTrace',
	'minCriteriaLine',
	'maxCriteriaLine'
];

export const TRACES_TO_SHOW_IN_OPTIMUM_ZOOM = [
	'preprocessedOnTrace',
	'preprocessedOffTrace',
	'oldOnTrace',
	'oldOffTrace',
	'readingRowSelectedOnTraces',
	'readingRowSelectedOffTraces',
	'highlightedOnTrace',
	'highlightedOffTrace'
];

export const COMMENTS_TRACES = ['oldOnCommentTrace'];

export const DEFAULT_MIN_Y_RANGE_MV = -2100;
export const DEFAULT_MIN_Y_RANGE_VOLTS = -2.1;

export const LINE_STYLE_OPTIONS = [
	{
		value: 'dash',
		label: 'Dash'
	},
	{
		value: 'dot',
		label: 'Dot'
	},
	{
		value: 'longdash',
		label: 'Long Dash'
	},
	{
		value: 'dashdot',
		label: 'Dash Dot'
	},
	{
		value: 'longdashdot',
		label: 'Long Dash Dot'
	},
	{
		value: 'solid',
		label: 'Solid'
	}
];

export const getOnName = surveyType => {
	switch (surveyType.toLowerCase()) {
		case 'depol':
			return 'Depol';
		case 'native':
			return 'Native';
		case 'on':
		case 'on-off':
			return 'On';
		// TOOD: What do we do with TS-Data, DCVG, ACCA, etc?
		default:
			return '';
	}
};
export const defaultState = {
	showSettingModal: false,
	selectedOriginalLineConfig: showOriginalLineOptions[1].value,
	selectedCommentConfig: showCommentsOptions[1].value,
	oldData: null,
	newData: null,
	useMv: false,
	defaultMv: false,
	previousChartData: null,
	nextChartData: null,
	layout: null,
	currentYRange: null,
	manualYRange: null,
	displayMinColorPicker: false,
	displayMaxColorPicker: false,
	minCritValue: -850,
	maxCritValue: -1200,
	feetPerPage: OPTIMUM_ZOOM_IN_FEET,
	gridAxesSetting: {},
	paginationStatus: null,
	selectActions: null,
	highlightedPoint: null,
	previousDatFile: {},
	nextDatFile: {},
	selectedDatFile: null,
	selectedDatFileIndex: null,
	traceOptionsByName: DEFAULT_TRACE_INFO_BY_NAME,
	readingRowsSelectedByDatFile: {
		/* will look like:
		 * [0]: [1, 2, 5, etc]
		 */
	},
	zoomPointFromReadings: {
		/* will look like:
		 * stationId: 123,
		 * datFileName: "bp080",
		 * feet
		 */
	},
	annotations: null,
	allAnnotations: null,
	allTraces: null,
	traces: null,
	navigationChangesByDatFile: {
		/* will look like:
		 * [tiny-dat]: [{layout}]
		 */
	}
};

export const DEFAULT_SELECT_ACTIONS = [
	{
		name: 'Interpolate',
		value: 'interpolate',
		checked: true,
		tooltip:
			'Interpolate the selected readings between the upstream and downstream values'
	},
	{
		name: 'Remove Autocorrection',
		value: 'remove-autocorrection',
		checked: false,
		disabled: true,
		tooltip:
			'Remove auto corrections from selected readings (this is disabled until auto correction is in process)'
	}
];

export const DEFAULT_MINIMUM_CRITERIA_LINE_TRACE = {
	x: [],
	y: [],
	type: 'scatter',
	mode: 'lines',
	name: 'Min criteria',
	line: {
		dash: 'dash',
		width: 10,
		color: 'lightgray'
	}
};

export const DEFAULT_MAXIMUM_CRITERIA_LINE_TRACE = {
	x: [],
	y: [],
	type: 'scatter',
	mode: 'lines',
	name: 'Max criteria',
	line: {
		dash: 'dash',
		width: 1,
		color: 'rgb(240,128,128)'
	}
};

export const FILE_TYPE = {
	ON: 0,
	ON_OFF: 1,
	ON_OFF_GPS_SYNC: 2,
	OTHER: 3
};
export const EDIT_TYPE = {
	ON: 0,
	OFF: 1
};
