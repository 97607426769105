import { NAME_SPACE as ns, ROOT_STATE_KEY as sk } from './constants';

export const SHOW_EXPORT_PANEL = `${ns}_${sk}_SHOW_EXPORT_PANEL`;

export const HIDE_EXPORT_PANEL = `${ns}_${sk}_HIDE_EXPORT_PANEL`;

export const SET_PROFILE_NAME = `${ns}_${sk}_SET_PROFILE_NAME`;

export const SET_SELECTED = `${ns}_${sk}_SET_SELECTED`;

export const SET_PROFILE = `${ns}_${sk}_SET_PROFILE`;
