/* eslint-disable no-continue */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */

import {
	config,
	calculateDirection
} from 'aegion_common_utilities/lib/MapProcessingUtil';

// TODO TURF: All turf references should either access a specific turf package or should be pulled from /commons
import * as turf from '@turf/turf';
import memoizeOne from 'memoize-one';
import _ from 'lodash';

import { createGetSegmentsWithExceptions } from './mapUtils.getSegmentsWithExceptions';

import { isTrueReading } from './utils/reading';
import colors from './utils/Colors';
import surveyMeta from './utils/SurveyMeta';

import alignReadings from './mapUtils.alignReadings.depol';

import calculateSimpleDistance from './utils/calculateSimpleDistance';

import {
	computeReadingDistance,
	computeSpatialDistance
} from '../commons/util/mapUtils/mapUtils.computeReadingDistance';

/**
 * ScanLine Utils for Map Component
 */

const scanlineMapUtils = {
	spatialChartYValues: {
		LINE_MARKERS: -1,
		FOREIGN_LINE_CROSSING: 10,
		RECTIFIER: 20,
		AERIAL: 30,
		VALVES: 40,
		TEST_STATIONS: 50
	},
	calculateSimpleDistance,
	computeSpatialDistance(reading1, reading2, options = {}) {
		return computeSpatialDistance(reading1, reading2, {
			algorithm: config.GEO_ALGORITHMS.TURF,
			...options
		});
	},
	computeReadingDistance(start, end, count, options = {}) {
		return computeReadingDistance(start, end, count, {
			algorithm: config.GEO_ALGORITHMS.TURF,
			...options
		});
	},

	shouldCreateDerivedSurveys(survey) {
		let result = scanlineMapUtils.isCis(survey.id);
		result =
			result ||
			(!survey.isMergedWithCIS && scanlineMapUtils.isDepol(survey.id));
		result =
			result ||
			(!survey.isMergedWithCIS && scanlineMapUtils.isNative(survey.id));

		return result;
	},

	isNative(id) {
		return id && id.indexOf('CIS-NATIVE') > -1;
	},
	isDepol(id) {
		// a DEPOL survey will have an id like CIS-DEPOL-...
		return id && id.indexOf('CIS-DEPOL') > -1;
	},
	isCis(id) {
		return id && id.indexOf('ON') > -1 && id.indexOf('CIS') > -1;
	},

	getFirstColor: () => colors.getFirstColor(),

	getColor: () => colors.getColor(),

	resetColorIndex: () => colors.resetColorIndex(),

	reversePolygonCoords: data => {
		const polygons = [];

		data.forEach(coords => {
			const polygon = [];
			coords.forEach(innerCoords => {
				polygon.push(innerCoords.map(coord => [coord[1], coord[0]]));
			});
			polygons.push(polygon);
		});

		return polygons;
	},

	createPolygonGraphicsOpts: data => {
		return data.map(rings => ({
			geometry: {
				type: 'polygon',
				rings
			},
			symbol: {
				type: 'simple-fill',
				color: [51, 136, 255, 0.2],
				outline: {
					color: [51, 136, 255],
					width: 4
				}
			}
		}));
	},

	mapReadingsToArcGisCoords: data => {
		const isArrayOfArrays =
			data && data instanceof Array && data[0] && data[0] instanceof Array;
		const isPolylineOfLocations =
			isArrayOfArrays && data[0][0] && data[0][0] instanceof Array;
		const isPolylineOfReadings =
			isArrayOfArrays &&
			(!!data[0][0].coordinates || !!data[0][0].loc || !!data[0][0].Loc);

		const flipXY = locations =>
			locations.map(location => {
				const coord =
					location.coordinates || location.loc || location.Loc || location;
				return [coord[1], coord[0]];
			});

		if (isPolylineOfReadings || isPolylineOfLocations) {
			return data.map(line => flipXY(line));
		}
		return flipXY(data);
	},

	simplifyCoords(coords, _options = {}, preventSimplify = false) {
		let result = coords;

		try {
			if (coords.length > 100 && !preventSimplify) {
				const geoJson = turf.lineString(coords);
				const options = { tolerance: 0.0001, highQuality: false, ..._options };
				const simplifiedGeoJson = turf.simplify(geoJson, options);
				result = simplifiedGeoJson.geometry.coordinates;
			}
		} catch (e) {
			// ignore
		}

		return result;
	},

	createPointGraphicOpts: (longitude, latitude) => {
		return {
			geometry: {
				type: 'point',
				longitude,
				latitude
			},
			symbol: {
				type: 'simple-marker',
				color: [255, 255, 255],
				size: 10,
				outline: {
					color: [255, 255, 255]
				}
			}
		};
	},

	createPolylineGraphicOpts: data => {
		return {
			geometry: {
				type: 'polyline',
				paths: data
			},
			symbol: {
				type: 'simple-line',
				color: [255, 0, 0],
				width: 4
			}
		};
	},

	createHighlightGraphicsOpts: data => {
		return {
			outer: {
				geometry: {
					type: 'polyline',
					paths: data
				},
				symbol: {
					type: 'simple-line',
					color: [255, 120, 0],
					cap: 'square',
					width: 50
				}
			},
			inner: {
				geometry: {
					type: 'polyline',
					paths: data
				},
				symbol: {
					type: 'simple-line',
					color: [255, 0, 0],
					width: 8
				}
			}
		};
	},

	// We compute the rotation angle by the following steps:
	// 1. Create a lineString from the data, then simplify it
	// 2. Get the edge having the maximum length
	// 3. Compute the azimuth from the bearing of the edge
	// 4. Consider station ids to be in ascending order
	computeRotationAngle: _data => {
		const isPolyline =
			_data &&
			_data instanceof Array &&
			_data[0] &&
			_data[0] instanceof Array &&
			_data[0][0] &&
			_data[0][0] instanceof Array;
		const data = isPolyline ? _.flatten(_data) : _data;

		// step 1
		let geojson;
		try {
			geojson = turf.lineString(data);
		} catch (err) {
			console.error(err);
			return undefined;
		}
		// const geojson = turf.lineString(data);
		const simplified = turf.simplify(geojson, { tolerance: 0.0005 });

		const simpleData = simplified.geometry.coordinates;
		if (simpleData.length < 2) {
			return 0;
		}

		// step 2
		const maxEdge = {
			distance: 0,
			p1: simpleData[0],
			p2: simpleData[1]
		};

		for (let i = 0, n = simpleData.length - 1; i < n; i += 1) {
			const distance = calculateSimpleDistance(
				simpleData[i],
				simpleData[i + 1]
			);

			if (distance > maxEdge.distance) {
				maxEdge.distance = distance;
				maxEdge.p1 = simpleData[i];
				maxEdge.p2 = simpleData[i + 1];
			}
		}

		const azimuth = calculateDirection(maxEdge.p1, maxEdge.p2, {
			useNegatives: false,
			reverse: false
		});
		let rotation = 0;
		if (azimuth >= 0 && azimuth <= 180) {
			rotation = 90 - azimuth;
		} else {
			rotation = 270 - azimuth;
		}

		// step 4
		if (azimuth > 180 && azimuth < 360) {
			rotation += 180;
		}

		return rotation;
	},

	zoomToGraphic(view, graphic, extentExpand) {
		const newExtent = scanlineMapUtils.getGraphicExtent(graphic, extentExpand);

		if (newExtent) {
			view.extent = newExtent;
			view.updateFromCode = true;
		}
	},

	getGraphicExtent(graphic, extentExpand = 1.5) {
		const { extent } = graphic.geometry;
		if (extent) {
			return extent.clone().expand(extentExpand);
		}

		return undefined;
	},

	createSurveyId(surveyType, surveySubtype, jobId) {
		const type = surveyType.replace(/ /g, '_');
		const subtype = surveySubtype.replace(/ /g, '_');
		if (jobId) {
			return `${type}-${subtype}-${jobId}`;
		}
		return `${type}-${subtype}`;
	},

	deriveSurveyId(survey, surveySubtype) {
		if (!survey) {
			return undefined;
		}
		const { survey_type, job_id } = survey;
		return scanlineMapUtils.createSurveyId(survey_type, surveySubtype, job_id);
	},

	// defines the reading keys and primary reading key
	// for the corresponding surveys
	getSurveyMeta(survey) {
		return surveyMeta.getSurveyMeta(survey);
	},

	getStartEndIndex(readings = [], minimum, maximum) {
		const nearestMinimum = { index: 0, diff: Infinity };
		const nearestMaximum = { index: 0, diff: Infinity };

		for (let i = 0, n = readings.length; i < n; i += 1) {
			const stationId = readings[i].stationId || readings[i].id;
			if (stationId >= minimum) {
				const minDiff = Math.abs(stationId - minimum);
				if (minDiff < nearestMinimum.diff) {
					nearestMinimum.index = i;
					nearestMinimum.diff = minDiff;
				}
			}
			if (stationId <= maximum) {
				const maxDiff = Math.abs(stationId - maximum);
				if (maxDiff <= nearestMaximum.diff) {
					nearestMaximum.index = i;
					nearestMaximum.diff = maxDiff;
				}
			}
		}
		const { index: startIndex } = nearestMinimum;
		const { index: endIndex } = nearestMaximum;

		return { startIndex, endIndex };
	},

	filterReadingsByMinMax(readings = [], minimum, maximum) {
		const indices = scanlineMapUtils.getStartEndIndex(
			readings,
			minimum,
			maximum
		);
		const { startIndex, endIndex } = indices;
		let filteredReadings = readings;

		if (startIndex >= 0 && endIndex >= 0) {
			filteredReadings = readings.slice(startIndex, endIndex + 1);
		}

		return filteredReadings;
	},

	findPrevReadingFromValue(readings = [], value) {
		let index = -1;
		let diff = Infinity;

		for (let i = 0, n = readings.length; i < n; i += 1) {
			if (isTrueReading(readings[i])) {
				const stationId = readings[i].id;
				if (stationId < value) {
					const newDiff = Math.abs(stationId - value);
					if (newDiff < diff) {
						index = i;
						diff = newDiff;
					}
				}
			}
		}

		return index;
	},

	findNextReadingFromValue(readings = [], value) {
		let index = readings.length;
		let diff = Infinity;

		for (let i = 0, n = readings.length; i < n; i += 1) {
			if (isTrueReading(readings[i])) {
				const stationId = readings[i].id;
				if (stationId > value) {
					const newDiff = Math.abs(stationId - value);
					if (newDiff < diff) {
						index = i;
						diff = newDiff;
					}
				}
			}
		}

		return index;
	},

	alignReadings,
	// This is an alias that is only here so that we don't have to change the `appScanline` file - once redux is settled, remove this, and update reference(s)
	normalizeReadings: alignReadings,

	nearestPoint(targetPoint, points) {
		let nearest = { index: 0, point: points[0] };
		let bestDistance = Infinity;

		for (let index = 0, n = points.length; index < n; index += 1) {
			const point = points[index];
			const distance = calculateSimpleDistance(point, targetPoint);
			if (distance < bestDistance) {
				bestDistance = distance;
				nearest = { index, point };
			}
		}
		return nearest;
	},

	matchRectifierFromComment(comment) {
		const match = /RECTIFIER/gi.exec(comment);
		return match && match.length && match[0];
	},

	matchAerialFromComment(comment) {
		const match = /AERIAL/gi.exec(comment);
		return match && match.length && match[0];
	},

	matchValveFromComment(comment) {
		const match = /VALVE/gi.exec(comment);
		return match && match.length && match[0];
	},

	matchTestStationFromComment(comment) {
		const match = /TEST/gi.exec(comment);
		return match && match.length && match[0];
	},

	matchLineMarkerFromComment(comment) {
		const match = /LINE MARKER/gi.exec(comment);
		return match && match.length && match[0];
	},

	matchForeignLineFromComment(comment) {
		const otherRegexComments = [
			/POWER LINE/gi,
			/RIVER/gi,
			/LINE CROSSING?/gi // Foreign Pipeline Crossing
		];
		const match = otherRegexComments.some(re => re.exec(comment));
		return match;
	},

	// TODO: extract other comments
	extractSpatialFromComments(readings) {
		const spatialReadings = [];
		for (let i = 0, n = readings.length; i < n; i += 1) {
			const reading = readings[i];
			const { cmt } = reading;

			if (!cmt) {
				continue;
			}

			const spatialItems = [];

			// Rectifier
			const rectifier = this.matchRectifierFromComment(cmt);
			spatialItems.push(rectifier);
			if (rectifier) {
				reading.rectifier = scanlineMapUtils.spatialChartYValues.RECTIFIER;
			}

			// Aerial
			const aerial = this.matchAerialFromComment(cmt);
			spatialItems.push(aerial);
			if (aerial) {
				reading.aerial = scanlineMapUtils.spatialChartYValues.AERIAL;
			}

			// Valve
			const valve = this.matchValveFromComment(cmt);
			spatialItems.push(valve);
			if (valve) {
				reading.valve = scanlineMapUtils.spatialChartYValues.VALVES;
			}

			// Test Station
			const testStation = this.matchTestStationFromComment(cmt);
			spatialItems.push(testStation);
			if (testStation) {
				reading.testStation =
					scanlineMapUtils.spatialChartYValues.TEST_STATIONS;
			}

			// Line Marker
			const lineMarker = this.matchLineMarkerFromComment(cmt);
			spatialItems.push(lineMarker);
			if (lineMarker) {
				reading.lineMarker = scanlineMapUtils.spatialChartYValues.LINE_MARKERS;
			}

			// Other Spatial Data
			const foreignLineCrossing = this.matchForeignLineFromComment(cmt);
			spatialItems.push(foreignLineCrossing);
			if (foreignLineCrossing) {
				reading.foreignLineCrossing =
					scanlineMapUtils.spatialChartYValues.FOREIGN_LINE_CROSSING;
			}

			// if any of them have a value
			if (spatialItems.some(Boolean)) {
				spatialReadings.push(reading);
			}
		}

		return spatialReadings;
	},

	getSegmentsWithExceptions(...args) {
		const getExceptions = createGetSegmentsWithExceptions(
			scanlineMapUtils.computeReadingDistance
		);
		return getExceptions(...args);
	},

	getExceptionsInfo: memoizeOne(
		(
			cisReadings = [],
			depolReadings = [],
			criterionInfo = null,
			bcSeries,
			bcReadingById,
			computedBcMap,
			customExceptionsMap
		) => {
			const criterion = criterionInfo || {};
			const series = criterion.series || 'OFF';
			const key = criterion.key || 'off';
			const type = criterion.type || 'threshold';
			const threshold = criterion.threshold || -850;
			const shadowMap = depolReadings.reduce((map, reading) => {
				map[reading.id] = reading.sh;
				return map;
			}, {});

			const exceptions = scanlineMapUtils.getSegmentsWithExceptions(
				cisReadings,
				key,
				type === 'threshold' ? threshold : null,
				bcSeries,
				bcReadingById,
				shadowMap,
				computedBcMap,
				customExceptionsMap
			);

			const info = {};
			info.criterion = type === 'threshold' ? `${threshold}IRF` : '100mV';
			info.series = series;
			info.list = exceptions;

			info.totalDistance = exceptions.reduce((sum, e) => sum + e.length, 0);
			const notes = `${info.criterion} "${series}" | Total footage: ${info.totalDistance} feet`;

			if (type === 'threshold') {
				info.belowThreshold = notes;
			} else {
				info.belowShadowLine = notes;
			}

			return info;
		}
	),

	formatDateMMDDYYYY(date) {
		const MM = `0${date.getMonth() + 1}`.slice(-2);
		const DD = `0${date.getDate()}`.slice(-2);
		const YYYY = date.getFullYear();
		return `${MM}/${DD}/${YYYY}`;
	},

	createSurveyTemplate(type, subtype, options = {}) {
		const surveyId = scanlineMapUtils.createSurveyId(
			type,
			subtype,
			options.jobId
		);
		const meta = scanlineMapUtils.getSurveyMeta({ survey_subtype: subtype });
		const isCIS = scanlineMapUtils.isCis(surveyId);

		const survey = {
			survey_type: type,
			survey_subtype: subtype,
			id: surveyId,
			checked: false,
			withThreshold: isCIS,
			withDepol: false,
			color: meta.readingKeys[0].color,
			...options,
			...meta
		};
		return survey;
	}
};

export default scanlineMapUtils;
