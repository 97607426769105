/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { store } from '../../scanline/store';
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../scanline/utils/token';
import { selectRootSliceAdmin } from '../redux/Main/selectors';
import { selectCompanyName } from '../redux/slices/UsersSearch/selectors';
import { goodEnoughUUID } from '../../scanline/utils/uuid';

let tokenGetUser;
export const getUsers = (params, callback) => {
	const stateAdmin = selectRootSliceAdmin(store.getState());
	const customer = selectCompanyName(stateAdmin);
	tokenGetUser = goodEnoughUUID();
	const url = `/users?customer=${customer}&withPermissions=true&requestToken=${encodeURIComponent(
		tokenGetUser
	)}`;

	if (hasAccessToken()) {
		const fetchUsers = createPromiseRequest({
			url,
			method: 'GET'
		});

		fetchUsers()
			.then(res => {
				const { requestToken: responseToken, users } = res;
				if (tokenGetUser === responseToken) {
					callback(null, users);
				}
			})
			.catch(err => {
				callback(err);
			});
	}
};

const toCustomerGroupName = customer =>
	`Customer:${customer.replace(/\s+/g, '_')}`;

let tokenSendInvite;
export const sendInvite = ({ email } = {}, callback = () => {}) => {
	if (tokenSendInvite) {
		callback(new Error('Invite request already in progress'));
		return undefined;
	}

	const stateAdmin = selectRootSliceAdmin(store.getState());
	const customer = selectCompanyName(stateAdmin);
	tokenSendInvite = goodEnoughUUID();
	const url = '/invite';
	const payload = {
		userName: email,
		groupName: toCustomerGroupName(customer)
	};

	const postUserInvite = createPromiseRequest({
		url,
		method: 'POST',
		data: payload,
		aip: 'customerusers'
	});

	postUserInvite()
		.then(res => {
			tokenSendInvite = undefined;
			callback(null, res);
		})
		.catch(err => {
			tokenSendInvite = undefined;
			callback(err);
		});
};

let tokenUpdateUser;
export const updateUser = ({ user } = {}, callback = () => {}) => {
	if (tokenUpdateUser) {
		callback(new Error('Invite request already in progress'));
		return undefined;
	}

	if (!user || !user.username) {
		callback(
			new Error('Invalid user object passed to api/users .updateUser()')
		);
		return undefined;
	}

	const postUserInvite = createPromiseRequest({
		url: '/user/update',
		method: 'PUT',
		data: user
	});

	postUserInvite()
		.then(updatedUser => {
			tokenUpdateUser = undefined;
			callback(null, updatedUser);
		})
		.catch(err => {
			tokenUpdateUser = undefined;
			callback(err);
		});
};
