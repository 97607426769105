export const setSelectedDataForStationAlignment = (surveyType, tableType) => ({
	type: 'CISV_STATION_ALIGNMENT_SET_DAT_FILE',
	surveyType,
	tableType
});

export const selectReadingForStationAlignment = (
	tableType,
	readingIndex,
	readingObject,
	convertedReadings
) => ({
	type: 'CISV_STATION_ALIGNMENT_SET_READING_SELECTED',
	tableType,
	readingIndex,
	readingObject,
	convertedReadings
});

export const resetSurveyAlignment = data => {
	return {
		type: 'CISV_STATION_ALIGNMENT_RESET',
		data
	};
};

export const setEditableSurveyType = surveyType => {
	return {
		type: 'CISV_STATION_ALIGNMENT_SET_EDITABLE_SURVEY_TYPE',
		surveyType
	};
};
