export const initialState = {
	users: [],
	usersTotalCount: 0,
	isLoadingUsers: false,
	isLoadedUsers: false,
	hasLoadingErrorUsers: false,
	selectedUser: undefined,
	selectedUserChangesClone: undefined,
	isSavingUser: false,
	newUser: undefined,
	isSavingNewUser: undefined,
	errorMessageSaveNewUser: undefined,
	isSavingEditedUser: undefined,
	errorMessageSaveEditedUser: undefined
};

export default initialState;
