import createBaseSurveyMeta from './_BASE';
import colors from '../../Colors';

// eslint-disable-next-line no-unused-vars
export default _survey => {
	// READING KEYS

	const readingKeys = [
		{
			label: 'Line Marker',
			value: 'lineMarker',
			seriesType: 'Scatter',
			marker: 'circle',
			isPercentage: true,
			color: colors.getColor(),
			showInTooltip: true
		},
		{
			label: 'Foreign Line Crossing',
			value: 'foreignLineCrossing',
			seriesType: 'Scatter',
			marker: 'rectRot',
			isPercentage: true,
			color: colors.getColor(),
			showInTooltip: true
		},
		{
			label: 'Rectifier',
			value: 'rectifier',
			seriesType: 'Scatter',
			marker: 'triangle',
			isPercentage: true,
			color: colors.getColor(),
			showInTooltip: true
		},
		{
			label: 'Aerial Marker',
			value: 'aerial',
			seriesType: 'Scatter',
			marker: 'circle',
			isPercentage: true,
			color: colors.getColor(),
			showInTooltip: true
		},
		{
			label: 'Valve',
			value: 'valve',
			seriesType: 'Scatter',
			marker: 'rect',
			isPercentage: true,
			color: colors.getColor(),
			showInTooltip: true
		},
		{
			label: 'Test Station',
			value: 'testStation',
			seriesType: 'Scatter',
			marker: 'star',
			isPercentage: true,
			color: colors.getColor(),
			showInTooltip: true
		}
	];
	const readingKeyProps = readingKeys.map(k => k.value);

	// INTERPOLATE

	const interpolate = (
		// eslint-disable-next-line no-unused-vars
		_prevDepolReading,
		// eslint-disable-next-line no-unused-vars
		_nextDepolReading,
		// eslint-disable-next-line no-unused-vars
		_alignToReading
	) => {};

	// SIMPLIFIED READINGS - creates a smaller data set to be used for rendering polyline on map
	// most survey types will not have simplified readings
	// eslint-disable-next-line no-unused-vars
	const createSimplifiedReadings = ({ readings, subtype }) => undefined;

	// MAIN

	const meta = {
		...createBaseSurveyMeta(_survey),
		isChartInversed: false,
		readingKeys,
		readingKeyProps,
		fkey: 'id',
		interpolate,
		createSimplifiedReadings
	};

	return meta;
};
