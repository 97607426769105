import createBaseSurveyMeta from './_BASE';
import colors from '../../Colors';
import { createThreshold } from '../util/general';
import { createComputeYMinYMax } from '../util/yMinYMax';
import { defaultComputeProps } from '../util/readings.parse.computeProps';

const INVERT_Y_AXIS = false;

const createThresholdsBySubtype = subtype => {
	const thresholds = [];

	switch (subtype) {
		case 'ACCD':
			thresholds.push(createThreshold(30, 30.01, '#24b378'));
			thresholds.push(createThreshold(100, 100.01, '#ff512f'));
			break;
		case 'ACPS':
			thresholds.push(createThreshold(15, 15.01, '#ff512f'));
			break;
		case 'DCCD':
			thresholds.push(createThreshold(1, 1.01, '#ff512f'));
			break;
		default:
		// do nothing
	}
	return thresholds;
};

export default _survey => {
	// CONSTANTS

	const gapThreshold = 200;

	// READING KEYS

	const readingKeys = [
		{
			label: 'ON',
			value: 'on',
			seriesType: 'Scatter',
			isPercentage: true,
			color: colors.getColor()
		}
	];
	const readingKeyProps = readingKeys.map(k => k.value);
	const computeYMinYMax = createComputeYMinYMax(readingKeys);

	// SKIP READING

	const isSkipReading = reading =>
		reading && readingKeys.every(rk => reading[rk.value] === 0);

	// HAS GAP

	const hasGap = (currReading, prevReading) => {
		return !!prevReading && currReading.id - prevReading.id > gapThreshold;
	};

	// INTERPOLATE

	const interpolate = (
		// eslint-disable-next-line no-unused-vars
		_prevDepolReading,
		// eslint-disable-next-line no-unused-vars
		_nextDepolReading,
		// eslint-disable-next-line no-unused-vars
		_alignToReading
	) => {};

	// SIMPLIFIED READINGS - creates a smaller data set to be used for rendering polyline on map
	// most survey types will not have simplified readings
	// eslint-disable-next-line no-unused-vars
	const createSimplifiedReadings = ({ readings, subtype }) => undefined;

	// MAIN

	const meta = {
		...createBaseSurveyMeta(_survey),
		isChartInversed: INVERT_Y_AXIS,
		readingKeys,
		readingKeyProps,
		fkey: 'on',
		columns: [
			{ label: 'ID', value: 'id', checked: true, width: 64, required: true },
			...readingKeys.map(key => ({
				label: key.label,
				value: key.value,
				checked: true,
				width: 44
			})),
			{ label: 'DISTANCE', value: 'dst', checked: true, width: 80 },
			{ label: 'LAT', value: 'lat', checked: true, width: 65 },
			{ label: 'LNG', value: 'lng', checked: true, width: 70 },
			{
				label: 'COMMENTS',
				value: 'cmt',
				checked: true,
				width: 85,
				type: 'text'
			}
		],
		thresholds: createThresholdsBySubtype(_survey.survey_subtype),
		isSkipReading,
		hasGap,
		interpolate,
		createSimplifiedReadings,
		computeProps: (survey, prevReading, currReading, currIdx) => {
			defaultComputeProps(survey, prevReading, currReading, currIdx);
			computeYMinYMax(survey, currReading);
		},
		computeYMinYMax
	};

	return meta;
};
