/* eslint import/no-cycle:0 */

// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax
import AssetsWorker from 'workerize-loader?inline!./../webworkers/assets.worker';
import Filters, {
	refine,
	filter as filterReq,
	counts as countsReq
} from '../filters';
import { NAME_SPACE, APPROVAL_MODE } from '../constants';
import {
	isUserInGroup,
	getCustomer as getTokenCustomer
} from '../../util/user';
import { setShowMap } from './manager';
import ExportWebSocket from '../websockets/ExportWebSocket';
import AccountUtil from '../../account/components/utils/AccountUtil';
import {
	FILE_EXPORT_TYPE,
	getSettingsName,
	getStructureTypes
} from '../components/Downloads/utils/fileExport';
import {
	setIsHideColumns,
	setUserColumnSettings
} from '../redux/Columns/actions';
import { setUserAppSettings } from '../redux/EditTable/actions';
import {
	getUserHiddenColumns,
	getColumnProfiles
} from '../redux/Columns/utils';
import { convertHiddenColumnsToSettings } from '../components/Manager/utils/columns';
import { setProfiles } from '../redux/Columns/Profiles/actions';
import { getSource } from '../../util/item';
import { APP_VIEW } from '../components/utils/AppView';

const { Credentials: AWSCredentials, S3 } = require('aws-sdk');

const assetsWorker = new AssetsWorker();

const getCustomer = selected => {
	const customer = getTokenCustomer();
	if (customer === 'Aegion') {
		if (selected.Customers && selected.Customers.length > 0) {
			return selected.Customers[0];
		}
	}
	return customer;
};

const injectCriteria = (item, assetViewMain) => {
	const { testStationCriteria } = assetViewMain;
	const { warning } = testStationCriteria;
	return {
		vals: { limit: warning },
		active: item.map(({ item: i }) => i)
	};
};

const updateTestPointCriteria = (assetViewMain, warning) => {
	const { selected } = assetViewMain;
	return {
		...selected,
		Status: { active: [], ...selected.Status, vals: { limit: warning } }
	};
};

function getUpdateFilters(updateFilters, newAppView, oldAppView) {
	if (!updateFilters) {
		return false;
	}

	if (newAppView === APP_VIEW.manager && oldAppView !== APP_VIEW.manager) {
		return true;
	}
	if (newAppView !== APP_VIEW.manager && oldAppView === APP_VIEW.manager) {
		return true;
	}

	return false;
}

function getActiveState(state) {
	const {
		assetViewMain: {
			selected,
			filters,
			isApprovalMode: approval,
			listSort,
			listSortProp,
			list,
			managerSettings
		},
		assetview: {
			search: { searches },
			map: { extent: mapExtent },
			manager: { isExtentFilter }
		}
	} = state;
	return {
		selected,
		searches,
		approval,
		listSort,
		listSortProp,
		mapExtent,
		isExtentFilter,
		list,
		managerSettings,
		filters
	};
}

export const S3Object = access => {
	const obj = {
		init(cred) {
			if (!cred) {
				return this;
			}
			const { Credentials = {} } = cred;
			const {
				AccessKeyId = '',
				SecretAccessKey = '',
				SessionToken = ''
			} = Credentials;
			const credentials = new AWSCredentials({
				accessKeyId: AccessKeyId,
				secretAccessKey: SecretAccessKey,
				sessionToken: SessionToken
			});
			credentials.expireTime = new Date(Credentials.Expiration);
			// AWS.config.update({region: 'us-west-2', credentials: credentials});
			this.s3 = new S3({ region: 'us-west-2', credentials });
			return this;
		},
		get(key, bucket = 'tiles.aip.aegion.com') {
			return new Promise((resolve, reject) => {
				if (!key) {
					resolve(null);
				} else {
					const params = {
						Bucket: bucket,
						Key: key
					};
					this.s3.getObject(params, (err, res) => {
						if (err) {
							reject(err);
						} else {
							resolve(res.Body);
						}
					});
				}
			});
		},
		headObject(key, bucket = 'tiles.aip.aegion.com') {
			return new Promise((resolve, reject) => {
				if (!key) {
					resolve(null);
				} else {
					const params = {
						Bucket: bucket,
						Key: key
					};
					this.s3.headObject(params, (err, res) => {
						if (err) {
							reject(err);
						} else {
							resolve(res.Body);
						}
					});
				}
			});
		},
		list(bucket = 'aegionassetviewjobs') {
			return new Promise((resolve, reject) => {
				const params = {
					Bucket: bucket
				};
				this.s3.listObjects(params, (err, res) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.Contents);
					}
				});
			});
		},
		getUrl(key, bucket = 'tiles.aip.aegion.com') {
			return new Promise((resolve, reject) => {
				const params = {
					Bucket: bucket,
					Key: key
				};
				this.s3.getSignedUrl('getObject', params, (err, url) => {
					if (err) {
						reject(err);
					} else {
						resolve(url);
					}
				});
			});
		},
		getUrlSync(key, bucket = 'tiles.aip.aegion.com') {
			const params = {
				Bucket: bucket,
				Key: key
			};
			return this.s3.getSignedUrl('getObject', params);
		}
	};
	obj.init(access);
	return obj;
};

const convertToStructure = ({ name, corrName, columns }) => {
	return {
		name,
		corrName,
		columns: columns
			.flat()
			.filter(({ visible }) => visible)
			.map(column => {
				const { name: cName = column.corTitle, corTitle, xlsTitle } = column;
				const col = { name: cName };
				if (corTitle) {
					col.corTitle = corTitle;
				}
				if (xlsTitle) {
					col.xlsTitle = xlsTitle;
				}
				return col;
			})
	};
};

class AssetViewActions {
	constructor() {
		this.filters = new Filters();
	}

	RESET_STATE = `${NAME_SPACE} RESET_STATE`;

	resetState = () => ({
		type: this.RESET_STATE
	});

	SET_VIEWPORT = `${NAME_SPACE} SET_VIEWPORT`;

	setViewport = payload => ({
		type: this.SET_VIEWPORT,
		payload
	});

	SET_SELECTION = `${NAME_SPACE} SET_SELECTION`;

	setSelection = payload => ({
		type: this.SET_SELECTION,
		payload
	});

	WINDOW_RESIZE = `${NAME_SPACE} WINDOW_RESIZE`;

	windowResize = () => {
		return {
			type: this.WINDOW_RESIZE,
			height: window.innerHeight
		};
	};

	FILTERS_UPDATED = `${NAME_SPACE} FILTERS_UPDATED`;

	filtersUpdated = (filters, clearAll, keepOpened = false) => (
		dispatch,
		getState
	) => {
		const {
			assetViewMain: { filters: oldFilters },
			assetview: {
				filters: { opened }
			}
		} = getState();
		const newFilters = oldFilters.reduce((acc, { original, ...oldFilter }) => {
			const [filter = {}] = filters.filter(
				({ category }) => category === oldFilter.category
			);

			const loadAll = oldFilter.loadAll && !clearAll;
			if (keepOpened && filter.category === opened) {
				acc.push({ ...filter, ...oldFilter, loadAll });
			} else {
				acc.push({ ...oldFilter, ...filter, loadAll });
			}
			return acc;
		}, []);
		dispatch({
			type: this.FILTERS_UPDATED,
			filters: newFilters
		});
	};

	SET_LIST_FROM = `${NAME_SPACE} SET_LIST_FROM`;

	setListFrom = from => {
		return { type: this.SET_LIST_FROM, payload: from };
	};

	LIST_UPDATED = `${NAME_SPACE} LIST_UPDATED`;

	listUpdated = (list, total) => (dispatch, getState) => {
		const {
			assetViewMain: {
				isApprovalMode,
				testStationCriteria,
				multiwireTestStationCriteria
			}
		} = getState();
		const criterias = { multiwireTestStationCriteria, testStationCriteria };
		assetsWorker
			.enhanceAssetsList(list, isApprovalMode, criterias)
			.then(res => {
				dispatch({
					type: this.LIST_UPDATED,
					list: res,
					total
				});
			});
	};

	FILTERS_UPDATING = `${NAME_SPACE} FILTERS_UPDATING`;

	filtersUpdating = () => {
		return {
			type: this.FILTERS_UPDATING
		};
	};

	FILTERS_REFINED = `${NAME_SPACE} FILTERS_REFINED`;

	filtersRefined = (json, loadAll) => (dispatch, getState) => {
		const {
			assetViewMain: { filters }
		} = getState();
		const categoryObj = filters.filter(i => i.category === json.category)[0];

		const newFilters = [...filters];
		newFilters.splice(newFilters.indexOf(categoryObj), 1, { ...json, loadAll });

		dispatch({
			type: this.FILTERS_REFINED,
			filters: newFilters
		});
	};

	SHOW_IMAGE = `${NAME_SPACE} SHOW_IMAGE`;

	showImg = url => {
		return {
			type: this.SHOW_IMAGE,
			url
		};
	};

	SHOW_ASSETS = `${NAME_SPACE} SHOW_ASSETS`;

	showAssets = (key, id, res) => {
		return {
			type: this.SHOW_ASSETS,
			key,
			items: res,
			id
		};
	};

	SHOW_ASSETS_TAB = `${NAME_SPACE} SHOW_ASSETS_TAB`;

	showAssetsTab = () => {
		return {
			type: this.SHOW_ASSETS_TAB
		};
	};

	SHOW_DOWNLOADS_TAB = `${NAME_SPACE} SHOW_DOWNLOADS_TAB`;

	showDownloadsTab = () => {
		return {
			type: this.SHOW_DOWNLOADS_TAB
		};
	};

	SET_ASSETS_REFRESH = `${NAME_SPACE} SET_ASSETS_REFRESH`;

	setAsset = (key, id) => dispatch => {
		dispatch({
			type: this.SET_ASSETS_REFRESH
		});
		this.filters.item(key, {}).then(res => {
			dispatch(this.showAssets(key, id, res));
		});
	};

	clearModal = () => {
		return () => {};
	};

	MAP_ITEM = `${NAME_SPACE} MAP_ITEM`;

	mapItem = (item, zoomTo = true) => {
		return {
			type: this.MAP_ITEM,
			item,
			zoomTo
		};
	};

	GOT_MAP_ITEM = `${NAME_SPACE} GOT_MAP_ITEM`;

	gotMapItem = () => {
		return {
			type: this.GOT_MAP_ITEM
		};
	};

	HIDE_MODAL = `${NAME_SPACE} HIDE_MODAL`;

	hideModal = () => {
		return {
			type: this.HIDE_MODAL
		};
	};

	SET_IS_MANAGER = `${NAME_SPACE} SET_IS_MANAGER`;

	setIsManager = isManager => {
		return {
			type: this.SET_IS_MANAGER,
			payload: isManager
		};
	};

	SET_ACCESS = `${NAME_SPACE} SET_ACCESS`;

	setAccess = access => {
		return {
			type: this.SET_ACCESS,
			s3Object: new S3Object(access)
		};
	};

	UPDATE_PAGE_ITEM = `${NAME_SPACE} UPDATE_PAGE_ITEM`;

	updatePageItem = pageItem => {
		return {
			type: this.UPDATE_PAGE_ITEM,
			payload: pageItem
		};
	};

	SET_APP_VIEW = `${NAME_SPACE} SET_APP_VIEW`;

	setAppView = (appView, updateFilter = true) => (dispatch, getState) => {
		dispatch(setShowMap(false));
		const {
			assetViewMain: { appView: lastAppView }
		} = getState();
		dispatch({
			type: this.SET_APP_VIEW,
			payload: appView
		});
		if (getUpdateFilters(updateFilter, appView, lastAppView)) {
			dispatch(this.getFilters({}));
			dispatch(this.getList());
		}
	};

	SET_DOWNLOAD_ACCESS = `${NAME_SPACE} SET_DOWNLOAD_ACCESS`;

	setDownloadAccess = access => {
		return {
			type: this.SET_DOWNLOAD_ACCESS,
			s3Object: new S3Object(access)
		};
	};

	SET_DOWNLOADS = `${NAME_SPACE} SET_DOWNLOADS`;

	setDownloads = data => {
		return {
			type: this.SET_DOWNLOADS,
			data
		};
	};

	SELECT_ASSET = `${NAME_SPACE} SELECT_ASSET`;

	selectAsset = (id, from) => {
		return {
			type: this.SELECT_ASSET,
			id,
			from
		};
	};

	SET_MANAGER_COUNTS = `${NAME_SPACE} SET_MANAGER_COUNTS`;

	setManagerCounts = counts => {
		return {
			type: this.SET_MANAGER_COUNTS,
			payload: counts
		};
	};

	refineFilters = ({ wildcard = '', category, loadAll }) => {
		return (dispatch, getState) => {
			const { selected, searches, approval } = getActiveState(getState());
			refine({
				wildcard,
				category,
				selected,
				loadAll,
				approval,
				searches
			}).then(res => {
				dispatch(this.filtersRefined(res, loadAll));
			});
			return dispatch(this.filtersUpdating());
		};
	};

	filterSectionChanged = (category, item) => (dispatch, getState) => {
		const {
			assetViewMain: { selected: oldSelected = {} }
		} = getState();
		const { [category]: oldCategories = [] } = oldSelected;
		const categories = Array.isArray(oldCategories)
			? [...oldCategories]
			: { ...oldCategories, active: [...oldCategories.active] };
		const list = Array.isArray(categories) ? categories : categories.active;

		if (list.indexOf(item) !== -1) {
			list.splice(list.indexOf(item), 1);
		} else {
			list.push(item);
		}
		const selected = { ...oldSelected, [category]: categories };
		dispatch(this.setSelection(selected));
		dispatch(this.getFilters({ keepOpened: true }));
		dispatch(this.getList());
	};

	bulkFilterSectionChanged = (changes = []) => (dispatch, getState) => {
		const {
			assetViewMain: { selected: oldSelected = {} }
		} = getState();
		let selected = { ...oldSelected };
		changes.forEach(({ category, item, clearAll, replaceAll }) => {
			const { [category]: oldCategories = [] } = selected;
			const categories = Array.isArray(oldCategories)
				? [...oldCategories]
				: { ...oldCategories, active: [...oldCategories.active] };
			const list = Array.isArray(categories) ? categories : categories.active;
			if (replaceAll) {
				list.length = 0;
				list.push(item);
			} else if (clearAll) {
				list.length = 0;
			} else if (list.indexOf(item) !== -1) {
				list.splice(list.indexOf(item), 1);
			} else {
				list.push(item);
			}

			selected = { ...selected, [category]: categories };
		});
		const keys = Object.keys(selected);
		keys.forEach(k => {
			if (selected[k] instanceof Array && selected[k].length === 0) {
				delete selected[k];
			}
		});
		dispatch(this.setSelection(selected));
		dispatch(this.getFilters({}));
		dispatch(this.getList());
	};

	selectionChanged = (category, item) => (dispatch, getState) => {
		const {
			assetViewMain,
			assetViewMain: { selected: oldSelected }
		} = getState();
		const selected = { ...oldSelected };
		if (category === 'Status') {
			selected[category] = injectCriteria(item, assetViewMain);
		} else {
			selected[category] = item.map(i => i.item);
		}
		dispatch(this.getFilters({ keepOpened: true }));
		dispatch(this.setSelection(selected));
	};

	clearSelection = oldSelected => dispatch => {
		const selected = { ...oldSelected };
		Object.keys(selected).forEach(attr => {
			if (Array.isArray(selected[attr])) {
				selected[attr] = [];
				return;
			}
			selected[attr].active = [];
		});
		dispatch(this.setApprovalMode(APPROVAL_MODE.approved));

		dispatch(this.setSelection(selected));
		dispatch(this.getFilters({ clearAll: true }));
		dispatch(this.getList());
	};

	getAccess = () => {
		return dispatch => {
			dispatch(this.setIsManager(isUserInGroup('AssetViewManager')));
			this.filters.access().then((res = {}) => {
				dispatch(this.setAccess(res));
			});
		};
	};

	getDownloadAccess = () => {
		return dispatch => {
			this.filters.downloadaccess().then(res => {
				dispatch(this.setDownloadAccess(res));
			});
			return Promise.resolve();
		};
	};

	loadAllFilter = category => dispatch => {
		dispatch(
			this.refineFilters({
				category,
				loadAll: true
			})
		);
	};

	getMenus = () => dispatch => {
		this.filters.menus().then(res => {
			dispatch(this.filtersUpdated(res));
			dispatch({
				type: this.FILTERS_UPDATED,
				filters: res
			});
		});
	};

	getFilters = ({ clearAll = false, category, keepOpened = false }) => {
		return (dispatch, getState) => {
			const {
				assetViewMain: { filters, appView }
			} = getState();
			const { selected, searches, approval } = getActiveState(getState());

			const loadAllFilters = filters
				.filter(({ loadAll = false }) => !clearAll && loadAll)
				.map(({ category: name }) => name);
			filterReq({
				selected,
				searches,
				loadAllFilters,
				approval,
				appView,
				category
			}).then(res => {
				dispatch(this.filtersUpdated(res, clearAll, keepOpened));
			});
			return dispatch(this.filtersUpdating());
		};
	};

	getMenuFilters = category => dispatch => {
		dispatch(this.getFilters({ clearAll: false, category }));
	};

	list = () => (dispatch, getState) => {
		const {
			assetViewMain: { listFrom }
		} = getState();
		dispatch(this.getList({ from: listFrom }));
	};

	getList = ({ from = 0 } = {}) => (dispatch, getState) => {
		const {
			selected,
			searches,
			approval,
			listSort,
			listSortProp
		} = getActiveState(getState());

		this.filters
			.list({
				selected,
				from,
				listSort,
				listSortProp,
				approval,
				searches
			})
			.then(({ hits, total }) => {
				dispatch(this.listUpdated(hits, total));
			});
	};

	reloadAssetList = () => dispatch => {
		dispatch(this.getList());
	};

	getCounts = () => (dispatch, getState) => {
		const {
			selected,
			searches,
			approval,
			mapExtent,
			isExtentFilter
		} = getActiveState(getState());
		const extent = mapExtent.toBBoxString();
		countsReq({
			selected,
			searches,
			extent: isExtentFilter ? JSON.parse(`[${extent}]`) : undefined,
			approval
		}).then(res => {
			dispatch(this.setManagerCounts(res));
		});
	};

	addList = (from, size) => {
		return (dispatch, getState) => {
			const {
				selected,
				searches,
				approval,
				listSort,
				list,
				listSortProp
			} = getActiveState(getState());
			this.filters
				.list({
					selected,
					from,
					size,
					listSort,
					listSortProp,
					approval,
					searches
				})
				.then(({ hits, total }) => {
					dispatch(this.setListFrom(from));
					dispatch(this.listUpdated([...list, ...hits], total));
				});
			return dispatch(this.filtersUpdating());
		};
	};

	LIST_ORDER_FLIP = 'LIST_ORDER_FLIP';

	listOrderFlip = ({ listSort, listSortProp }) => (dispatch, getState) => {
		const { selected, searches, approval } = getActiveState(getState());
		this.filters
			.list({ selected, from: 0, approval, searches, listSort, listSortProp })

			.then(({ hits, total }) => {
				dispatch(this.listUpdated(hits, total));
			});
		return dispatch(this.setListOrder(listSort, listSortProp));
	};

	SET_LIST_ORDER = 'SET_LIST_ORDER';

	setListOrder = (listSort, listSortProp) => {
		return {
			type: this.SET_LIST_ORDER,
			listSort,
			listSortProp
		};
	};

	SEARCH_CHANGED = 'SEARCH_CHANGED';

	searchChanged = text => {
		return {
			type: this.SEARCH_CHANGED,
			value: text
		};
	};

	SEARCH_UPDATED = 'SEARCH_UPDATED';

	searchUpdated = res => {
		return {
			type: this.SEARCH_UPDATED,
			values: res
		};
	};

	getAssetCardAsset = (key, id, assetType, assetPanel) => (
		dispatch,
		getState
	) => {
		const {
			assetViewMain: {
				isApprovalMode,
				testStationCriteria,
				multiwireTestStationCriteria
			}
		} = getState();
		const criterias = { multiwireTestStationCriteria, testStationCriteria };
		this.filters
			.getAssetItem(id, assetType)
			.then(res =>
				assetsWorker.processAsset({ _source: res }, isApprovalMode, criterias)
			)
			.then(res => {
				const source = getSource(res, isApprovalMode);
				if (source.item) {
					dispatch(this.setAssetPanel(assetPanel));
					dispatch(
						this.setAssetCardAsset(
							key,
							id,
							source.photos,
							assetType,
							source.item,
							res,
							source.labels
						)
					);
				}
			});
	};

	SET_ASSETCARD_ASSET = 'SET_ASSETCARD_ASSET';

	setAssetCardAsset = (key, id, photos, assetType, item, zoomItem, labels) => {
		if (item) {
			return {
				type: this.SET_ASSETCARD_ASSET,
				values: {
					cardKey: key,
					id,
					photos,
					assetType,
					item,
					labels
				},
				zoomItem
			};
		}
		throw new Error('Should get here');
	};

	CLEAR_CARD = 'CLEAR_CARD';

	clearCard = () => {
		return {
			type: this.CLEAR_CARD
		};
	};

	search = text => {
		return dispatch => {
			this.filters.search(text).then(res => {
				dispatch(this.searchUpdated(res));
			});
			return dispatch(this.searchChanged(text));
		};
	};

	getDownloads = () => (dispatch, getState) => {
		const { selected } = getActiveState(getState());
		const customer = getCustomer(selected);
		this.filters.downloads(customer).then(data => {
			dispatch(this.setDownloads(data));
		});
		return Promise.resolve();
	};

	getIsHideColumns = (exportType, state) => {
		const {
			assetview: {
				managerSettings: {
					active: {
						present: { isHideColumns: isHideColumnsMan = true }
					}
				},
				xlsExportColumns: {
					active: {
						present: { isHideColumns: isHideColumnsXLS = true }
					}
				},
				corrExportColumns: {
					active: {
						present: { isHideColumns: isHideColumnsCor = true }
					}
				}
			}
		} = state;
		if (exportType === FILE_EXPORT_TYPE.EXCEL) {
			return isHideColumnsXLS;
		}
		if (exportType === FILE_EXPORT_TYPE.MANAGER) {
			return isHideColumnsMan || [];
		}
		return isHideColumnsCor;
	};

	saveExportColumns = (exportType, profileName) => (dispatch, getState) => {
		const state = getState();
		const newProfiles = { ...getColumnProfiles(exportType, state) };

		if (profileName !== undefined) {
			const userHiddenColumns = getUserHiddenColumns(exportType, state);
			newProfiles[profileName] = userHiddenColumns;
		}

		const settingsName = getSettingsName(exportType);
		// const isHideColumns = this.getIsHideColumns(exportType, getState());
		AccountUtil.updateSettings({
			[settingsName]: { profiles: newProfiles }
		});
		dispatch(setProfiles(newProfiles, exportType));
	};

	exportdata = ({
		history = false,
		images = false,
		approval = APPROVAL_MODE.approved,
		useExtent = false,
		isManager = false
	}) => (dispatch, getState) => {
		dispatch(this.exporting());

		const {
			selected,
			filters,
			searches,
			mapExtent,
			managerSettings
		} = getActiveState(getState());

		const userHiddenColumns = getUserHiddenColumns(
			isManager ? FILE_EXPORT_TYPE.MANAGER : FILE_EXPORT_TYPE.EXCEL,
			getState()
		);

		const { items: structureTypes } = filters.find(
			({ category }) => category === 'Structure'
		);

		const filteredManagerSettings = getStructureTypes(
			managerSettings,
			structureTypes,
			selected.Structure
		);

		const exportColumns = convertHiddenColumnsToSettings(
			filteredManagerSettings,
			userHiddenColumns,
			structureTypes
		);

		const isHideColumns = this.getIsHideColumns(
			isManager ? FILE_EXPORT_TYPE.MANAGER : FILE_EXPORT_TYPE.EXCEL,
			getState()
		);

		const extent = useExtent
			? JSON.parse(`[${mapExtent.toBBoxString()}]`)
			: undefined;

		ExportWebSocket.startDownload(
			{
				selected,
				exportColumns: exportColumns.filter(
					({ isHidden = false }) => !isHidden
				),
				history,
				images,
				approval,
				extent,
				hideColumns: isHideColumns,
				searches
			},
			{
				handleResponse: (err, res) => {
					dispatch(this.exported());
					if (res) {
						const { url, finished } = res;
						if (url) {
							window.open(url, '_blank');
						}
						if (finished) {
							dispatch(this.zippedImages());
						}
					}
				},
				onMessage: res => {
					if (res.message) {
						dispatch(this.addExportMessage(res.message));
					}
				}
			}
		);
	};

	cloudSave = (exportType = FILE_EXPORT_TYPE.EXCEL) => (dispatch, getState) => {
		dispatch(this.cloudSaving());
		const { selected, searches, managerSettings, filters } = getActiveState(
			getState()
		);
		const userHiddenColumns = getUserHiddenColumns(exportType, getState());

		const { items: structureTypes } = filters.find(
			({ category }) => category === 'Structure'
		);

		const filteredManagerSettings = getStructureTypes(
			managerSettings,
			structureTypes,
			selected.Structure
		);

		const exportColumns = convertHiddenColumnsToSettings(
			filteredManagerSettings,
			userHiddenColumns,
			structureTypes
		);

		const isHideColumns = this.getIsHideColumns(exportType, getState());

		this.filters
			.saveCloud(
				selected,
				searches,
				exportColumns.filter(({ isHidden = false }) => !isHidden),
				isHideColumns
			)
			.then(res => {
				dispatch(this.cloudSaved(res));
			})
			.catch(() => {
				dispatch(this.cloudSaved());
			});
	};

	SET_ZIP_TEMPLATES = `${NAME_SPACE} SET_ZIP_TEMPLATES`;

	setZipTemplates = zipTemplates => {
		return {
			type: this.SET_ZIP_TEMPLATES,
			payload: zipTemplates
		};
	};

	SET_ZIP_TEMPLATE = `${NAME_SPACE} SET_ZIP_TEMPLATE`;

	setZipTemplate = zipTemplate => {
		return {
			type: this.SET_ZIP_TEMPLATE,
			payload: zipTemplate
		};
	};

	getZipImagesTemplates = () => (dispatch, getState) => {
		const {
			assetViewMain: { zipTemplates = [] }
		} = getState();
		if (zipTemplates.length === 0) {
			this.filters.zipImagesTemplates().then(res => {
				dispatch(this.setZipTemplates(res));
				const [zipTemplate] = res;
				dispatch(this.setZipTemplate(zipTemplate));
			});
		}
	};

	zipImages = () => (dispatch, getState) => {
		dispatch(this.zippingImages());
		const { assetViewMain } = getState();
		const { zipTemplate } = assetViewMain;
		const { selected, searches } = getActiveState(getState());
		ExportWebSocket.startDownload(
			{ selected, searches, template: zipTemplate.name },
			(err, res) => {
				if (res) {
					const { url, finished } = res;
					if (url) {
						window.open(url, '_blank');
					}
					if (finished) {
						dispatch(this.zippedImages());
					}
				}
			}
		);
	};

	saveCorrelator = () => (dispatch, getState) => {
		dispatch(this.savingCorrelator());
		const {
			selected,
			searches,
			managerSettings,
			approval,
			filters
		} = getActiveState(getState());
		const userHiddenColumns = getUserHiddenColumns(
			FILE_EXPORT_TYPE.CORRELATOR,
			getState()
		);

		const { items: structureTypes } = filters.find(
			({ category }) => category === 'Structure'
		);

		const filteredManagerSettings = getStructureTypes(
			managerSettings,
			structureTypes,
			selected.Structure
		);

		const exportColumns = convertHiddenColumnsToSettings(
			filteredManagerSettings,
			userHiddenColumns,
			structureTypes
		);

		const isHideColumns = this.getIsHideColumns(
			FILE_EXPORT_TYPE.CORRELATOR,
			getState()
		);

		this.filters
			.saveCorrelator(
				selected,
				searches,
				exportColumns.filter(({ isHidden = false }) => !isHidden),
				isHideColumns,
				approval
			)
			.then(res => {
				const { url } = res;
				if (url) {
					window.open(url, '_blank');
				}
				dispatch(this.savedCorrelator(res));
			})
			.catch(() => {
				dispatch(this.savedCorrelator());
			});
	};

	EXPORTING = `${NAME_SPACE} EXPORTING`;

	exporting = () => ({
		type: this.EXPORTING
	});

	ADD_EXPORT_MESSAGE = `${NAME_SPACE} ADD_EXPORT_MESSAGE`;

	addExportMessage = message => ({
		type: this.ADD_EXPORT_MESSAGE,
		payload: message
	});

	EXPORTED = `${NAME_SPACE} EXPORTED`;

	exported = () => ({
		type: this.EXPORTED
	});

	CLOUD_SAVING = `${NAME_SPACE} CLOUD_SAVING`;

	cloudSaving = () => ({
		type: this.CLOUD_SAVING
	});

	CLOUD_SAVED = `${NAME_SPACE} CLOUD_SAVED`;

	cloudSaved = ({ url = '' }) => ({
		type: this.CLOUD_SAVED,
		values: url
	});

	ZIPPING_IMAGES = `${NAME_SPACE} ZIPPING_IMAGES`;

	zippingImages = () => ({
		type: this.ZIPPING_IMAGES
	});

	ZIPPED_IMAGES = `${NAME_SPACE} ZIPPED_IMAGES`;

	zippedImages = () => ({
		type: this.ZIPPED_IMAGES
	});

	SAVING_CORRELATOR = `${NAME_SPACE} SAVING_CORRELATOR`;

	savingCorrelator = () => ({
		type: this.SAVING_CORRELATOR
	});

	SAVED_CORRELATOR = `${NAME_SPACE} SAVED_CORRELATOR`;

	savedCorrelator = (res = {}) => ({
		type: this.SAVED_CORRELATOR,
		values: res.url
	});

	SET_CRITERIA = `${NAME_SPACE} SET_CRITERIA`;

	setCriteria = (criteria, criteriaName) => (dispatch, getState) => {
		const { assetViewMain } = getState();
		const selected = updateTestPointCriteria(assetViewMain, criteria.warning);
		dispatch(this.updateTestPointCriteria(criteria, selected, criteriaName));
		dispatch(this.getFilters({}));
	};

	updateTestPointCriteria = (criteria, selected, criteriaName) => {
		return {
			type: this.SET_CRITERIA,
			payload: { criteria, selected, criteriaName }
		};
	};

	SET_EXPORT_SETTINGS = `${NAME_SPACE} SET_EXPORT_SETTINGS`;

	setExportSettings = (exportColumns, exportType) => {
		return {
			type: this.SET_EXPORT_SETTINGS,
			payload: { exportColumns, exportType }
		};
	};

	SET_MANAGER_SETTINGS = `${NAME_SPACE} SET_MANAGER_SETTINGS`;

	getSettings = (exportType = FILE_EXPORT_TYPE.EXCEL) => dispatch => {
		dispatch(this.getMenuFilters('Structure'));

		const settingsName = getSettingsName(exportType);
		Promise.all([
			AccountUtil.settings(settingsName),
			this.filters.managerSettings(),
			AccountUtil.settings('assetView')
		]).then(res => {
			const [columnSettings, settings, userAppSettings] = res;
			dispatch(setUserAppSettings(userAppSettings));
			dispatch(setUserColumnSettings(columnSettings, settings, exportType));
			const exportColumns = settings
				.filter(({ columns }) => {
					return columns.filter(({ visible }) => visible).length !== 0;
				})
				.map(convertToStructure)
				.map(struct => {
					const newStruct = { ...struct };
					const {
						deltas = Array.isArray(columnSettings) ? columnSettings : []
					} = columnSettings;
					const [delta] = deltas.filter(({ name }) => name === struct.name);
					if (delta) {
						if (delta.isHidden) {
							newStruct.isHidden = delta.isHidden;
						}
						if (delta.columns) {
							const cols = struct.columns.filter(
								({ name }) => delta.columns.indexOf(name) === -1
							);
							newStruct.columns = cols;
						}
					}
					return newStruct;
				});
			dispatch({
				type: this.SET_MANAGER_SETTINGS,
				payload: { settings }
			});

			const { isHideColumns = true } = columnSettings;

			dispatch(this.setExportSettings(exportColumns, exportType));
			// dispatch(setColumns(exportColumns, exportType));
			dispatch(setIsHideColumns(isHideColumns, exportType));
		});
	};

	SET_DETAILS_MODE = `${NAME_SPACE} SET_DETAILS_MODE`;

	setDetailsMode = mode => {
		return {
			type: this.SET_DETAILS_MODE,
			payload: mode
		};
	};

	SET_ASSET_PANEL = `${NAME_SPACE} SET_ASSET_PANEL`;

	setAssetPanel = panel => {
		return {
			type: this.SET_ASSET_PANEL,
			payload: panel
		};
	};

	SET_GROUP_CLASS_CODE = `${NAME_SPACE} SET_GROUP_CLASS_CODE`;

	setGroupClassCode = classCode => {
		return {
			type: this.SET_GROUP_CLASS_CODE,
			payload: classCode
		};
	};

	SET_FILTERS_ORDER = `${NAME_SPACE} SET_FILTERS_ORDER`;

	setFilterOrder = (category, order) => (dispatch, getState) => {
		const {
			assetViewMain: { filtersOrder = {} }
		} = getState();
		dispatch({
			type: this.SET_FILTERS_ORDER,
			payload: {
				...filtersOrder,
				[category]: order
			}
		});
	};

	SET_XLS_EXPORT_COLUMNS = `${NAME_SPACE} SET_XLS_EXPORT_COLUMNS`;

	SET_CORR_EXPORT_COLUMNS = `${NAME_SPACE} SET_CORR_EXPORT_COLUMNS`;

	SET_APPROVAL_MODE = `${NAME_SPACE} SET_APPROVAL_MODE`;

	setApprovalMode = approvalMode => (dispatch, getState) => {
		dispatch({
			type: this.LIST_UPDATED,
			list: [],
			total: 0
		});
		if (approvalMode !== undefined) {
			dispatch({
				type: this.SET_APPROVAL_MODE,
				payload: approvalMode
			});
			return;
		}

		const {
			assetViewMain: { isApprovalMode }
		} = getState();

		if (isApprovalMode === APPROVAL_MODE.approved) {
			dispatch({
				type: this.SET_APPROVAL_MODE,
				payload: APPROVAL_MODE.unapproved
			});
			return;
		}
		dispatch({
			type: this.SET_APPROVAL_MODE,
			payload: APPROVAL_MODE.approved
		});
	};

	TOGGLE_LIST_PHOTO_CHECKER = `${NAME_SPACE} TOGGLE_LIST_PHOTO_CHECKER`;

	toggleListPhotoChecker = () => (dispatch, getState) => {
		const {
			assetViewMain: { listPhotoCheckerEnabled }
		} = getState();

		dispatch({
			type: this.TOGGLE_LIST_PHOTO_CHECKER,
			payload: !listPhotoCheckerEnabled
		});
	};

	updateApprovalMode = () => dispatch => {
		dispatch(this.setApprovalMode());
		dispatch(this.getFilters({}));
		dispatch(this.getList());
	};
}

export default new AssetViewActions();
