/* eslint-disable no-param-reassign */
import colors from '../../Colors';
import createBaseSurveyMeta from './_BASE';
import { createThreshold } from '../util/general';
import {
	computeUuid,
	computeFormattedStationId,
	computeMvTransform,
	assumeIsInMV
} from '../util/readings.parse.computeProps';
import { createComputeYMinYMax } from '../util/yMinYMax';

const INVERT_Y_AXIS = true;

// eslint-disable-next-line no-unused-vars
export default _survey => {
	// CONSTANTS

	const gapThreshold = 200;
	const voltPercentThreashold = 5;

	// READING KEYS

	const readingKeys = [
		{
			label: 'ON',
			value: 'on',
			seriesType: 'Scatter',
			isPercentage: true,
			color: colors.getColor()
		},
		{
			label: 'OFF',
			value: 'off',
			seriesType: 'Scatter',
			isPercentage: true,
			color: colors.getColor()
		}
	];
	const readingKeyProps = readingKeys.map(k => k.value);
	const computeYMinYMax = createComputeYMinYMax(readingKeys);

	// SKIP READING

	const isSkipReading = reading =>
		reading && readingKeys.every(rk => reading[rk.value] === 0);

	// HAS GAP

	const hasGap = (currReading, prevReading) => {
		return !!prevReading && currReading.id - prevReading.id > gapThreshold;
	};

	// INTERPOLATE

	const interpolate = (
		// eslint-disable-next-line no-unused-vars
		_prevDepolReading,
		// eslint-disable-next-line no-unused-vars
		_nextDepolReading,
		// eslint-disable-next-line no-unused-vars
		_alignToReading
	) => {};

	const shouldStandardizeToMV = (cntReadings, cntVoltReadings) => {
		const voltPercent = (cntReadings / cntVoltReadings) * 100;
		return voltPercent >= voltPercentThreashold;
	};

	const standardizeToMV = reading => {
		computeMvTransform(reading, 'on');
		computeMvTransform(reading, 'off');
	};

	// @note - @tobedeleted once mvfuzzy logic is removed;
	const computeAggregates = (currReading, aggregates = {}) => {
		aggregates.cntVoltReadings =
			(aggregates.cntVoltReadings || 0) +
			(assumeIsInMV(currReading.on) ? 0 : 1);
		aggregates.cntVoltReadings =
			(aggregates.cntVoltReadings || 0) +
			(assumeIsInMV(currReading.off) ? 0 : 1);

		return aggregates;
	};

	const computeProps = (survey, _prevReading, currReading, currIdx) => {
		computeUuid(currReading, currIdx);
		computeFormattedStationId(currReading);
		computeYMinYMax(survey, currReading);
	};

	// SIMPLIFIED READINGS - creates a smaller data set to be used for rendering polyline on map
	// most survey types will not have simplified readings
	// eslint-disable-next-line no-unused-vars
	const createSimplifiedReadings = ({ readings, subtype }) => undefined;

	// MAIN

	const meta = {
		...createBaseSurveyMeta(_survey),
		isChartInversed: INVERT_Y_AXIS,
		readingKeyProps,
		readingKeys,
		fkey: 'on',
		columns: [
			{ label: 'ID', value: 'id', checked: true, width: 64, required: true },
			...readingKeys.map(key => ({
				label: key.label,
				value: key.value,
				checked: true,
				width: 44
			})),
			{ label: 'DISTANCE', value: 'dst', checked: true, width: 80 },
			{ label: 'LAT', value: 'lat', checked: true, width: 65 },
			{ label: 'LNG', value: 'lng', checked: true, width: 70 },
			{
				label: 'COMMENTS',
				value: 'cmt',
				checked: true,
				width: 85,
				type: 'text'
			}
		],
		thresholds: [createThreshold(-0.8501, -0.85, '#ff512f')],
		isSkipReading,
		hasGap,
		interpolate,
		computeProps,
		computeYMinYMax,
		shouldCheckMVOnLoad: true,
		shouldStandardizeToMV,
		standardizeToMV,
		computeAggregates,
		createSimplifiedReadings
	};

	return meta;
};
