/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
	setCustomers,
	setCustomersApplications,
	setCustomersApplicationsChangesClone,
	setIsLoadingLoadedCustomers,
	setHasLoadingErrorCustomers,
	setSelectedCustomer,
	setSelectedCustomerChangesClone,
	setErrorMessageSaveEditedCustomer,
	setIsSavingEditedCustomer
} = createActions(
	{
		SET_CUSTOMERS: customers => ({ customers }),
		SET_CUSTOMERS_APPLICATIONS: customersApplications => ({
			customersApplications
		}),
		SET_CUSTOMERS_APPLICATIONS_CHANGES_CLONE: customersApplicationsChangesClone => ({
			customersApplicationsChangesClone
		}),
		SET_IS_LOADING_LOADED_CUSTOMERS: ({
			isLoadingCustomers,
			isLoadedCustomers
		}) => ({ isLoadingCustomers, isLoadedCustomers }),
		SET_HAS_LOADING_ERROR_CUSTOMERS: hasError => ({ hasError }),
		SET_SELECTED_CUSTOMER: selectedCustomer => ({ selectedCustomer }),
		SET_SELECTED_CUSTOMER_CHANGES_CLONE: selectedCustomerChangesClone => ({
			selectedCustomerChangesClone
		}),
		SET_ERROR_MESSAGE_SAVE_EDITED_CUSTOMER: errorMessageSaveEditedCustomer => ({
			errorMessageSaveEditedCustomer
		}),
		SET_IS_SAVING_EDITED_CUSTOMER: isSavingEditedCustomer => ({
			isSavingEditedCustomer
		})
	},
	{ prefix: ROOT_SLICE }
);

export {
	setCustomers,
	setIsLoadingLoadedCustomers,
	setHasLoadingErrorCustomers,
	setSelectedCustomer,
	setSelectedCustomerChangesClone,
	setErrorMessageSaveEditedCustomer,
	setIsSavingEditedCustomer,
	setCustomersApplications,
	setCustomersApplicationsChangesClone
};
