/* eslint-disable no-param-reassign */

const isDefined = v => v !== undefined && v !== null;

const isNum = val => {
	return isDefined(val) && !Number.isNaN(parseFloat(val, 10));
};

// ZOOM SETTINGS
const getChartZoomSettings = chart => {
	return (chart || {}).zoomSettings;
};

const setChartZoomSettings = (chart, settings) => {
	if (chart && chart.zoomSettings !== settings) {
		chart.zoomSettings = settings;
	}
};

// ENABLE MOUSE WHEEL ZOOMING
const getChartEnableMouseWheelZooming = chart => {
	const zoomSettings = getChartZoomSettings(chart) || {};
	return zoomSettings.enableMouseWheelZooming;
};

const setChartEnableMouseWheelZooming = (chart, val) => {
	const isEnabled = getChartEnableMouseWheelZooming(chart);
	if (chart && isEnabled !== val) {
		chart.zoomSettings.enableMouseWheelZooming = val;
	}
};

// ENABLE SELECTION ZOOMING
const getChartEnableSelectionZooming = chart => {
	const zoomSettings = getChartZoomSettings(chart) || {};
	return zoomSettings.enableSelectionZooming;
};

const setChartEnableSelectionZooming = (chart, val) => {
	const isEnabled = getChartEnableSelectionZooming(chart);
	if (chart && isEnabled !== val) {
		chart.zoomSettings.enableSelectionZooming = val;
	}
};

// ZOOM MODE
const getChartZoomMode = chart => {
	const zoomSettings = getChartZoomSettings(chart) || {};
	return zoomSettings.mode;
};

const setChartZoomMode = (chart, val) => {
	const zoomSettings = getChartZoomSettings(chart) || {};
	if (chart && zoomSettings.mode !== val) {
		chart.zoomSettings.mode = val;
	}
};

// SELECTION MODE
const getChartSelectionMode = chart => {
	return (chart || {}).selectionMode;
};

const setChartSelectionMode = (chart, val) => {
	if (chart && chart.selectionMode !== val) {
		chart.selectionMode = val;
	}
};

// XMIN XMAX
const getChartXMinXMax = (chart = {}) => {
	const { primaryXAxis = {} } = chart;
	const { minimum, maximum } = primaryXAxis;
	return { xMin: minimum, xMax: maximum };
};

const setChartXMinXMax = (chart = {}, { xMin, xMax }) => {
	const { primaryXAxis = {} } = chart;
	primaryXAxis.minimum = xMin;
	primaryXAxis.maximum = xMax;
};

// YMIN YMAX
const getChartYMinYMax = (chart = {}) => {
	const { primaryYAxis = {} } = chart;
	const { minimum, maximum } = primaryYAxis;
	return { yMin: minimum, yMax: maximum };
};

const setChartYMinYMax = (chart = {}, { yMin, yMax }) => {
	chart.primaryYAxis = chart.primaryYAxis || {};
	const { primaryYAxis = {} } = chart;
	primaryYAxis.minimum = isNum(yMin) ? yMin : null;
	primaryYAxis.maximum = isNum(yMax) ? yMax : null;
};
// ZOOM PROPS
const getChartZoomProps = (chart = {}) => {
	const { primaryXAxis = {} } = chart;
	const { zoomFactor, zoomPosition } = primaryXAxis;
	return { zoomFactor, zoomPosition };
};

const setChartZoomProps = (chart = {}, { zoomFactor, zoomPosition }) => {
	const { primaryXAxis = {} } = chart;
	primaryXAxis.zoomFactor = zoomFactor;
	primaryXAxis.zoomPosition = zoomPosition;
};

// RANGE
const getChartRange = (chart = {}) => {
	const { startValue, endValue } = chart;
	return { minimum: startValue, maximum: endValue };
};

const setChartRange = (chart = {}, { minimum, maximum }) => {
	chart.startValue = minimum;
	chart.endValue = maximum;
};

// SERIES LIST
const getChartSeriesList = (chart = {}) => {
	return chart.series;
};

const setChartSeriesList = (chart = {}, seriesList) => {
	chart.series = seriesList;
};

// THRESHOLD
const getChartThreshold = chart => {
	const hasThresholdLine = !!chart.primaryYAxis.stripLines[0];
	if (hasThresholdLine && chart.primaryYAxis.stripLines[0].visible) {
		return chart.primaryYAxis.stripLines[0].start + 0.1;
	}
	return undefined;
};
const setChartThreshold = (chart, x) => {
	if (chart && !!chart.primaryYAxis.stripLines[0]) {
		if (isNum(x)) {
			chart.primaryYAxis.stripLines[0].start = x - 0.1;
			chart.primaryYAxis.stripLines[0].end = x + 0.1;
			chart.primaryYAxis.stripLines[0].visible = true;
		} else {
			chart.primaryYAxis.stripLines[0].visible = false;
		}
	}
};

// EMPHASIS LINE
const setEmphasisLine = (chart, x) => {
	if (chart) {
		if (isNum(x)) {
			chart.primaryXAxis.stripLines[0].start = x - 0.1;
			chart.primaryXAxis.stripLines[0].end = x + 0.1;
			chart.primaryXAxis.stripLines[0].visible = true;
		} else if (chart.primaryXAxis.stripLines[0].visible) {
			chart.primaryXAxis.stripLines[0].visible = false;
		}
	}
};

export {
	// ZOOM SETTINGS
	getChartZoomSettings,
	setChartZoomSettings,
	// ENABLE MOUSE WHEEL ZOOMING
	getChartEnableMouseWheelZooming,
	setChartEnableMouseWheelZooming,
	// ENABLE SELECTION ZOOMING
	getChartEnableSelectionZooming,
	setChartEnableSelectionZooming,
	// SELECTION MODE
	getChartSelectionMode,
	setChartSelectionMode,
	// XMIN XMAX
	getChartXMinXMax,
	setChartXMinXMax,
	// YMIN YMAX
	getChartYMinYMax,
	setChartYMinYMax,
	// SERIES LIST
	getChartSeriesList,
	setChartSeriesList,
	// ZOOM PROPS
	getChartZoomProps,
	setChartZoomProps,
	// ZOOM MODE
	getChartZoomMode,
	setChartZoomMode,
	// RANGE
	getChartRange,
	setChartRange,
	// THRESHOLD
	getChartThreshold,
	setChartThreshold,
	// EMPASHIS LINE
	setEmphasisLine
};

export default {
	// ZOOM SETTINGS
	getChartZoomSettings,
	setChartZoomSettings,
	// ENABLE MOUSE WHEEL ZOOMING
	getChartEnableMouseWheelZooming,
	setChartEnableMouseWheelZooming,
	// ENABLE SELECTION ZOOMING
	getChartEnableSelectionZooming,
	setChartEnableSelectionZooming,
	// SELECTION MODE
	getChartSelectionMode,
	setChartSelectionMode,
	// XMIN XMAX
	getChartXMinXMax,
	setChartXMinXMax,
	// YMIN YMAX
	getChartYMinYMax,
	setChartYMinYMax,
	// ZOOM PROPS
	getChartZoomProps,
	setChartZoomProps,
	// ZOOM MODE
	getChartZoomMode,
	setChartZoomMode,
	// RANGE
	getChartRange,
	setChartRange,
	// SERIES LIST
	getChartSeriesList,
	setChartSeriesList,
	// THRESHOLD
	getChartThreshold,
	setChartThreshold,
	// EMPASHIS LINE
	setEmphasisLine
};
