export default {
	CISVIEW: {
		name: 'CISView',
		abbreviation: 'CISV',
		order: 2,
		permissions: {
			VIEWER: {
				name: 'Viewer',
				abbreviation: 'VWR',
				description:
					'Read only access. Can open tabs, view files, and download them.',
				awsGroupName: 'Permission:Cisv.Viewer',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 1
			},
			PROCESSOR: {
				name: 'Processor',
				abbreviation: 'PRC',
				description: 'Read / write access. Access to core CISView features.',
				awsGroupName: 'Permission:Cisv.Processor',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 2
			},
			MANAGER: {
				name: 'CISView Manager',
				abbreviation: 'MGR',
				description:
					'Processor Access + ability to approve data and a few higher operations',
				awsGroupName: 'Permission:Cisv.Manager',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 3
			},
			ADMINISTRATOR: {
				name: 'Administrator',
				abbreviation: 'ADM',
				description:
					'CISView Manager + ability to add customers and other special operations.',
				awsGroupName: 'Permission:Cisv.Administrator',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 4
			},
			SYSTEM_ADMINISTRATOR: {
				name: 'System Administrator',
				abbreviation: 'SYSA',
				description: 'Full access - AIM team members',
				awsGroupName: 'Permission:Cisv.System_Administrator',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 5
			}
		}
	},
	CORRELATOR: {
		name: 'Correlator',
		abbreviation: 'COR',
		order: 3,
		permissions: {
			TECHNICIAN: {
				name: 'Technician',
				abbreviation: 'TCH',
				description: 'Only Tablet Access',
				awsGroupName: 'Permission:Cor.Technician',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 1
			},
			VIEWER: {
				name: 'Survey Viewer',
				abbreviation: 'VWR',
				description: 'Read only access to web interface.',
				awsGroupName: 'Permission:Cor.Survey_Viewer',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 2
			},
			MANAGER: {
				name: 'Survey Manager',
				abbreviation: 'MGR',
				description:
					'Survey Viewer + ability to build surveys and make minor data edits.',
				awsGroupName: 'Permission:Cor.Survey_Manager',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 3
			},
			ADMINISTRATOR: {
				name: 'Administrator',
				abbreviation: 'ADM',
				description: 'Survey Manager + ability to modify and delete data.',
				awsGroupName: 'Permission:Cor.Administrator',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 4
			},
			SYSTEM_ADMINISTRATOR: {
				name: 'System Administrator',
				abbreviation: 'SYSA',
				description: 'Full access - subset of AIM Team',
				awsGroupName: 'Permission:Cor.System_Administrator',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 5
			}
		}
	},
	ASSETVIEW: {
		name: 'AssetView',
		abbreviation: 'AV',
		order: 4,
		permissions: {
			VIEWER: {
				name: 'Viewer',
				abbreviation: 'VWR',
				description:
					"View access only, can't see unapproved assets, this is the only customer level available.",
				awsGroupName: 'Permission:Av.Viewer',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 1
			},
			MANAGER: {
				name: 'AssetView Manager',
				abbreviation: 'MGR',
				description:
					'Viewer + ability to view unapproved assets, approve them, and make data edits.',
				awsGroupName: 'Permission:Av.AssetView_Manager',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 2
			},
			ADMINISTRATOR: {
				name: 'Administrator',
				abbreviation: 'ADM',
				description:
					'AssetView Manager + ability to delete data from the system.',
				awsGroupName: 'Permission:Av.Administrator',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 3
			},
			SYSTEM_ADMINISTRATOR: {
				name: 'System Administrator',
				abbreviation: 'SYSA',
				description: 'Full access - AIM team members',
				awsGroupName: 'Permission:Av.System_Administrator',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 4
			}
		}
	},
	SCANLINE: {
		name: 'ScanLine',
		abbreviation: 'SCAN',
		order: 5,
		permissions: {
			VIEWER: {
				name: 'Viewer',
				abbreviation: 'VWR',
				description: 'Read / write access to core ScanLine features.',
				awsGroupName: 'Permission:Scan.Viewer',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 1
			},
			MANAGER: {
				name: 'ScanLine Manager',
				abbreviation: 'MGR',
				description: 'Viewer + ability to approve action plans in their area',
				awsGroupName: 'Permission:Scan.ScanLine_Manager',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 2
			},
			ADMINISTRATOR: {
				name: 'Administrator',
				abbreviation: 'ADM',
				description:
					'Manager + ability to approve and modify any action plan they have access to',
				awsGroupName: 'Permission:Scan.Administrator',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 3
			},
			SYSTEM_ADMINISTRATOR: {
				name: 'System Administrator',
				abbreviation: 'SYSA',
				description: 'Full access - AIM team members',
				awsGroupName: 'Permission:Scan.System_Administrator',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 4
			}
		}
	},
	LIVELINE: {
		name: 'LiveLine',
		abbreviation: 'LL',
		order: 6,
		permissions: {
			VIEWER: {
				name: 'Viewer',
				abbreviation: 'VWR',
				description: 'Customer view, remove setup screen access.',
				awsGroupName: 'Permission:Ll.Viewer',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 1
			},
			SYSTEM_ADMINISTRATOR: {
				name: 'System Administrator',
				abbreviation: 'SYSA',
				description: 'Full access - AIM team members',
				awsGroupName: 'Permission:Ll.System_Administrator',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 2
			}
		}
	},
	LEGACY: {
		name: 'Legacy',
		abbreviation: 'LGCY',
		order: 7,
		permissions: {
			AEGION_DATAPROCESSOR: {
				name: 'Aegion Dataprocessor',
				abbreviation: 'ADP',
				description:
					'An Ageion user with permissions to perform any Dataprocessor tasks.',
				awsGroupName: 'Role:DP',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 1
			},
			AEGION_PROJECT_MANAGER: {
				name: 'Aegion Project Manager',
				abbreviation: 'APM',
				description:
					'An Ageion user with permissions to perform any Project Manager tasks.',
				awsGroupName: 'Role:PM',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 2
			},
			CUSTOMER_CORROSION_TECHNICIAN: {
				name: 'Customer Corrosion Technician',
				abbreviation: 'CCT',
				description:
					'A customer user with permissions to perform any customer Corrosion Technician tasks.',
				awsGroupName: 'CorrosionTechnician',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 3
			},
			CUSTOMER_PROJECT_MANAGER: {
				name: 'Customer Project Manager',
				abbreviation: 'CPM',
				description:
					'A customer user with permissions to perform any customer Project Manager tasks.',
				awsGroupName: 'ProjectManager',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 4
			},
			CUSTOMER_ADMINS: {
				name: 'Customer Admins',
				abbreviation: 'CADM',
				description:
					'Customer user accounts with ability to manage employee accounts through customer admin.',
				awsGroupName: 'CustomerAdmins',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 5
			},
			AIP_ADMINS: {
				name: 'AIP Admins',
				abbreviation: 'AADM',
				description: 'Aegion user accounts with super privileges.',
				awsGroupName: 'AIPAdmins',
				canSelfEdit: true,
				toggleablePermissionAwsGroupName: [],
				order: 6
			}
		}
	}
};
