/* eslint import/no-cycle:0 */

import AWS from 'aws-sdk';

import {
	hasAccessTokens,
	hasAccessToken,
	hasRefreshToken,
	getRefreshToken,
	destroyAllTokens,
	saveAccessToken,
	saveIdToken,
	saveRefreshToken
} from '../../../scanline/utils/token';
import ajax from '../../../scanline/utils/ajax';

import getAipUrls from '../../../config/getAipUrls';

const AIP_URLS = getAipUrls();

const AccountUtil = {
	auth: null,
	passwordVerifier: new RegExp(
		/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
	),
	hasTokens() {
		return hasAccessTokens();
	},
	password(params, callback) {
		this._ajax(
			'/authchallenge',
			JSON.stringify(params),
			'POST',
			(err, data) => {
				if (data) {
					this._setCookies(data.AuthenticationResult);
				}
				callback(err, data);
			},
			true
		);
	},
	login(cred, rememberMe, callback) {
		this._ajax(
			'/login',
			JSON.stringify(cred),
			'POST',
			(err, data) => {
				if (data) {
					this.auth = data;
					this._setCookies(data.AuthenticationResult, rememberMe);
				}
				callback(err, data);
			},
			true
		);
	},
	updateattributes(data, callback) {
		this._ajax('/updateattributes', JSON.stringify(data), 'POST', callback);
	},
	user(callback) {
		if (hasAccessToken()) {
			this.refresh((err, data) => {
				if (data) {
					this._ajax('/user', null, 'GET', callback);
				} else {
					callback(new Error('Not logged in'));
				}
			}).catch(() => {
				callback(new Error('Not logged in'));
			});
		} else {
			this._ajax('/user', null, 'GET', (err, data) => {
				if (data) {
					this.auth = data;
				}
				callback(err, data);
			});
		}
	},
	refresh(callback) {
		if (hasRefreshToken()) {
			return this._ajax(
				'/refresh',
				JSON.stringify({ token: getRefreshToken() }),
				'POST',
				undefined,
				true
			)
				.then(data => {
					if (data) {
						this.auth = data;
						this._setCookies(data.AuthenticationResult);
					}
					if (callback) {
						callback(null, data);
					}
					return data;
				})
				.catch(() => {
					AccountUtil.logoff();
					window.location = '/Account/Login';
				});
		}
		return Promise.reject(new Error('Missing Refresh Token'));
	},
	logoff() {
		destroyAllTokens();
	},
	reset(user, callback) {
		this._ajax('/reset', JSON.stringify({ user }), 'POST', callback, true);
	},
	verify(data, callback) {
		this._ajax('/verify', JSON.stringify(data), 'POST', callback, true);
	},
	verifycode(data, callback) {
		this._ajax('/verifycode', JSON.stringify(data), 'POST', callback);
	},
	updateSettings(data) {
		return this._ajax('/updatesettings', JSON.stringify(data), 'POST');
	},
	settings(column) {
		return this._ajax(`/settings/${column}`, undefined, 'GET');
	},
	profiles(column) {
		return this._ajax(`/profiles/${column}`, undefined, 'GET');
	},
	profile(column, name) {
		return this._ajax(`/profiles/${column}/${name}`, undefined, 'GET');
	},
	setProfile(column, data) {
		return this._ajax(`/profiles/${column}`, JSON.stringify(data), 'PUT');
	},
	deleteProfile(column, name) {
		return this._ajax(`/profiles/${column}/${name}`, undefined, 'DELETE');
	},
	applications() {
		return this._ajax('/applications', null, 'GET');
	},

	_ajax(url, data, method, callback, isAnonymous) {
		return ajax(url, data, method, callback, isAnonymous, 'account');
	},
	_setCookies(authenticationResult, rememberMe) {
		if (authenticationResult) {
			saveAccessToken(authenticationResult.AccessToken);
			saveIdToken(authenticationResult.IdToken);
			if (authenticationResult.RefreshToken) {
				if (rememberMe) {
					const now = new Date();
					let time = now.getTime();
					time += 3600 * 1000 * 24 * 30;
					now.setTime(time);
					saveRefreshToken(authenticationResult.RefreshToken, now);
				} else {
					saveRefreshToken(authenticationResult.RefreshToken);
				}
			}
		}
	},
	getUrlParameter(sParam) {
		const sURLVariables = window.location.search.substring(1).split('&');
		let sParameterName;
		let i;

		for (i = 0; i < sURLVariables.length; i += 1) {
			sParameterName = sURLVariables[i].split('=');

			if (sParameterName[0] === sParam) {
				return sParameterName[1] === undefined
					? true
					: decodeURIComponent(sParameterName[1]);
			}
		}
		return null;
	},
	verifyPassword(password, confirmPassword) {
		const isVerified =
			password === confirmPassword && this.passwordVerifier.test(password);
		return isVerified;
	},

	signup(user, password) {
		const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider(
			{ apiVersion: '2016-04-18', region: 'us-west-2' }
		);
		const params = {
			ClientId: AIP_URLS.clientid,
			Password: password,
			Username: user,
			UserAttributes: [
				{
					Name: 'email',
					Value: user
				}
			]
		};
		return cognitoidentityserviceprovider.signUp(params).promise();
	},

	confirmSignup(user, code) {
		const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider(
			{ apiVersion: '2016-04-18', region: 'us-west-2' }
		);
		const params = {
			ClientId: AIP_URLS.clientid,
			ConfirmationCode: code,
			Username: user
		};
		return cognitoidentityserviceprovider.confirmSignUp(params).promise();
	}
};

export default AccountUtil;
