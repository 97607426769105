import _ from 'lodash';
import {
	_isValidTargetSurvey,
	_isValidPrimarySurvey
} from '../util/alignedReadings.align';
import { selectSeries, selectSeriesIndexMap } from './series';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const DEFAULT_ALIGNMENT_THRESHOLD_IN_FEET = 35;

const _selectSurveyIdsRenderingToPrimary = (primarySurvey, stateApp) => {
	const { alignedReadingsControl: controls } = stateApp;
	const surveyIds = Object.keys(controls);
	// TODO: Eventually filter by primaryId too
	return surveyIds.filter(
		surveyId => controls[surveyId].renderToSurvey === primarySurvey.id
	);
};

/**
 * ALIGNED READINGS CONTROL
 */
const selectAlignedReadingsControl = (survey, stateApp) => {
	const { alignedReadingsControl } = stateApp;
	return alignedReadingsControl[survey.id];
};

/**
 * SURVEY IDS ALIGNED TO PRIMARY
 */
export const selectSurveyIdsAlignedToSelf = (
	primarySurvey = {},
	stateApp = {},
	options = {}
) => {
	const { requireDisplayInRibbon = false } = options;
	const { alignedReadingsControl: controls } = stateApp;

	const allSurveyIds = _selectSurveyIdsRenderingToPrimary(
		primarySurvey,
		stateApp
	);

	if (!requireDisplayInRibbon) {
		return allSurveyIds;
	}

	return allSurveyIds.filter(id => controls[id].displayInRibbon);
};

export const selectHasSurveysAlignedToSelf = (
	primarySurvey = {},
	stateApp = {},
	options = {}
) => {
	const surveysAttached = selectSurveyIdsAlignedToSelf(
		primarySurvey,
		stateApp,
		options
	);
	return surveysAttached.length > 0;
};

/**
 * SURVEYS
 */
const memFilterAlignableSurveys = _.memoize((surveys = []) =>
	surveys.filter(_isValidTargetSurvey)
);

export const selectAlignableSurveys = stateApp => {
	const { surveys } = stateApp;
	return memFilterAlignableSurveys(surveys);
};

const memIsValidTargetSurvey = _.memoize((survey = {}) =>
	_isValidTargetSurvey(survey)
);

export const selectIsValidTargetSurvey = survey =>
	memIsValidTargetSurvey(survey);

const memFilterAlignedSurveys = _.memoize(
	(_uniqueKeys, surveys = [], targetSurveyIds) =>
		surveys.filter(s => targetSurveyIds.indexOf(s.id) > -1)
);

export const selectAlignedSurveys = (
	primarySurvey = {},
	stateApp = {},
	options = {}
) => {
	const { requireDisplayInRibbon = false } = options;
	const { surveys } = stateApp;
	const targetSurveyIds = selectSurveyIdsAlignedToSelf(
		primarySurvey,
		stateApp,
		{ requireDisplayInRibbon }
	);
	const uniqueKeys = [
		requireDisplayInRibbon,
		primarySurvey.id,
		...targetSurveyIds
	].join(',');
	return memFilterAlignedSurveys(uniqueKeys, surveys, targetSurveyIds);
};

export const selectAlignedSurveysForRibbon = (
	primarySurvey = {},
	stateApp = {}
) =>
	selectAlignedSurveys(primarySurvey, stateApp, {
		requireDisplayInRibbon: true
	});

/**
 * ALIGNED DATA STRUCTURES
 */

export const selectAllAlignedDataStructures = (
	primarySurvey = {},
	stateApp = {},
	options = {}
) => {
	const { requireDisplayInRibbon = false } = options;
	const { alignedReadings } = stateApp;

	const surveyIds = selectSurveyIdsAlignedToSelf(primarySurvey, stateApp, {
		requireDisplayInRibbon
	});
	const dataStructureStore = alignedReadings[primarySurvey.id] || {};

	return surveyIds.reduce(
		(acc, id) => ({
			...acc,
			[id]: dataStructureStore[id]
		}),
		EMPTY_OBJECT
	);
};

export const selectStatsForAlignment = (
	primarySurvey,
	targetSurvey,
	stateApp
) => {
	const { alignedReadingsStats } = stateApp;

	const primarySurveyLeaf = alignedReadingsStats[primarySurvey.id];

	if (!primarySurveyLeaf) {
		return null;
	}

	const targetSurveyLeaf = primarySurveyLeaf[targetSurvey.id];

	if (!targetSurveyLeaf) {
		return null;
	}

	return targetSurveyLeaf.stats;
};

export const selectAlignedDataStructures = (
	primarySurvey = {},
	targetSurvey = {},
	stateApp = {}
) => {
	const allAlignedDataStructures = selectAllAlignedDataStructures(
		primarySurvey,
		stateApp
	);
	return allAlignedDataStructures[targetSurvey.id] || EMPTY_OBJECT;
};

/**
 * HELPERS: Aligned Readings
 */
export const selectAlignedReadings = (
	primarySurvey,
	targetSurvey,
	stateApp = {}
) => {
	const ds = selectAlignedDataStructures(primarySurvey, targetSurvey, stateApp);
	return ds.readings || EMPTY_ARRAY;
};

export const selectAlignedReadingsMap = (
	primarySurvey,
	targetSurvey,
	stateApp = {}
) => {
	const ds = selectAlignedDataStructures(primarySurvey, targetSurvey, stateApp);
	return ds.readingsMap || EMPTY_OBJECT;
};

export const selectIsAlignedRibbonVisible = (primarySurvey, stateApp) => {
	const { alignedReadingsRibbonsVisible } = stateApp;

	return alignedReadingsRibbonsVisible[primarySurvey.id];
};

/**
 * DATA SET FILTERS
 */
const _selectAllOriginalFilteredDataSets = (survey, stateApp) => {
	const { readingsFiltered } = stateApp;
	return readingsFiltered[survey.id];
};

const selectAllFilteredDataSets = (survey, stateApp) => {
	const { alignedReadingsFiltered } = stateApp;
	const control = selectAlignedReadingsControl(survey, stateApp) || {};
	const { renderToSurvey } = control;

	if (renderToSurvey) {
		return alignedReadingsFiltered[renderToSurvey][survey.id];
	}

	return _selectAllOriginalFilteredDataSets(survey, stateApp);
};

export const selectAllReadingFilterKeys = (survey, stateApp) => {
	const filteredDataSets = selectAllFilteredDataSets(survey, stateApp);
	return Object.keys(filteredDataSets || {});
};

export const selectHasReadingFilterKeys = (survey, stateApp) => {
	return (selectAllReadingFilterKeys(survey, stateApp) || []).length > 0;
};

export const selectReadingFilterKey = (survey, stateApp) => {
	const control = selectAlignedReadingsControl(survey, stateApp) || {};
	const { filterKey } = control;

	return filterKey;
};

export const selectHasReadingFilter = (survey, stateApp) => {
	return !!selectReadingFilterKey(survey, stateApp);
};

/**
 * SELECT MY READING DATA STRUCTURES
 */

const selectMyAlignedReadingDataStructures = (survey, stateApp) => {
	const { alignedReadings = {} } = stateApp;

	const control = selectAlignedReadingsControl(survey, stateApp) || {};
	const primarySurveyId = control.renderToSurvey;

	return (alignedReadings[primarySurveyId] || {})[survey.id];
};

const selectMyAlignedReadingFilteredDataStructures = (survey, stateApp) => {
	const { alignedReadingsFiltered = {} } = stateApp;

	const control = selectAlignedReadingsControl(survey, stateApp) || {};
	const { renderToSurvey, filterKey } = control;

	return ((alignedReadingsFiltered[renderToSurvey] || {})[survey.id] || {})[
		filterKey
	];
};

const selectMyReadingFilteredDataStructures = (survey, stateApp) => {
	const { readingsFiltered = {} } = stateApp;

	const control = selectAlignedReadingsControl(survey, stateApp) || {};
	const { filterKey } = control;

	return (readingsFiltered[survey.id] || {})[filterKey];
};

export const selectMyReadingDataStructures = (survey, stateApp) => {
	try {
		const control = selectAlignedReadingsControl(survey, stateApp) || {};
		const { renderToSurvey, filterKey } = control;

		if (renderToSurvey && filterKey) {
			return selectMyAlignedReadingFilteredDataStructures(survey, stateApp);
		}

		if (renderToSurvey) {
			return selectMyAlignedReadingDataStructures(survey, stateApp);
		}

		if (filterKey) {
			return selectMyReadingFilteredDataStructures(survey, stateApp);
		}

		const {
			readings,
			readingsMap,
			readingsIndexMap,
			readingsWithChartGaps,
			readingsWithChartGapsIndexMap
		} = stateApp;

		return {
			readings: readings[survey.id],
			readingsMap: readingsMap[survey.id],
			readingsIndexMap: readingsIndexMap[survey.id] || EMPTY_OBJECT,
			readingsWithChartGaps: readingsWithChartGaps[survey.id],
			readingsWithChartGapsIndexMap:
				readingsWithChartGapsIndexMap[survey.id] || EMPTY_OBJECT,
			series: selectSeries(stateApp, survey),
			seriesIndexMap: selectSeriesIndexMap(stateApp, survey)
		};
	} catch (err) {
		debugger;
		return {};
	}
};

export const selectAllComputedAlignedDataStructures = (
	survey,
	stateApp,
	options
) => {
	const targetSurveyIds = selectSurveyIdsAlignedToSelf(
		survey,
		stateApp,
		options
	);
	return targetSurveyIds.reduce(
		(acc, targetSurveyId) => ({
			...acc,
			[targetSurveyId]: selectMyReadingDataStructures(
				{ id: targetSurveyId },
				stateApp
			)
		}),
		{}
	);
};

/**
 * HELPERS: Standard Readings
 */
export const selectReadings = (survey, stateApp = {}) => {
	const { id } = survey;
	const { readings: appReadings = {} } = stateApp;

	return appReadings[id] || [];
};

export const selectReadingsMap = (survey, stateApp = {}) => {
	const { id } = survey;
	const { readingsMap: appReadingsMap = {} } = stateApp;

	return appReadingsMap[id] || {};
};

/**
 * HELPERS: Align to dropdown
 */

const memIsValidPrimarySurvey = _.memoize((survey = []) =>
	_isValidPrimarySurvey(survey)
);

export const selectIsValidPrimarySurvey = survey =>
	memIsValidPrimarySurvey(survey);

export const selectShouldShowAlignedRibbon = (survey, app) => {
	const isValidPrimarySurvey = selectIsValidPrimarySurvey(survey);
	const hasVisibleAlignedSurveys = selectHasSurveysAlignedToSelf(survey, app, {
		requireDisplayInRibbon: true
	});

	const isAlignedRibbonVisible = selectIsAlignedRibbonVisible(survey, app);

	return (
		isAlignedRibbonVisible && hasVisibleAlignedSurveys && isValidPrimarySurvey
	);
};

export const selectAlignedReadingsThreshold = (primarySurvey, stateApp) => {
	const { alignedReadingsThreshold } = stateApp;

	const thresholdObject = alignedReadingsThreshold[primarySurvey.id] || {};

	return {
		threshold: thresholdObject.threshold || DEFAULT_ALIGNMENT_THRESHOLD_IN_FEET,
		tempThreshold: thresholdObject.tempThreshold
	};
};

const memFilterPossiblePrimarySurveys = _.memoize((surveys = []) =>
	surveys.filter(selectIsValidPrimarySurvey)
);

export const selectPrimarySurveys = (stateApp = {}) => {
	const { surveys = EMPTY_ARRAY } = stateApp;
	return memFilterPossiblePrimarySurveys(surveys);
};

const _exports = {
	selectSurveyIdsAlignedToSelf,
	selectHasSurveysAlignedToSelf,
	selectAlignedSurveys,
	selectAlignedSurveysForRibbon,
	selectAllAlignedDataStructures,
	selectAlignedDataStructures,
	selectAlignedReadings,
	selectAlignedReadingsMap,
	selectMyReadingDataStructures,
	selectReadings,
	selectReadingsMap,
	selectPrimarySurveys
};

export const TESTS = {
	..._exports,
	_selectSurveyIdsRenderingToPrimary
};

export default {
	..._exports
};
