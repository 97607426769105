/* eslint import/no-cycle:0 */
import { reverse } from 'dns';
import { latLngBounds } from 'leaflet';
import { debounce } from 'lodash';

import { MiscUtils } from 'aegion_common_utilities';

// eslint-disable-next-line import/no-webpack-loader-syntax, import/extensions, import/no-unresolved
// import ReadingWorker from 'workerize-loader?inline!./workers/readings.worker.js';
import {
	getConvertedData,
	getUpdateFilteredReadings
} from './workers/readings.worker';
import ReadingsUtils from './workers/readings.worker.utils';

import JobUtil from '../../utils/JobUtil/JobUtil';
import SurveyUtil from '../../utils/SurveyUtil';
import { extentChanged } from './maps';
import { getBoundingBox } from '../../utils/DatFiles/index';

// const readingWorker = ReadingWorker();

const NS_MAPS = 'cisview_additionalSurveys_';

const getWithDatFilesBySurveyType = surveys => {
	const datFilesBySurveyType = {};

	surveys.forEach(survey => {
		const { MasterLST } = survey;
		const datFiles = Object.values(MasterLST).map(datFile =>
			SurveyUtil.extendDataWithSurveyType(datFile, survey)
		);

		datFilesBySurveyType[survey.surveyType] = datFiles;
	});

	return datFilesBySurveyType;
};

const _sortSurveys = surveys => {
	return [...surveys] // Make copy
		.sort((a, b) => {
			const nameA = a.surveyType.toUpperCase();
			const nameB = b.surveyType.toUpperCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			return 0;
		});
};

export const SET_FILTERS = `${NS_MAPS}SET_FILTERS`;
const setFiltersByType = filtersByType => {
	return {
		type: SET_FILTERS,
		payload: {
			filtersByType
		}
	};
};

export const SET_SURVEYS = `${NS_MAPS}SET_SURVEYS`;
const setSurveys = rawSurveys => {
	const surveysWithSurveyType = rawSurveys.map(
		SurveyUtil.extendSurveyFilesWithSurveyType
	);
	const surveys = _sortSurveys(surveysWithSurveyType);
	const datFilesBySurveyType = getWithDatFilesBySurveyType(surveys);

	return {
		type: SET_SURVEYS,
		payload: { surveys, datFilesBySurveyType }
	};
};

export const SET_CONVERTED_READINGS = `${NS_MAPS}SET_CONVERTED_READINGS`;
const setConvertedReadings = convertedReadingsBySurveytype => ({
	type: SET_CONVERTED_READINGS,
	payload: convertedReadingsBySurveytype
});

// This is where we seet visible surveys - not sure if this is super helpful though
export const SET_VISIBLE_SURVEYS = `${NS_MAPS}SET_VISIBLE_SURVEYS`;
const setVisibleSurveys = surveys => {
	return {
		type: SET_VISIBLE_SURVEYS,
		payload: surveys
	};
};

// This will pull all survey data metadata (not gpsreadings, etc)
export const getSurveys = (jobNum, lineName) => dispatch => {
	JobUtil.list(jobNum).then(res => {
		const { lines = {} } = res;
		const { [lineName]: surveys = [] } = lines;

		const outSurveys = surveys
			.filter(s => s.MasterLST)
			.map((survey, idx) => {
				const color = MiscUtils.getColorByIndexOrRandom(idx, reverse);

				return {
					...survey,
					color,
					MasterLST: SurveyUtil.cleanMasterLST(survey.MasterLST)
				};
			});

		dispatch(setSurveys(outSurveys));
	});
};

const filterSurveysByType = (surveys, surveyType) => {
	return surveys.filter(s => s.surveyType === surveyType);
};

const _getSurveyByType = (surveyType, getState) => {
	const state = getState();
	const { cisview } = state;
	const { additionalSurveys } = cisview;
	let { surveys } = additionalSurveys;

	surveys = filterSurveysByType(surveys, surveyType);

	return surveys[0];
};

const _getBoundingBoxForSurvey = (surveyType, getState) => {
	const survey = _getSurveyByType(surveyType, getState);
	if (!survey) {
		return null;
	}

	const { MasterLST } = survey;
	return Object.keys(MasterLST).reduce((acc, fileName) => {
		const { [fileName]: dat } = MasterLST;
		const { bounds } = dat;
		if (!bounds) {
			return acc;
		}
		if (acc === null) {
			return latLngBounds(bounds.getSouthWest(), bounds.getNorthEast());
		}
		acc.extend(bounds);
		return acc;
	}, null);
};

export const zoomTo = surveyType => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { maps } = cisview;
	const { map } = maps;

	const bbox = _getBoundingBoxForSurvey(surveyType, getState);
	if (!bbox) {
		return;
	}

	const level = map.getBoundsZoom(bbox);
	const center = bbox.getCenter();
	const { lat, lng } = center;

	dispatch(extentChanged([lat, lng], level));
};

const _extendSurveyWithExistingColor = (survey, color) => {
	const newMasterLST = {};
	Object.keys(survey.MasterLST).forEach(fileName => {
		newMasterLST[fileName] = {
			...survey.MasterLST[fileName],
			color
		};
	});

	return {
		...survey,
		MasterLST: newMasterLST,
		color
	};
};

const _pullAllDataForSurvey = survey => {
	const { color: originalColor } = survey;
	return new Promise((res, rej) => {
		JobUtil.getJobData(survey.timeuuid)
			.then(newSurvey => {
				const newSurveyWithOriginalColor = _extendSurveyWithExistingColor(
					newSurvey,
					originalColor
				);

				const withBounds = Object.keys(
					newSurveyWithOriginalColor.MasterLST
				).reduce((acc, key) => {
					const { gpsreadings } = newSurveyWithOriginalColor.MasterLST[key];
					const bounds = ReadingsUtils.getBounds(gpsreadings);
					const bbox = getBoundingBox(bounds);
					return {
						...acc,
						[key]: {
							...newSurveyWithOriginalColor.MasterLST[key],
							bounds: bbox
						}
					};
				}, {});

				return res({
					...newSurveyWithOriginalColor,
					MasterLST: withBounds,
					loaded: true
				});
			})
			.catch(e => {
				rej(e);
			});
	});
};

const _mergeSurveyWithOthers = (survey, getState) => {
	const state = getState();
	const { cisview } = state;
	const { additionalSurveys } = cisview;
	const { surveys } = additionalSurveys;

	const otherSurveys = surveys.filter(s => s.surveyType !== survey.surveyType);

	return [...otherSurveys, survey];
};

const _mergeConvertedReadings = (
	convertedReadingObject,
	surveyType,
	getState
) => {
	const state = getState();
	const { cisview } = state;
	const { additionalSurveys } = cisview;
	const { convertedReadingsBySurveytype } = additionalSurveys;

	return {
		...convertedReadingsBySurveytype,
		[surveyType]: convertedReadingObject
	};
};

const _getAlreadyConvertedReadings = (getState, surveyType) => {
	const state = getState();
	const { cisview } = state;
	const { additionalSurveys } = cisview;
	const { convertedReadingsBySurveytype } = additionalSurveys;

	if (!convertedReadingsBySurveytype[surveyType]) {
		return null;
	}

	return convertedReadingsBySurveytype[surveyType];
};

const _setConvertedReadingsFromSurvey = survey => async (
	dispatch,
	getState
) => {
	const fileNames = Object.keys(survey.MasterLST);
	const { surveyType } = survey;

	const datFiles = fileNames.map(fileName => {
		return {
			...survey.MasterLST[fileName],
			fileName,
			surveyType
		};
	});
	const datFilesWithGpsReadings = datFiles.filter(
		datFile => datFile.gpsreadings
	);
	if (datFilesWithGpsReadings.length === 0) {
		return;
	}
	const convertedReadingsAlreadyExist = _getAlreadyConvertedReadings(
		getState,
		surveyType
	);
	if (convertedReadingsAlreadyExist) {
		return;
	}

	const { convertedReadings } = await getConvertedData(datFilesWithGpsReadings);

	const mergedConvertedReadings = _mergeConvertedReadings(
		convertedReadings,
		surveyType,
		getState
	);

	dispatch(setConvertedReadings(mergedConvertedReadings));
};

const _setSurveyLoading = (survey, loading = true) => (dispatch, getState) => {
	const surveyLoading = {
		...survey,
		loading
	};

	dispatch(setSurveys(_mergeSurveyWithOthers(surveyLoading, getState)));

	return surveyLoading;
};

const _loadSurveyByType = surveyType => async (dispatch, getState) => {
	const survey = _getSurveyByType(surveyType, getState);

	if (!survey) {
		return;
	}

	if (survey.loaded || survey.loading) {
		return;
	}
	const loadingSurvey = _setSurveyLoading(survey, true)(dispatch, getState);
	const newSurvey = await _pullAllDataForSurvey(loadingSurvey);
	const finishedLoadingSurvey = _setSurveyLoading(newSurvey, false)(
		dispatch,
		getState
	);
	dispatch(setSurveys(_mergeSurveyWithOthers(finishedLoadingSurvey, getState)));
};

const hideSurvey = () => () => {};

const _getVisibleSurveys = getState => {
	const state = getState();
	const { cisview } = state;
	const { additionalSurveys } = cisview;
	const { visibleSurveys } = additionalSurveys;

	return visibleSurveys;
};

const _isSurveyVisbile = surveyType => getState => {
	const visibleSurveys = _getVisibleSurveys(getState);

	return visibleSurveys.indexOf(surveyType) !== -1;
};

export const setVisible = surveyType => async (dispatch, getState) => {
	const visibleSurveys = _getVisibleSurveys(getState);

	if (!_isSurveyVisbile(surveyType)(getState)) {
		dispatch(setVisibleSurveys([...visibleSurveys, surveyType]));
		await _loadSurveyByType(surveyType)(dispatch, getState);
	} else {
		const index = visibleSurveys.indexOf(surveyType);
		dispatch(
			setVisibleSurveys(visibleSurveys.filter((_, idx) => idx !== index))
		);
		dispatch(hideSurvey(surveyType));
	}
};

// This is the "editable" type (as found in Station Alignment)
export const setConvertedReadingsForAdditionalSurvey = convertedReadings => (
	dispatch,
	getState
) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;

	const { globalData } = job;
	const { surveyType } = globalData;

	// First we want to go ahead and update the survey from globalData
	const surveys = _mergeSurveyWithOthers(globalData, getState);
	dispatch(setSurveys(surveys));

	const mergedConvertedReadings = _mergeConvertedReadings(
		convertedReadings,
		surveyType,
		getState
	);

	dispatch(setConvertedReadings(mergedConvertedReadings));
};

const _isSurveyTypeSameAsGlobalData = (getState, surveyType) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;

	const { globalData } = job;
	return globalData.surveyType === surveyType;
};

// For the readings table, we want to have the equivalent "convertedReadings" (flattened array structure)
// The functions within should be smart enough to only work when needed (ie. we can call this function as many times as we want without a problem)
export const loadSurveyForReadingsTable = surveyType => async (
	dispatch,
	getState
) => {
	// We want globalData to be updated automatically (through the readings action)
	if (_isSurveyTypeSameAsGlobalData(getState, surveyType)) {
		return;
	}

	// First pull all data for surveys - only call if not visible (otherwise it will actually hide the survey)
	if (!_isSurveyVisbile(surveyType)(getState)) {
		await setVisible(surveyType)(dispatch, getState);
	}

	// Then zoom to show survey on map
	zoomTo(surveyType)(dispatch, getState);

	// Then get convertedReadings for all dat files in surveys
	const survey = _getSurveyByType(surveyType, getState);
	if (survey.loading) {
		return;
	}
	_setConvertedReadingsFromSurvey(survey)(dispatch, getState);
};

const skeletonFilterOptions = () => {
	return {
		filterText: '',
		filteredReadings: []
	};
};

const getFilterText = (getState, surveyType) => {
	const { filtersByType } = getState().cisview.additionalSurveys;
	if (!filtersByType[surveyType]) {
		return '';
	}

	return filtersByType[surveyType].filterText;
};

const _updateReadingsFilter = async (
	filter,
	surveyType,
	dispatch,
	getState
) => {
	const state = getState();
	const { cisview } = state;
	const { additionalSurveys } = cisview;

	const { convertedReadingsBySurveytype, filtersByType } = additionalSurveys;

	const convertedReadings = convertedReadingsBySurveytype[surveyType];
	if (!convertedReadings) {
		return;
	}

	const startFilterText = getFilterText(getState, surveyType);

	const filteredReadings = await getUpdateFilteredReadings(
		filter,
		convertedReadings
	);

	// The filterText has changed, abort
	if (startFilterText !== getFilterText(getState, surveyType)) {
		return;
	}

	const thisFilter = filtersByType[surveyType] || skeletonFilterOptions();
	const newFilter = {
		...thisFilter,
		filteredReadings
	};
	const newFiltersByTypes = {
		...filtersByType,
		[surveyType]: newFilter
	};

	dispatch(setFiltersByType(newFiltersByTypes));
};

// We debounce this logic as the user might be typing quickly and it might take a while to finish
const _updateReadingsFilterDebounce = debounce(_updateReadingsFilter, 100);

const _updateFilterValueByType = (filter, surveyType, dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { additionalSurveys } = cisview;
	const { filtersByType } = additionalSurveys;

	const thisFilter = filtersByType[surveyType] || skeletonFilterOptions();
	const newFilter = {
		...thisFilter,
		filterText: filter
	};

	const newFiltersByTypes = {
		...filtersByType,
		[surveyType]: newFilter
	};

	dispatch(setFiltersByType(newFiltersByTypes));
};

export const updateReadingsFilter = (filter, surveyType) => (
	dispatch,
	getState
) => {
	// We immediatelly set the filter value so they user can keep typing
	_updateFilterValueByType(filter, surveyType, dispatch, getState);
	// We debounce the logic as it might take a while to finish
	_updateReadingsFilterDebounce(filter, surveyType, dispatch, getState);
};
