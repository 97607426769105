import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';

import { Route, Switch, Redirect } from 'react-router-dom';
import Loading from '../../../cisview/oldWebShared/aip/loading';

const AppAccount = lazy(() =>
	import(/* webpackChunkName: "AppAccount" */ '../../../account')
);

const AppScanline = lazy(() =>
	import(/* webpackChunkName: "AppScanline" */ '../../../scanline')
);

const WebsiteBridge = lazy(() =>
	import(/* webpackChunkName: "Website" */ '../../../websiteBridge')
);

const UnauthorizedRoutes = () => {
	return (
		<Suspense fallback={<Loading title="Loading..." />}>
			<Switch>
				<Route path="/Account" component={AppAccount} />
				<Route path="/Scanline/share" component={AppScanline} />
				<Route path="/Index" component={WebsiteBridge} />
				<Route exact path="/" component={WebsiteBridge} />
				<Route
					path="/"
					render={({ location: { pathname, search } }) => {
						return (
							<Redirect
								to={{
									pathname: `/Account/Login?ReturnUrl=${encodeURIComponent(
										pathname + search
									)}`
								}}
							/>
						);
					}}
				/>
			</Switch>
		</Suspense>
	);
};

export default connect()(UnauthorizedRoutes);
