/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import AssetsWorker from 'workerize-loader?inline!./webworkers/assets.worker';
import { ajaxNew as ajax } from '../scanline/utils/ajax';
import { getIdToken } from '../scanline/utils/token';
import { APPROVAL_MODE, MANAGER_LIST_PAGING } from './constants';
import { APP_VIEW } from './components/utils/AppView';

const assetsWorker = AssetsWorker();

function _ajaxNew({ url, data, method, callback, isAnonymous, token }) {
	return ajax({
		url,
		data,
		method,
		callback,
		isAnonymous,
		aip: 'assetview',
		token
	});
}

function _ajax(url, data, method, callback, isAnonymous) {
	return _ajaxNew({ url, data, method, callback, isAnonymous });
}

function managerSettings() {
	return _ajaxNew({ url: '/manager/settings', method: 'GET' }).then(manager => {
		const {
			settings,
			defaultColumns: { before = [], after = [] } = {}
		} = manager;

		const manSettings = settings.map(structure => {
			const { columns } = structure;
			return { ...structure, columns: [...before, ...columns, ...after] };
		});
		return [
			...manSettings,
			{ name: 'default', columns: [...before, ...after] }
		];
	});
}

export default class Filters {
	access() {
		return _ajax('/access', undefined, 'POST');
	}

	getAssetItem(name, template) {
		return _ajax(`/asset/item/${template}/${name}`, undefined, 'GET');
	}

	downloadaccess() {
		return new Promise((resolve, reject) => {
			_ajax('/downloadaccess', null, 'GET', (err, res) => {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			});
		});
	}

	downloads(customer) {
		return new Promise((resolve, reject) => {
			_ajax(`/downloads/${customer}`, null, 'GET', (err, res) => {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			});
		});
	}

	menus() {
		return _ajaxNew({
			url: '/menus',
			method: 'GET'
		});
	}

	// eslint-disable-next-line class-methods-use-this
	listPaging({
		selected,
		size = 10,
		from = 0,
		listSort = 'desc',
		listSortProp,
		extent,
		approval = APPROVAL_MODE.approved,
		searches,
		isManager
	}) {
		const promises = [];
		const loop = Math.ceil(size / MANAGER_LIST_PAGING);
		const loopStart = Math.floor(from / MANAGER_LIST_PAGING);
		const token = isManager ? getIdToken() : undefined;
		for (let i = loopStart; i < loop; i += 1) {
			const pagingSize =
				MANAGER_LIST_PAGING * (i + 1) > size
					? size - MANAGER_LIST_PAGING * i
					: MANAGER_LIST_PAGING;
			const req = {
				selected,
				searches,
				extent,
				from: i * MANAGER_LIST_PAGING + from,
				sort: listSort,
				sortProp: listSortProp,
				size: pagingSize,
				approval,
				manager: isManager
			};
			promises.push(
				_ajaxNew({
					url: '/list',
					data: JSON.stringify(req),
					method: 'POST',
					token
				})
			);
		}

		return Promise.all(promises).then(res => {
			return res.reduce(
				(acc, { hits, total }) => {
					return {
						hits: [...acc.hits, ...hits],
						total: { value: total.value, relation: 'eq' }
					};
				},
				{ hits: [], total: { value: 0, relation: 'eq' } }
			);
		});
	}

	list({
		selected,
		from = 0,
		size = 50,
		listSort = 'desc',
		listSortProp = 'date',
		extent,
		approval = APPROVAL_MODE.approved,
		isManager = false,
		searches
	}) {
		if (size > MANAGER_LIST_PAGING) {
			return this.listPaging({
				selected,
				size,
				from,
				listSort,
				listSortProp,
				extent,
				approval,
				searches,
				isManager
			});
		}
		const req = {
			selected,
			extent,
			from,
			sort: listSort,
			sortProp: listSortProp,
			size,
			approval,
			manager: isManager,
			searches
		};
		const token = isManager ? getIdToken() : undefined;
		return _ajaxNew({
			url: '/list',
			data: JSON.stringify(req),
			method: 'POST',
			token
		});
	}

	aggsgeojson(selected, searches, approval = APPROVAL_MODE.approved, calback) {
		return _ajax(
			'/aggsgeojson',
			JSON.stringify({
				selected,
				searches,
				approval
			}),
			'POST',
			calback
		);
	}

	assetsgeojson(selected, searches, coords, approval, criterias) {
		return _ajax(
			'/assetsgeojson',
			JSON.stringify({ selected, searches, coords, approval }),
			'POST'
		)
			.then(res => {
				return assetsWorker.enhanceAssetsGeoJSON(res, approval, criterias);
			})
			.then(res => {
				return res;
			});
	}

	item(key, selected) {
		return new Promise((resolve, reject) => {
			_ajax('/item', JSON.stringify({ key, selected }), 'POST', (err, res) => {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			});
		});
	}

	suggestions({ text, selected, approval, searches }) {
		return _ajax(
			'/suggestions',
			JSON.stringify({ text, selected, approval, searches }),
			'POST'
		);
	}

	exportdata({
		selected,
		searches,
		exportColumns,
		history = false,
		images = false,
		approval = APPROVAL_MODE.approved,
		extent,
		hideColumns
	}) {
		return _ajax(
			'/export/all',
			JSON.stringify({
				history,
				images,
				exportColumns,
				selected,
				searches,
				approval,
				extent,
				hideColumns
			}),
			'POST'
		);
	}

	zipImagesTemplates() {
		return _ajax('/zipImagesTemplates', undefined, 'GET');
	}

	saveCloud(selected, searches, exportColumns, hideColumns) {
		return _ajax(
			'/savecloud/all',
			JSON.stringify({ selected, searches, exportColumns, hideColumns }),
			'POST'
		);
	}

	saveCorrelator(
		selected,
		searches,
		exportColumns,
		hideColumns,
		approval = APPROVAL_MODE.approved
	) {
		return _ajax(
			'/savecorrelator/all',
			JSON.stringify({
				selected,
				searches,
				exportColumns,
				hideColumns,
				approval
			}),
			'POST'
		);
	}

	updates(updates) {
		return _ajax('/updates', JSON.stringify(updates), 'POST');
	}

	managerSettings() {
		return managerSettings();
	}

	approve(ids) {
		const promises = [];
		const loop = Math.ceil(ids.length / MANAGER_LIST_PAGING);
		for (let i = 0; i < loop; i += 1) {
			promises.push(
				_ajax(
					'/manager/approve',
					JSON.stringify(
						ids.slice(i * MANAGER_LIST_PAGING, (i + 1) * MANAGER_LIST_PAGING)
					),
					'POST'
				)
			);
		}
		return Promise.all(promises).then(() => {
			return loop;
		});
	}

	markEOL(ids) {
		return _ajax('/manager/markEOL', JSON.stringify(ids), 'POST');
	}

	// eslint-disable-next-line class-methods-use-this
	deleteAssets(ids, approval) {
		const token = getIdToken();
		return _ajaxNew({
			url: '/manager/delete',
			data: JSON.stringify({ ids, approval }),
			method: 'POST',
			token
		});
	}
}

function filter({
	selected,
	searches,
	loadAllFilters,
	approval,
	appView,
	category
}) {
	const token = appView === APP_VIEW.manager ? getIdToken() : undefined;
	const manager = appView === APP_VIEW.manager;

	return _ajaxNew({
		url: '/filters',
		data: JSON.stringify({
			selected,
			searches,
			loadAllFilters,
			approval,
			manager,
			category
		}),
		method: 'POST',
		token
	});
}

function refine({
	wildcard,
	category,
	selected,
	searches,
	loadAll,
	approval = APPROVAL_MODE.approved
}) {
	return _ajax(
		'/refine',
		JSON.stringify({
			category,
			wildcard,
			selected,
			loadAll,
			approval,
			searches
		}),
		'POST'
	);
}

function counts({
	selected,
	searches,
	extent,
	approval = APPROVAL_MODE.approved
}) {
	const req = { selected, extent, approval, searches };
	const token = getIdToken();
	return _ajaxNew({
		url: '/counts',
		data: JSON.stringify(req),
		method: 'POST',
		token
	});
}

export { refine, filter, counts, managerSettings };
