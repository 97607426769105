/* eslint import/no-cycle:0 */

import { save, deactivateMapTools, undoMapTools, setGlobalData } from './job';

import { onNewGpsData } from './gps-auto-correction';

import {
	getMovedAndSmoothedData,
	getAllData,
	trackMovedAndSmoothedEvents,
	getDataForSave
} from './util/editorToolbarTools';

const NS_MAPS = 'cisview_editorToolbar_';

export const SET_ACTIVE_TOOL = `${NS_MAPS}SET_ACTIVE_TOOL`;
export const setActiveTool = tool => {
	return {
		type: SET_ACTIVE_TOOL,
		payload: tool
	};
};

export const SET_IS_EDITED = `${NS_MAPS}SET_IS_EDITED`;
export const setIsEdited = isEdited => {
	return { type: SET_IS_EDITED, payload: isEdited };
};

export const CLEAR = `${NS_MAPS}CLEAR`;
export const clearEditor = () => {
	return {
		type: CLEAR
	};
};

export const SET_LAST_SAVED = `${NS_MAPS}SET_LAST_SAVED`;
export const setLastSaved = lastEdits => {
	return {
		type: SET_LAST_SAVED,
		payload: lastEdits
	};
};

export const undo = lastSaved => dispatch => {
	dispatch(undoMapTools(lastSaved));
};

const saveJob = (data, globalData) => (dispatch, getState) => {
	const { timeuuid } = globalData;
	const editedDats = data
		.filter(d => d.isEdited)
		.map(({ fileName, datFile }) => ({ fileName, datFile }));
	dispatch(setGlobalData(globalData, data));
	save({ data, globalData, setData: false })(dispatch, getState);
	dispatch(setLastSaved({ timeuuid, editedDats }));

	dispatch(deactivateMapTools());
};

export const saveEditor = () => (dispatch, getState) => {
	const {
		movedPoints,
		existingSmoothPoints,
		currentSmoothPoints
	} = getMovedAndSmoothedData(getState);
	const { globalData } = getAllData(getState);

	trackMovedAndSmoothedEvents(
		movedPoints,
		existingSmoothPoints,
		currentSmoothPoints,
		globalData
	);

	const [newDats, newGlobalData] = getDataForSave(getState);

	// console.log(newDats, newGlobalData);
	saveJob(newDats, newGlobalData)(dispatch, getState);
	dispatch(onNewGpsData(newDats));
};
