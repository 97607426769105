/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { assumeCognitoUser } from '../../../cognito';
import scanlineUtils from '../../../../scanline/scanlineUtils';
import getAipUrls from '../../../../config/getAipUrls';
import { renderAsText, asyncTimeout } from './helpers';

const aipUrls = getAipUrls();

const S3_ACTION_DESC = 'getObject -> s3.getObject';

const emailCustomerSupport = ({ payload, error, attemptCount }) => {
	const title = `AIP - request "${S3_ACTION_DESC}" failed (attempt ${attemptCount})`;

	scanlineUtils.emailCustomerSupport(
		{
			cc: 'wbarnes@aegion.com',
			appendToSubject: title,
			content: `
        <div>
          <h2>${title}</h2>
          <div>Error: ${renderAsText(error)}</div>
          <div>Payload: ${renderAsText(payload)}</div>
        <div>
      `,
			docType: 'HTML'
		},
		{ ignoreError: true }
	);
};

const _getObject = async (s3, params, attemptCount = 1) => {
	const retryOnFail = attemptCount === 1;
	return s3
		.getObject(params)
		.promise()
		.catch(async err => {
			emailCustomerSupport({
				payload: params,
				error: err,
				attemptCount
			});
			if (retryOnFail) {
				await asyncTimeout(300);
				return _getObject(s3, params, attemptCount + 1);
			}
			throw err;
		});
};

export const getObject = async (Key, opts, enableFallback = false) => {
	const { bucket, versioned = false, versionId } = opts;
	const Bucket = bucket || versioned ? aipUrls.versionedBucket : aipUrls.bucket;
	const params = {
		Bucket,
		Key,
		VersionId: versionId
	};

	const AWS = await assumeCognitoUser();
	const s3 = new AWS.S3({ apiVersion: '2006-03-01' });
	// console.log(params);
	// debugger;
	return _getObject(s3, params, enableFallback ? 1 : 0);
};
