import { createSelector } from 'reselect';
import { DEFAULT_THRESHOLDS, EMPTY_OBJECT } from './constants';
import { isDefined } from './util';
import { selectSurvey } from '../../../../selectors/surveys';
import { selectDataXYMinMax } from '../../../StoreXYMinMax/selectors';

export const selectChartsSettings = (stateApp = EMPTY_OBJECT) => {
	const { chartsSettings = EMPTY_OBJECT } = stateApp;
	return chartsSettings;
};

export const selectChartSettings = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartsSettings = selectChartsSettings(stateApp);
	const chartSettings = chartsSettings[chartId] || EMPTY_OBJECT;

	return chartSettings;
};

export const selectThresholds = (stateApp, { chartId } = {}) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { thresholds } = chartSettings;

	return isDefined(thresholds) ? thresholds : DEFAULT_THRESHOLDS;
};

/**
 * @deprecated
 * @todo We need to move away from single threshold per chart
 */
export const selectThreshold = (stateApp, { chartId } = {}) => {
	const thresholds = selectThresholds(stateApp, { chartId });
	return thresholds[0];
};

export const selectShowDecimatedData = (stateApp, { chartId } = {}) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { showDecimatedData } = chartSettings;
	return showDecimatedData;
};

export const selectRequiresThresholdChangeConfirmation = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { thresholdsPendingChangeRequiresConfirmation } = chartSettings;

	return !!thresholdsPendingChangeRequiresConfirmation;
};

export const selectUseThreshold = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { useThreshold } = chartSettings;

	return !!useThreshold;
};

export const selectUseShadow = (stateApp = EMPTY_OBJECT, { chartId } = {}) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { useShadow } = chartSettings;

	return !!useShadow;
};

export const selectUseSawtooth = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { useSawtooth } = chartSettings;

	return !!useSawtooth;
};

export const selectChartPrimarySurveyId = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { primarySurveyId } = chartSettings;

	if (!primarySurveyId) {
		// todo - need fallback functionality here for when chart settings is not set from backend - and primarySurveyId is not stored in settings.
		// debugger;
	}

	return primarySurveyId;
};

export const selectChartPrimarySurvey = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const primarySurveyId = selectChartPrimarySurveyId(stateApp, { chartId });
	return selectSurvey(stateApp, primarySurveyId);
};

export const selectChartDepolSurveyId = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { depolSurveyId } = chartSettings;

	return depolSurveyId;
};

export const selectChartDepolSurvey = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const depolSurveyId = selectChartDepolSurveyId(stateApp, { chartId });
	return selectSurvey(stateApp, depolSurveyId);
};

export const selectUseInterpolatedDepol = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { useInterpolatedDepol } = chartSettings;

	return useInterpolatedDepol;
};

export const selectUseNormalizedDepol = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { useNormalizedDepol } = chartSettings;

	return useNormalizedDepol;
};

// @deprecated - this is required due to previous arch combined with readings table modal implementation being inline in Map.jsx
export const selectUseNormalizedDepolMap = createSelector(
	selectChartsSettings,
	chartsSettings => {
		const chartIds = Object.keys(chartsSettings);
		const useNormalizedDepolMap = chartIds.reduce((acc, chartId) => {
			const chartSettings = chartsSettings[chartId];
			const { primarySurveyId, useNormalizedDepol } = chartSettings;
			acc[primarySurveyId] = useNormalizedDepol;
			return acc;
		}, {});
		return useNormalizedDepolMap;
	}
);

// @deprecated - this is required due to previous arch combined with readings table modal implementation being inline in Map.jsx
export const selectUseInterpolatedDepolMap = createSelector(
	selectChartsSettings,
	chartsSettings => {
		const chartIds = Object.keys(chartsSettings);
		const useInterpolatedDepolMap = chartIds.reduce((acc, chartId) => {
			const chartSettings = chartsSettings[chartId];
			const { primarySurveyId, useInterpolatedDepol } = chartSettings;
			acc[primarySurveyId] = useInterpolatedDepol;
			return acc;
		}, {});
		return useInterpolatedDepolMap;
	}
);

export const selectSelectedRemediationGroup = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { selectedRemediationGroup } = chartSettings;

	return selectedRemediationGroup;
};

export const selectSelectedRemediationGroupIndex = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { selectedRemediationGroupIndex } = chartSettings;

	return selectedRemediationGroupIndex;
};

export const selectInputThresholdResetToken = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { inputThresholdResetToken } = chartSettings;
	return inputThresholdResetToken;
};

export const selectSeriesVisibilityResetToken = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { seriesVisibilityResetToken } = chartSettings;
	return seriesVisibilityResetToken;
};

export const selectSeriesVisibilityDataStoreForChart = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { seriesVisibility } = chartSettings;
	return seriesVisibility;
};

export const selectSeriesVisibility = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {},
	{
		dataSetKey, // could be a survey id - could be the name of a series
		propKey // name of property which is plotted on chart
	}
) => {
	const seriesVisibilityDataStore = selectSeriesVisibilityDataStoreForChart(
		stateApp,
		{ chartId }
	);

	const isVisible = ((seriesVisibilityDataStore || {})[dataSetKey] || {})[
		propKey
	];

	return isDefined(isVisible) ? isVisible : true; // for now assume is visible
};

export const selectIsVisible = (stateApp = EMPTY_OBJECT, { chartId } = {}) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { isVisible } = chartSettings;

	return isVisible;
};

export const selectBelowCriterionComparator = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { belowCriterionComparator } = chartSettings;
	return belowCriterionComparator;
};

export const selectShowConfirmation = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { showConfirmation } = chartSettings;

	return showConfirmation;
};

export const selectConfirmationMessageKey = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { confirmationMessageKey } = chartSettings;

	return confirmationMessageKey;
};

// eslint-disable-next-line no-unused-vars
export const selectBoundaryXYMinMax = (
	stateApp = EMPTY_OBJECT,
	{ chartId, surveyId } = {}
) => {
	const chartSettings = selectChartSettings(stateApp, { chartId });
	const { boundaryXYMinMax = {} } = chartSettings;

	if (
		isDefined(boundaryXYMinMax.yMin) ||
		isDefined(boundaryXYMinMax.yMax) ||
		isDefined(boundaryXYMinMax.xMin) ||
		isDefined(boundaryXYMinMax.xMax)
	) {
		return boundaryXYMinMax;
	}

	// use ymin/ymax from dataset as fallback
	const { xMin, xMax, yMin, yMax } =
		selectDataXYMinMax(stateApp, surveyId) || {};
	return { xMin, xMax, yMin, yMax };
};

export default {
	selectChartSettings,
	selectUseThreshold,
	selectUseShadow,
	selectThresholds,
	selectUseSawtooth,
	selectChartPrimarySurveyId,
	selectChartPrimarySurvey,
	selectChartDepolSurveyId,
	selectChartDepolSurvey,
	selectUseNormalizedDepol,
	selectUseInterpolatedDepol,
	selectSeriesVisibilityDataStoreForChart,
	selectSeriesVisibility,
	selectIsVisible,
	selectUseNormalizedDepolMap,
	selectUseInterpolatedDepolMap,
	selectShowConfirmation,
	selectBoundaryXYMinMax
};
