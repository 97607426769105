/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../scanline/utils/token';

export const getCustomersApplications = customerId => {
	const url = `/customers/applications/${customerId}`;

	if (hasAccessToken()) {
		const fetchApplicationDefinitions = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetchApplicationDefinitions();
	}
	return Promise.reject(new Error('Missing token'));
};

export const insertCustomersApplication = (customerId, applicationId) => {
	const url = `/customers/applications/${customerId}/${applicationId}`;

	if (hasAccessToken()) {
		const fetchApplicationDefinitions = createPromiseRequest({
			url,
			method: 'PUT'
		});

		return fetchApplicationDefinitions();
	}
	return Promise.reject(new Error('Missing token'));
};

export const deleteCustomersApplication = (customerId, applicationId) => {
	const url = `/customers/applications/${customerId}/${applicationId}`;

	if (hasAccessToken()) {
		const fetchApplicationDefinitions = createPromiseRequest({
			url,
			method: 'DELETE'
		});

		return fetchApplicationDefinitions();
	}
	return Promise.reject(new Error('Missing token'));
};
