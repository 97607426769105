import ajax from '../../../../scanline/utils/ajax';

export const getAsset = (template, name, type = 'chart') => {
	return ajax(
		`/asset/history/${template}/${encodeURIComponent(name)}/${type}`,
		undefined,
		'GET',
		undefined,
		false,
		'assetview'
	);
};

export const getGroup = (template, name) => {
	return ajax(
		`/group/history/${template}/${encodeURIComponent(name)}`,
		undefined,
		'POST',
		undefined,
		false,
		'assetview'
	);
};

export const getAPI = type => {
	switch (type) {
		case 'asset':
			return getAsset;
		case 'group':
			return getGroup;
		default:
			return null;
	}
};
