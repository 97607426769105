/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../scanline/utils/token';

export const getCustomers = () => {
	const url = '/customers';

	if (hasAccessToken()) {
		const fetchData = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetchData();
	}
	return Promise.reject(new Error('Missing Token'));
};

export const updateCustomer = ({ customer } = {}) => {
	if (!customer || !customer.id) {
		return Promise.reject(
			new Error(
				'Invalid customer object passed to api/customers .updateCustomer()'
			)
		);
	}

	const postAppInvite = createPromiseRequest({
		url: '/customers',
		method: 'POST',
		data: customer
	});

	return postAppInvite();
};

export const deleteCustomer = ({ customer } = {}) => {
	if (!customer || !customer.id) {
		return Promise.reject(
			new Error(
				'Invalid customer object passed to api/customers .deleteCustomer()'
			)
		);
	}

	const postAppInvite = createPromiseRequest({
		url: '/customers',
		method: 'DELETE',
		data: customer
	});

	return postAppInvite();
};

export const insertCustomer = ({ customer } = {}) => {
	if (!customer || !customer.name) {
		return Promise.reject(
			new Error(
				'Invalid customer object passed to api/customers .updateCustomer()'
			)
		);
	}

	const postAppInvite = createPromiseRequest({
		url: '/customers',
		method: 'PUT',
		data: customer
	});

	return postAppInvite();
};
