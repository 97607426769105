/* eslint-disable no-param-reassign */
import {
	config,
	calculateDistance
} from 'aegion_common_utilities/lib/MapProcessingUtil';
import { formatStationId } from '../../stationId';

/** ******************
 * HELPERS
 ******************* */

export const assumeIsInMV = val => {
	return !val || (val && Math.abs(val) >= 10);
};

export const computeShadow = r => {
	if (r.on) {
		r.sh = r.on - 100;
	}
};

/** ******************
 * SPATIAL HELPERS
 ******************* */

const matchRectifierFromComment = comment => {
	const match = /RECTIFIER/gi.exec(comment);
	return match && match.length && match[0];
};

const matchAerialFromComment = comment => {
	const match = /AERIAL/gi.exec(comment);
	return match && match.length && match[0];
};

const matchValveFromComment = comment => {
	const match = /VALVE/gi.exec(comment);
	return match && match.length && match[0];
};

const matchTestStationFromComment = comment => {
	const match = /TEST/gi.exec(comment);
	return match && match.length && match[0];
};

const matchLineMarkerFromComment = comment => {
	const match = /LINE MARKER/gi.exec(comment);
	return match && match.length && match[0];
};

const matchForeignLineFromComment = comment => {
	const otherRegexComments = [
		/POWER LINE/gi,
		/RIVER/gi,
		/LINE CROSSING?/gi // Foreign Pipeline Crossing
	];
	const match = otherRegexComments.some(re => re.exec(comment));
	return match;
};

const SPATIAL_CHART_Y_VALUES = {
	LINE_MARKERS: -1,
	FOREIGN_LINE_CROSSING: 10,
	RECTIFIER: 20,
	AERIAL: 30,
	VALVES: 40,
	TEST_STATIONS: 50
};

/** ******************
 * MUTATORS
 ******************* */

export const computeMvTransform = (r, key) => {
	if (r && key && !assumeIsInMV(r[key])) {
		r[key] *= 1000;
	}
};

export const computeUuid = (r, idx) => {
	r.uuid = `${r.id}~${idx}`;
};

// TODO: Delete
global.count = 0;

export const computeDistance = (prevReading, currReading) => {
	if (!prevReading) {
		currReading.dst = 0.0;
	} else {
		currReading.dst = calculateDistance(prevReading.loc, currReading.loc, {
			algorithm: config.GEO_ALGORITHMS.TURF,
			roundTo: 2
		});
	}
};

export const computeFormattedStationId = reading => {
	reading.formattedStationId = formatStationId(reading.id);
};

export const computePipeDepth = (reading = {}) => {
	const { cmt } = reading;

	if (cmt) {
		const match = /PIPE ?DEPTH=\d+(\.\d+)?(?=(\s|$))/gi.exec(cmt);
		const foundPipeDepth = match && match.length;

		if (foundPipeDepth) {
			const depth = parseFloat(match[0].split('=')[1], 10);
			const isValidDepth = !Number.isNaN(depth);

			if (isValidDepth) {
				const formattedDepth = +depth.toFixed(2);
				reading.topDep = formattedDepth;
				reading.elcDep = formattedDepth;

				// @depricated
				reading.doc = formattedDepth;
			}
		}
	}
};

export const computeAC = (reading = {}) => {
	const WARNING_THRESHOLD = 2;
	const PROBLEM_THRESHOLD = 15;
	const reAC = /(?<=(^|\s)\(?acv?(:|\/)\s*)\d(\.(?=\d))?\d*(?=v\)?($|\s))/i;
	const { cmt } = reading;

	if (cmt && reAC.test(cmt)) {
		const strAC = cmt.match(reAC)[0];
		const ac = Number.parseFloat(strAC, 10);

		if (!Number.isNaN(ac)) {
			const val = +ac.toFixed(4);
			reading.ac = val;

			if (val >= PROBLEM_THRESHOLD) {
				reading.acProblem = val;
			} else if (val >= WARNING_THRESHOLD) {
				reading.acWarning = val;
			} else {
				reading.acPure = val;
			}
		}
	}
};

export const computeSpatial = (reading = {}) => {
	const { cmt } = reading;

	if (cmt) {
		// Rectifier
		const rectifier = matchRectifierFromComment(cmt);
		if (rectifier) {
			reading.rectifier = SPATIAL_CHART_Y_VALUES.RECTIFIER;
			reading.isSpatial = true;
		}

		// Aerial
		const aerial = matchAerialFromComment(cmt);
		if (aerial) {
			reading.aerial = SPATIAL_CHART_Y_VALUES.AERIAL;
			reading.isSpatial = true;
		}

		// Valve
		const valve = matchValveFromComment(cmt);
		if (valve) {
			reading.valve = SPATIAL_CHART_Y_VALUES.VALVES;
			reading.isSpatial = true;
		}

		// Test Station
		const testStation = matchTestStationFromComment(cmt);
		if (testStation) {
			reading.testStation = SPATIAL_CHART_Y_VALUES.TEST_STATIONS;
			reading.isSpatial = true;
		}

		// Line Marker
		const lineMarker = matchLineMarkerFromComment(cmt);
		if (lineMarker) {
			reading.lineMarker = SPATIAL_CHART_Y_VALUES.LINE_MARKERS;
			reading.isSpatial = true;
		}

		// Other Spatial Data
		const foreignLineCrossing = matchForeignLineFromComment(cmt);
		if (foreignLineCrossing) {
			reading.foreignLineCrossing =
				SPATIAL_CHART_Y_VALUES.FOREIGN_LINE_CROSSING;
			reading.isSpatial = true;
		}
	}
};

export const defaultComputeProps = (
	survey,
	_prevReading,
	currReading,
	currIdx
) => {
	computeUuid(currReading, currIdx);
	computeFormattedStationId(currReading);
};
